<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addEmployee()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"AddEmployee" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="md-form">
            <input mdbInput id="firstName" name="firstName" type="text" class="form-control" [(ngModel)]="employee.firstName" required="required" />
            <label for="firstName">{{"FirstName" | translate}}</label>
          </div>
          <div class="md-form">
            <input mdbInput id="lastName" name="lastName" type="text" class="form-control" [(ngModel)]="employee.lastName" required="required" />
            <label for="lastName">{{"LastName" | translate}}</label>
          </div>
          <div class="form-group">
            <mdb-select name="roles" [options]="roles" [placeholder]="'ChooseRole' | translate" [(ngModel)]="employee.role"></mdb-select>
          </div>
          <div class="md-form">
            <input mdbInput type="text" id="userName" name="userName" class="form-control" [(ngModel)]="employee.userName" required="required" style="text-transform: lowercase;" autocorrect="off" autocomplete="off" autocapitalize="off" spellcheck="false"/>
            <label for="userName">{{"UserName" | translate}}</label>
          </div>
          <div class="md-form">
            <input mdbInput type="password" id="password" name="password" class="form-control" [(ngModel)]="employee.userPasswordHash" (ngModelChange)="passwordUpdated()" (focus)="emptyPassword()" (blur)="resetPassword()" required="required" />
            <label for="password">{{"Password" | translate}}</label>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" data-dismiss="modal">
            {{(employee.userId ? "Save" : "Add") | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
