import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-order-without-refresh',
  templateUrl: './self-order-without-refresh.component.html',
  styleUrls: ['./self-order-without-refresh.component.scss']
})
export class SelfOrderWithoutRefreshComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
