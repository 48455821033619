<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalSelfOrder" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">{{"QRCodeForUser" | translate}} {{employee.firstName}} {{employee.lastName}}</p>
      </div>
      <div class="modal-body">
        <div style="min-height:350px;">
          <div class="qrcode-container row m-0">
            <div id="qrcode-image" class="qrcode-image">
              <div id='css-loader' class='css-loader css-loader-big ml-auto mr-auto' style="margin-top: 95px;" *ngIf="showLoading"></div>
              <a id='download-link' [attr.download]="employee.userId + '.png'"></a>
              <img id='qr-code' alt='QR-Code' [attr.src]="qrCodeSrc" (load)="onLoadQrCode()"  [hidden]="showLoading"/>
              <a class="short-url" href="#" id="shortUrl" [href]="qrCodeShortUrl" target="_blank">{{qrCodeShortUrl}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
          {{"Close" | translate}}
        </a>

        <a type="button" mdbBtn color="primary" rounded="true" (click)="downloadQrCode()">
          <i class="fas fa-download mr-1" style="color:inherit"></i>
          <span>{{"Download" | translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
