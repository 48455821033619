<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right products-modal" id="tableCatModal" role="dialog" [style.z-index]="zIndex+1">
    <div class="modal-dialog modal-notify modal-full-height modal-right modal-info w-100 mw-100 m-0" role="document">
        <div class="modal-content" style="min-height:500px;">
            <div class="modal-header p-0 d-flex justify-content-between align-items-center">
                <button mdbBtn type="button" flat="true" class="text-white pl-2 d-flex align-items-center" mdbWavesEffect (click)="hide()">
          <mdb-icon fas icon="chevron-left" size="2x" class="mr-1"></mdb-icon>
          <span class="text-white" style="font-size: 20px; font-weight: 500">{{"Back" | translate}}</span>
        </button>
                <h4 class="modal-title white-text mr-2">{{category.productCategoryName}} ({{products.length}})</h4>
            </div>

            <div class="modal-body p-0" style="max-height: calc(100vh - 65px);height: calc(100vh - 65px);">
                <div id="tableContainer">
                    <div class="card flex-row justify-content-between mb-3 action-toolbar">
                        <div class="action-toolbar p-0 m-0">
                            <button class="btn btn-outline-primary btn-rounded background-white toolbar-item" (click)="showAddProductDialog()">
                <i class="fa fa-plus"></i>&nbsp;
                <span>{{"AddProduct" | translate}}</span>
              </button>
                            <button class="btn btn-outline-primary btn-rounded background-white toolbar-item" (click)="showImportProductsDialog()">
                <i class="fa fa-upload"></i>&nbsp;
                <span>{{"ImportProducts" | translate}}</span>
              </button>
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="btn-group" data-toggle="buttons" style="margin:10px;">
                                <label class="btn btn-info btn-rounded active waves-effect waves-light" [class.active]="listType != 'list'" (click)="listType=''">
                  <input class="form-check-input" type="radio" checked autocomplete="off"/>
                  <span class="fa fa-th-large white-text"></span>
                </label>
                                <label class="btn btn-info btn-rounded waves-effect waves-light" [class.active]="listType == 'list'" (click)="listType='list'">
                  <input class="form-check-input" type="radio" autocomplete="off"/>
                  <span class="fa fa-list white-text"></span>
                </label>
                            </div>
                        </div>
                    </div>

                    <div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="products.length == 0">
                        <img src="/assets/images/dinner.svg" style="width: 200px;" />
                        <div class="text-info informative-text m-3" role="alert">{{"NoProduct" | translate}}</div>
                    </div>

                    <div style="display: flex" [class.product-list]="listType == 'list'">
                        <div id="productList" class="row" style="margin: 0;text-align:center;">
                            <app-single-product *ngFor="let product of products" class="col-lg-4 col-md-6 col-xs-12 p-0" [product]="product" [category]="category" [allProducts]="products"></app-single-product>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-import-products-dialog></app-import-products-dialog>