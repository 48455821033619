import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, findIndex} from "lodash";
import {TabsetComponent} from "ng-uikit-pro-standard";
import {ConnectionService} from "ngx-connection-service";
import {Subscription} from "rxjs";
import {Table, TableCategory} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {SignalRService} from "../../../services/signalr.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {TableService} from "../../../services/table.service";

@Component({
  selector: "app-table-category-list",
  templateUrl: "./table-category-list.component.html",
  styleUrls: ["./table-category-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TableCategoryListComponent implements OnInit, OnDestroy {
  @ViewChild("tableTabs") public tableTabs: TabsetComponent;

  public tableCategories: TableCategory[] = [];
  public tables: Table[] = [];
  public activeTableId: string;
  public groupedTables: any = {};
  public loadingTables = true;
  public activeCategoryId: string;

  private splittedTableDeleteSubscription: Subscription;
  private tableSplittedSubscription: Subscription;
  private tableUpdatedSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private signalRService: SignalRService,
    private tableService: TableService,
    private connectionService: ConnectionService) {
  }

  public ngOnInit() {
    this.translateService.get("TablesWithoutCategories").subscribe(async () => {
      this.loadTables();
    });

    this.tableSplittedSubscription = this.subscriptionService.tableSplittedEvent.subscribe(async (table: Table) => {
      this.tables = await this.localStorage.getTablesAsync();

      const tablesArrs = Object.values(this.groupedTables);
      tablesArrs.forEach((arr: Table[]) => {
        const index = findIndex(arr, {id: table.splittedFrom});
        if (index > -1) {
          arr.splice(index + 1, 0, table);
          this.activeCategoryId = table.categoryId;
          return;
        }
      });
    });

    this.splittedTableDeleteSubscription = this.subscriptionService.deleteSplittedTableEvent.subscribe((tableId) => {
      const tablesArrs = Object.values(this.groupedTables);
      tablesArrs.forEach((arr: Table[]) => {
        const index = findIndex(arr, {id: tableId});
        if (index > -1) {
          const removedTables = arr.splice(index, 1);
          if(removedTables.length > 0)
          this.activeCategoryId = removedTables[0].categoryId;
          return;
        }
      });
    });

    this.tableUpdatedSubscription = this.signalRService.tableUpdated.subscribe(async () => {
      const activeTabIndex = this.tableTabs?.getActive() || 0;
      await this.initTables();
      setTimeout(() => this.tableTabs?.setActiveTab(activeTabIndex + 1), 50);
    });
  }

  public groupTables(): any {
    const result = {};

    this.tableCategories.forEach((category) => {
      result[category.id] = filter(this.tables, {categoryId: category.id});
    });

    const tablesWoCats = filter(this.tables, {categoryId: null});
    if (tablesWoCats?.length > 0) {
      const nullId = null;
      result[nullId] = tablesWoCats;
    }

    return result;
  }

  public ngOnDestroy(): void {
    this.tableSplittedSubscription?.unsubscribe();
    this.splittedTableDeleteSubscription?.unsubscribe();
    this.tableUpdatedSubscription?.unsubscribe();
  }

  private async initTables(): Promise<any> {
    this.tables = await this.localStorage.getTablesAsync();
    this.tableCategories = await this.localStorage.getTableCategoriesAsync();

    const tablesWoCats = find(this.tables, {categoryId: null});
    const length = this.tableCategories.filter((c) => c.id == null).length;
    if (tablesWoCats && length === 0) {
      this.tableCategories.unshift({id: null, name: this.translateService.instant("TablesWithoutCategories")});
    }

    this.groupedTables = this.groupTables();

    if(!this.activeCategoryId)
      this.activeCategoryId = Object.keys(this.groupedTables)[0];
  }

  private loadTables() {
    this.tableService.getAllTablesAndCategories().subscribe(async (result) => {
      result.tableCategories = result.tableCategories.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: "base"}));

      this.localStorage.setTableCategories(result.tableCategories);
      this.localStorage.setTables(result.tables);

      await this.initTables();
      this.loadingTables = false;
    }, async () => {
      await this.initTables();
    });
  }
}
