import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {BadgeModule, ButtonsModule, CardsModule, CarouselModule, ChipsModule, DropdownModule, IconsModule, InputsModule, MdbSelectModule, ModalModule, PopoverModule, SelectModule, TabsModule, WavesModule} from "ng-uikit-pro-standard";
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {DROPZONE_CONFIG} from "ngx-dropzone-wrapper";
import {DropzoneConfigInterface} from "ngx-dropzone-wrapper";
import {AppCommonModule} from "../../common/common.module";
import {ProductCategoryListComponent} from "./category-list/product-category-list.component";
import {ProductCategoryComponent} from "./category/product-category.component";
import {CreateCategoryDialogComponent} from "./create-category-dialog/create-category-dialog.component";
import {CreateProductDialogComponent} from "./create-product-dialog/create-product-dialog.component";
import {ImportCategoriesDialogComponent} from "./import-categories-dialog/import-categories-dialog.component";
import {ImportProductsDialogComponent} from "./import-products-dialog/import-products-dialog.component";
import {ProductRoutingModule} from "./products-routing.module";
import {ProductsComponent} from "./products.component";
import {ShowProductListDialogComponent} from "./show-product-list-dialog/show-product-list-dialog.component";
import {SingleProductComponent} from "./single-product/single-product.component";
import { PublishMenuDialogComponent } from "./publish-menu-dialog/publish-menu-dialog.component";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "/api/v1/product/addfile/",
  maxFilesize: 50,
  acceptedFiles: "image/*",
};

// @ts-ignore
@NgModule({
  declarations: [
    ProductsComponent,
    ProductCategoryComponent,
    ProductCategoryListComponent,
    CreateCategoryDialogComponent,
    ShowProductListDialogComponent,
    SingleProductComponent,
    CreateProductDialogComponent,
    ImportProductsDialogComponent,
    ImportCategoriesDialogComponent,
    PublishMenuDialogComponent

  ],
    imports: [
        CommonModule,
        AppCommonModule,
        ProductRoutingModule,
        FormsModule,
        ButtonsModule.forRoot(),
        WavesModule,
        SelectModule,
        DropdownModule.forRoot(),
        ModalModule.forRoot(),
        InputsModule.forRoot(),
        TabsModule.forRoot(),
        CarouselModule.forRoot(),
        DropzoneModule,
        ChipsModule,
        BadgeModule,
        PopoverModule,
        CardsModule,
        IconsModule,
        MdbSelectModule
    ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
})
export class ProductsModule {
}
