<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-danger discount-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{"Discount" | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class='discount-container d-flex mb-4'>
          <div class="md-form mb-0 mt-0 mr-2">
            <input mdbInput [(ngModel)]="amount" type='number' min='0' id="discountAmount" class='form-control discount-amount mt-0' />
            <label for="discountAmount" class="">{{"Discount" | translate}}</label>
          </div>
          <mdb-select [options]="discountOptions"
                      [(ngModel)]="discountType"
                      class="w-100  colorful-select dropdown-info"></mdb-select>
        </div>
        <div class="d-flex">
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 5 && discountType === currencySymbol" [class.btn-outline-info]="amount !== 5 || discountType !== currencySymbol" [value]="'5' + currencySymbol" (click)="setDiscount(5, currencySymbol)"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 10 && discountType === currencySymbol" [class.btn-outline-info]="amount !== 10 || discountType !== currencySymbol" [value]="'10' + currencySymbol" (click)="setDiscount(10, currencySymbol)"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 20 && discountType === currencySymbol" [class.btn-outline-info]="amount !== 20 || discountType !== currencySymbol" [value]="'20' + currencySymbol" (click)="setDiscount(20, currencySymbol)"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 50 && discountType === currencySymbol" [class.btn-outline-info]="amount !== 50 || discountType !== currencySymbol" [value]="'50' + currencySymbol" (click)="setDiscount(50, currencySymbol)"/>
        </div>

        <div class="d-flex">
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 1 && discountType === '%'" [class.btn-outline-info]="amount !== 1 || discountType !== '%'" value="1%" (click)="setDiscount(1, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 2 && discountType === '%'" [class.btn-outline-info]="amount !== 2 || discountType !== '%'" value="2%" (click)="setDiscount(2, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 5 && discountType === '%'" [class.btn-outline-info]="amount !== 5 || discountType !== '%'" value="5%" (click)="setDiscount(5, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 10 && discountType === '%'" [class.btn-outline-info]="amount !== 10 || discountType !== '%'" value="10%" (click)="setDiscount(10, '%')"/>
        </div>

        <div class="d-flex">
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 20 && discountType === '%'" [class.btn-outline-info]="amount !== 20 || discountType !== '%'" value="20%" (click)="setDiscount(20, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 25 && discountType === '%'" [class.btn-outline-info]="amount !== 25 || discountType !== '%'" value="25%" (click)="setDiscount(25, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 50 && discountType === '%'" [class.btn-outline-info]="amount !== 50 || discountType !== '%'" value="50%" (click)="setDiscount(50, '%')"/>
          <input type="button" class="btn btn-sm btn-discount" [class.btn-info]="amount == 75 && discountType === '%'" [class.btn-outline-info]="amount !== 75 || discountType !== '%'" value="75%" (click)="setDiscount(75, '%')"/>
        </div>
      </div>
      <div class="modal-footer">
        <button mdbBtn color="danger" rounded="true"
                (click)="applyDiscount()">
          <i class="fas fa-check pr-1"></i>
          <span>{{"Apply" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
