import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from "@angular/platform-browser";

import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";
import {AuthGuard} from "./guards/AuthGuard";

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BlockUIModule} from "ng-block-ui";
import {ButtonsModule, CheckboxModule, IconsModule, ModalModule, PreloadersModule, ToastModule} from "ng-uikit-pro-standard";
import { MDBSpinningPreloader } from "ng-uikit-pro-standard";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../environments/environment";
import {AppRouting} from "./app-routing.module";
import {BlockTemplateComponent} from "./components/block/block.template.component";
import {ConfirmDialogComponent} from "./components/confirm-dialog/confirm-dialog.component";
import { CookieLawBannerComponent } from "./components/cookie-law-banner/cookie-law-banner.component";
import { InlineEditorComponent } from "./components/inline-editor/inline-editor.component";
import { NotificationsDialogComponent } from "./components/notifications-dialog/notifications-dialog.component";
import {YoutubeDialogComponent} from "./components/youtube-dialog/youtube-dialog.component";
import {AdminGuard} from "./guards/AdminGuard";
import {AdminHelperGuard} from "./guards/AdminHelperGuard";
import {CookBarmanGuard} from "./guards/CookBarmanGuard";
import {WaiterGuard} from "./guards/WaiterGuard";
import {ErrorInterceptor} from "./helpers/error.interceptor";
import {JwtInterceptor} from "./helpers/jwt.interceptor";
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from "./helpers/timeout.interceptor";
import {AccessDeniedModule} from "./modules/access-denied/access-denied.module";
import {AuthModule} from "./modules/auth/auth.module";
import {AppCommonModule} from "./modules/common/common.module";
import {DaterangePickerLocaleService, LOCALE_CONFIG, NgxDaterangepickerMd} from "./modules/daterangepicker";
import {HomeModule} from "./modules/home/home.module";
import {KdsModule} from "./modules/kds/kds.module";
import {CustomerModule} from "./modules/master-data/customer/customer.module";
import {EmployeesModule} from "./modules/master-data/employees/employees.module";
import {ProductsModule} from "./modules/master-data/products/products.module";
import { TableModule } from "./modules/master-data/table/table.module";
import { MenuModule } from "./modules/menu/menu.module";
import {PosDeliveryModule} from "./modules/pos-delivery/pos-delivery.module";
import {PosRetailModule} from "./modules/pos-retail/pos-retail.module";
import {MenuCardComponent} from "./modules/pos/menu-card/menu-card.component";
import {PosModule} from "./modules/pos/pos.module";
import { SelfOrderApproveModule } from "./modules/self-order-approve/self-order-approve.module";
import {SelfOrderModule} from "./modules/self-order/self-order.module";
import {SettingsModule} from "./modules/settings/settings.module";
import { MyHammerConfig } from "./myhammer.config";
import { NGFORAGE_CONFIG_PROVIDER } from "./ngforage.config";
import {LocalizationService} from "./services/localization.service";
import {LocalStorageService} from "./services/localstorage.service";
import {ConnectionServiceModule, ConnectionServiceOptionsToken, ConnectionServiceOptions} from 'ngx-connection-service';
import { UiSwitchModule } from "ngx-ui-switch";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { BaseUrlInterceptor } from "./helpers/baseurl.interceptor";

function initializeApp(): Promise<any> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(resolve, 2000);
  });
}

const tooltipVariation = {
  theme: null,
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
};

@NgModule({
    declarations: [
        AppComponent,
        BlockTemplateComponent,
        ConfirmDialogComponent,
        YoutubeDialogComponent,
        CookieLawBannerComponent,
        NotificationsDialogComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        ButtonsModule,
        PreloadersModule,
        ToastModule.forRoot(),
        ModalModule.forRoot(),
        IconsModule,
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: httpTranslateLoader, deps: [HttpClient] } }),
        BlockUIModule.forRoot({ template: BlockTemplateComponent }),
        NgxStripeModule.forChild(environment.stripeKey),
        ConnectionServiceModule,
        FormsModule,
        AppRouting,
        PosModule,
        PosRetailModule,
        PosDeliveryModule,
        AuthModule,
        HomeModule,
        KdsModule,
        MenuModule,
        TableModule,
        ProductsModule,
        CustomerModule,
        EmployeesModule,
        SettingsModule,
        AppCommonModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        AccessDeniedModule,
        CheckboxModule,
        SelfOrderModule,
        SelfOrderApproveModule,
        UiSwitchModule,
    ],
    exports: [
        TranslateModule,
        IconsModule,
        InlineEditorComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
        [{ provide: DEFAULT_TIMEOUT, useValue: 10000 }],
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        {
            provide: ConnectionServiceOptionsToken,
            useValue: <ConnectionServiceOptions>{
                heartbeatUrl: '/assets/images/favicon.png',
                heartbeatInterval: 3000
            }
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true
        },
        {
          provide: "BASE_API_URL", useValue: environment.baseUrl
        },
        /*{
            provide: APP_INITIALIZER,
            useFactory: () => initializeApp,
            multi: true
        },*/
        AuthGuard, CookBarmanGuard, WaiterGuard, AdminGuard, AdminHelperGuard,
        MDBSpinningPreloader,
        NGFORAGE_CONFIG_PROVIDER,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private localStorageService: LocalStorageService,
    private localeService: LocalizationService) {

    this.migrateToIndexedDb();
    this.localeService.setInitialCulture();
    this.localStorageService.getUserAsync().then((user) => {
      if (user) {
        localStorage.setItem("currencySymbol", user.currencySymbol);
      }
    });
  }

  private migrateToIndexedDb() {
    if (!localStorage.getItem("user")) {
      return;
    }

    this.localStorageService.setProductCategories(JSON.parse(localStorage.getItem("categories")));
    localStorage.removeItem("categories");
    this.localStorageService.setProductCategoryPictures(JSON.parse(localStorage.getItem("category-pictures")));
    localStorage.removeItem("category-pictures");
    this.localStorageService.setCustomers(JSON.parse(localStorage.getItem("customers")));
    localStorage.removeItem("customers");
    this.localStorageService.setDayEndStatistic(JSON.parse(localStorage.getItem("day-end-stats")));
    localStorage.removeItem("day-end-stats");
    this.localStorageService.setEmployees(JSON.parse(localStorage.getItem("employees")));
    localStorage.removeItem("employees");
    this.localStorageService.setFavoriteProducts(JSON.parse(localStorage.getItem("favorite-products")));
    localStorage.removeItem("favorite-products");
    this.localStorageService.setLastSoldProductIds(JSON.parse(localStorage.getItem("last-sold-product-ids")));
    localStorage.removeItem("last-sold-product-ids");
    this.localStorageService.setMostSoldProductIds(JSON.parse(localStorage.getItem("most-sold-product-ids")));
    localStorage.removeItem("most-sold-product-ids");
    this.localStorageService.setNotifications(JSON.parse(localStorage.getItem("notifications")));
    localStorage.removeItem("notifications");
    this.localStorageService.setOrderedItems(JSON.parse(localStorage.getItem("ordered-items")));
    localStorage.removeItem("ordered-items");
    this.localStorageService.setPackagesInfo(JSON.parse(localStorage.getItem("packages-info")));
    localStorage.removeItem("packages-info");
    this.localStorageService.setPrinterSettings(JSON.parse(localStorage.getItem("printer-settings")));
    localStorage.removeItem("printer-settings");
    this.localStorageService.setProductPictures(JSON.parse(localStorage.getItem("product-pictures")));
    localStorage.removeItem("product-pictures");
    this.localStorageService.setProducts(JSON.parse(localStorage.getItem("products")));
    localStorage.removeItem("products");
    this.localStorageService.setReceiptSettings(JSON.parse(localStorage.getItem("receipt-settings")));
    localStorage.removeItem("receipt-settings");
    this.localStorageService.setTableCategories(JSON.parse(localStorage.getItem("table-categories")));
    localStorage.removeItem("table-categories");
    this.localStorageService.setTables(JSON.parse(localStorage.getItem("tables")));
    localStorage.removeItem("tables");
    this.localStorageService.setValue("jwt_token", localStorage.getItem("jwt_token"));
    localStorage.removeItem("jwt_token");
    this.localStorageService.setValue("token_expiration", localStorage.getItem("token_expiration"));
    localStorage.removeItem("token_expiration");
    this.localStorageService.setUser(JSON.parse(localStorage.getItem("user")));
    localStorage.removeItem("user");
    this.localStorageService.setUserSettings(JSON.parse(localStorage.getItem("user-settings")));
    localStorage.removeItem("user-settings");

    const visibleCategories = localStorage.getItem("visibleCategories");
    if (visibleCategories) {
      this.localStorageService.setValue("visibleCategories", JSON.parse(visibleCategories));
      localStorage.removeItem("visibleCategories");
    }

    const user: any = this.localStorageService.getUserSync();
    localStorage.setItem("customerId", user.partitionKey);
  }

  private compareVersion(a, b) {
    if (a === b) {
      return 0;
    }

    const a_components = a.split(".");
    const b_components = b.split(".");

    const len = Math.min(a_components.length, b_components.length);

    // loop while the components are equal
    for (let i = 0; i < len; i++) {
      // A bigger than B
      if (parseInt(a_components[i]) > parseInt(b_components[i])) {
        return 1;
      }

      // B bigger than A
      if (parseInt(a_components[i]) < parseInt(b_components[i])) {
        return -1;
      }
    }

    // If one's a prefix of the other, the longer one is greater.
    if (a_components.length > b_components.length) {
      return 1;
    }

    if (a_components.length < b_components.length) {
      return -1;
    }

    // Otherwise they are the same.
    return 0;
  }
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
