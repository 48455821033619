<div style="margin-bottom: 10px;padding: 10px 20px 10px 10px;flex-direction:row;border-radius: 0;" class="card d-flex action-toolbar">
  <a mdbBtn color="info" outline="true" rounded="true" mdbWavesEffect class="btn-employee-action background-white" (click)="showAddEmployee()">
    <i class="fa fa-plus" style="font-size: 16px;"></i>
    <span>{{"AddEmployee" | translate}}</span>
  </a>

  <a mdbBtn color="info" outline="true" rounded="true" mdbWavesEffect class="btn-employee-action background-white" (click)="showSendEmployeesMessage()">
    <i class="far fa-comments" style="font-size: 16px;"></i>
    <span>{{"SendEmployeesMessage" | translate}}</span>
  </a>
</div>

<div class="row" style="margin: 0px;">
  <app-single-employee *ngFor="let employee of employees" [employee]="employee" [employeeList]="employees"
    class="d-block col-lg-4 col-md-6 col-sm-12"></app-single-employee>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="employees.length == 0">
  <img src="/images/team.svg" style="width: 250px;" />
  <div class="text-info informative-text m-3" role="alert">{{"NoEmployee" | translate}}</div>
</div>

<app-create-employee-dialog></app-create-employee-dialog>
<app-message-dialog></app-message-dialog>
<app-qr-code-dialog></app-qr-code-dialog>
