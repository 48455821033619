<div id="orderListContainer" style="position: absolute;top: 0px; bottom:0px; width: 100%;background:#efefef;">
  <div id="orderContainer" class="order-container">
    <div class="navbar row m-0 mb-3">
      <button class="btn btn-outline-primary btn-rounded toolbar-item background-white">
        <i class="fa fa-plus"></i>
        {{"AddCustomer" | translate}}
      </button>

      <button class="btn btn-outline-primary btn-rounded toolbar-item back background-white" (click)="showMenu()">
        <i class="fa fa-plus"></i>
        {{"Order" | translate}}
      </button>
    </div>

    <div id="orderListView"
         style="margin: 0;display: inline-flex;position: absolute;top: 80px;bottom: 10px;left: 10px;right: 10px;overflow: auto;">
      <app-single-table-orders *ngFor="let orders of tableOrders"
                               [orders]="orders"
                               [readOnly]="true"
                               [tableOrderList]="tableOrders"></app-single-table-orders>
    </div>
  </div>
</div>

<app-menu-card [orderedItems]="[]"></app-menu-card>
