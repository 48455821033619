<div class="mx-2 module-settings" style="padding: 50px 10px 10px 10px;">
  <div style="max-width: 600px;margin-left: auto;margin-right: auto;">
    <div class="panel panel-default">
      <div class="panel-body">
        <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && saveUser()" action="/account/update" method="post" id="updateForm" class="col-sm-12 html5-form" style="padding:0px;" role="form">
          <!-- Prevent implicit submission of the form -->
          <button type="submit" disabled style="display: none" aria-hidden="true"></button>
          <div class="md-form" style="margin:0px;margin-bottom:20px;">
            <input mdbInput type="text" name="restaurantName" id="restaurantName" class="form-control" [(ngModel)]="user.restaurantName" required="required" />
            <label for="restaurantName">{{"RestaurantName" | translate}}</label>
          </div>
          <div class="row d-block mb-5" style="padding-left:20px">
            <div>
              {{"Logo" | translate}}
              <label *ngIf="user.logoUrl">(<a class="text-danger" (click)="resetImage()">{{"Delete" | translate}}</a>)</label>
            </div>
            <dropzone [config]="dropzoneConfig" [message]="'ClickToUpload' | translate" (init)="initDropzone($event)" (complete)="onUploadSuccess($event)"></dropzone>
          </div>
          <div class="row" style="margin:0px;">
            <div class="col-sm-6 md-form" style="padding: 0; margin: 0;margin-bottom:35px;">
              <input mdbInput type="text" name="firstName" id="firstName" [(ngModel)]="user.firstName" class="form-control" required="required">
              <label for="firstName">{{"FirstName" | translate}}</label>
            </div>
            <div class="col-sm-6 md-form" style="padding: 0; margin: 0; margin-bottom:35px;">
              <input mdbInput type="text" name="lastName" [(ngModel)]="user.lastName" id="lastName" class="form-control" required="required">
              <label for="lastName">{{"LastName" | translate}}</label>
            </div>
          </div>
          <div class="md-form" style="margin:0px;margin-bottom:35px;">
            <input mdbInput type="text" name="userName" [(ngModel)]="user.userName" id="userName" data-minlength="1" class="form-control" required="required" />
            <label for="userName">{{"UserName" | translate}}</label>
          </div>
          <div class="md-form" style="margin:0px;margin-bottom:35px;">
            <input mdbInput type="text" [(ngModel)]="user.address" name="address" id="address" class="form-control" required="required" />
            <label for="address">{{"Address" | translate}}</label>
          </div>
          <div class="row" style="margin:0px;">
            <div class="col-sm-4 md-form" style="padding: 0; margin: 0; margin-bottom:35px;">
              <input mdbInput type="text" [(ngModel)]="user.city" name="city" id="city" class="form-control" required="required" />
              <label for="city">{{"City" | translate}}</label>
            </div>
            <div class="col-sm-4 md-form" style="padding: 0; margin: 0;margin-bottom:35px;">
              <input mdbInput type="text" [(ngModel)]="user.state" name="state" id="state" class="form-control" />
              <label for="state">{{"State" | translate}}</label>
            </div>
            <div class="col-sm-4 md-form" style="padding: 0; margin: 0; margin-bottom:35px;">
              <input mdbInput type="text" [(ngModel)]="user.zip" name="zip" id="zip" class="form-control" required="required" />
              <label for="zip">{{"Zip" | translate}}</label>
            </div>
          </div>
          <div class="md-form" style="margin:0px;margin-bottom:35px;">
            <input mdbInput type="text" [(ngModel)]="user.taxId" id="taxId" name="taxId" class="form-control" required="required"/>
            <label for="taxId">{{"TaxId" | translate}}</label>
          </div>
          <div class="md-form" style="margin:0px;margin-bottom:35px;">
            <input mdbInput type="text" [(ngModel)]="user.phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control" />
            <label for="phoneNumber">{{"PhoneNumber" | translate}}</label>
          </div>
          <div class="md-form" style="margin:0px;margin-bottom:35px;">
            <input mdbInput type="email" [(ngModel)]="user.email" id="email" name="email" class="form-control" required="required" />
            <label for="email">{{"EmailAddress" | translate}}</label>
          </div>
          <div class="row" style="margin:0px;margin-bottom: 35px">
            <div class="col-sm-12 md-form" style="padding: 0; margin: 0;">
              <mdb-select-2 name="country-list"
                            [(ngModel)]="selectedCountry"
                            (ngModelChange)="setCountry($event)">
                <mdb-select-option *ngFor="let option of countryList" [value]="option.value">
                  <img [src]="option.icon" style="width:30px;margin-right:5px;"/>
                  {{ option.label }}
                </mdb-select-option>
              </mdb-select-2>
            </div>
          </div>
          <div class="row" style="margin:0px;margin-bottom: 15px">
            <div class="col-sm-6 md-form" style="padding: 0; margin: 0;">
              <div style="display:flex">
                <span>{{"Language" | translate}}</span>:&nbsp;
                <span id="txtLanguage" style="font-weight:bold;">{{languageName}}</span>
              </div>
            </div>
            <div class="col-sm-6 md-form" style="padding: 0; margin: 0;">
              <div style="display:flex">
                <span>{{"Currency" | translate}}</span>:&nbsp;
                <span id="txtCurrency" style="font-weight:bold;">{{currency}}</span>
              </div>
            </div>
          </div>
          <button id="btnUpdateForm" mdbBtn color="primary" rounded="true" class="btn-block mt-5" style="margin-left:0;">
            {{"Update" | translate}}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
