<div class="card justify-content-between flex-row action-toolbar" style="border-radius: 0; min-height: 60px;">
  <div class="action-toolbar p-0 m-0">
    <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded toolbar-item background-white"
       (click)="showAddCategoryDialog()">
      <i class="fa fa-plus"></i>
      <span>{{"AddCategory" | translate}}</span>
    </a>
    <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded toolbar-item background-white pl-4 pr-4"
       (click)="showImportCategoriesDialog()">
        <i class="fa fa-upload mr-2"></i>
      <span>{{"ImportCategories" | translate}}</span>
    </a>
    <a href="/api/v1/productcategory/export" class="btn btn-outline-primary btn-rounded background-white toolbar-item">
      <i class="fas fa-download mr-2"></i>
      <span>{{"DownloadMenu" | translate}}</span>
    </a>
    <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded toolbar-item background-white pl-4 pr-4"
       (click)="showPublishMenuDialog()">
      <i class="fas fa-globe mr-2"></i>
      <span>{{"PublishMenu" | translate}}</span>
    </a>
  </div>

  <a href="javascript:void(0);" style="font-size: 15px; margin: 10px; padding-top: 8px;white-space: nowrap;" (click)="showHelp()">
    <span class="far fa-play-circle" style="font-size: 20px; vertical-align: middle;"></span>
    <span>{{"Help" | translate}}</span>
  </a>
</div>

<app-product-category-list [productCategories]="productCategories"></app-product-category-list>

<app-show-product-list-dialog></app-show-product-list-dialog>
<app-create-category-dialog></app-create-category-dialog>
<app-create-product-dialog></app-create-product-dialog>
<app-import-categories-dialog></app-import-categories-dialog>
<app-publish-menu-dialog></app-publish-menu-dialog>
