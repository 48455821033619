<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right product-details-modal" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-full-height modal-right modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{item.product.productName}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body mh-100">

        <div class="d-flex">
          <textarea id="manualExtra" [attr.placeholder]='"EnterSpecialWishes" | translate'
                    [(ngModel)]="item.manualExtra"
                    (ngModelChange)="setExtra()"
              style="width: 100%;border: 1px solid #dedede;margin-bottom: 20px; padding-left: 10px;"
              class="flex-grow-1"></textarea>
          <button class="btn btn-rounded btn-outline-primary ml-1"
                  style="padding: 0;width: 45px;height: 40px;"
                  (click)="showSpecialWishes()">
            <span style="white-space: nowrap;font-size: 22px;line-height: 11px;vertical-align: super;">...</span>
          </button>
        </div>
        <table class="w-100 h-auto">
          <thead>
          <tr style="border-bottom: 1px solid #dedede;">
            <th></th>
            <th>#</th>
            <th></th>
            <th>{{"Extra" | translate}}</th>
            <th>{{"Price" | translate}}</th>
          </tr>
          </thead>
          <tbody *ngIf="extras.length === 0">
          <tr>
            <td colspan="5" style="padding:10px 5px;">
              <div class="alert-warning alert" role="alert">
                {{"NoExtras" | translate}}
              </div>
            </td>
          </tr>
          </tbody>

          <tbody>
          <tr style="border-bottom: 1px solid #dedede;" *ngFor="let extra of extras">
            <td style="width: 50px; padding: 5px;">
              <button type="button" class="btn btn-primary m-0" style="padding: 5px 10px;"
                      (click)="increaseCount(extra)">
                <i class="fa fa-plus" style="font-size:25px; padding-top: 1px;"></i>
              </button>
            </td>
            <td style="width: 35px; padding-right: 5px;">
              <span type="text" readonly="readonly" style="width: 100%; height:40px; border: none; padding-left: 5px;">
                {{extra.count}}x
              </span>
            </td>
            <td style="width: 50px;">
              <button type="button" class="btn btn-danger m-0" style="padding: 5px 10px;"
                      (click)="decreaseCount(extra)">
                <i class="fa fa-minus" style="font-size:25px; padding-top: 2px;"></i>
              </button>
            </td>
            <td style="padding-left: 5px;">{{extra.name}}</td>
            <td style="width: 60px;">
              <input type="text" readonly="readonly" style="width: 90%; height:40px; border: none;"
                     [ngModel]="extra.price | otcurrency"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
