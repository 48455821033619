import {Component, Input, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {assign, cloneDeep, findIndex} from "lodash";
import {Employee} from "../../../../models/employee.model";
import {EmployeeService} from "../../../../services/employee.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: "app-single-employee",
  templateUrl: "./single-employee.component.html",
  styleUrls: ["./single-employee.component.scss"],
})
export class SingleEmployeeComponent implements OnInit {

  @Input()
  public employee: Employee = new Employee();

  @Input()
  public employeeList: Employee[];

  public image: string;
  public role: string;
  public sendMessageTooltip: string;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private employeeService: EmployeeService,
    private messageService: MessageService,
  ) { }

  public ngOnInit(): void {
    this.image = this.getImage();
    this.sendMessageTooltip = this.translateService.instant("SendMessageTooltip").printf([this.employee.firstName + " " + this.employee.lastName]);
  }

  public showUpdateEmployee() {
    this.modalService.show("create-employee-dialog", {
      employee: cloneDeep(this.employee),
      onEmployeeAdded: (employee) => {
        assign(this.employee, employee);

        this.image = this.getImage();
        this.sendMessageTooltip = this.translateService.instant("SendMessageTooltip").printf([this.employee.firstName + " " + this.employee.lastName]);
      },
    });
  }

  public showDeleteEmployeeConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("DeleteEmployeeConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {this.deleteCustomer(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public showSendMessageDialog() {
    this.modalService.show("send-message-dialog", {
      userId: this.employee.userId,
      to: this.employee.firstName + " " + this.employee.lastName,
    });
  }

  private getImage() {
    if (this.employee.role === "Administrator" || this.employee.role === "AdminHelper") { return "/images/woman.png"; } else if (this.employee.role === "Waiter") { return "/images/waiter.png"; } else if (this.employee.role === "CookBarman") { return "/images/cook.png"; }

    return "";
  }

  private deleteCustomer() {
    this.employeeService.deleteEmployee(this.employee["rowKey"]).subscribe(() => {
      this.messageService.success(this.translateService.instant("DeleteEmployeeSuccess"));
      const index = findIndex(this.employeeList, {userId: this.employee.userId});
      if (index > -1) {
        this.employeeList.splice(index, 1);
      }
    }, (error) => {
      this.messageService.error(error);
    });
  }

  public showQrCodeDialog() {
    this.modalService.show("employee-qr-code-dialog", {employee: this.employee});
  }
}
