import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as html2canvas from "html2canvas";
import {ModalDirective} from "ng-uikit-pro-standard";
import { v4 as uuid } from "uuid";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import { Table } from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import { ModalService } from "../../../../services/modal.service";
import { NativeAppService } from "../../../../services/native-app.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-selforder-settings-dialog",
  templateUrl: "./selforder-settings-dialog.component.html",
  styleUrls: ["./selforder-settings-dialog.component.scss"],
})
export class SelfOrderSettingsDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public table: Table = new Table();

  public showLoading = false;

  public qrCodeSrc: string;
  public qrCodeShortUrl: string;

  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private nativeAppService: NativeAppService,
    private localStorage: LocalStorageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("selforder-settings-dialog", this);

    const that = this;
    window["selfOrderDisplayRefreshResult"] = function(result) {
      if (!result) {
        that.messageService.error(that.translateService.instant("ErrorUpdatingQrCode"));
      } else {
        that.qrCodeSrc = "/api/v1/qrcode/create/table?tableId=" + that.table.id + "&qrCodeId=" + uuid() + "&width=250&height=250&time=" + new Date().toISOString();
      }
    };
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.table = params.table;
    this.modal.show();
    this.qrCodeSrc = "/api/v1/qrcode/create/table?tableId=" + this.table.id + "&qrCodeId=" + uuid() + "&width=250&height=250&time=" + new Date().toISOString();
  }

  public onLoadQrCode() {
    this.showLoading = false;

    this.loadQrCodeShortUrl(this.table.id);
  }

  public showRenewQrCodeConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("RenewQrCodeConfirm"),
      type: "info",
      confirmed: async () => {await this.renewQrCode(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public downloadQrCode() {
    // @ts-ignore
    html2canvas(document.getElementById("qrcode-image")).then((canvas) => {
      // Convert the canvas to blob
      canvas.toBlob((blob) =>  {
        // To download directly on browser default 'downloads' location
        const link = document.createElement("a");
        link.download = this.table.name + ".png";
        link.href = URL.createObjectURL(blob);
        link.click();

        // To save manually somewhere in file explorer
        // @ts-ignore
        window.saveAs(blob, "image.png");

      }, "image/png");
    });
  }

  private loadQrCodeShortUrl(id: string) {
    this.tableService.loadTableShortUrl(id).subscribe((url) => {
      this.qrCodeShortUrl = url;
    }, (error) => {
      this.messageService.error(error);
    });
  }

  private async renewQrCode(): Promise<any> {
    const userSettings = await this.localStorage.getUserSettingsAsync();
    if (!userSettings.selfOrderDigitalQRCode || !this.table.macAddress) {
      this.showLoading = true;
      this.qrCodeShortUrl = "";
      this.qrCodeSrc = "/api/v1/qrcode/refresh/table?tableId=" + this.table.id + "&qrCodeId=" + uuid() + "&width=250&height=250&time=" + new Date().toISOString();
    } else {
      this.nativeAppService.refreshDisplay(this.table);
    }
  }
}
