<div id="table-tabs" class="classic-tabs text-center">
  <div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="tables.length == 0">
    <img src="/images/interface.svg" style="width: 150px;" />
    <div class="danger-text" [innerHTML]='"PosNoTables" | translate' style="margin: 30px 20px 10px 20px;"></div>
  </div>
  <div *ngIf="tableCategories.length > 1">
  <ul class="nav nav-pills mb-3 table-category-list" id="pills-tab" role="tablist">
    <li class="nav-item" *ngFor="let category of tableCategories;">
      <a class="nav-link"
         [class.active]="activeCategoryId === category.id"
          (click)="activeCategoryId = category.id">{{category.name}}</a>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade"
         *ngFor="let category of tableCategories; let $index = index;"
         [class.show]="activeCategoryId === category.id"
         [class.active]="activeCategoryId === category.id"
         [id]="category.id"
         role="tabpanel">
      <app-pos-table *ngFor="let table of groupedTables[category.id]" [table]="table"></app-pos-table>
      <div *ngIf="groupedTables[category.id]?.length == 0" style="margin: 10px;">
        <img src="/images/table.svg" style="width: 200px;" />
        <div class="text-info informative-text m-3" role="alert" [innerHTML]="'PosNoTables' | translate"></div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="tableCategories.length == 1">
    <app-pos-table *ngFor="let table of groupedTables[tableCategories[0].id]" [table]="table"></app-pos-table>
  </div>
</div>
