
<div class="row category-list" style="margin: 0px;">
  <app-table-category *ngFor="let category of tableCategories"
    class="col-lg-4 col-sm-6 col-xs-12" style="margin-bottom:30px;padding: 5px;"
    [category]="category"
    [categoryList]="tableCategories"></app-table-category>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="tableCategories?.length == 0">
  <img src="/images/interface.svg" style="width: 150px;" />
  <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;">{{"NoTableCategoriesHelp" | translate}}</div>
</div>
