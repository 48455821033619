import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderedItem, Product, UserSettings} from '../../../models';
import {SubscriptionService} from '../../../services/subscription.service';
import {Subscription} from 'rxjs';
import {OrderStatus} from '../../../enums/order.status.enum';
import {find, findIndex} from 'lodash';
import {v4 as uuid} from 'uuid';
import {LocalStorageService} from '../../../services/localstorage.service';
import {PaymentViewModel} from '../../../view-model/payment.view.model';
import {PaymentModel} from '../../../enums/payment.model.enum';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'app-retail-orders-list',
  templateUrl: './retail-orders-list.component.html',
  styleUrls: ['./retail-orders-list.component.scss']
})
export class RetailOrdersListComponent implements OnInit, OnDestroy {
  orderedItems: OrderedItem[] = [];
  totalAmount = 0;
  totalPrice = 0;
  productOrderedSubscription: Subscription;
  userSettings: UserSettings;
  productOrderRemovedSubscription: Subscription;
  tableRefreshSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService
  ) {
  }

  async ngOnInit() {
    this.userSettings = await this.localStorage.getUserSettingsAsync();
    this.productOrderedSubscription = this.subscriptionService.productOrderedEvent.subscribe(async product => {
      await this.orderProduct(product);
      this.refreshUI();
    });

    this.productOrderRemovedSubscription = this.subscriptionService.removeOrderedItemEvent.subscribe(async item => {
      this.removeOrderedItem(item);
      this.refreshUI();
    });

    this.tableRefreshSubscription = this.subscriptionService.tableRefreshEvent.subscribe(() => {
      this.refreshUI();
    });
  }

  ngOnDestroy(): void {
    this.productOrderedSubscription?.unsubscribe();
    this.productOrderRemovedSubscription?.unsubscribe();
    this.tableRefreshSubscription?.unsubscribe();
  }

  private async orderProduct(product) {
    const orderedItem = await this.createOrderedItemFromProduct(product);
    this.orderedItems.push(orderedItem);
    this.orderedItems.map(item => item.isActive = false);
  }

  showTotalPayment() {
    const tipAmount = this.totalPrice * this.userSettings.tipToIncludeInPrice / 100;

    const paymentViewModel = <PaymentViewModel>{
      itemsToBePaid: this.orderedItems,
      invoiceId: uuid(),
      tableId: '',
      paymentModel: PaymentModel.total,
      paymentType: "CashPayment",
      cardPaymentValue: 0,
      cashPaymentValue: 0,
      voucherPaymentValue: 0,
      subPayments: {},
      subPaymentType: 'CashPayment',
      tipAmount: tipAmount,
      subTotal: this.totalPrice,
      grandTotal: this.totalPrice + tipAmount,
      discount: 0,
      discountType: '',
      billAmount: 0,
      billRemainder: 0,
      cardFeeAmount: 0,
      isSeparatePaymentActive: false,
      paymentFinished: () => this.paymentFinished(),
      retailMode: true
    };

    this.modalService.show('payment-dialog', paymentViewModel);
  }

  closeItemList() {

  }

  private async createOrderedItemFromProduct(product: Product): Promise<OrderedItem> {
    let productCategoryName = '';
    const productCategory = find(await this.localStorage.getProductCategoriesAsync(), {productCategoryId: product.productCategoryId});
    if (productCategory) { productCategoryName = productCategory.productCategoryName; }

    const orderedItem = <OrderedItem>{
      id: uuid(),
      customerId: '',
      orderedItemId: uuid(),
      tableId: '',
      tableName: '',
      productId: product.productId,
      productName: product.productName,
      courseId: '',
      courseName: '',
      categoryName: productCategoryName,
      quantity: 1,
      unitPrice: product.unitPrice,
      totalPrice: product.unitPrice + (product.extrasInfo ? product.extrasInfo.extrasPrice : 0),
      product: product,
      sideDishes: product.extrasInfo?.sideDishes,
      extrasIds: product.extrasInfo?.extrasId,
      extras: product.extrasInfo?.extras,
      manualExtra: product.extrasInfo?.manualExtra,
      extrasPrice: product.extrasInfo?.extrasPrice,
      selectedPriceCategory: product.extrasInfo?.selectedPriceCategory,
      ingredientsToRemove: product.extrasInfo?.ingredientsToRemove,
      selectedExtra: product.extrasInfo?.selectedExtra,
      orderedVariations: product.extrasInfo?.orderedVariations,
      status: OrderStatus.new,
      orderDate: new Date().toISOString(),
      isActive: true,
      tax: await this.getItemTax(product),
      taxPrice: 0,
      selectionCount: 0,
      server: '',
      servedBy: '',
      productCategoryId: ''
    };

    orderedItem.taxPrice = this.getItemTaxPrice(orderedItem);

    return orderedItem;
  }

  async getItemTax(product): Promise<any> {
    if (product) {
      const cat = find(await this.localStorage.getProductCategoriesAsync(), function (c) {
        return c.productCategoryId == product.productCategoryId;
      });
      if (cat?.tax != null && cat.tax >= 0) { return cat.tax; }
    }

    return this.userSettings.tax;
  }

  getItemTaxPrice(item) {
    const tax = item.tax >= 0 ? item.tax : this.userSettings.tax;

    let taxValue = 0;
    if (tax > 0) {
      if (this.userSettings.isTaxInPriceIncluded) {
        taxValue = item.totalPrice - item.totalPrice / (1 + tax / 100);
      }
      else {
        taxValue = item.totalPrice * tax / 100;
      }
    }

    return taxValue;
  }

  private refreshUI() {
    let totalAmount = 0;
    let totalPrice = 0;
    this.orderedItems.forEach(item => {
      totalAmount += item.quantity;
      totalPrice += item.totalPrice;
    });

    this.totalAmount = totalAmount;
    this.totalPrice = totalPrice;
  }

  private removeOrderedItem(item: OrderedItem) {
    const index = findIndex(this.orderedItems, {orderedItemId: item.orderedItemId});
    this.orderedItems.splice(index, 1);
  }

  getUnitPrice(item) {
    if (item.unitPrice) { return item.unitPrice; }

    if (!item.product) { return 0; }

    let unitPrice = item.product.unitPrice;
    if (item.selectedPriceCategory) {
      const index = item.product.priceCategories.indexOf(item.selectedPriceCategory);
      unitPrice = item.product.unitPrices[index];
    } else if (item.product.unitPrices.length === 1) {
      unitPrice = item.product.unitPrices[0];
    }

    return unitPrice;
  }

  private getTipPrice() {
    return this.totalPrice * this.userSettings.tipToIncludeInPrice / 100;
  }

  private paymentFinished() {
    this.totalAmount = 0;
    this.totalPrice = 0;
    this.orderedItems = [];
    this.modalService.hide('payment-dialog');
  }
}
