import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {OrderStatus} from "../../../enums/order.status.enum";
import {OrderedItem} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {SignalRService} from "../../../services/signalr.service";
import {SoundService} from "../../../services/sound.service";

@Component({
  selector: "app-single-table-order",
  templateUrl: "./single-table-order.component.html",
  styleUrls: ["./single-table-order.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SingleTableOrderComponent implements OnInit, OnDestroy {
  @Input()
  public item: any = {};
  @Input()
  public orders: { orderDate, orderedItems, tableId, isNew };
  @Input()
  public selectedCategories: string[] = [];
  @Input()
  public course: {id, index, title, active, visible, collapsed, items: OrderedItem[]};
  @Input()
  public readOnly: true;

  @Output()
  public onDelete: EventEmitter<any> = new EventEmitter();

  public temporaryCompletedStatusId = 1.5;
  public cancelledStatusId: OrderStatus.cancelled;
  public cookingStatusId = OrderStatus.cooking;

  public quantity: string;
  public extras: string;
  public productName: string;

  private itemReturnedSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private signalRService: SignalRService,
    private soundService: SoundService,
    private localStorage: LocalStorageService
  ) {
  }

  public ngOnInit(): void {
    this.quantity = this.getQuantity();
    this.extras = this.getExtras();
    this.productName = this.getProductName();

    this.itemReturnedSubscription = this.signalRService.itemReturned.subscribe(async (orderedItemId) => {
      if (orderedItemId === this.item.orderedItemId) {
        this.item.updated = true;
        this.item.quantity --;
        this.quantity = this.getQuantity();
        if (this.item.quantity === 0) {
          this.item.status = OrderStatus.cancelled;
        }
        await this.soundService.play();

        setTimeout(() => {
          this.item.updated = false;
        }, 8000);
      }
    });
  }

  public ngOnDestroy(): void {
    this.itemReturnedSubscription.unsubscribe();
  }

  public getQuantity() {
    if (this.item.quantity === this.item.originalQuantity) { return this.item.originalQuantity + "x"; } else {
      return this.item.quantity + "x <br /> <hr style='margin: 2px 12%;width: 75%;'/><span class='cancelled-order-count'>" + this.item.originalQuantity + "x</span>";
    }
  }

  public getProductName() {
    let productName = this.item.productName;
    if (!productName) {
      if (this.item.product) {
        productName = this.item.product.productName;
      }
    }

    if (!productName) { return ""; }

    if (!this.item.selectedPriceCategory) { return productName; }

    return productName + " (" + this.item.selectedPriceCategory + ")";
  }

  public getExtras() {
    const extras = this.item.extras;
    if (!extras) { return ""; }

    return extras.replace(/\n/g, "<br />").replace(/\(\+\)/, "<b class='with'>(+)</b>").replace(/\(-\)/, "<b class='without'>(-)</b>");
  }

  public selectItem(item: any, event) {
    if (this.readOnly || item.status === OrderStatus.cancelled) { return; }

    event.stopPropagation();
    item.status = this.temporaryCompletedStatusId;
  }

  public async showProductDetails() {
    this.modalService.show("product-details", {product: this.item.product, productPictures: await this.localStorage.getProductPicturesAsync()});
  }

  public actionItemClicked(item: any, event) {
    event.stopPropagation();
    if (item.status === this.cookingStatusId) {
      item.status = this.temporaryCompletedStatusId;
    } else if (item.status === this.temporaryCompletedStatusId) {
      item.status = this.cookingStatusId;
    } else if (item.status === OrderStatus.cancelled) {
      this.course.items = this.course.items.filter((i) => i.orderedItemId !== item.orderedItemId);
      this.orders.orderedItems = this.orders.orderedItems.filter((i) => i.orderedItemId !== item.orderedItemId);
      this.onDelete.emit();
    }
  }
}
