import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, sortBy} from "lodash";
import {Subscription} from "rxjs";
import {OrderedItem, Product, ProductCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {SignalRService} from "../../../../services/signalr.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {UtilsService} from "../../../../services/utils.service";

@Component({
  selector: "app-menu-category-products",
  templateUrl: "./menu-category-products.component.html",
  styleUrls: ["./menu-category-products.component.scss", "../menu-single-product/menu-single-product.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuCategoryProductsComponent implements OnInit, OnDestroy {
  @Input()
  public category: ProductCategory = new ProductCategory();

  @Input()
  public orderedItems: OrderedItem[] = [];

  @Input()
  public retailMode: boolean;

  public showAsList: boolean;
  public products: Product[] = [];
  public allProductsLength: number;

  public title = "";
  public utilsService: UtilsService;
  private menuSelectedSubscriber: Subscription;
  private searchSubscriber: Subscription;
  private updateProductSubscription: Subscription;

  constructor(
    private subscriptionService: SubscriptionService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private util: UtilsService,
    private signalRService: SignalRService,
  ) {
  }

  public ngOnInit() {
    this.utilsService = this.util;
    this.localStorage.getValueAsync<boolean>("show-product-as-list").then(res => {
      this.utilsService.showAsList = res;
    });

    if (this.category.productCategoryName) {
      this.loadCategoryItems(this.category).then();
    }

    this.menuSelectedSubscriber = this.subscriptionService.menuCardCategorySelectedEvent.subscribe(async (category) => {
      this.category = category;
      if (category.productCategoryId !== "3") {
        await this.loadCategoryItems(category);
      }
    });

    this.searchSubscriber = this.subscriptionService.searchStartedEvent.subscribe(async (searchText) => {
      await this.searchProducts(searchText);
    });

    this.updateProductSubscription = this.signalRService.updateProduct.subscribe(async () => {
      const catProducts = filter(await this.localStorage.getProductsAsync(), {productCategoryId: this.category.productCategoryId});
      this.products = sortBy(catProducts, [product => product.productName.toLowerCase()]);
    });
  }

  public ngOnDestroy(): void {
    this.menuSelectedSubscriber?.unsubscribe();
    this.searchSubscriber?.unsubscribe();
    this.updateProductSubscription?.unsubscribe();

    this.category = null;
    this.products = null;
  }

  public changeListType(type: string) {
    this.utilsService.showAsList = type === "list";
    this.localStorage.setValue("show-product-as-list", this.utilsService.showAsList);
  }

  private async searchProducts(searchText: string): Promise<any> {
    if (searchText) {
      const foundProducts = [];
      const allProducts = await this.localStorage.getProductsAsync();
      const categoryProducts = filter(allProducts, { pluCode: searchText });
      if (categoryProducts?.length > 0) {
        this.products = categoryProducts;
      } else {
        allProducts.forEach((product) => {
          // @ts-ignore
          const textLower = searchText.toLowerCase().replaceSpecialChars();
          // @ts-ignore
          const productName = product.productName.toLowerCase().replaceSpecialChars();
          // @ts-ignore
          const description = product.productDescription?.toLowerCase().replaceSpecialChars();
          const found = productName.indexOf(textLower) > -1 ||
            description?.indexOf(textLower) > -1 ||
            product.barcode && product.barcode.toLowerCase().indexOf(textLower) > -1;

          if (found) { foundProducts.push(product); }
        });
      }

      this.products = sortBy(foundProducts, [product => product.productName.toLowerCase()]);
      this.title = this.translateService.instant("SearchResult");
      if (this.category.productCategoryId != "1" && this.category.productCategoryId != "2") {
        this.title += " (" + this.products.length + ")";
      }
    }
  }

  private async loadCategoryItems(category: ProductCategory): Promise<any> {
    this.category = category;
    const products = await this.localStorage.getProductsAsync();
    this.allProductsLength = products.length;
    const catProducts = filter(products, {productCategoryId: this.category.productCategoryId});
    this.products = sortBy(catProducts, [product => product.productName.toLowerCase()]);
    this.title = this.category.productCategoryName;
    if (this.category.productCategoryId != "1" && this.category.productCategoryId != "2") {
      this.title += " (" + this.products.length + ")";
    }
  }
}
