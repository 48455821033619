import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ModalDirective} from "ng-uikit-pro-standard";
import {PaymentModel} from "../../../enums/payment.model.enum";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Customer} from "../../../models/customer.model";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {PosService} from "../../../services/pos.service";
import {UtilsService} from "../../../services/utils.service";
import {PaymentViewModel} from "../../../view-model/payment.view.model";
import { sum } from "lodash";

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"],
})
export class PaymentDialogComponent extends ModalClassComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  @ViewChild("modal")
  public modal: ModalDirective;

  public title: string;
  public paymentViewModel: PaymentViewModel = {subPayments: {}} as PaymentViewModel;
  public showFee: boolean;
  public canDiscount: boolean;
  public showPrintReceipt: boolean;
  public shouldPrintReceipt: boolean;
  public showHostingSplit: boolean;
  public showDetails: boolean;
  public selectedCustomer: Customer;
  public cardFeeOptions: any[];
  public cardFee: number;
  public paymentTypeOptions: any[] = [];
  public decimalSeparator: string;
  public payItemsSubscription: any;

  private creditCardFee: number;
  private ecCardFee: number;
  private paymentTexts = {};

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private localStorage: LocalStorageService,
    private utilsService: UtilsService,
    private posService: PosService,
    private messageService: MessageService,
  ) {
    super();
    this.decimalSeparator = this.utilsService.getDecimalSeparator();
  }

  public async ngOnInit() {
    this.modalService.register("payment-dialog", this);
    this.localStorage.getUserSettingsAsync().then(userSettings => {
      this.canDiscount = userSettings.canWaiterDeduct;
      this.showHostingSplit = userSettings.allowHostingSlip;
      this.showPrintReceipt = !userSettings.printReceiptAlways;
    });

    this.cardFeeOptions = [
      {value: 0, label: "CardFee"},
      {value: this.ecCardFee, label: "CardFeeEC"},
      {value: this.creditCardFee, label: "CardFeeCreditCard"},
    ];

    this.localStorage.getPaymentTypesAsync().then(types => {
      types = types.filter(p => p.isActive);
      types.forEach(t => {
        this.paymentTypeOptions.push({
          value: t.name,
          label: t.label
        });
      });

      console.log("this.paymentTypeOptions = ", this.paymentTypeOptions);
    });
  }

  public hide() {
    super.hide();
    this.paymentViewModel = ({subPayments: {}} as PaymentViewModel);
    this.modal.hide();
  }

  public show(paymentViewModel: PaymentViewModel) {
    console.log("paymentViewModel = ", paymentViewModel);
    super.show(paymentViewModel);
    this.paymentViewModel = paymentViewModel;

    this.title = this.paymentViewModel.paymentModel == PaymentModel.separate ?
      this.translateService.instant("SeperatePayment")
      : this.translateService.instant("TogetherPayment");

    this.modal.show();
  }

  public setPaymentType(type: string) {
    console.log("type = ", type);
    this.paymentViewModel.paymentType = type;
  }

  public showDiscountDialog() {
    this.modalService.show("discount-dialog", { paymentDialog: this });
  }

  public setSubPaymentType(subPaymentType: string) {
    this.paymentViewModel.subPaymentType = subPaymentType;
  }

  public appendNumber(number: number) {
    let enteredText = this.getActivePaymentText();

    if (enteredText.indexOf(this.decimalSeparator) !== -1) {
      const parts = enteredText.split(this.decimalSeparator);
      if (parts[1].length > 1) { return; }
    }

    enteredText += number;

    this.setActivePaymentValue(this.utilsService.toFloat(enteredText));
    this.setActivePaymentText(enteredText);

    const totalPaidValue = this.getTotalPaymentValue();
    this.paymentViewModel.billAmount = totalPaidValue;
    let remaining = totalPaidValue - this.paymentViewModel.grandTotal;
    if (remaining < 0) { remaining = 0; }
    this.paymentViewModel.billRemainder = remaining;
  }

  public appendValue(number: number) {
    let enteredText = this.getActivePaymentText();
    if (enteredText.indexOf(this.decimalSeparator) > -1) {
      let decimal = enteredText.substring(0, enteredText.indexOf(this.decimalSeparator));
      decimal = (parseInt(decimal) + number).toString();
      const points = enteredText.substring(enteredText.indexOf(this.decimalSeparator) + 1);

      enteredText = decimal + "." + points;
    } else {
      const existingDecimal = enteredText !== "" ? this.utilsService.toFloat(enteredText) : 0;
      enteredText = (existingDecimal + number).toString();
    }

    this.setActivePaymentValue(this.utilsService.toFloat(enteredText));
    this.setActivePaymentText(enteredText);

    const totalPaidValue = this.getTotalPaymentValue();
    this.paymentViewModel.billAmount = totalPaidValue;
    let remaining = totalPaidValue - this.paymentViewModel.grandTotal;
    if (remaining < 0) { remaining = 0; }
    this.paymentViewModel.billRemainder = remaining;
  }

  public appendDecimalSeparator() {
    let enteredText = this.getActivePaymentText();
    if (enteredText.indexOf(this.decimalSeparator) == -1) {
      enteredText += this.decimalSeparator;
    }

    this.setActivePaymentText(enteredText);
  }

  public deleteLastNumber() {
    let enteredText = this.getActivePaymentText();

    if (enteredText) {
      enteredText = enteredText.substring(0, enteredText.length - 1);

      this.setActivePaymentValue(this.utilsService.toFloat(enteredText));
      this.setActivePaymentText(enteredText);

      const totalPaidValue = this.getTotalPaymentValue();
      this.paymentViewModel.billAmount = totalPaidValue;
      let remaining = totalPaidValue - this.paymentViewModel.grandTotal;

      if (remaining < 0) { remaining = 0; }
      this.paymentViewModel.billRemainder = remaining;
    }
  }

  public showCustomerList() {

  }

  public removeCustomer() {

  }

  public doPayment() {
    const cardFeePrice = this.paymentViewModel.cardFeeAmount;
    const discount = this.paymentViewModel.discount;
    const tipPrice = this.paymentViewModel.tipAmount;

    const combinedPaymentTypes = this.getCombinedPaymentTypes();
    if (this.paymentViewModel.paymentType === "CombinedPayment") {
      const enteredPayment = this.getTotalPaymentValue();
      if (enteredPayment !== this.paymentViewModel.subTotal - discount + tipPrice + cardFeePrice) {
        this.messageService.error(this.translateService.instant("EnterSameAmountOfTotalGross"), 10000);
        return;
      }
    }

    const updateOrderViewModel = {
      invoiceId: this.paymentViewModel.invoiceId,
      tableId: this.paymentViewModel.tableId,
      paidItems: this.paymentViewModel.itemsToBePaid,
      paymentType: this.paymentViewModel.paymentType,
      combinedPaymentTypes: JSON.stringify(combinedPaymentTypes),
      discount,
      tipAmount: tipPrice,
      cardFee: cardFeePrice,
      billAmount: this.paymentViewModel.billAmount,
      billRemainder: this.paymentViewModel.billRemainder,
    };

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.payItemsSubscription = this.posService.payItems(updateOrderViewModel, this.paymentViewModel.retailMode).subscribe((result) => {
      this.blockUI.stop();
      if ( this.paymentViewModel.paymentFinished) {
        result.shouldPrintReceipt = this.shouldPrintReceipt;
        result.printDetails = this.showDetails;
        this.paymentViewModel.paymentFinished(result);
      }
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public getActivePaymentText(): string {
    let paymentType: any = this.paymentViewModel.paymentType;
    if (paymentType === "CombinedPayment") { paymentType = this.paymentViewModel.subPaymentType; }

    if(!this.paymentTexts[paymentType]) this.paymentTexts[paymentType] = "";
    return this.paymentTexts[paymentType];
  }

  public setActivePaymentValue(amount: number): void {
    if (isNaN(amount)) { amount = 0; }
    let paymentType: any = this.paymentViewModel.paymentType;
    if (paymentType === "CombinedPayment") { paymentType = this.paymentViewModel.subPaymentType; }

    this.paymentViewModel.subPayments[paymentType] = amount;
  }

  public setActivePaymentText(text): void {
    let paymentType: any = this.paymentViewModel.paymentType;
    if (paymentType === "CombinedPayment") { paymentType = this.paymentViewModel.subPaymentType; }

    this.paymentTexts[paymentType] = text;
  }

  public getTotalPaymentValue(): number {
    return sum(Object.values(this.paymentViewModel.subPayments));
  }

  public getCombinedPaymentTypes() {
    if (this.paymentViewModel.paymentType !== "CombinedPayment") { return ""; }

    const keys = Object.keys(this.paymentViewModel.subPayments);
    keys.forEach(key => {
      if(this.paymentViewModel.subPayments[key] == 0){
        delete this.paymentViewModel.subPayments[key];
      }
    });

    return this.paymentViewModel.subPayments;
  }

  public applyDiscount(discountAmount: number, discountType: string) {
    if (discountAmount > -1) {
      if (discountType === "%") {
        this.paymentViewModel.discount = this.paymentViewModel.subTotal * discountAmount / 100;
      } else {
        this.paymentViewModel.discount = discountAmount;
      }

      this.paymentViewModel.grandTotal = this.paymentViewModel.subTotal - this.paymentViewModel.discount +
        this.paymentViewModel.tipAmount + this.paymentViewModel.cardFeeAmount;

      let remaining = this.paymentViewModel.billAmount - this.paymentViewModel.grandTotal;
      if (remaining < 0) { remaining = 0; }
      this.paymentViewModel.billRemainder = remaining;
    }
  }
}
