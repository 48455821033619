import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, findIndex} from "lodash";
import {ModalDirective, TabsetComponent} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import { ModalClassComponent } from "../../../../interfaces/ModalClassComponent";
import {Product, ProductCategory, ProductPicture, SideDish} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";
import {LocalCurrencyPipe} from "../../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-create-product-dialog",
  templateUrl: "./create-product-dialog.component.html",
  styleUrls: ["./create-product-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateProductDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("nameInputRef")
  public nameInputRef: ElementRef;

  @ViewChild("nameInputRef")
  public txtSpecialWishRef: ElementRef;

  @ViewChild("tabs", {static: true}) public tabs: TabsetComponent;

  public showExcludeFromSelfOrdering = false;
  public product: Product = new Product();
  public onProductAdded: any;
  public onDialogHide: any;

  public ingredients: string[] = [];
  public additives: string[] = [];
  public specialWishes: string[] = [];
  public fileUploader: any;
  public productPictures: ProductPicture[] = [];
  public dropzoneConfig: any;
  public category: ProductCategory;
  public action: string;

  private currencyPipe: LocalCurrencyPipe;
  private ignoreDelete: boolean = true;

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private productService: ProductService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public ngOnInit(): void {
    this.modalService.register("create-product-dialog", this);
    this.localStorage.getUserSettingsAsync().then(settings => {
      this.showExcludeFromSelfOrdering = settings.allowAnonymousSelfOrdering;
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();

    if(this.fileUploader) {
      this.ignoreDelete = true;
    }

    this.productPictures = [];
    this.onDialogHide && this.onDialogHide();
  }

  public show(params: any) {
    super.show(params);

    this.ignoreDelete = true;
    this.onProductAdded = params.onProductAdded;
    this.onDialogHide = params.onDialogHide;
    this.product = params.product;
    this.category = params.category;
    this.action = params.action;

    if (this.product.priceCategories?.length === 0) {
      this.product.priceCategories = [this.translateService.instant("Standard")];
    }

    if (!this.product.productName) {
      this.product.productCategoryId = this.category.productCategoryId;
      if(this.category.priceCategories.length > 0) {
        this.product.priceCategories = this.category.priceCategories;
      }

      this.product.unitPrices.fill("", this.product.priceCategories.length);
    }

    this.ingredients = [];
    if (this.product.ingredients) {
      this.ingredients = this.product.ingredients.split(",");
    }

    this.additives = [];
    if (this.product.additives) {
      this.additives = this.product.additives.split(",");
    }

    this.specialWishes = [];
    if (this.product.specialWishes) {
      this.specialWishes = this.product.specialWishes.split(",");
    }

    this.getProductImages().then(pictures => {
      this.productPictures = pictures;

      this.dropzoneConfig = {
        url: `api/v1/product/addfile/${this.product.productId}/`,
        maxFilesize: 10,
        acceptedFiles: "image/*",
        height: 150,
        //addRemoveLinks: true,
        //dictRemoveFile: this.translateService.instant("Delete"),
      };

      if(this.fileUploader)
        this.initDropzone(this.fileUploader);

      setTimeout(() => { this.ignoreDelete = false }, 1000);
    });

    this.tabs.setActiveTab(1);
    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      300,
    );
  }

  public addProduct() {
    this.product.ingredients = this.ingredients.join(",");
    this.product.additives = this.additives.join(",");
    this.product.specialWishes = this.specialWishes.join(",");

    this.productService.updateProduct(this.product).subscribe((product) => {
      this.hide();
      if (this.onProductAdded) {
        this.onProductAdded(product);
      }
    }, (error) => {
      this.messageService.error(error);
    });
  }

  public onGetActiveTab($event: any) {
    if (!$event) { return; }

    if ($event.activeTabIndex === 0) {
      this.nameInputRef.nativeElement.focus();
    } else if ($event.activeTabIndex === 1) {
      this.txtSpecialWishRef.nativeElement.focus();
    }
  }

  public initDropzone(uploader: any) {
    this.fileUploader = uploader;
    uploader.removeAllFiles();
    const files = this.productPictures;
    files.forEach(async (file) => {
      const thumbnailImage = find(await this.localStorage.getProductPicturesAsync(), {dimensions: "thumbnail", originalFileId: file.id});
      const url = thumbnailImage ? thumbnailImage.url : file.url;

      const mockFile = {name: file.fileName, size: file.size, type: file.contentType, id: file["rowKey"]};
      uploader.emit("addedfile", mockFile);
      uploader.emit("thumbnail", mockFile, url);
      uploader.files.push(mockFile);

      mockFile["previewElement"].classList.add("dz-success");
      mockFile["previewElement"].classList.add("dz-complete");

      this.appendRemoveButton(mockFile);
    });
  }

  public async onUploadSuccess(file: any): Promise<any> {
    if (file.status === "success") {
      const addedFiles = JSON.parse(file.xhr.response);
      const addedFile = find(addedFiles, {fileName: file.name});

      file.id = addedFile.id;

      const allProductPictures = await this.localStorage.getProductPicturesAsync();
      allProductPictures.push(addedFile);
      this.localStorage.setProductPictures(allProductPictures);

      this.productPictures = await this.getProductImages();
    }

    this.appendRemoveButton(file);
  }

  public async getProductImages() {
    const allProductPictures = await this.localStorage.getProductPicturesAsync();
    let result = filter(allProductPictures, {productId: this.product.productId, dimensions: "thumbnail"});
    if(result.length == 0) {
      result = filter(allProductPictures, {productId: this.product.productId, dimensions: "original"});
    }

    return result;
  }

  public addSideDish() {
    const sideDish = new SideDish();
    sideDish.id = uuid();
    this.product.sideDishes.push(sideDish);
  }

  public deleteSideDish(sideDish) {
    const index = findIndex(this.product.sideDishes, {id: sideDish.id});
    this.product.sideDishes.splice(index, 1);
  }

  public addNewPriceCategory() {
    this.product.priceCategories.push("");
    this.product.unitPrices.push("");
  }

  public deletePriceCategory(index: number) {
    this.product.priceCategories.splice(index, 1);
    this.product.unitPrices.splice(index, 1);
  }

  public trackByIdx(index: number, obj: any): any {
    return index;
  }

  private appendRemoveButton(file: any) {
    const productPictureContainer = document.getElementById("product-pictures");
    productPictureContainer.querySelectorAll(".dz-error .dz-error-message").forEach((el: HTMLElement) => {
      el.style.display = "none";
    });

    const loader = document.createElement("i");
    loader.classList.add("d-none", "css-loader", "css-loader-small", "mr-1");

    const removeBtn = document.createElement("a");
    removeBtn.setAttribute("href", "javascript:void(0)");
    removeBtn.innerHTML = this.translateService.instant("Delete");
    removeBtn.addEventListener("click", () => {
      loader.classList.remove("d-none");
      this.productService.deleteFile(file.id).subscribe(() => {
        this.localStorage.removeProductPicture(file.id);
        this.fileUploader.removeFile(file);
        loader.classList.add("d-none");
      }, error=> {
        loader.classList.add("d-none");
      });
    });

    const container = document.createElement("div");
    container.classList.add("d-flex", "justify-content-center");

    container.appendChild(loader);

    container.appendChild(removeBtn);

    file.previewElement.appendChild(container);
  }
}
