import {EventEmitter, Injectable, Output} from "@angular/core";
import {Route} from "@angular/router";
import {OrderedItem, Product, ProductCategory, Table} from "../models";
import {DayEndReport} from "../models/day.end.report.model";
import { InvoiceModel } from "../models/invoice.model";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {

  public isLoggedIn = false;

  @Output() public authEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public orderedItemsReloadedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public tableClickEvent: EventEmitter<string> = new EventEmitter();
  @Output() public menuCardCategorySelectedEvent: EventEmitter<ProductCategory> = new EventEmitter();
  @Output() public productOrderedEvent: EventEmitter<Product> = new EventEmitter();
  @Output() public tableRefreshEvent: EventEmitter<string> = new EventEmitter();
  @Output() public removeOrderedItemEvent: EventEmitter<OrderedItem> = new EventEmitter();
  @Output() public deleteSplittedTableEvent: EventEmitter<string> = new EventEmitter();
  @Output() public tableSplittedEvent: EventEmitter<Table> = new EventEmitter();
  @Output() public favoriteProductEvent: EventEmitter<Product> = new EventEmitter();
  @Output() public searchStartedEvent: EventEmitter<string> = new EventEmitter();
  @Output() public visibleCategoriesUpdateEvent: EventEmitter<string[]> = new EventEmitter();
  @Output() public dashboardItemsEvent: EventEmitter<OrderedItem[]> = new EventEmitter();
  @Output() public importProductFinishedEvent: EventEmitter<string> = new EventEmitter();
  @Output() public loadingProductsFinishedEvent: EventEmitter<string> = new EventEmitter();
  @Output() public receiptSelectedEvent: EventEmitter<InvoiceModel> = new EventEmitter();
  @Output() public receiptUpdatedEvent: EventEmitter<InvoiceModel> = new EventEmitter();
  @Output() public receiptAddedEvent: EventEmitter<InvoiceModel> = new EventEmitter();
  @Output() public zReportSelectedEvent: EventEmitter<DayEndReport> = new EventEmitter();
  @Output() public navigatedToEvent: EventEmitter<Route> = new EventEmitter();
  @Output() public settingsUpdatedEvent: EventEmitter<string> = new EventEmitter();
  @Output() public selfOrderListEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public selfOrderMenuEvent: EventEmitter<OrderedItem[]> = new EventEmitter();
  @Output() public closeSelfOrderMenuEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() public selfOrderProductOrderEvent: EventEmitter<any> = new EventEmitter();
  @Output() public selfOrderProductDetailsEvent: EventEmitter<Product> = new EventEmitter();
  @Output() public orderMenuEvent: EventEmitter<any> = new EventEmitter();
  @Output() public updateOrderItemEvent: EventEmitter<OrderedItem> = new EventEmitter();
  @Output() public orderedItemUpdatedEvent: EventEmitter<OrderedItem> = new EventEmitter();
  @Output() public tableMovedEvent: EventEmitter<string> = new EventEmitter();
  @Output() public refreshNotificationsEvent: EventEmitter<string> = new EventEmitter();

  public logInStatusChanged(isLoggedIn) {
    this.isLoggedIn = isLoggedIn;
    this.authEvent.emit(this.isLoggedIn);
  }

  public orderedItemsReloaded() {
    this.orderedItemsReloadedEvent.emit();
  }

  public tableClicked(tableId: string) {
    this.tableClickEvent.emit(tableId);
  }

  public menuCardCategorySelected(category: ProductCategory) {
    this.menuCardCategorySelectedEvent.emit(category);
  }

  public productOrdered(product: Product) {
    this.productOrderedEvent.emit(product);
  }

  public tableRefresh(tableId: string) {
    this.tableRefreshEvent.emit(tableId);
  }

  public removeOrderedItem(item: OrderedItem) {
    this.removeOrderedItemEvent.emit(item);
  }

  public deleteSplittedTable(tableId: string) {
    this.deleteSplittedTableEvent.emit(tableId);
  }

  public tableSplitted(table: Table) {
    this.tableSplittedEvent.emit(table);
  }

  public favoriteProductUpdated(product: Product) {
    this.favoriteProductEvent.emit(product);
  }

  public searchStarted(searchText: string) {
    this.searchStartedEvent.emit(searchText);
  }

  public visibleCategoriesUpdated(categories: string[]) {
    this.visibleCategoriesUpdateEvent.emit(categories);
  }

  public dashboardItems(items: OrderedItem[]) {
    this.dashboardItemsEvent.emit(items);
  }

  public importProductFinished() {
    this.importProductFinishedEvent.emit("");
  }

  public loadingProductsFinished() {
    this.loadingProductsFinishedEvent.emit("");
  }

  public receiptSelected(receipt: InvoiceModel) {
    this.receiptSelectedEvent.emit(receipt);
  }

  public receiptUpdated(receipt: InvoiceModel) {
    this.receiptUpdatedEvent.emit(receipt);
  }

  public receiptAdded(receipt: InvoiceModel) {
    this.receiptAddedEvent.emit(receipt);
  }

  public zReportSelected(report: DayEndReport) {
    this.zReportSelectedEvent.emit(report);
  }

  public navigatedTo(page: Route) {
    this.navigatedToEvent.emit(page);
  }

  public settingsUpdated() {
    this.settingsUpdatedEvent.emit("");
  }

  public openSelfOrderList() {
    this.selfOrderListEvent.emit(true);
  }

  public openSelfOrderMenu(items: OrderedItem[]) {
    this.selfOrderMenuEvent.emit(items);
  }

  public closeSelfOrderMenu() {
    this.closeSelfOrderMenuEvent.emit(true);
  }

  public openProductForOrder(product: Product, category: ProductCategory) {
    this.selfOrderProductOrderEvent.emit({product, category});
  }

  public openProductForDetails(product: Product) {
    this.selfOrderProductDetailsEvent.emit(product);
  }

  public orderMenuOpened() {
    this.orderMenuEvent.emit(null);
  }

  public orderedItemUpdated(item: OrderedItem) {
    this.orderedItemUpdatedEvent.emit(item);
  }

  public tableMoved(categoryId: string) {
    this.tableMovedEvent.emit(categoryId);
  }

  public refreshNotifications() {
    this.refreshNotificationsEvent.emit();
  }
}
