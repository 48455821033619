export enum OrderStatus {
  new = 0,
  cooking = 1,
  ready = 2,
  served = 3,
  paid = 4,
  cancelled = 5,
  returned = 6,
  waitingForApproval = 7,
}
