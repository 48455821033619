import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import { ModalClassComponent } from "src/app/interfaces/ModalClassComponent";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-table-split-enter-name",
  templateUrl: "./table-split-enter-name.component.html",
  styleUrls: ["./table-split-enter-name.component.scss"],
})
export class CreateCategoryDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("inputName")
  public nameInputRef: ElementRef;

  public name: string;
  public splitTableCallback: any;

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("table-split-enter-name", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.name = params.name ?? "";
    this.splitTableCallback = params.splitTable;
    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
        this.nameInputRef.nativeElement.select();
      },
      300,
    );
  }

  public splitTable() {
    this.nameInputRef.nativeElement.focus();
    if (this.splitTableCallback) {
      this.splitTableCallback(this.name);
      this.hide();
    }
  }
}
