<div class="card justify-content-between align-items-center flex-row action-toolbar" style="flex-direction: row !important;">
  <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded toolbar-item" style="background-color:white !important;"
     (click)="showAddCategoryDialog()">
    <i class="fa fa-plus"></i>{{"AddCategory" | translate}}
  </a>

  <a href="javascript:void(0);" class="d-flex m-3" style="font-size: 15px;" (click)="showHelp()">
    <span class="far fa-play-circle" style="font-size: 20px; vertical-align: middle;"></span>
    <span>{{"Help" | translate}}</span>
  </a>
</div>

<app-table-category-list [tableCategories]="tableCategories"></app-table-category-list>
<app-show-table-list-dialog></app-show-table-list-dialog>
<app-create-category-dialog></app-create-category-dialog>
<app-create-table-dialog></app-create-table-dialog>
<app-create-multi-tables-dialog></app-create-multi-tables-dialog>
<app-selforder-settings-dialog></app-selforder-settings-dialog>
<app-import-tables-dialog></app-import-tables-dialog>
