import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import {LocalStorageService} from "../../../../services/localstorage.service";

@Component({
  selector: "app-receipt-toolbar",
  templateUrl: "./receipt-toolbar.component.html",
  styleUrls: ["./receipt-toolbar.component.scss"],
})
export class ReceiptToolbarComponent implements OnInit {
  @Output()
  public onFilterUpdated = new EventEmitter();

  public ranges: any = {};
  public selectedDates = {startDate: dayjs(), endDate: dayjs()};
  public applyLabel = "";
  public customRangeLabel = "";
  public paymentTypes = [];
  public selectedPaymentType = "";
  private sub: any;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {

    this.ranges[this.translateService.instant("Today")] = [dayjs(), dayjs()];
    this.ranges[this.translateService.instant("Yesterday")] = [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")];
    this.ranges[this.translateService.instant("Last7Days")] = [dayjs().subtract(6, "day"), dayjs()];
    this.ranges[this.translateService.instant("Last30Days")] = [dayjs().subtract(29, "day"), dayjs()];
    this.ranges[this.translateService.instant("ThisMonth")] = [dayjs().startOf("month"), dayjs().endOf("month")];
    this.ranges[this.translateService.instant("LastMonth")] = [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")];

    this.applyLabel = this.translateService.instant("Apply");
    this.customRangeLabel = this.translateService.instant("CustomRange");
  }

  public async ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      const from = params['from'];
      const to = params['to'];

      if(from && to) {
        const fromDate = dayjs(from);
        const toDate = dayjs(to);

        this.selectedDates = {startDate: fromDate, endDate: toDate};
        this.onFilterUpdated.emit({date: this.selectedDates, paymentType: this.selectedPaymentType});
      }
      else {
        this.datesUpdated(this.selectedDates);
      }
    });

    this.paymentTypes = await this.localStorage.getPaymentTypesAsync();
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  public datesUpdated($event: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }) {
    if ($event.startDate != null && $event.endDate != null) {
      $event.startDate = $event.startDate.hour(0).minute(0).second(0);
      $event.endDate = $event.endDate.hour(23).minute(59).second(0);

      this.selectedDates = $event;
      this.onFilterUpdated.emit({date: this.selectedDates, paymentType: this.selectedPaymentType});
    }
  }

  filterInvoices(paymentType: string) {
    this.onFilterUpdated.emit({date: this.selectedDates, paymentType: this.selectedPaymentType});
  }
}
