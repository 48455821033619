import {Injectable} from "@angular/core";
import {LocalStorageService} from "./localstorage.service";
@Injectable({
  providedIn: "root",
})
export class SoundService {
  private audio: HTMLAudioElement;

  constructor(
    private localStorage: LocalStorageService,
  ) {
   this.audio = new Audio("/sounds/elevator-bell.mp3");
  }

  public async play(): Promise<any> {
    const volume = await this.localStorage.getValueAsync<number>("volume");
    if (!isNaN(volume)) {
      if (volume == 0) { return; }

      this.audio.volume = volume / 100;
    } else {
      return;
    }

    this.audio.muted = true;
    this.audio.muted = false;
    const promise = this.audio.play();

    if (promise.then) {
      promise.then((_) => {

      }).catch((error) => {
        console.error(error);
      });
    }
  }

}
