import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {AboutComponent} from "./about/about.component";
import {HomeComponent} from "./home/home.component";
import {KitchenModuleComponent} from "./kitchen-module/kitchen-module.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {ReportingComponent} from "./reporting/reporting.component";

const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full"},
  { path: "home", component: HomeComponent, pathMatch: "full"},
  { path: "about", component: AboutComponent},
  { path: "reporting", component: ReportingComponent},
  { path: "kitchen-module", component: KitchenModuleComponent},
  { path: "privacy/policy", component: PrivacyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule { }
