import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {Table, TableCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: "app-show-table-list-dialog",
  templateUrl: "./show-table-list-dialog.component.html",
  styleUrls: ["./show-table-list-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShowTableListDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @Input()
  public category: TableCategory = new TableCategory();
  public tables: Table[] = [];

  public listType = "";

  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("show-table-list-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params) {
    super.show(params);
    this.modal.show();
    this.category = params.category;
    this.tables = params.tables;
  }

  public showAddTableDialog() {
    this.modalService.show("create-table-dialog", {
      category: this.category,
      onTableAdded: (table) => {
        this.tables.push(table);
        this.localStorage.addTable(table);
      },
    });
  }

  public showAddMultipleTablesDialog() {
    this.modalService.show("create-multiple-tables-dialog", {
      category: this.category,
      onTableAdded: (tables) => {
        tables.forEach((table) => {
          this.tables.push(table);
          this.localStorage.addTable(table);
        });
      },
    });
  }

  public showImportTablesDialog() {
    this.modalService.show("import-tables-dialog", {
      category: this.category,
      onTableAdded: (tables) => {
        tables.forEach((table) => {
          this.tables.push(table);
          this.localStorage.addTable(table);
        });
      },
    });
  }
}
