import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ModalDirective} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import { Customer } from "../../../../models/customer.model";
import {CustomerService} from "../../../../services/customer.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: "app-create-customer",
  templateUrl: "./create-customer.component.html",
  styleUrls: ["./create-customer.component.scss"],
})
export class CreateCustomerComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @BlockUI() public blockUI: NgBlockUI;

  public customer: Customer = new Customer();
  public onCustomerAdded: any;
  constructor(
    private modalService: ModalService,
    private customerService: CustomerService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("create-customer-dialog", this);
  }

  public async addCustomer() {
    this.blockUI.start(await this.translateService.get("PleaseWait").toPromise());

    if (this.customer.id) {
      this.customer.id = uuid();
    }

    this.customerService.updateCustomer(this.customer).subscribe(async (customer) => {
      this.messageService.success(await this.translateService.get("UpdateCustomerSuccess").toPromise());
      this.hide();

      if (this.onCustomerAdded) {
        this.onCustomerAdded(customer);
      }

      this.blockUI.stop();
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.customer = params.customer;
    this.onCustomerAdded = params.onCustomerAdded;
    this.modal.show();
  }
}
