<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalPublishMenu" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">{{"PublishMenu" | translate}}</p>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" [hidden]="packageInfo.price > 0" [innerHTML]='"NeedToSubscribeToPaidPackage" | translate'></div>

        <div class="alert alert-warning">
          {{"MenuDigitalQRCode_Help" | translate}}
        </div>

        <div class="form-group d-flex justify-content-between" style="margin-bottom: 0px;">
          <label for="menuDigitalQRCode" style="margin-top: 2px;">{{"MenuDigitalQRCode" | translate}}</label>

          <div class="switch round blue-white-switch">
            <label>
              <input type="checkbox" name="selfOrderDigitalQRCode"
                     id="menuDigitalQRCode" value="true"
                     [(ngModel)]="userSettings.enableDigitalMenu"
                     [disabled]="packageInfo.price == 0"
                      (ngModelChange)="updateEnableDigitalMenu($event)"/>
              <span class="lever"></span>
            </label>
          </div>
        </div>

        <div *ngIf="packageInfo.price > 0 && userSettings.enableDigitalMenu">
          <a *ngIf="userSettings.digitalMenuUrl" [href]="userSettings.digitalMenuUrl">{{userSettings.digitalMenuUrl}}</a>
          <a href="javascript:void(0);" (click)="downloadQRCode()">{{"DownloadMenuQrCode" | translate}}</a>
        </div>
        <div *ngIf="packageInfo.price > 0 && userSettings.enableDigitalMenu">
          <img src="/api/v1/qrcode/create/menu?width=200&height=200&date={{currentDate}}" />
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
          {{"Close" | translate}}
        </a>
      </div>
    </div>
  </div>
</div>
