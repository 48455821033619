import { BaseModel } from "./base.model";
import { InvoiceItem } from "./invoice-item.model";
import { InvoiceTaxInfo } from "./invoice-taxinfo.model";

export class InvoiceModel extends BaseModel {
  public customerId: string;

  public type: string;

  public date: Date | string;

  public invoiceNumber: number;

  public referenceInvoiceNumber: number[];

  public cashRegisterNumber: number;

  public tableId: string;

  public tableName: string;

  public paymentType: string;

  public employeeId: string;

  public employeeName: string;

  public currency: string;

  public cancellation: string;

  public description: string;

  public taxIdentification: string;

  public discountPercentage: number;

  public discountAmount: number;

  public totalPriceGross: number;

  public totalPriceNet: number;

  public totalTaxAmount: number;

  public tipAmount: number;

  public cardFeeAmount: number;

  public taxAmounts: InvoiceTaxInfo[];

  public billAmount: number;

  public billRemainder: number;

  public combinedPaymentTypes: { [key: string]: number };

  public invoiceItems: InvoiceItem[];
}
