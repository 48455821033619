<div id="ordersList" class="right-container"
     [class.right-container-slide-right]="menuState == 'closed'"
     [class.right-container-slide-left]="menuState == 'opened'">
  <nav class="navbar navbar-expand-lg navbar-inverse bg-primary navbar-pos-order-list sub-nav">
    <div class="navbar-collapse full-width" style="display:flex;height:65px;">
      <ul class="navbar-nav navbar-header mr-auto">
        <li class="nav-item">
          <a href="javascript:void(0);" (click)="closeItemList()" class="toolbar-item back-nav-item">
            <i class="fa fa-chevron-left"></i>
            <span>{{"Back" | translate}}</span>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" style="float:right;margin: 0;flex-direction:row;">
        <li class="nav-item" style="margin-left:0;">
          <div class="navbar-title">{{tableName}}</div>
        </li>
      </ul>
    </div>
  </nav>

  <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 10; ">
    <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;background: #dedede; opacity: 0.8"></div>
    <div style="background-color: rgba(0, 0, 0, 0.8);border-radius: 10px;padding: 20px;z-index:1">
      <div class='css-loader margin-bottom m-auto'></div>
      <div class="text-white">{{"PleaseWait" | translate}}</div>
    </div>
  </div>

  <div *ngIf="!tableId">
    <div class="alert alert-warning" style="margin:10px;" role="alert">
      {{"ChooseTable" | translate}}
    </div>
  </div>

  <div *ngIf="tableId">
    <div style="margin-top: 5px; margin-bottom:5px;">
      <div class="order-list-action-bar">
        <button *ngIf="allowCourses" (click)="addCourse()" class="btn btn-outline-primary btn-rounded toolbar-item"
                data-toggle="modal">
          <i class="fa fa-plus"></i>
          <span>{{"AddCourse" | translate}}</span>
        </button>
        <div mdbDropdown #dropdown2='bs-dropdown' class="btn-group" *ngIf="!allowCourses && allowAddProducts" (click)="$event.stopPropagation()">
          <button id="btn-show-menu" (click)="showMenu(courses[0])" type="button" class="btn btn-outline-primary btn-rounded toolbar-item"
                  style="padding:9px; background: white !important;">
            <i class="fa fa-plus"></i>
            <span style="white-space: nowrap">{{"Add" | translate}}</span>
          </button>
          <button type="button" mdbDropdownToggle mdbBtn color="primary" outline="true" rounded="true"
                  class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
                  style="background: white !important;"
                  mdbWavesEffect></button>
          <div class="dropdown-menu z-depth-2" style="right: 0; left: auto;">
            <a (click)="createItemAtRunTime(dropdown2)" class="dropdown-item text-primary toolbar-item justify-content-start" href="javascript:void(0);">
              <i class="fa fa-plus"></i>
              <span style="white-space: nowrap">{{"AddProductQuickly" | translate}}</span>
            </a>
            <a (click)="showItemsCreatedAtRunTime(dropdown2)" class="dropdown-item text-primary toolbar-item justify-content-start" href="javascript:void(0);">
              <i class="fa fa-list"></i>
              <span style="white-space: nowrap">{{"ChooseFromQuickProducts" | translate}}</span>
            </a>
          </div>
        </div>
        <button id="btn-show-menu" (click)="showMenu(courses[0])" *ngIf="!allowCourses && !allowAddProducts" type="button"
                class="btn btn-outline-primary btn-rounded toolbar-item"
                style="background: white !important;">
          <i class="fa fa-plus" aria-hidden="true"></i>
          <span>{{"Add" | translate}}</span>
        </button>
        <button (click)="serveItems()" *ngIf="isServeButtonVisible" class="btn btn-outline-primary btn-rounded toolbar-item">
          <i class="fa fa-check mr-1"></i>
          <span>{{"Served" | translate}}</span>
        </button>

        <button (click)="sendItems()" *ngIf="isSendButtonVisible" class="btn btn-outline-primary btn-rounded toolbar-item">
          <i class="fas fa-paper-plane"></i>
          <span>{{"SendToKitchen" | translate}}</span>
        </button>
      </div>
    </div>
    <div class="table-name-cont z-depth-1" style="display:none;">{{tableName}}</div>
    <div class="accordion md-accordion pos-course-list"
         id="accordion-gangs"
         role="tablist"
         aria-multiselectable="true"
         style="bottom: 110px">
      <div class="no-radius border-0" *ngFor="let course of courses">
        <div *ngIf="course.visible" class="card-header info-color lighten-3 p-0 z-depth-1-half"
             [class.orange]="course.active">
          <table style="color:white;width:100%;" (click)="toggleSlide(course)" [class.collapsed]="course.collapsed">
            <tr>
              <td style="width: 20px;">
                <i class="fa fa-angle-up rotate-icon mt-2 mb-2 ml-1" style="font-size:30px;"></i>
              </td>
              <td>
                <h6 class="text-uppercase align-middle ml-2 mb-2 mt-2">{{course.title}}</h6>
              </td>
              <td style="width: 20px;" (click)="$event.stopPropagation();">
                <div mdbDropdown #dropdown='bs-dropdown' class="btn-group" *ngIf="allowAddProducts">
                  <button id="btn-show-menu" (click)="showMenu(course)" type="button" class="btn btn-outline-primary btn-rounded background-white toolbar-item"
                          style="padding:9px;">
                    <i class="fa fa-plus mr-1"></i>
                    <span style="white-space: nowrap">{{"Add" | translate}}</span>
                  </button>
                  <button type="button" mdbDropdownToggle mdbBtn color="primary" outline="true" rounded="true"
                          class="dropdown-toggle dropdown-toggle-split px-3 background-white"
                          mdbWavesEffect></button>
                  <div class="dropdown-menu z-depth-2" style="right: 0; left: auto;">
                    <a (click)="createItemAtRunTime(dropdown)" class="dropdown-item text-primary toolbar-item justify-content-start"
                       href="javascript:void(0);">
                      <i class="fa fa-plus"></i>
                      <span style="white-space: nowrap">{{"AddProductQuickly" | translate}}</span>
                    </a>
                    <a (click)="showItemsCreatedAtRunTime(dropdown)" class="dropdown-item text-primary toolbar-item justify-content-start" href="javascript:void(0);">
                      <i class="fa fa-list"></i>
                      <span style="white-space: nowrap">{{"ChooseFromQuickProducts" | translate}}</span>
                    </a>
                  </div>
                </div>
                <button id="btn-show-menu" (click)="showMenu(course)" [hidden]="allowAddProducts" type="button"
                        class="btn btn-outline-primary btn-rounded background-white toolbar-item"
                        style="height: 35px; padding: 5px 10px;">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  <span>{{"Add" | translate}}</span>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="course-items-container collapse show" role="tabpanel" [attr.id]="course.id" *ngIf="!course.collapsed">
          <div class="card-body p-0">
            <div class="pos-orders-list">
              <div class="speech-bubble color-block warning-color z-depth-1"
                   *ngIf="course.items.length == 0">{{"NoOrdersAvailable_AddButton" | translate}}</div>
              <div class="d-flex flex-column">
                <app-single-order-item *ngFor="let item of course.items"
                                       [item]="item"
                                       [courseItems]="course.items"
                                       [showEditPrice]="false"
                                       [showCancelButton]="true"
                                       [isSeparatePaymentActive]="isSeparatePaymentActive"
                                       [isSeparateMoveActive]="isSeparateMoveActive">

                </app-single-order-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="text-align: center;position: absolute;bottom: 55px;left: 0;right: 0;height: 50px;background: #b7b7b7;">
      <label class="w-100" style="font-size:25px;line-height:1;margin-top: 12px;">
        {{totalQuantity}}x / {{ subTotal | otcurrency }}
      </label>
    </div>
    <div class="table-payment-cont">
      <button [mdbPopover]="paymentTypeTemplate" [triggers]="'click:focus'" [containerClass]="'info-popover'"
              class="btn btn-primary btn-rounded toolbar-item"
              *ngIf="isPaymentButtonVisible && !isSeparatePaymentActive && !isSeparateMoveActive">
        <i class="fa fa-credit-card"></i>
        <span>{{"Pay" | translate}}</span>
      </button>
      <button (click)="showPaymentDialog(separatePayment)" *ngIf="isSeparatePaymentActive"
              class="btn btn-primary btn-rounded toolbar-item">
        <i class="fa fa-credit-card"></i>
        <span>{{"Pay" | translate}}</span>
      </button>
      <button (click)="showMoveDialog()" *ngIf="isSeparateMoveActive" class="btn btn-primary btn-rounded toolbar-item">
        <i class="fa fa-random"></i>
        <span>{{"Move" | translate}}</span>
      </button>
      <button [mdbPopover]="moveTypeTemplate" [triggers]="'click:focus'" [containerClass]="'info-popover'"
              class="btn btn-primary btn-rounded toolbar-item ml-auto"
              *ngIf="orderedItems.length > 0 && !isSeparatePaymentActive && !isSeparateMoveActive">
        <i class="fa fa-random"></i>
        <span>{{"MoveItems" | translate}}</span>
      </button>
      <button (click)="cancelSeparatePayment()" class="btn btn-danger btn-rounded toolbar-item"
              *ngIf="isSeparatePaymentActive || isSeparateMoveActive">
        <i class="fa fa-remove mr-1"></i>
        <span>{{"Cancel" | translate}}</span>
      </button>
    </div>
  </div>
</div>

<ng-template #paymentTypeTemplate>
  <div>
    <button mdbBtn color="primary" (click)="activateSeparatePayment()"
            class="w-100 mb-2">{{"SeperatePayment" | translate}}</button>

    <button mdbBtn color="primary" (click)="showPaymentDialog(totalPayment)"
            class="w-100">{{"TogetherPayment" | translate}}</button>
  </div>
</ng-template>

<ng-template #moveTypeTemplate>
  <div>
    <button mdbBtn color="primary" (click)="activateSeparateMove()"
            class="w-100 mb-2">{{"SeparateMove" | translate}}</button>

    <button mdbBtn color="primary" (click)="showMoveDialog()" class="w-100">{{"TogetherMove" | translate}}</button>
  </div>
</ng-template>

