import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {timeout} from "rxjs/operators";
import { User } from "../models";
import { UserSettings } from "../models";
@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient) {
  }

  public resetLogo() {
    return this.http.delete(`/api/v1/account/deletelogo`);
  }

  public saveUser(user: User) {
    return this.http.post<User>(`/api/v1/account/update`, user);
  }

  public applyPackage(packageId: string) {
    return this.http.post(`/api/v1/account/updatepackage/${packageId}`, null);
  }

  public updateCreditCard(creditCardId: string): Observable<{result, client_secret}> {
    return this.http.post<{result: any, client_secret: string}>(`/api/v1/account/updatecreditcard/${creditCardId}`, null);
  }

  public updatePaymentMethod(paymentMethodId: string): Observable<{result, client_secret}> {
    return this.http.post<{result: any, client_secret: string}>(`/api/v1/account/updatepaymentmethod/${paymentMethodId}`, null);
  }

  public saveSettings(userSettings: UserSettings) {
    return this.http.post<UserSettings>(`/api/v1/account/updateusersettings/`, userSettings);
  }

  savePaypalSubscription(subscriptionID: string) {
    return this.http.post(`/api/v1/account/updatepaypalsubscription/${subscriptionID}`, null);
  }
}
