<nav class="navbar navbar-expand-lg navbar-inverse bg-primary navbar-pos-order-list sub-nav">
  <div class="navbar-collapse full-width" style="display:flex;height:65px;">
    <ul class="navbar-nav navbar-header mr-auto">
      <li class="nav-item">
        <a href="javascript:void(0);" (click)="closeItemList()" style="font-size: 20px;white-space:nowrap;">
          <i class="fa fa-chevron-left"></i>
          <span>{{"Back" | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="retail-payment-container" style="">
  <div class="table-payment-cont" style="position: absolute; left: 0; right: 0; top: 0;">
    <table style="width: 100%; height: 50px; min-height: 65px;">
      <tr>
        <td style="font-size:25px;text-align: left; padding-left: 10px;">
          <label>
            {{totalAmount}}x
          </label>
        </td>
        <td style="font-size:25px;text-align: right; padding-right: 10px;">
          <label>
            {{totalPrice | otcurrency}}
          </label>
        </td>
      </tr>
    </table>
  </div>

  <div style="position: absolute;top: 65px; bottom: 65px; left: 0;right: 0;">
    <div [hidden]="orderedItems.length > 0">
      <div class="alert color-block warning-color white-text z-depth-1" style="margin:10px;z-index:1" role="alert">
        {{"AddProductsFromMenu" | translate}}
      </div>
    </div>

    <div class="retail-orders-list">
      <ul class="list-unstyled">
        <app-single-order-item *ngFor="let item of orderedItems"
                               [item]="item"
                               [showEditPrice]="true"
                               [courseItems]="orderedItems  "></app-single-order-item>
      </ul>
    </div>
  </div>

  <div class="table-payment-cont">
    <button class="btn btn-primary btn-rounded"
            (click)="showTotalPayment()"
            [attr.disabled]="orderedItems.length == 0 ? '' : null"
            style="width: 90%; margin-left: 5%;">
      <i class="fa fa-credit-card mr-1" style="font-size:18px;"></i>
      <span>{{"Pay" | translate}}</span>
    </button>
  </div>
</div>

