import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {TableCategory} from "../../../../models";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";

@Component({
  selector: "app-import-categories-dialog",
  templateUrl: "./import-categories-dialog.component.html",
  styleUrls: ["./import-categories-dialog.component.scss"],
})
export class ImportCategoriesDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public category: TableCategory;
  public addCategory: any;

  public selectedFile: any;

  public loading: boolean;

  public importSuccess: boolean;

  public importedCount: number;

  constructor(
    private modalService: ModalService,
    private productService: ProductService,
    private messageService: MessageService,
    public translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("import-categories-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);

    // reset variables
    this.selectedFile = null;
    this.loading = false;
    this.importSuccess = false;
    this.importedCount = null;

    this.addCategory = params.addCategory;
    this.category = params.category;

    this.modal.show();
  }

  public importCategories() {
    if (!this.selectedFile) {
      this.messageService.warning(this.translateService.instant("NoFileChosen"));
      return false;
    } else if (!this.selectedFile.name.endsWith(".xlsx")) {
      this.messageService.warning(this.translateService.instant("ChooseExcelFile"));
      return false;
    }

    this.loading = true;

    const fd = new FormData();
    fd.append("file", this.selectedFile);
    this.productService.importCategories(fd).subscribe((categories) => {
      this.loading = false;
      this.selectedFile = null;
      this.importSuccess = true;
      this.importedCount = categories.length;

      if (this.addCategory) {
        this.addCategory(categories);
      }
    }, (error) => {
      this.loading = false;

      this.messageService.error(error);
    });
  }

  public onFileUploaded(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
    }
  }
}
