import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss", "./home.component.media.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  public selfOrderingEmbed: Observable<string | any>;

  public restaurantName: string;
  public logoUrl: string;
  public backgroundColor = "white";

  constructor(
    private translateService: TranslateService,) {
  }

  public ngOnInit() {
    this.restaurantName = localStorage.getItem("restaurant-name");
    this.logoUrl = localStorage.getItem("user-logo");
    if(this.logoUrl == "null") this.logoUrl = null;

    const userSettings = localStorage.getItem("user-settings");
    if(userSettings) {
      const settings = JSON.parse(userSettings);
      this.backgroundColor = settings.selfOrderMainColor;
    }

    this.selfOrderingEmbed = this.translateService.get("Youtube_SelfOrdering");
  }
}
