import {Component, OnInit, ViewChild} from "@angular/core";
import * as html2canvas from "html2canvas";
import {ModalDirective} from "ng-uikit-pro-standard";
import { Employee } from "src/app/models/employee.model";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {MessageService} from "../../../../services/message.service";
import { ModalService } from "../../../../services/modal.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-qr-code-dialog",
  templateUrl: "./qr-code-dialog.component.html",
  styleUrls: ["./qr-code-dialog.component.scss"],
})
export class QrCodeDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public employee: Employee = new Employee();

  public showLoading = false;

  public qrCodeSrc: string;
  public qrCodeShortUrl: string;

  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private messageService: MessageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("employee-qr-code-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.employee = params.employee;
    this.modal.show();
    this.qrCodeSrc = "/api/v1/qrcode/create/user?userId=" + this.employee.userId + "&width=250&height=250&time=" + new Date().toISOString();
  }

  public onLoadQrCode() {
    this.showLoading = false;

    this.loadQrCodeShortUrl(this.employee.id);
  }

  public downloadQrCode() {
    // @ts-ignore
    html2canvas(document.getElementById("qrcode-image")).then((canvas) => {
      // Convert the canvas to blob
      canvas.toBlob((blob) =>  {
        // To download directly on browser default 'downloads' location
        const link = document.createElement("a");
        link.download = this.employee.userName + ".png";
        link.href = URL.createObjectURL(blob);
        link.click();

        // To save manually somewhere in file explorer
        // @ts-ignore
        window.saveAs(blob, "image.png");

      }, "image/png");
    });
  }

  private loadQrCodeShortUrl(id: string) {
    this.tableService.loadTableShortUrl(id).subscribe((url) => {
      this.qrCodeShortUrl = url;
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
