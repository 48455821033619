<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-lg modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{"MoveOrder_ChooseTable" | translate}}</h4>

        <button type="button" class="close" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div id="table-tabs" class="classic-tabs text-center">
          <div *ngIf="tableCategories.length > 1">
            <ul class="nav nav-pills mb-3 table-category-list" id="pills-tab" role="tablist">
              <li class="nav-item" *ngFor="let category of tableCategories;">
                <a class="nav-link"
                   [class.active]="activeCategoryId === category.id"
                   (click)="activeCategoryId = category.id">{{category.name}}</a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade"
                   *ngFor="let category of tableCategories; let $index = index;"
                   [class.show]="activeCategoryId === category.id"
                   [class.active]="activeCategoryId === category.id"
                   [id]="category.id"
                   role="tabpanel">
                <div *ngFor="let table of groupedTables[category.id]"
                     [id]="table.id"
                     [class.selected]="selectedTableId === table.id"
                     (click)="selectTable(table.id)"
                     class="panel panel-default table-cont pos-table-cont">
                  <div class="inner">
                    <span style="width:100%;">{{table.name}}</span>
                  </div>
                </div>
                <div *ngIf="groupedTables[category.id]?.length == 0" style="margin: 10px;">
                  <img src="/images/table.svg" style="width: 200px;"/>
                  <div class="text-info informative-text m-3" role="alert" [innerHTML]="'PosNoTablesToMove' | translate"></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="tableCategories.length == 1">
            <div *ngFor="let table of groupedTables[tableCategories[0].id]"
                 [id]="table.id"
                 [class.selected]="selectedTableId === table.id"
                 (click)="selectTable(table.id)"
                 class="panel panel-default table-cont pos-table-cont mt-2">
              <div class="inner">
                <span style="width:100%;">{{table.name}}</span>
              </div>
            </div>

            <div *ngIf="tables.length == 0" style="margin: 10px;">
              <img src="/images/table.svg" style="width: 200px;" />
              <div class="text-info informative-text m-3" role="alert" [innerHTML]="'PosNoTablesToMove' | translate"></div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button mdbBtn rounded="true" color="info" (click)="moveItems()">
          <i class="fas fa-check pr-1"></i>
          <span>{{"Move" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
