import {Injectable} from "@angular/core";
import {ToastService} from "ng-uikit-pro-standard";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  public options = {
    progressBar: false,
    showDuration: 100,
    hideDuration: 100,
    timeOut: 5000,
    opacity: 1,
    positionClass: "md-toast-top-center",
    enableHtml: true,
  };
  private loadingToast: any;
  constructor(
    private toast: ToastService) {
    this.toast.toastConfig.preventDuplicates = true;
    this.toast.toastConfig.newestOnTop = true;
  }

  public info(message: string, timeout: number = 5000) {
    this.options.timeOut = timeout;
    this.toast.info(message, "", this.options);
  }

  public success(message: string, timeout: number = 5000) {
    this.options.timeOut = timeout;
    this.toast.success(message, "", this.options);
  }

  public warning(message: string, timeout: number = 5000) {
    this.options.timeOut = timeout;
    this.toast.warning(message, "", this.options);
  }

  public error(message: string, timeout: number = 5000) {
    this.options.timeOut = timeout;
    this.toast.error(message, "", this.options);
  }

  public showLoading(message: string) {
    const options = {
      timeOut: 5000,
      opacity: 1,
      positionClass: "md-toast-top-center",
      toastClass: "black loading-toast",
    };
    this.loadingToast = this.toast.info(message, "", options);
  }

  public hideLoading() {
    if (this.loadingToast) {
      this.toast.remove(this.loadingToast.toastId);
    }
  }
}
