import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as _dayjs from "dayjs";
import {MdbTableDirective, MdbTablePaginationComponent} from "ng-uikit-pro-standard";
import {InvoiceInformation} from "../../../models/invoice.information.model";
import {AccountService} from "../../../services/account.service";
import {MessageService} from "../../../services/message.service";

const localizedFormat = require("dayjs/plugin/localizedFormat");
_dayjs.extend(localizedFormat);

@Component({
  selector: "app-ot-receipts-settings",
  templateUrl: "./ot-receipts.component.html",
  styleUrls: ["./ot-receipts.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OTReceiptsComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTableDirective, { static: true }) public mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) public mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("row", { static: true }) public row: ElementRef;

  public receipts: InvoiceInformation[] = [];
  public columns;
  public previous: string;
  public maxVisibleItems = 10;
  public dayjs = _dayjs;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private messageService: MessageService,
    private accountService: AccountService,
  ) {
  }

  public ngOnInit(): void {
    this.columns = [
      { key: "number", name: this.translateService.instant("InvoiceNumber")},
      { key: "creationDate", name: this.translateService.instant("CreateDate") },
      { key: "subtotal", name: this.translateService.instant("Subtotal") },
      { key: "tax", name: this.translateService.instant("Tax") },
      { key: "total", name: this.translateService.instant("TotalSum") },
      { key: "currency", name: this.translateService.instant("Currency") },
      { key: "status", name: this.translateService.instant("Status") },
    ];
  }

  public ngAfterViewInit() {
    this.messageService.showLoading(this.translateService.instant("LoadingReceipts"));
    this.accountService.loadReceipts().subscribe((receipts: InvoiceInformation[]) => {
      this.messageService.hideLoading();
      this.receipts = receipts;

      this.mdbTable.setDataSource(this.receipts);
      this.previous = this.mdbTable.getDataSource();
    }, (error) => {
      this.messageService.hideLoading();
      this.messageService.error(error);
    });

    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
}
