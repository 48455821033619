import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { Observable } from "rxjs";
import {OrderedItem, Table} from "../models";
import {LocalStorageService} from "./localstorage.service";
@Injectable({
  providedIn: "root",
})
export class PosService {
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  public getOrderedItems(): Observable<OrderedItem[]> {
    return this.http.get<OrderedItem[]>("/api/v1/ordereditem/detailed");
  }

  public getAllOrderedItems(): Observable<OrderedItem[]> {
    return this.http.get<OrderedItem[]>("/api/v1/ordereditem/all", {
      params: {
        "timeout": "30000"
      }
    });
  }

  public getTableIdsWithOrders(): Observable<string[]> {
    return this.http.get<string[]>("/api/v1/ordereditem/tableids", {
      "headers": {"timeout": "30000"}
    });
  }

  public getTableOrderedItems(tableId: string): Observable<OrderedItem[]> {
    return this.http.get<OrderedItem[]>("api/v1/ordereditem/list/" + tableId + "/true");
  }

  public sendItemsToKitchen(tableId: string, userId: string, mergedItems: any[]) {
    return this.http.put<OrderedItem[]>(`/api/v1/ordereditem/tokitchen/${tableId}/${userId}`, mergedItems);
  }

  public setItemsServed(tableId: string) {
    return this.http.put(`/api/v1/ordereditem/served/${tableId}/`, {});
  }

  public returnItem(orderedItemId: string) {
    return this.http.put<any>(`/api/v1/ordereditem/returnitem/${orderedItemId}`, {});
  }

  public payItems(paymentViewModel, retailMode: boolean) {
    let url = "/api/v1/ordereditem/pay";
    if (retailMode) { url = "/api/v1/ordereditem/payretail"; }

    return this.http.put<any>(url, paymentViewModel, {
      params: {
        "timeout": "20000"
      }
    });
  }

  public moveItems(moveViewModel) {
    return this.http.put<any>("/api/v1/ordereditem/move", moveViewModel);
  }

  public splitTable(tableId, name) {
    return this.http.get<any>(`/api/v1/table/split/${tableId}?tableName=${name}`);
  }

  public mergeTable(tableId: string) {
    return this.http.get<any>(`/api/v1/table/merge/${tableId}`);
  }

  public callTableWaiter(tableId: string) {
    return this.http.get<any>(`/api/v1/ordereditem/callwaiter/${tableId}`);
  }

  public callAllWaiters() {
    return this.http.get<any>(`/api/v1/ordereditem/callwaiter/-1`);
  }

  public updateOrderStatus(data: any) {
    return this.http.post<any>(`/api/v1/ordereditem/updatestatus`, data);
  }

  public getTableNewItems(tableId: string) {
    return this.http.get<{orderedItems: OrderedItem[], table: Table}>(`/api/v1/ordereditem/neworders/${tableId}/true`).toPromise();
  }

  public getOrdersToBeApproved(tableId: string = "") {
    let url = `/api/v1/ordereditem/waitingapproval`;
    if (tableId) { url += "/" + tableId; }

    return this.http.get<OrderedItem[]>(url);
  }

  public deleteCancelledItem(orderedItemId: string) {
    return this.http.delete(`/api/v1/ordereditem/${orderedItemId}`);
  }
}
