import {DOCUMENT} from "@angular/common";
import {Inject, NgModule} from "@angular/core";
import {Meta} from "@angular/platform-browser";
import {Router, RouterModule, Routes} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {AdminGuard} from "./guards/AdminGuard";
import {AdminHelperGuard} from "./guards/AdminHelperGuard";
import { AuthGuard } from "./guards/AuthGuard";
import {CookBarmanGuard} from "./guards/CookBarmanGuard";
import { WaiterGuard } from "./guards/WaiterGuard";
import {ReceiptsComponent} from "./modules/reports/receipts/receipts.component";
import {WaiterDayendReportComponent} from "./modules/reports/waiter-dayend-report/waiter-dayend-report.component";
import {LocalStorageService} from "./services/localstorage.service";

const appRoutes: Routes = [
  { path: "", loadChildren: () => import("./modules/home/home.module").then((m) => m.HomeModule)},
  { path: "m", loadChildren: () => import("./modules/self-order/self-order.module").then((m) => m.SelfOrderModule)},
  { path: "access-denied", loadChildren: () => import("./modules/access-denied/access-denied.module").then((m) => m.AccessDeniedModule) },
  { path: "not-found", loadChildren: () => import("./modules/page-not-found/page-not-found.module").then((m) => m.PageNotFoundModule) },
  { path: "auth", loadChildren: () => import("./modules/auth/auth.module").then((m) => m.AuthModule) },
  { path: "login", loadChildren: () => import("./modules/auth/auth.module").then((m) => m.AuthModule) },
  { path: "pos", loadChildren: () => import("./modules/pos/pos.module").then((m) => m.PosModule), canActivate: [AuthGuard, WaiterGuard], data: {id: "menu-item-pos", title: "POS_Title", iconClass: "fas fa-concierge-bell", roles: ["Administrator", "Waiter", "AdminHelper"]}},
  { path: "retail", loadChildren: () => import("./modules/pos-retail/pos-retail.module").then((m) => m.PosRetailModule), canActivate: [AuthGuard, WaiterGuard], data: {id: "menu-item-pos-retail", title: "POS_Retail_Title", iconClass: "fas fa-cash-register", roles: ["Administrator", "Waiter", "AdminHelper"]}},
  // { path: 'delivery', loadChildren: () => import('./modules/pos-delivery/pos-delivery.module').then(m => m.PosDeliveryModule), canActivate: [AuthGuard, WaiterGuard], data: {title: 'POS_Delivery_Title', iconClass: 'fas fa-truck', roles: ["Administrator", "Waiter", "AdminHelper"]}},
  { path: "kds", loadChildren: () => import("./modules/kds/kds.module").then((m) => m.KdsModule), canActivate: [AuthGuard, CookBarmanGuard], data: {id: "menu-item-kds", title: "KitchenDisplay", iconClass: "fas fa-tv", roles: ["Administrator", "CookBarman", "AdminHelper"]}},
  { path: "approve", loadChildren: () => import("./modules/self-order-approve/self-order-approve.module").then((m) => m.SelfOrderApproveModule), canActivate: [AuthGuard, WaiterGuard], data: {id: "menu-item-approval-list", title: "Orders_Waiting_Approval_Title", iconClass: "fas fa-hourglass-half", roles: ["Administrator", "Waiter", "AdminHelper"]}},
  { path: "so", loadChildren: () => import("./modules/self-order/self-order.module").then((m) => m.SelfOrderModule)},
  { path: "", canActivate: [AuthGuard], data: {id: "menu-item-master-data", title: "MasterData", dummy: true, iconClass: "fas fa-marker", roles: ["Administrator", "AdminHelper"] },
      children: [
        { path: "tables", loadChildren: () => import("./modules/master-data/table/table.module").then((m) => m.TableModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-master-data-tables", dummy: false, title: "Tables", iconClass: "fa fa-th-large", roles: ["Administrator", "AdminHelper"]}},
        { path: "menu", loadChildren: () => import("./modules/master-data/products/products.module").then((m) => m.ProductsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-master-data-menu", dummy: false, title: "Menu", iconClass: "fas fa-book-open", roles: ["Administrator", "AdminHelper"]}},
        { path: "customers", loadChildren: () => import("./modules/master-data/customer/customer.module").then((m) => m.CustomerModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-master-data-customers", dummy: false, title: "Customers", iconClass: "fas fa-user-tie", roles: ["Administrator", "AdminHelper"]}},
        { path: "employees", loadChildren: () => import("./modules/master-data/employees/employees.module").then((m) => m.EmployeesModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-master-data-employees", dummy: false, title: "Employees", iconClass: "fas fa-user-circle", roles: ["Administrator", "AdminHelper"]}},
      ],
  },
  { path: "", canActivate: [AuthGuard], data: {id: "menu-item-reports", title: "Receipts", dummy: true, iconClass: "fas fa-receipt", roles: ["Administrator", "AdminHelper"] },
    children: [
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-receipts", dummy: false,  subPath: "receipts", title: "ReceiptList", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-items", dummy: false,  subPath: "receipt-items", title: "ItemList", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-day-end", dummy: false,  subPath: "day-end", title: "ZReportList", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-datev", dummy: false,  subPath: "datev", title: "Datev", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-gobd", dummy: false,  subPath: "gobd", title: "GoBD", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
      { path: "reports", loadChildren: () => import("./modules/reports/reports.module").then((m) => m.ReportsModule), canActivate: [AuthGuard, AdminHelperGuard], data: {id: "menu-item-reports-time-recording", dummy: false,  subPath: "time-recording", title: "TimeRecording", iconClass: "", roles: ["Administrator", "AdminHelper"]}},
    ],
  },
  { path: "", canActivate: [AuthGuard], data: {id: "menu-item-settings", title: "Settings", dummy: true, iconClass: "fas fa-cog", roles: ["Administrator"] },
    children: [
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-profile", dummy: false,  subPath: "profile", title: "ProfileSettings", iconClass: "fa fa-th-large", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-receipts", dummy: false,  subPath: "receipt", title: "ReceiptSettings", iconClass: "fas fa-receipt", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-packages", dummy: false, visible: window["isInReview"] !== true,  subPath: "packages", title: "PackageSettings", iconClass: "fa fa-th-large", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-payment", dummy: false, visible: window["isInReview"] !== true,  subPath: "payment", title: "PaymentSettings", iconClass: "fa fa-credit-card", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-common", dummy: false,  subPath: "common", title: "CommonSettings", iconClass: "fas fa-cog", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-printer", dummy: false,  subPath: "printer", title: "PrinterSettings", iconClass: "fa fa-print", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-my-receipts", dummy: false,  subPath: "myreceipts", title: "MyReceipts", iconClass: "fas fa-file-invoice", roles: ["Administrator"]}},
      { path: "settings", loadChildren: () => import("./modules/settings/settings.module").then((m) => m.SettingsModule), canActivate: [AuthGuard, AdminGuard], data: {id: "menu-item-settings-tse", visible: false, dummy: false,  subPath: "tse", title: "DSFinV-K und TSE", iconClass: "", roles: ["Administrator"]}},
    ],
  },
  { path: "waiter-report", component: WaiterDayendReportComponent, canActivate: [AuthGuard, WaiterGuard], data: {id: "menu-item-waiter-cash",dummy: false, title: "WaiterCashReport", iconClass: "fas fa-hand-holding-usd", roles: ["Administrator", "AdminHelper", "Waiter"]}},
  { path: "waiter-receipts",component: ReceiptsComponent, canActivate: [AuthGuard, WaiterGuard], data: {id: "menu-item-waiter-receipts", dummy: false,  title: "ReceiptList", iconClass: "fas fa-receipt", roles: ["Waiter"]}},
  { path: ":menuId", loadChildren: () => import("./modules/self-order/self-order.module").then((m) => m.SelfOrderModule)},
  { path: "**", loadChildren: () => import("./modules/page-not-found/page-not-found.module").then((m) => m.PageNotFoundModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting {
}
