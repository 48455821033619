import {Component, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {InvoiceModel} from "../../../models/invoice.model";
import {MessageService} from "../../../services/message.service";
import {ReportService} from "../../../services/report.service";
import {SubscriptionService} from "../../../services/subscription.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-receipts",
  templateUrl: "./receipts.component.html",
  styleUrls: ["./receipts.component.scss"],
})
export class ReceiptsComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;
  public receipts: InvoiceModel[] = [];
  public showReceipt: boolean;

  public showReceiptsWarning: boolean;
  public receiptSelectedSubscription: Subscription;
  public receiptAddedSubscription: Subscription;
  private loadingReceipts: boolean = false;

  constructor(
    private reportService: ReportService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) { }

  public ngOnInit(): void {
    this.receiptSelectedSubscription = this.subscriptionService.receiptSelectedEvent.subscribe((receipt) => {
      this.showReceipt = receipt.id != null;
    });

    this.receiptAddedSubscription = this.subscriptionService.receiptAddedEvent.subscribe((receipt) => {
      this.receipts.unshift(receipt);
    });
  }

  public ngOnDestroy(): void {
    this.receiptSelectedSubscription.unsubscribe();
    this.receiptAddedSubscription.unsubscribe();
  }

  public dateUpdated($event: any) {
    this.loadReceipts($event.date.startDate, $event.date.endDate, $event.paymentType);
  }

  public loadReceipts(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, paymentType: string) {
    if(this.loadingReceipts) return;
    this.loadingReceipts = true;

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.showReceiptsWarning = false;
    this.reportService.getReceipts(startDate.toISOString(), endDate.toISOString(), paymentType).subscribe((receipts) => {
      this.receipts = receipts;
      this.blockUI.stop();

      if (this.receipts.length > 0 && window.innerWidth > 768) {
        setTimeout(() => {
          this.subscriptionService.receiptSelected(this.receipts[0]);
        }, 300);
      }

      this.showReceiptsWarning = this.receipts.length == 0;
      this.loadingReceipts = false;
    }, (err) => {
      this.messageService.error(err);
      this.blockUI.stop();
      this.loadingReceipts = false;
    });
  }
}
