<div class="row printer-settings-container">
  <div class="d-flex justify-content-between w-100 action-toolbar">
    <a href="javascript:void(0);"
       class="btn btn-outline-danger btn-rounded background-white toolbar-item"
       (click)="deleteSettings()">
      <i class="far fa-trash-alt"></i>
      <span>{{'DeleteSettings' | translate}}</span>
    </a>
    <a href="javascript:void(0);"
       class="btn btn-outline-info btn-rounded background-white toolbar-item"
       (click)="refreshPrinters()">
      <i class="fas fa-sync"></i>
      <span>{{'RefreshPrinters' | translate}}</span>
    </a>
    <a href="javascript:void(0);"
       class="btn btn-outline-info btn-rounded background-white toolbar-item"
       (click)="testConnection()">
      <i class="fa fa-print"></i>
      <span>{{'TestSettings' | translate}}</span>
    </a>
  </div>
  <div class="row p-3">
    <div class="col-md-6 left-part">
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle"
           [mdbPopover]="'PrinterName_Description' | translate"
           triggers="focus" containerClass="info-popover"></i>
        <div class="md-form flex-grow-1 mb-0">
          <input mdbInput type="text" class="form-control printer-setting-field" required="required"
                 [attr.placeholder]="'PrinterName' | translate"
                 id="blePrinterName" [(ngModel)]="settings.printerName"/>
          <label class="printer-settings-label">{{"PrinterName" | translate}}</label>
        </div>
      </div>
      <div class="form-group form-inline printer-category-container" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle"
           [mdbPopover]="'PaperWidth_Description' | translate"
           triggers="focus" containerClass="info-popover"></i>
        <div class="md-form select-container mb-0">
          <label class="printer-settings-label">{{"PaperWidth" | translate}}</label>
          <mdb-select [options]="printerTypes"
                      [highlightFirst]="false"
                      [placeholder]="'ChoosePaperWidth' | translate"
                      [(ngModel)]="settings.columnCounts"></mdb-select>
        </div>
      </div>
    </div>
    <div class="col-md-6 right-part">
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle"
           [mdbPopover]="'IpAddress_Description' | translate"
           triggers="focus" containerClass="info-popover"></i>
        <div class="md-form select-container mb-0">
          <label class="printer-settings-label">{{"BluetoothPrinter" | translate}}</label>
          <mdb-select [options]="pairedBluetoothPrinters"
                      [highlightFirst]="false"
                      [placeholder]="'ChooseBluetoothPrinter' | translate"
                      [(ngModel)]="settings.bluetoothAddress"
                      (opened)="reloadBluetoothPrinters()"></mdb-select>
        </div>
      </div>

      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle"
           [mdbPopover]="'PrinterCoding_Description' | translate"
           triggers="focus" containerClass="info-popover"></i>
        <div class="md-form select-container mb-0">
          <label class="printer-settings-label">{{"PrinterCoding" | translate}}</label>
          <mdb-select [options]="codePages"
                      [highlightFirst]="false"
                      [placeholder]="'ChoosePrinterCoding' | translate"
                      [(ngModel)]="settings.coding"></mdb-select>
        </div>
      </div>

      <div class="form-group form-inline printer-category-container" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle"
           [mdbPopover]="'Categories_Description' | translate"
           triggers="focus" containerClass="info-popover"></i>
        <div class="md-form mb-0 select-container">
          <label class="printer-settings-label active">
            {{"Categories" | translate}}
          </label>
          <mdb-select-2
                        name="bluetooth-category-select"
                        [(ngModel)]="selectedCategories"
                        [highlightFirst]="false"
                        [multiple]="true"
                        [placeholder]="'ChooseCategoryToFilter' | translate">
            <mdb-select-all-option>{{"SelectAll" | translate}}</mdb-select-all-option>
            <mdb-select-option *ngFor="let option of productCategories" [value]="option.value" (selectionChange)="setSelectedCategories($event)">
              {{ option.label }}
            </mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
    </div>
  </div>
</div>
