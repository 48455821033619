import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";

@Directive({
  selector: "[youTubeVideo]",
})
export class YoutubeDirective implements OnInit {
  @Input() public embedObservable: Observable<string | any>;
  @Input() public embed: string;
  @Input() public autoPlay = false;
  public iframe: HTMLElement;

  public element;
  constructor(private el: ElementRef) {
    this.element = this.el.nativeElement;
  }

  public ngOnInit() {

    if (this.embedObservable) {
      this.embedObservable.subscribe((res) => {
        if (res) {
          this.embedVideo(res);
        } else {
          this.stopVideo();
        }
      });
    }

    if (this.embed) {
      this.embedVideo(this.embed);
    }
  }

  private embedVideo(embed) {
    const source = "https://img.youtube.com/vi/" + embed + "/sddefault.jpg";

    // Load the image asynchronously
    const image = new Image();
    image.src = source;
    image.alt = "Youtube video";

    const that = this;
    image.addEventListener( "load", function() {
      that.element.appendChild( image );
    });

    this.element.addEventListener( "click", () => {
      this.iframe = document.createElement( "iframe" );

      this.iframe.setAttribute("frameborder", "0");
      this.iframe.setAttribute("allow", "autoplay; fullscreen");
      this.iframe.setAttribute("id", "frame-" + embed);
      this.iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?rel=0&showinfo=0&autoplay=1&enablejsapi=1");
      this.iframe.onload = () => {
        const contentWindow = this.iframe["contentWindow"];
        contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
      };

      const els = that.element.getElementsByClassName("play-button");
      if (els.length > 0) { els[0].style.display = "none"; }

      image.style.display = "none";
      this.element.appendChild( this.iframe );
    });

    if (this.autoPlay) {
      this.element.click();
    }
  }

  private stopVideo() {
    this.iframe.setAttribute("src", "");
    this.element.removeChild(this.iframe);
  }
}
