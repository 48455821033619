import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import {Subscription} from "rxjs";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {Product, ProductCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {SubscriptionService} from "../../../../services/subscription.service";

@Component({
  selector: "app-show-product-list-dialog",
  templateUrl: "./show-product-list-dialog.component.html",
  styleUrls: ["./show-product-list-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShowProductListDialogComponent extends ModalClassComponent implements OnInit, OnDestroy {
  @ViewChild("modal")
  public modal: ModalDirective;

  @Input()
  public category: ProductCategory = new ProductCategory();
  public products: Product[] = [];

  public listType = "";
  public importFinishedSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("show-product-list-dialog", this);

    this.importFinishedSubscription = this.subscriptionService.loadingProductsFinishedEvent.subscribe(async () => {
      this.products = filter(await this.localStorage.getProductsAsync(), {productCategoryId: this.category.productCategoryId});
    });
  }

  public ngOnDestroy(): void {
    this.importFinishedSubscription.unsubscribe();
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params) {
    super.show(params);
    this.modal.show();
    this.category = params.category;
    this.products = params.products;
  }

  public showAddProductDialog() {
    this.modalService.show("create-product-dialog", {
      product: new Product(),
      category: this.category,
      action: "add",
      onProductAdded: (product) => {
        this.products.push(product);
        this.messageService.success(this.translateService.instant("AddProductSuccess"));
        this.localStorage.addProduct(product);
      },
    });
  }

  public showImportProductsDialog() {
    this.modalService.show("import-products-dialog", {
      category: this.category,
      onProductAdded: (products) => {
        products.forEach((product) => {
          this.products.push(product);
          this.localStorage.addProduct(product);
        });
      },
    });
  }
}
