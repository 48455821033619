import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {PaymentDialogComponent} from "../payment-dialog/payment-dialog.component";

@Component({
  selector: "app-payment-discount-dialog",
  templateUrl: "./payment-discount-dialog.component.html",
  styleUrls: ["./payment-discount-dialog.component.scss"],
})
export class PaymentDiscountDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  public amount: number;
  public discountType: string;

  public discountOptions: any[];
  public currencySymbol: string;
  private paymentDialog: PaymentDialogComponent;

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
  ) {
    super();
  }

  public async ngOnInit() {
    this.modalService.register("discount-dialog", this);
    this.currencySymbol = (await this.localStorage.getUserAsync()).currencySymbol;

    this.discountOptions = [
      { value: (await this.localStorage.getUserAsync()).currencySymbol, label: this.currencySymbol },
      { value: "%", label: "%" },
    ];
  }

  public applyDiscount() {
    this.paymentDialog.applyDiscount(this.amount, this.discountType);
    this.hide();
  }

  public hide() {
    super.hide();
    this.paymentDialog = null;
    this.modal.hide();
  }

  public show(args) {
    super.show(args);
    this.paymentDialog = args.paymentDialog;
    this.modal.show();
  }

  public setDiscount(number: number, type: string) {
    this.amount = number;
    this.discountType = type;
  }
}
