import { CookieService } from 'src/app/services/cookie.service';
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {MessageService} from "../../../services/message.service";
import {SelfOrderService} from "../../../services/self.order.service";

@Component({
  selector: "app-self-order-sms-verify",
  templateUrl: "./self-order-sms-verify.component.html",
  styleUrls: ["./self-order-sms-verify.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderSmsVerifyComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  public code: string;

  constructor(
    private translateService: TranslateService,
    private selfOrderService: SelfOrderService,
    private messageService: MessageService,
    private cookieService: CookieService,
  ) {
  }

  public ngOnInit(): void {
  }

  public resendCode() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    var userId = this.cookieService.getCookie("UserId");
    this.selfOrderService.resendCode(userId).subscribe(() => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("CodeRenewedSuccessfully"));
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public verifyCode() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    var userId = this.cookieService.getCookie("UserId");
    this.selfOrderService.verifyCode(userId, this.code).subscribe((res) => {
      this.blockUI.stop();
      if (!res) {
        this.messageService.error(this.translateService.instant("WrongCodeEntered"));
      } else {
        location.href = "/so";
      }
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
