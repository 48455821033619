import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {
  ButtonsModule,
  IconsModule,
  ModalModule,
  NavbarModule, WavesModule,
} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../common/common.module";
import {NavMenuComponent} from "./nav-menu/nav-menu.component";
import {SideNavComponent} from "./side-nav/side-nav.component";

@NgModule({
  declarations: [
    NavMenuComponent,
    SideNavComponent,
  ],
  exports: [
    NavMenuComponent,
    SideNavComponent,
  ],
  imports: [
    AppCommonModule,
    WavesModule.forRoot(),
    NavbarModule,
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    IconsModule,
    FormsModule,
    RouterModule,
  ],
})
export class MenuModule {
}
