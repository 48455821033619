<div class="mx-2 module-settings" style="padding: 10px;">
  <div role="tabpanel" class="tab-pane container" id="acc-package">
    <div style="margin-left:auto;margin-right:auto;">
      <div class="panel panel-default">
        <div class="alert alert-info" style="border-radius:0px;color:black;" [innerHTML]='"PackageUpdateInfo" | translate'>
        </div>
        <div class="price section-padding dark-bg panel-body">
          <div class="container" style="width:100%;padding:0px;">
            <div class="btn-group" data-toggle="buttons" *ngIf="packageTypes.length > 1">
              <label class="btn btn-outline-light-blue form-check-label price-recursion-label"
                     *ngFor="let packageType of packageTypes"
                     (click)="setPackageType(packageType)"
                     [class.selected]="activePackageType === packageType">
                {{packageType | translate}}
              </label>
            </div>
            <div *ngIf="packageTypes.length > 1" style="padding: 10px 0;">{{"YearSubAdvantage" | translate}}</div>

            <div>
              <div class="row" style="margin:0;">
                <ng-container *ngFor="let package of groupedPackages[activePackageType]">
                  <div class="col col-lg-3 col-md-6 col-sm-12"
                       *ngIf="package.isActive"
                       [class.active-package]="this.activePackage.id == package.id">
                    <div class="price-table" style="background-color: #ececec;" [class.featured-price]="package.featured">
                      <div class="rate" [class.with-recursion]="package.recursion">
                        <span>{{package.currency}}{{package.price.toFixed(2)}}</span>
                        <div style="font-size:16px;margin-top:-15px;">{{package.recursion ? ((package.recursion + '_Recursion') | translate) : ''}}</div>
                      </div>

                      <h2>{{(package.name + '_Title') | translate}}</h2>
                      <p>{{(package.name + '_Desc') | translate}}</p>
                      <ul>
                        <li>
                          <i class="fa fa-check"></i>
                          <span>{{(package.name + '_Count') | translate}}</span>
                        </li>
                      </ul>
                      <a role="button" class="btn btn-rounded buy-now" (click)="applyPackage(package)" [class.btn-default]="!package.featured" [class.btn-primary]="package.featured">
                        {{ "Order" | translate }}
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-payment-settings-dialog></app-payment-settings-dialog>

