import {BaseModel} from "./base.model";

export class Customer extends BaseModel {
  public name: string;

  public firstName: string;

  public lastName: string;

  public street: string;

  public postalCode: string;

  public city: string;

  public country: string;

  public telephone: string;

  public email: string;
}
