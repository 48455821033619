import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-reporting",
  templateUrl: "./reporting.component.html",
  styleUrls: ["./reporting.component.scss"],
})
export class ReportingComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
