import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {StripeService} from "ngx-stripe";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {UserService} from "../../../services/user.service";
import { AccountService } from "src/app/services/account.service";
import {CreateSourceData, StripeElements, StripeElementsOptions} from "@stripe/stripe-js";

@Component({
  selector: "app-payment-settings",
  templateUrl: "./payment-settings.component.html",
  styleUrls: ["./payment-settings.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PaymentSettingsComponent implements OnInit, AfterViewInit {
  @BlockUI() public blockUI: NgBlockUI;

  @ViewChild('ibanNumber') ibanNumber: ElementRef;

  @Output() onPaymentDone = new EventEmitter<any>();

  @Input() paypalPlanId: string;

  public paymentType = "CreditCard";
  public elements: StripeElements;

  public sepaPaymentError: string;
  public creditCardPaymentError: string;
  public bankName: string;
  public cardHolder = "";
  public ibanCardHolder = "";
  public isCardHolderFocused: boolean;
  private cardNumber: any;
  private iban: any;
  private stripe: any;
  private setupIntent: any;
  private elementsOptions: StripeElementsOptions = {
    locale: this.getStripeLocale(this.translateService.currentLang),
  };

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private userService: UserService,
    private accountService: AccountService,
    private messageService: MessageService,
    private stripeService: StripeService,
  ) {
  }

  public ngOnInit(): void {
    this.accountService.loadPSetupIntent().subscribe(res => {
      this.setupIntent = res;
    });
  }

  public ngAfterViewInit(): void {
    this.initStripe();

    if(this.paypalPlanId) {
      const that = this;
      window["paypal"].Buttons({
        style: {
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'subscribe',

        },
        locale: 'en_US',
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            'plan_id': that.paypalPlanId
          });
        },
        onApprove: function(data, actions) {
          that.savePaypalSubscription(data.subscriptionID);
        },
      }).render('#paypal-button-container');
    }
  }

  savePaypalSubscription(subscriptionID: string) {
    this.userService.savePaypalSubscription(subscriptionID).subscribe(() => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("CreditCardUpdateSuccess"));
      this.onPaymentDone?.emit();
    }, (err) => {
      this.blockUI.stop();
      this.messageService.error(err);
    });
  }

  public setPaymentType(paymentType: string) {
    this.paymentType = paymentType;
  }

  public getStripeLocale(currentLang: string): "de" | "en" | "es" | "fr" | "auto" {
    if (currentLang === "de" || currentLang === "en" || currentLang === "es" || currentLang === "fr") {
      return currentLang;
    }

    return "auto";
  }

  public initStripe() {
    const elementOptions = {
      style: {
        base: {
          "::placeholder": {color: "#CFD7DF"},
          ":-webkit-autofill": {color: "#e39f48"},
        },
      },
      classes: {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      },
    };

    this.stripe = this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.cardNumber) {
        // create card number element and bind it
        this.cardNumber = this.elements.create("cardNumber", elementOptions);
        this.cardNumber.mount("#card-number");

        // create card expiry element element and bind it
        const cardExpiry = elements.create("cardExpiry", elementOptions);
        cardExpiry.mount("#card-expiry");

        // create cvc element and bind it
        const cardCvc = elements.create("cardCvc", elementOptions);
        cardCvc.mount("#card-cvc");

        this.registerCreditCardElements([this.cardNumber, cardExpiry, cardCvc]);
      }

      if (!this.iban) {
        this.iban = elements.create("iban", {
          ...elementOptions,
          supportedCountries: ["SEPA"],
        });

        // Add an instance of the iban Element into the `iban-element` <div>.
        this.iban.mount(this.ibanNumber.nativeElement);

        this.registerSepaElements([this.iban]);
      }
    });
  }

  public registerSepaElements(elements) {
    const that = this;

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach(function(element, idx) {
      element.on("change", function(event) {
        if (event.error) {
          savedErrors[idx] = event.error.message;
          that.sepaPaymentError = event.error.message;
        } else {
          that.bankName = event.bankName;

          savedErrors[idx] = null;

          // Loop over the saved errors and find the first one, if any.
          that.sepaPaymentError = Object.keys(savedErrors).sort().reduce(function(maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);
        }
      });
    });
  }

  public registerCreditCardElements(elements) {
    const that = this;

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach(function(element, idx) {
      element.on("change", function(event) {
        if (event.error) {
          savedErrors[idx] = event.error.message;
          that.creditCardPaymentError = event.error.message;
        } else {
          that.bankName = event.bankName;

          savedErrors[idx] = null;

          // Loop over the saved errors and find the first one, if any.
          that.creditCardPaymentError = Object.keys(savedErrors).sort().reduce(function(maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);
        }
      });
    });
  }

  public saveCardData() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.stripeService.handleCardSetup(this.setupIntent.client_secret, this.cardNumber, {
      billing_details : {
        name: this.cardHolder
      }
    }).subscribe((result) => {
      if (result.error) {
        this.creditCardPaymentError = result.error.message;
        this.blockUI.stop();
      } else {
        console.log(result);
        this.userService.updatePaymentMethod(result.setupIntent.id).subscribe(() => {
          this.blockUI.stop();
          this.messageService.success(this.translateService.instant("CreditCardUpdateSuccess"));
          this.onPaymentDone?.emit();
        }, (err) => {
          this.blockUI.stop();
          this.messageService.error(err);
        });
      }
    });

    /*this.stripeService.createToken(this.cardNumber, {name: this.cardHolder}).subscribe((result) => {
      if (result.error) {
        this.creditCardPaymentError = result.error.message;
        this.blockUI.stop();
      } else if (result.token) {
        // Send the token to your server
        this.userService.updateCreditCard(result.token.id).subscribe(() => {
          this.blockUI.stop();
          this.messageService.success(this.translateService.instant("CreditCardUpdateSuccess"));
          this.onPaymentDone?.emit();
        }, (err) => {
          this.blockUI.stop();
          this.messageService.error(err);
        });
      }
    });*/
  }

  public async saveSEPAData(): Promise<any> {
    const additionalData = {
      type: "sepa_debit",
      currency: "eur",
      owner: {
        name: this.ibanCardHolder,
        email: (await this.localStorage.getUserAsync()).email,
      },
      mandate: {
        notification_method: "email",
      },
    } as CreateSourceData;

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.stripeService.createSource(this.iban, additionalData).subscribe((result) => {
      if (result.error) {
        this.sepaPaymentError = result.error.message;
        this.blockUI.stop();
      } else if (result.source) {
        // Send the token to your server
        this.userService.updateCreditCard(result.source.id).subscribe(() => {
          this.blockUI.stop();
          this.messageService.success(this.translateService.instant("SEPAUpdateSuccess"));
          this.onPaymentDone?.emit();
        }, (err) => {
          this.blockUI.stop();
          this.messageService.error(err);
        });
      }
    });
  }
}
