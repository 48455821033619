<div class="panel panel-default table-cont pos-table-cont"
     [attr.id]="tableId"
     (click)="showTableItems()"
     [class.active-table-cont]="activeTableId == table.id"
     [style.backgroundColor]="backgroundColor"
     [hidden]="!visible">
  <span (click)="splitTable($event)" [hidden]="table.splittedFrom" class="fas fa-project-diagram pos-table-split"></span>
  <span (click)="mergeTable($event)" [hidden]="!table.splittedFrom" class="fas fa-times-circle pos-table-split"></span>
  <span *ngIf="existingNewItems" class="fa fa-exclamation-circle pos-table-new-items"></span>
  <span *ngIf="table.tax > 0" class="pos-table-tax-info">{{table.tax}}%</span>
  <div style="width: 100%; height: 100%;">
    <div class="order-date-time" >{{orderTime}}</div>
    <div class="pos-table-name">
      <span>{{table.name}}</span>
    </div>
    <div class="order-info">{{orderInfo}}</div>
  </div>
</div>
