import { SideDish } from './../../../models/sidedish.model';
import { ProductCategory } from './../../../models/product.category.model';
import { ProductPicture } from './../../../models/product.picture.model';
import {Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {Extra, Product} from "../../../models";
import {SubscriptionService} from "../../../services/subscription.service";
import { filter } from "lodash";
import { LocalCurrencyPipe } from '../../common/pipes/local.currency.pipe';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-self-order-product-details",
  templateUrl: "./self-order-product-details.component.html",
  styleUrls: ["./self-order-product-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderProductDetailsComponent implements OnInit, OnDestroy {
  public show: boolean;
  public searchText: string;
  public selfOrderMenuEventSubscription: Subscription;
  public product: Product = null;
  public productCategories: ProductCategory[] = [];
  public productCategory: ProductCategory = null;
  public images: ProductPicture[];
  public ingredients: string[] = [];
  public additives: string[] = [];
  public allergenes: string[] = [];
  public extras: Extra[] = [];
  public selectedExtras = {};
  public sideDishes: SideDish[] = [];
  public formattedUnitPrices: string[] = [];
  public currencyPipe: LocalCurrencyPipe;
  public selectedPortionSizeIndex: number;

  @HostBinding("style.--so-secondary-color") soSecondaryColor: string = '';
  private productPictures: ProductPicture[] = [];

  constructor(
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService,
  ) {
    this.productCategories = JSON.parse(localStorage.getItem("categories"));
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public async ngOnInit() {
    this.selfOrderMenuEventSubscription = this.subscriptionService.selfOrderProductDetailsEvent.subscribe(async (product) => {
      this.show = true;
      this.product = product;
      this.productCategory = this.productCategories.find(cat => cat.productCategoryId == this.product.productCategoryId);
      this.selectedPortionSizeIndex = 0;
      this.sideDishes = this.product.sideDishes;
      product["orderCount"] = 1;
      this.soSecondaryColor = this.product.color ?? this.productCategory.color;

      if (this.product.ingredients) {
        this.ingredients = this.product.ingredients.split(",");
      }

      if (this.product.additives) {
        this.additives = this.product.additives.split(",");
      }

      if (this.product.allergenes) {
        this.allergenes = this.product.allergenes.split(",");
      }

      if (this.productCategory) {
        this.extras = this.productCategory.extras;
        for(const extra of this.extras) {
          extra.count = 0;
        }

        this.sideDishes = this.sideDishes.concat(...this.productCategory.sideDishes);
      }

      const prices = [];
      this.product.unitPrices.forEach((price) => {
        prices.push(this.formatCurrency(price));
      });
      this.formattedUnitPrices = prices;

      this.productPictures = JSON.parse(localStorage.getItem("product-pictures"));
      this.images = filter(this.productPictures, { productId: this.product.productId, dimensions: "original" });
      if (this.images.length === 0) { this.images = [{ url: "/assets/images/plate.png", id: "", contentType: "", dimensions: "", fileName: "", originalFileId: "", productId: "", size: 0 }]; }

    });
  }

  public ngOnDestroy(): void {
    this.selfOrderMenuEventSubscription?.unsubscribe();
  }

  close() {
    this.show = false;
  }

  public decreaseCount(product: Product, $event) {
    const count = product["orderCount"];
    if (count > 1) {
      product["orderCount"] = count - 1;
    }
  }

  public increaseCount(product: Product, $event) {
    product["orderCount"] = product["orderCount"] + 1;
  }

  public decreaseAdditiveCount(extra: Extra, $event) {
    if (extra.count > 1) {
      extra.count = extra.count - 1;
    }
  }

  public increaseAdditiveCount(extra: Extra, $event) {
    extra.count = extra.count + 1;
  }

  public formatCurrency(currency: string) {
    return this.currencyPipe.transform(parseFloat(currency));
  }

  public getSourceUrl(productPicture: ProductPicture, dimension: string) {
    var picture = this.productPictures.find(p => p.originalFileId == productPicture.id && p.dimensions == dimension);
    if(picture) {
      return picture.url;
    }

    return "";
  }
}
