import { Component, OnInit } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Employee} from "../../../models/employee.model";
import { EmployeeService } from "../../../services/employee.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
})
export class EmployeesComponent implements OnInit {

  public employees: Employee[] = [];

  constructor(
    private employeeService: EmployeeService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) { }

  public async ngOnInit() {
    this.messageService.showLoading(this.translateService.instant("LoadingEmployees"));
    this.employeeService.loadAllEmployees().subscribe((employees) => {
      this.employees = employees;
      this.messageService.hideLoading();
    }, () => {
      this.messageService.hideLoading();
    });
  }

  public showAddEmployee() {
    this.modalService.show("create-employee-dialog", {
      employee: new Employee(),
      onEmployeeAdded: (employee) => {
        this.employees.unshift(employee);
      },
    });
  }

  public showSendEmployeesMessage() {
    this.modalService.show("send-message-dialog", {
      userId: -1,
      to: this.translateService.instant("All"),
    });
  }
}
