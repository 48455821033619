import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {findIndex} from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Product} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {ProductService} from "../../../services/product.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: "app-show-diverse-items-dialog",
  templateUrl: "./show-diverse-items-dialog.component.html",
  styleUrls: ["./show-diverse-items-dialog.component.scss"],
})
export class ShowDiverseItemsDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  public products: Product[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private utilsService: UtilsService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private productService: ProductService,
    private messageService: MessageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("show-diverse-items-dialog", this);
  }

  public hide() {
    super.hide();
    this.modal.hide();
  }

  public async show(params: any): Promise<any> {
    super.show(params);
    this.products = (await this.localStorage.getProductsAsync()).filter((p) => p.productCategoryId === this.utilsService.generateEmptyGuid());
    this.modal.show();
  }

  public showDeleteProductConfirmation(product: Product) {
    const confirmModel = {
      message: this.translateService.instant("DeleteProductConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.deleteProduct(product);
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public addProduct(product: Product) {
    this.subscriptionService.productOrdered(product);
  }

  private deleteProduct(product: Product) {
    this.productService.deleteProduct(product.productId).subscribe( () => {
      const index = findIndex(this.products, {productId: product.productId});
      if (index > -1) {
        this.products.splice(index, 1);
      }

      this.localStorage.removeProduct(product);
      this.messageService.success(this.translateService.instant("DeleteProductSuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
