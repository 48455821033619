import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PrinterSettings, ReceiptSettings} from "../models";
import {InvoiceInformation} from "../models/invoice.information.model";
import { PaymentTypes } from "../models/payment.types.model";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(
    private http: HttpClient) {
  }

  public saveReceiptSettings(settings: ReceiptSettings) {
    return this.http.post<ReceiptSettings>("/api/v1/account/updatereceiptsettings", settings);
  }

  public savePaymentTypes(types: PaymentTypes[]) : Observable<PaymentTypes[]> {
    return this.http.put<PaymentTypes[]>("/api/v1/account/paymenttypes", types);
  }

  public deletePrinterSettings(settingsId: string) {
    return this.http.delete<ReceiptSettings>("/account/printersettings/" + settingsId);
  }

  public savePrinterSettings(printerSettings: { printerSettings: PrinterSettings[] }) {
    return this.http.post<PrinterSettings[]>("/account/printersettings/", printerSettings);
  }

  public loadReceipts() {
    return this.http.get<InvoiceInformation[]>("/api/v1/account/otinvoices");
  }

  public loadPrinterSettings() {
    return this.http.get<PrinterSettings[]>("/api/v1/account/printersettings");
  }

  public loadPSetupIntent() {
    return this.http.get<any>("/api/v1/account/setupIntent");
  }
}
