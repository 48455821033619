import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AppCommonModule} from "../common/common.module";
import {PosModule} from "../pos/pos.module";
import { PosRetailRoutingModule } from "./pos-retail-routing.module";
import { PosRetailComponent } from "./pos-retail.component";
import { RetailOrdersListComponent } from "./retail-orders-list/retail-orders-list.component";

@NgModule({
  declarations: [
    PosRetailComponent,
    RetailOrdersListComponent,
  ],
  imports: [
    CommonModule,
    PosRetailRoutingModule,
    PosModule,
    TranslateModule,
    AppCommonModule,
    FormsModule,
  ],
})
export class PosRetailModule { }
