<div class="mx-2 module-settings payment-settings" style="padding: 10px;">
  <div role="tabpanel" class="tab-pane container" id="acc-payment">
    <ul class="nav white">
      <li class="nav-item ">
        <a href="javascript:void(0);" class="nav-link waves-light active"
           (click)="setPaymentType('CreditCard')"
           [class.active]="paymentType === 'CreditCard'">
          <i class="far fa-credit-card fa-2x"></i> <br><span>{{"CreditCard" | translate}}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0);" class="nav-link waves-light"
           (click)="setPaymentType('SEPA')"
           [class.active]="paymentType === 'SEPA'">
          <i class="far fa-money-bill-alt fa-2x"></i><br><span>{{"SEPADirectDebit" | translate}}</span>
        </a>
      </li>
      <li class="nav-item" [hidden]="!paypalPlanId">
        <a href="javascript:void(0);" class="nav-link waves-light"
           (click)="setPaymentType('PayPal')"
           [class.active]="paymentType === 'PayPal'">
          <i class="fab fa-paypal fa-2x"></i><br> <span>{{"PayPal" | translate}}</span>
        </a>
      </li>
    </ul>

    <div id="payment-form-container">
      <div style="max-width: 600px;" class="card ml-auto mr-auto p-5" [hidden]="paymentType !== 'CreditCard'">
        <div class="error">{{creditCardPaymentError}}</div>
        <div data-locale-reversible>
          <div class="row m-0">
            <div class="field mdb-form">
              <input mdbInput id="card-holder" name="card-holder" class="input empty shadow-none" type="text"
                     [(ngModel)]="cardHolder"
                     [class.focused]="isCardHolderFocused || cardHolder.length > 0"
                     (focus)="isCardHolderFocused = true"
                     (blur)="isCardHolderFocused = false"
                     required="required">
              <label for="card-holder" translate="NameOnCard" class="active"></label>
              <div class="baseline"></div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="field">
            <div id="card-number" class="input empty"></div>
            <label for="card-number" data-tid="elements_examples.form.card_number_label"
                   translate="CreditCard"></label>
            <div class="baseline"></div>
          </div>
        </div>
        <div class="row m-0">
          <div class="field col-6">
            <div id="card-expiry" class="input empty"></div>
            <label for="card-expiry" data-tid="elements_examples.form.card_expiry_label"
                   translate="CreditCardExpiration"></label>
            <div class="baseline"></div>
          </div>
          <div class="field col-6">
            <div id="card-cvc" class="input empty"></div>
            <label for="card-cvc" data-tid="elements_examples.form.card_cvc_label">CVC</label>
            <div class="baseline"></div>
          </div>
        </div>
        <div class="row m-0" style="padding-top:50px;">
          <button type="submit" id="btnPayment" class="btn btn-primary btn-rounded btn-block mt-3 ml-0" (click)="saveCardData()">
            {{"Update" | translate}}
          </button>
        </div>
      </div>
      <div style="max-width: 600px;" class="card ml-auto mr-auto p-5" [hidden]="paymentType !== 'SEPA'">
        <div class="error" role="alert">
          <span class="message">{{sepaPaymentError}}</span>
        </div>

        <div data-locale-reversible>
          <div class="row m-0">
            <div class="field">
              <input mdbInput id="iban-card-holder" name="iban-card-holder" class="input empty shadow-none" type="text"
                     [(ngModel)]="ibanCardHolder"
                     [class.focused]="isCardHolderFocused || ibanCardHolder.length > 0"
                     (focus)="isCardHolderFocused = true"
                     (blur)="isCardHolderFocused = false"
                     required="required">
              <label for="iban-card-holder">
                {{"NameOnCard" | translate}}
              </label>
              <div class="baseline"></div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="field">
            <div #ibanNumber id="iban-number" class="input empty"></div>
            <label for="iban-number">{{"IBANNumber" | translate}}</label>
            <div class="baseline"></div>
          </div>
          <div id="bank-name">{{bankName}}</div>
        </div>
        <div class="row m-0" style="padding-top:20px;">
          <button class="btn btn-primary btn-rounded btn-block mt-3 ml-0" (click)="saveSEPAData()">
            {{"Update" | translate}}
          </button>
        </div>
        <div id="mandate-acceptance" [innerHTML]='"SEPADescription" | translate' style="color:#5a5a5a;font-size:12px;margin-top:30px;">
          {{"SEPADescription" | translate}}
        </div>
      </div>
      <div style="max-width: 600px;" class="card ml-auto mr-auto p-5" [hidden]="paymentType !== 'PayPal'">
        <div id="paypal-button-container"></div>
      </div>
    </div>
  </div>
</div>
