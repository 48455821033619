import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {LocalStorageService} from "../services/localstorage.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private localStorage: LocalStorageService,
    public router: Router) {
  }

  public async canActivate() {
    if (await this.localStorage.getUserAsync()) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.localStorage.removeAll();
    await this.router.navigate(["/auth/login"]);
    return false;
  }
}
