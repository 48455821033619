<div class="right-container" id="add-product-modal" style="width: 100%;" [class.right-container-slide-left]="show === true" [class.right-container-slide-right]="show === false">
    <div class="circle"></div>
    <div>
        <div class="navbar-collapse full-width d-flex justify-content-between" style="display:flex;height:65px;">
            <ul class="navbar-nav navbar-header mr-auto w-100 flex-row justify-content-between">
                <li class="nav-item">
                    <a href="javascript:void(0);" (click)="close()" class="back-link">
                        <i class="fas fa-arrow-left" style="margin-right: 6px;"></i>
                        <span>{{"Back" | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div style="width: 100%;padding: 0 24px;" *ngIf="product">
        <div class="product-details-modal">
            <div *ngIf="images?.length > 1" class="product-images carousel slide" data-ride="carousel">
                <mdb-carousel [isControls]="true" [animation]="'slide'" #carousel>
                    <mdb-carousel-item *ngFor="let image of images">
                        <picture>
                            <source media="(min-width: 768px)" srcset="{{image.url}}" />
                            <source media="(min-width: 450px)" srcset="{{getSourceUrl(image, '450')}}" />
                            <source media="(min-width: 300px)" srcset="{{getSourceUrl(image, '300')}}" />
                            <img class="d-block ml-auto mr-auto carousel-image" src="{{image.url}}" alt="Product image" />
                        </picture>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
        </div>
        <div *ngIf="images?.length ==1">
            <picture>
                <source media="(min-width: 768px)" srcset="{{images[0].url}}" />
                <source media="(min-width: 450px)" srcset="{{getSourceUrl(images[0], '450')}}" />
                <source media="(min-width: 300px)" srcset="{{getSourceUrl(images[0], '300')}}" />
                <img class="d-block ml-auto mr-auto single-image" src="{{images[0].url}}" alt="Product image">
            </picture>
        </div>
        <div class="product-name">{{product?.productName}}</div>
        <div class="details-title mt-3" *ngIf="product.productDescription">{{"Description" | translate}}</div>
        <div class="details-desc" *ngIf="product.productDescription" [innerHTML]="product.productDescription | sanitizeHtml"></div>
        <hr *ngIf="product.productDescription" />

        <div class="details-title" *ngIf="product.ingredients?.length > 0">{{"Ingredients" | translate}}</div>
        <div class="chips chips-initial" *ngIf="product.ingredients?.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div class="chip" *ngFor="let ingredient of ingredients">{{ingredient}}</div>
        </div>
        <hr *ngIf="product.ingredients?.length > 0" />
        <div class="details-title" *ngIf="allergenes.length > 0">{{"Allergenes" | translate}}</div>
        <div class="chips chips-initial" *ngIf="allergenes.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div class="chip" *ngFor="let allergene of allergenes">{{allergene}}</div>
        </div>
        <hr *ngIf="allergenes.length > 0" />

        <div class="details-title" *ngIf="additives.length > 0">{{"Additives" | translate}}</div>
        <div class="chips chips-initial" *ngIf="additives.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div class="chip" *ngFor="let additive of additives">{{additive}}</div>
        </div>
        <hr *ngIf="additives.length > 0" />

        <div class="details-title mt-1">{{"Price" | translate}}</div>
        <div class="product-price mb-3">
            <label *ngIf="product.unitPrices?.length < 2" class='product-single-price'>{{ formattedUnitPrices[0] }}</label>
            <div *ngIf="product.unitPrices?.length > 1" class='table-responsive'>
                <table class='table-borderless m-0 w-100'>
                    <thead>
                        <tr>
                            <th *ngFor="let price of product.priceCategories">{{price}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngFor="let price of formattedUnitPrices" class="price-label">{{ price }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>