<app-side-nav></app-side-nav>
<mdb-navbar SideClass="main-navbar navbar navbar-expand-lg navbar-inverse bg-primary fixed-top main-nav" [class.offline]="!isConnected">
  <mdb-navbar-brand>
    <a class="navbar-brand" style="padding: 5px !important;height:64px;" routerLink="/" [hidden]="isLoggedIn || isInReview">
      <img src="/assets/images/logo.png" style="height: 100%; border-radius: 2px;" alt="OT">
    </a>
  </mdb-navbar-brand>

    <ul class="navbar-nav navbar-header ml-2 mr-auto flex-row" [hidden]="!isLoggedIn">
      <li class="nav-item">
        <a id="menu-toggle" href="javascript:void(0);" (click)="toggleMenu()" style="font-size: 18px;">
          <i class="fa fa-bars mr-1" *ngIf="pages.length > 1"></i>
          <span style="font-size:17px;">{{currentPageTitle}}</span>
        </a>
      </li>
    </ul>

    <ul class="navbar-nav mr-auto flex-row" [hidden]="isLoggedIn || isInReview">
      <li class="nav-item"><a class="nav-link" routerLink="/" [fragment]="'demo'">{{'Demo' | translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/" [fragment]="'price'">{{'Pricing' | translate}}</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/" [fragment]="'contact'">{{'Contact' | translate}}</a></li>
    </ul>

    <ul class="navbar-nav ml-auto mr-2 flex-row">
      <li class="nav-item" [hidden]="isLoggedIn" style="display:flex;">
        <a routerLink="/auth/login" class="nav-link" style="padding-left: 0; padding-right: 0;">
          {{'Login' | translate }}
        </a>
        <span style="border-left: 1px solid white;height: 23px;margin: 0px 10px 0;"></span>
        <a routerLink="/auth/register" class="nav-link" style="padding-left: 0; padding-right: 0;">
          {{'Register' | translate }}
        </a>
      </li>
      <li class="nav-item" [hidden]="hideLogout" style="display:flex;">
        <a href="javascript:void(0);" (click)="showLogoutModal()" class="nav-link">
          {{'Logoff' | translate }}
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" [hidden]="hideUserName" class="nav-link">
          <span>{{loggedInUser?.userName}}</span>
        </a>
      </li>
      <li *ngIf="showNotifications">
        <a href="javascript:void(0);" (click)="openNotificationDialog()" class="nav-link d-flex align-items-center">
          <i class="far fa-bell mr-1" style="font-size:20px;"></i>
          <span>{{"Notifications" | translate}}</span>
          <span class="notification-badge" [hidden]="notifications.length == 0">{{notifications.length}}</span>
        </a>
      </li>
    </ul>

</mdb-navbar>
