import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {find, groupBy, sortBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {StripeService} from "ngx-stripe";
import {PackageInfo, User} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "app-package-settings",
  templateUrl: "./package-settings.component.html",
  styleUrls: ["./package-settings.component.scss"],
})
export class PackageSettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  public user: User;
  public packageTypes: string[] = [];
  public packagesInfo: PackageInfo[] = [];
  public activePackage: PackageInfo;
  public groupedPackages: any = [];
  public activePackageType: string;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private userService: UserService,
    private messageService: MessageService,
    private stripeService: StripeService,
    private modalService: ModalService
  ) {
  }

  public async ngOnInit() {
    this.user = await this.localStorage.getUserAsync();
    this.packagesInfo = await this.localStorage.getPackagesInfoAsync();
    this.groupedPackages = this.groupPackagesInfo();
    this.packageTypes = Object.keys(this.groupedPackages).reverse();

    this.activePackage = find(this.packagesInfo, {id: this.user.packageId});
    if (this.activePackage) {
      this.activePackageType = this.activePackage.recursion;
    } else {
      this.activePackage = find(this.packagesInfo, {price: 0});
    }

    if (!this.activePackageType && this.packageTypes.length > 0) {
      this.activePackageType = this.packageTypes[0];
    }
  }

  public groupPackagesInfo() {
    const groupedPackages = groupBy(this.packagesInfo, "recursion");
    const packageWithNoRecursion = groupedPackages[""];
    if (packageWithNoRecursion) {
      delete groupedPackages[""];
      for (const key in groupedPackages) {
        groupedPackages[key] = groupedPackages[key].concat(packageWithNoRecursion);
      }
    }

    for (const key in groupedPackages) {
      groupedPackages[key] = sortBy(groupedPackages[key], ["price"]);
    }

    return groupedPackages;
  }

  public setPackageType(type: string) {
    this.activePackageType = type;
  }

  public applyPackage(selectedPackage: PackageInfo) {
    const packageId = selectedPackage.id;

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.userService.applyPackage(packageId).subscribe((res: {result, client_secret}) => {
      this.blockUI.stop();

      if (res.result === "requires_action") {
        this.stripeService.handleCardPayment(res.client_secret, {}).subscribe((result) => {
          this.blockUI.stop();
          if (result.error) {
            this.messageService.error(result.error.message);
          } else {
            this.messageService.success(this.translateService.instant("PackageUpdateSuccess"));

            this.activePackage = selectedPackage;
            this.user.packageId = packageId;
            this.localStorage.setUser(this.user);
          }
        });
      } else {
        this.messageService.success(this.translateService.instant("PackageUpdateSuccess"));

        this.activePackage = selectedPackage;
        this.user.packageId = packageId;
        this.localStorage.setUser(this.user);
      }
    }, (err) => {
      this.blockUI.stop();
      if(err == "NoPaymentInfo"){
        this.modalService.show("payment-settings-dialog", {
          packageInfo: selectedPackage,
          paymentFinished: (selectedPackage) => {
            this.applyPackage(selectedPackage);
          }
        });
      }
      else {
        this.messageService.error(err);
      }
    });
  }
}
