import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep, find, orderBy} from "lodash";
import {Subscription} from "rxjs";
import {OrderedItem, Product} from "../../../models";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionService} from "../../../services/subscription.service";
import { LocalCurrencyPipe } from "../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-self-order-category-products",
  templateUrl: "./self-order-category-products.component.html",
  styleUrls: ["./self-order-category-products.component.scss"],
})
export class SelfOrderCategoryProductsComponent implements OnInit, OnDestroy {
  @Input()
  public category: any;
  @Input()
  public products: Product[] = [];
  @Input()
  readOnly: boolean;

  public orderedItems: OrderedItem[] = [];

  public isVisible: boolean;
  public formattedUnitPrices = {};

  public selfOrderMenuEventSubscription: Subscription;
  public productOrderedEventSubscription: Subscription;

  public currencyPipe: LocalCurrencyPipe;
  constructor(
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService
  ) {
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public ngOnInit(): void {
    this.products = orderBy(this.products, "sortingId");
    this.products.map((p) => p["orderCount"] = 1);

    this.selfOrderMenuEventSubscription = this.subscriptionService.selfOrderMenuEvent.subscribe((items) => {
      this.orderedItems = items;
      this.products.forEach((product) => {
        const productOrders = this.orderedItems.filter((i) => i.productId == product.productId);
        let count = 0;
        productOrders.forEach((o) => count += o.quantity);
        product["orderCount"] = count;
      });
    });

    this.productOrderedEventSubscription = this.subscriptionService.productOrderedEvent.subscribe((product) => {
      product["ordered"] = true;
      this.category.insertingProduct = true;
      setTimeout(() => {
        product["ordered"] = false;
        product["orderCount"] = 1;
        this.category.insertingProduct = false;
      }, 1000);
    });

    this.products.forEach(product => {
      const prices = [];
      product.unitPrices.forEach((price) => {
        prices.push(this.currencyPipe.transform(parseFloat(price)));
      });
      this.formattedUnitPrices[product.productId] = prices;
      product.productDescription = product.productDescription?.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;");
    });
  }

  public ngOnDestroy(): void {
    this.selfOrderMenuEventSubscription.unsubscribe();
    this.productOrderedEventSubscription.unsubscribe();
  }

  public orderProduct(product: Product, $event) {
    this.subscriptionService.openProductForOrder(product, this.category);
    return ;

    $event.stopPropagation();

    if (product.isOutOfStock) { return; }

    if (product.priceCategories && product.priceCategories.length > 1 ||
      product.ingredients?.length > 0 ||
      find(this.category.extras, {categoryId: product.productCategoryId})  ||
      find(product.sideDishes, {categoryId: product.productCategoryId})) {
      this.modalService.show("sidedish-dialog", { product:product, category: this.category, onProductAdded: () => {
          product['ordered'] = true;
          setTimeout(() => {
            product['ordered'] = false;
          }, 2000);
        }
      });
    } else {
      product.unitPrice = parseFloat(product.unitPrices[0]);
      const clonedProduct = cloneDeep(product);
      this.subscriptionService.productOrdered(clonedProduct);
      product['ordered'] = true;
      setTimeout(() => {
        product['ordered'] = false;
      }, 2000);
    }
  }

  public showProductDetails(product: Product, $event) {
    this.subscriptionService.openProductForDetails(product);
  }

  public decreaseCount(product: Product, $event) {
    this.stopPropagation($event);
    const count = product["orderCount"];
    if (count > 1) {
      product["orderCount"] = count - 1;
    }
  }

  public increaseCount(product: Product, $event) {
    this.stopPropagation($event);
    product["orderCount"] = product["orderCount"] + 1;
  }

  public stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }
}
