import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { TseExport, TseExportList } from "../models/tse.export";

@Injectable({
  providedIn: "root",
})
export class TseService {
  constructor(
    private http: HttpClient) {
  }

  public activateTSE(): Observable<any> {
    return this.http.get<any>(`/api/v1/tse/activate`, {
      params: {
        "timeout": "25000"
      }
    });
  }

  public export(startDate, endDate): Observable<TseExport> {
    return this.http.get<TseExport>(`/api/v1/tse/export/${startDate}/${endDate}`);
  }

  public getAllExport(): Observable<TseExportList> {
    return this.http.get<TseExportList>(`/api/v1/tse/export/all`);
  }

  public getExport(id: string): Observable<TseExport> {
    return this.http.get<TseExport>(`/api/v1/tse/export/${id}`);
  }
}
