<div class="navbar row m-0 mb-3">
  <a class="btn btn-outline-primary btn-rounded" (click)="showAddCustomer()">
    <i class="fa fa-plus"></i>
    <span>{{"AddCustomer" | translate}}</span>
  </a>

  <div class="md-form m-0">
    <input mdbInput type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchItems()" id="search-input">
    <label for="search-input">Search</label>
  </div>
</div>

<div class="customers-container table-responsive" style="padding:15px 15px 0 15px;" [hidden]="customers.length == 0">
  <table id="customersTable" mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
      <thead>
      <tr>
        <th *ngFor="let head of columns; let i = index" aria-controls="customersTable" scope="col" [mdbTableSort]="customers" [sortBy]="columns[i].key">{{head.name | titlecase}}
          <mdb-icon fas icon="sort"></mdb-icon>
        </th>
      </tr>
      </thead>
      <tbody #row>
      <tr mdbTableCol *ngFor="let customer of customers; let i = index">
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
          <div class="d-flex">
            <a class="btn-primary btn-floating btn-sm m-0" style="margin-right: 10px !important;" (click)="showEditCustomer(customer)">
              <i class="far fa-edit"></i>
            </a>

            <a class="btn-danger btn-floating btn-sm m-0" style="margin-right: 10px !important;" (click)="showDeleteCustomerConfirmation(customer)">
              <i class="far fa-trash-alt"></i>
            </a>
          </div>
        </td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{customer.name}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{customer.telephone}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{customer.email}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{customer.firstName}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{customer.lastName}}</td>
      </tr>
      </tbody>
      <tfoot class="grey lighten-5 w-100">
      <tr>
        <td colspan="6">
          <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="customers"></mdb-table-pagination>
        </td>
      </tr>
      </tfoot>
    </table>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="customers.length == 0">
  <img src="/images/dining.svg" style="width: 250px;"/>
  <div class="text-info informative-text m-3">{{"NoCustomers" | translate}}</div>
</div>

<app-create-customer></app-create-customer>
