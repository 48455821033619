export abstract class ModalClassComponent {
  public zIndex = 2050;
  public id = "";

  public backdrop: HTMLElement;
  public parentElement: HTMLElement;
  public params: any;

  public createBackdrop() {
    if (this.id && document.getElementById(this.id)) { return; }

    this.backdrop = document.createElement("div");
    if (this.id) {
      this.backdrop.setAttribute("id", this.id);
    }

    if (this.params.onBackdropClick) {
      this.backdrop.addEventListener("click", this.params.onBackdropClick);
    }

    this.backdrop.classList.add("modal-backdrop");
    this.backdrop.style.zIndex = this.zIndex.toString();

    this.parentElement = document.getElementById("page");
    this.parentElement.appendChild(this.backdrop);
  }

  public show(params: any) {
    this.params = params;
    this.zIndex = params.zIndex;
    this.id = params.id;

    this.createBackdrop();
  }

  public hide() {
    if (this.params?.onBackdropClick) {
      this.backdrop.removeEventListener("click", this.params.onBackdropClick);
    }

    this.parentElement = document.getElementById("page");
    if(this.backdrop?.parentElement)
      this.backdrop.parentElement.removeChild(this.backdrop);
  }
}
