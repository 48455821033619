<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right product-details-modal" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-full-height modal-right modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{"SpecialWishes" | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body mh-100">
        <table style="width: 100%;">
          <thead *ngIf="wishList.length > 0">
          </thead>
          <tbody *ngIf="wishList.length == 0">
          <tr>
            <td colspan="5" style="padding:10px 5px;">
              <div class="alert alert-warning" role="alert">
                {{"NoSpecialWishes" | translate}}
              </div>
            </td>
          </tr>
          </tbody>

          <tbody>
          <tr style="border-bottom: 1px solid #dedede;" *ngFor="let wish of wishList">
            <td style="width: 50px; padding: 5px;">
              <button type="button" class="btn btn-floating primary-color" style="position:initial;"
                      [disabled]="manualExtras.indexOf(wish) > -1"
                      (click)="addSpecialWish(wish)">
                <i class="fa fa-plus" style="font-size:25px; padding-top: 1px;"></i>
              </button>
            </td>
            <td>{{wish}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
