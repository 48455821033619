import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {CookieService} from "../../services/cookie.service";
import {ModalService} from "../../services/modal.service";
import {ThirdPartyService} from "../../services/third.party.service";

@Component({
  selector: "app-cookie-law-banner",
  templateUrl: "./cookie-law-banner.component.html",
  styleUrls: ["./cookie-law-banner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CookieLawBannerComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public delay = 600;
  public expires = 365; // 1 year
  public cookieTypes = [
    {
      type: "Cookie_Essential_Title",
      value: "necessary",
      checked: true,
      disabled: true,
      description: "Cookie_Essential_Description",
    },
    {
      type: "Cookie_SitePreferences_Title",
      value: "preferences",
      checked: false,
      disabled: false,
      description: this.translateService.instant("Cookie_SitePreferences_Description"),
    },
    {
      type: "Cookie_Analytics_Title",
      value: "analytics",
      checked: false,
      disabled: false,
      description: this.translateService.instant("Cookie_Analytics_Description"),
    },
    {
      type: this.translateService.instant("Cookie_Marketing_Title"),
      value: "marketing",
      checked: false,
      disabled: false,
      description: this.translateService.instant("Cookie_Marketing_Description"),
    },
  ];
  public customized = false;

  constructor(
    private translateService: TranslateService,
    private thirdPartyService: ThirdPartyService,
    private modalService: ModalService,
    private cookieService: CookieService,
  ) {
  }

  public ngOnInit(): void {
    this.modalService.register("cookie-law-banner-dialog", this);
  }

  public hide() {
    this.modal.hide();
  }

  public show(args: any) {
    const preferences = this.cookieService.getCookie("cookieControlPrefs");
    if (!preferences) {
      this.modal.show();
    }
  }

  public onAccept() {
    const preferences = [];
    this.cookieTypes.forEach((cookieType) => {
      if (cookieType.checked || !this.customized) {
        preferences.push(cookieType.value);
      }
    });
    this.cookieService.setCookie("cookieControlPrefs", preferences.join("|"), this.expires);

    if (preferences.indexOf("analytics") !== -1) {
      this.thirdPartyService.addPageRecordingTool();
    }

    this.hide();
  }

  public onCustomize() {
    this.customized = true;
  }
}
