import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {IMyOptions, LocaleService, MdbTableDirective, MdbTablePaginationComponent} from "ng-uikit-pro-standard";
import {PaymentTypes} from "../../../models/payment.types.model";
import {WaiterDayEndReport} from "../../../models/waiter-dayend-report.model";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {ReportService} from "../../../services/report.service";

@Component({
  selector: 'app-waiter-dayend-report',
  templateUrl: './waiter-dayend-report.component.html',
  styleUrls: ['./waiter-dayend-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LocaleService]
})
export class WaiterDayendReportComponent implements OnInit {

  @BlockUI()
  public blockUI: NgBlockUI;

  @ViewChild(MdbTableDirective, { static: true }) public mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) public mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("row", { static: true }) public row: ElementRef;

  public startDateStr: string;
  public startDate: any;
  public startTimeStr: any;

  public endDateStr: string;
  public endTime: any;
  public endDate: any;
  public endTimeStr: any;
  public columns: any;
  public reports: WaiterDayEndReport[] = [];
  public currentLocale = this.translateService.currentLang;
  public dateFormat = dayjs.Ls[this.currentLocale].formats.L.toLowerCase();
  public clearLabel: string;
  public doneLabel: string;
  public paymentTypes = [];
  public selectedPaymentTypes = [];

  public datePickerOptions: IMyOptions = {
    dateFormat: this.dateFormat,
  };
 public  calendarOpened: boolean;

  constructor(
    private reportService: ReportService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private messageService: MessageService,
    private localStorage: LocalStorageService
  ) {
    const selectedTypes = window.localStorage.getItem("waiter-report-selected-payment-types");
    if(selectedTypes) {
      this.selectedPaymentTypes = JSON.parse(selectedTypes);
    }

    if(this.selectedPaymentTypes.length === 0){
      this.selectedPaymentTypes.push("CashPayment");
    }
  }

  public async ngOnInit() {
    this.startDate = dayjs();
    this.startDateStr = this.startDate.format("L");
    this.startTimeStr = "00:00";

    this.endDate = dayjs();
    this.endDateStr = this.endDate.format("L");
    this.endTimeStr = this.endDate.format("HH:mm");

    this.clearLabel = this.translateService.instant("Delete");
    this.doneLabel = this.translateService.instant("Apply");

    this.localStorage.getPaymentTypesAsync().then(res => {
      this.paymentTypes = res;
      this.getTableColumns();

      this.loadWaiterReports(dayjs().add(-3, 'day'), dayjs().add(1, 'hour'));
    });
  }

  public startDateUpdated($event: any) {
    this.startDate = dayjs($event.jsdate);
  }

  public endDateUpdated($event: any) {
    this.endDate = dayjs($event.jsdate);
  }

  public exportWaiterDayEndReport() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));

    const startTimeParts = this.startTimeStr.split((":"));
    this.startDate = this.startDate.hour(startTimeParts[0]);
    this.startDate = this.startDate.minute(startTimeParts[1]);

    const endTimeParts = this.endTimeStr.split((":"));
    this.endDate = this.endDate.hour(endTimeParts[0]);
    this.endDate = this.endDate.minute(endTimeParts[1]);

    var startDateTime = this.startDate.format().replace("+", ";");
    var endDateTime = this.endDate.format().replace("+", ";");

    this.reportService.exportWaiterDayEndReport(startDateTime, endDateTime).subscribe((report) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("WaiterCashReport_ExportSuccess"));

      report["createDate"] = dayjs(report["timestamp"]).format("L LT");
      report.startDate = dayjs(report.startDate).format("L LT");
      report.endDate = dayjs(report.endDate).format("L LT");
      this.reports.unshift(report);
      this.mdbTable.setDataSource(this.reports);

      setTimeout(() => {
        const firstRow = document.querySelector("#export-list table tbody tr:first-child");
        firstRow?.classList.add("higlight-report");
      }, 500);
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  private loadWaiterReports(startDate, endDate) {

    this.reportService.getWaiterReports(startDate.toISOString(), endDate.toISOString()).subscribe((reports) => {
      reports.forEach((report) => {
        report["endDateDayJs"] = dayjs(report.endDate);
        report["createDate"] = dayjs(report["timestamp"]).format("L LT");
        report.startDate = dayjs(report.startDate).format("L LT");
        report.endDate = dayjs(report.endDate).format("L LT");
      });
      this.reports = reports;
      this.mdbTable.setDataSource(this.reports);

      if(reports.length > 0) {
        this.startDate = reports[0]["endDateDayJs"];
        this.startDateStr = this.startDate.format("L");
        this.startTimeStr = this.startDate.format("HH:mm");
      }
    });
  }

  setSelectedPaymentType(payment: PaymentTypes, selected: any) {
    if(selected) {
      if(this.selectedPaymentTypes.indexOf(payment.name)) {
        this.selectedPaymentTypes.push(payment.name);
      }
    }
    else {
      const index = this.selectedPaymentTypes.indexOf(payment.name);
      if(index > -1) {
        this.selectedPaymentTypes.splice(index, 1);
      }
    }

    window.localStorage.setItem("waiter-report-selected-payment-types", JSON.stringify(this.selectedPaymentTypes));
    this.getTableColumns();
  }

  private getTableColumns() {
    this.columns = [
      {key: "startDate", name: "DatevExportStartDate"},
      {key: "endDate", name: "DatevExportEndDate"},
      {key: "employeeName", name: "InvoiceOperator"},
    ];

    this.selectedPaymentTypes.forEach(payment => {
      const type = this.paymentTypes.find(p => p.name == payment);
      if(type)
        this.columns.push({key: type.name, name: type.label})
    });
  }
}
