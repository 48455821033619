import { v4 as uuid} from "uuid";
import {BaseModel} from "./base.model";
import { Extra } from "./extra.model";
import {Product} from "./product.model";
import { SideDish } from "./sidedish.model";
import { ProductVariationGroup } from "./product.variation.group.model";

export class ProductCategory extends BaseModel {

  public productCategoryId: string;

  public productCategoryName: string;

  public productCategoryDescription: string;

  // List of products in the category.
  public products: Product[];

  public sortingId: number;

  public priceCategories: string[];

  public tax: number | null;

  public allowSendToKitchen: boolean | null;

  public extras: Extra[];

  public sideDishes: SideDish[];

  public variationGroups: ProductVariationGroup[];

  public color: string;

  public selected: boolean;
  constructor() {
    super();

    this.productCategoryId = uuid();
    this.extras = [];
    this.sideDishes = [];
    this.priceCategories = [];
    this.products = [];
    this.allowSendToKitchen = true;
  }
}
