import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, findIndex} from "lodash";
import {ModalDirective, TabsetComponent} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {Extra, ProductCategory, SideDish} from "../../../../models";
import {ProductCategoryPicture} from "../../../../models/product.category.picture.model";
import {VatDefinition} from "../../../../models/vat.definition.model";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";

@Component({
  selector: "app-create-category-dialog",
  templateUrl: "./create-category-dialog.component.html",
  styleUrls: ["./create-category-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateCategoryDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("nameInputRef")
  public nameInputRef: ElementRef;

  @ViewChild("txtPriceCatRef")
  public txtPriceCatRef: ElementRef;

  @ViewChild("txtExtraNameRef")
  public txtExtraNameRef: ElementRef;

  @ViewChild("txtSideDishNameRef")
  public txtSideDishNameRef: ElementRef;

  @ViewChild("tabs", {static: true}) public tabs: TabsetComponent;

  public name: string;
  public addCatCallback: any;
  public category: ProductCategory = new ProductCategory();
  public setItemsAsReady: boolean;
  public newPriceCategory: string;
  public newExtraName: string;
  public newExtraPrice = 0;

  public newSideDishName: string;
  public newSideDishPrice = 0;

  public addedFiles = [];
  public categoryPictures: ProductCategoryPicture[] = [];

  public dropzoneConfig = {};
  public fileUploader: any;
  public action: string;

  public vatDefinitions: any[];

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private productService: ProductService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("create-product-category-dialog", this);

    this.localStorage.getVatDefinitions().then((res: VatDefinition[]) => {
      if(res != null ) {
        this.vatDefinitions = [];
        this.vatDefinitions.push({value: null, label: this.translateService.instant("NoTaxRate")});
        res.forEach(entry => {
          if(this.vatDefinitions.find(e => e.value == entry.percentage) == null)
            this.vatDefinitions.push({value: entry.percentage, label: entry.percentage + "%"});
        });
      }
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public async show(params: any): Promise<any> {
    super.show(params);
    this.category = params.category;
    this.addCatCallback = params.addCategory;
    this.action = params.action;

    this.dropzoneConfig = {
      url: `api/v1/productcategory/addfile/${this.category.productCategoryId}/`,
      maxFiles: 1,
      uploadMultiple: false,
      maxFilesize: 10,
      acceptedFiles: "image/*",
      height: 150,
    };
    this.setItemsAsReady = !this.category.allowSendToKitchen;
    this.categoryPictures = await this.getProductCategoryImages();
    this.tabs.setActiveTab(1);
    this.modal.show();

    const pictures = filter(await this.localStorage.getProductCategoryPicturesAsync(), {
      categoryId: this.category.productCategoryId,
      dimensions: "original",
    });
    for (const picture of pictures) {
      picture.file = await this.createFile(picture);
    }

    this.categoryPictures = pictures;

    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      300,
    );
  }

  public async createFile(picture: ProductCategoryPicture): Promise<any> {
    const customerId = picture["partitionKey"];
    const response = await fetch(`/api/v1/productcategory/image/${customerId}/${picture.id}`);
    const data = await response.blob();
    const metadata = {
      type: "image/jpeg",
    };
    return new File([data], "test.jpg", metadata);
  }

  public addCategory() {
    this.category.allowSendToKitchen = !this.setItemsAsReady;
    this.productService.updateCategory(this.category).subscribe((category) => {
      if (this.addCatCallback) {
        this.addCatCallback(category);
      }
      this.hide();
    }, (error) => {
      this.messageService.error(error);
    });
  }

  public addPriceCategory(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.newPriceCategory) {
      if (!this.category.priceCategories) { this.category.priceCategories = []; }

      this.category.priceCategories.push(this.newPriceCategory);
      this.newPriceCategory = "";
    }
  }

  public deletePriceCategory(cat) {
    const index = this.category.priceCategories.indexOf(cat);
    this.category.priceCategories.splice(index, 1);
  }

  public trackByIdx(index: number, obj: any): any {
    return index;
  }

  public onFileRemoved(file: any) {
    const index = findIndex(this.categoryPictures, {id: file.id});
    if (index > -1) {
      this.categoryPictures.splice(index, 1);
    }
  }

  public async onUploadSuccess(file: any): Promise<any> {
    if (file.status === "success") {
      const addedFiles = JSON.parse(file.xhr.response);
      const addedFile = find(addedFiles, {fileName: file.name});

      file.id = addedFile.id;

      const allCategoryPictures = await this.localStorage.getProductCategoryPicturesAsync();
      allCategoryPictures.push(addedFile);
      this.localStorage.setProductCategoryPictures(allCategoryPictures);

      this.categoryPictures = await this.getProductCategoryImages();
      this.categoryPictures = await this.getProductCategoryImages();
    } else {
      const categoryPictures = document.getElementById("product-category-pictures");
      categoryPictures.querySelectorAll(".dz-error .dz-error-message").forEach((el: HTMLElement) => {
        el.style.display = "none";
      });

      const removeBtnDocument = new DOMParser().parseFromString("<a class='btn-remove-image'><i class='far fa-times-circle fa-2x text-danger'></i></a>", "text/html");
      const removeBtn = removeBtnDocument.body.firstElementChild;
      removeBtn.addEventListener("click", () => {
        this.fileUploader.removeFile(file);
      });
      categoryPictures.querySelectorAll(".dz-error").forEach((el: HTMLElement) => {
        el.appendChild(removeBtn);
      });
    }
  }

  public async getProductCategoryImages(): Promise<any> {
    const images = filter(await this.localStorage.getProductCategoryPicturesAsync(), {categoryId: this.category.productCategoryId, dimensions: "original"});
    if (images.length === 0) { return [{
      id: uuid(),
      categoryId: this.category.productCategoryId,
      url: "/assets/images/plate.png",
      nonexisting: true,
    }];
    }

    return images;
  }

  public initDropzone(uploader: any) {
    this.fileUploader = uploader;
    uploader.removeAllFiles();
    const files = this.categoryPictures;
    files.forEach(async (file) => {
      const thumbnailImage = find(await this.localStorage.getProductCategoryPicturesAsync(), {dimensions: "thumbnail", originalFileId: file.id});
      const url = thumbnailImage ? thumbnailImage.url : file.url;

      const mockFile = {name: file.fileName, size: file.size, type: file.contentType, id: file.id};
      uploader.emit("addedfile", mockFile);
      uploader.emit("thumbnail", mockFile, url);
      uploader.files.push(mockFile);

      mockFile["previewElement"].classList.add("dz-success");
      mockFile["previewElement"].classList.add("dz-complete");
    });
  }

  public resetImage() {
    const confirmModel = {
      message: this.translateService.instant("DeleteImageConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.doResetImage();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public addExtra(event: any) {
    this.stopEventPropagation(event);

    if (this.newExtraName) {
      this.txtExtraNameRef.nativeElement.focus();
      const extra = new Extra();
      extra.id = uuid();
      extra.name = this.newExtraName;
      extra.price = this.newExtraPrice;

      this.category.extras.push(extra);

      this.newExtraName = "";
      this.newExtraPrice = 0;
    }
  }

  public deleteExtra(extra: Extra) {
    const index = findIndex(this.category.extras, {id: extra.id});
    if (index > -1) {
      this.category.extras.splice(index, 1);
    }
  }

  public addSideDish(event: any) {
    this.stopEventPropagation(event);

    if (this.newSideDishName) {
      this.txtSideDishNameRef.nativeElement.focus();

      const sideDish = {
        id: uuid(),
        name: this.newSideDishName,
        price: this.newSideDishPrice,
      } as SideDish;

      this.category.sideDishes.push(sideDish);

      this.newSideDishName = "";
      this.newSideDishPrice = 0;
    }
  }

  public deleteSideDish(sideDish) {
    const index = findIndex(this.category.sideDishes, {id: sideDish.id});
    if (index > -1) {
      this.category.sideDishes.splice(index, 1);
    }
  }

  public onGetActiveTab($event: any) {
    if (!$event) { return; }

    if ($event.activeTabIndex === 0) {
      this.nameInputRef.nativeElement.focus();
    } else if ($event.activeTabIndex === 1) {
      this.txtPriceCatRef.nativeElement.focus();
    } else if ($event.activeTabIndex === 2) {
      this.txtExtraNameRef.nativeElement.focus();
    } else if ($event.activeTabIndex === 3) {
      this.txtSideDishNameRef.nativeElement.focus();
    }
  }

  public stopEventPropagation(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  private doResetImage() {
    this.productService.resetCategoryPictures(this.category.productCategoryId).subscribe(async () => {
      let allCategoryPictures = await this.localStorage.getProductCategoryPicturesAsync();
      allCategoryPictures = allCategoryPictures.filter((c) => c.categoryId !== this.category.productCategoryId);
      this.localStorage.setProductCategoryPictures(allCategoryPictures);

      this.fileUploader.removeAllFiles();
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
