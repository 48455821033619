<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right product-details-modal" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-full-height modal-right modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{item.product.productName}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body mh-100">
        <table style="width: 100%;">
          <tbody>
          <tr *ngFor="let ingredient of ingredients"
              (click)="toggleSelect(ingredient, $event)"
              style="border-bottom: 1px solid #dedede;">
            <td style="padding: 5px;">{{ingredient.name}}</td>
            <td style="width: 45px; padding: 5px;">
              <div class="switch round blue-white-switch">
                <label>
                  <input type="checkbox" readonly
                         [checked]="ingredient.selected"
                         [attr.id]="ingredient.name" />
                  <span class="lever"></span>
                </label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
