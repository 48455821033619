import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
  selector: "app-inline-editor",
  templateUrl: "./inline-editor.component.html",
  styleUrls: ["./inline-editor.component.scss"],
})
export class InlineEditorComponent implements OnInit {
  private _model: string;
  @Input() set model(value: string) {
    this._model = value;
    this.modelCopy = this._model;
  }
  get model(): string {
    // other logic
    return this._model;
  }

  @Output() public modelChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() public classes: string;
  @Input() public helpText: string;
  @Input() public inputType: string;
  @Input() public placement: string;

  public modelCopy: string;
  constructor() {}

  public ngOnInit() {
    if (!this.inputType) { this.inputType = "text"; }

    if (!this.placement) { this.placement = "bottom"; }

    this.modelCopy = this.model;
  }

  public applyValue() {
    this.modelChange.emit(this.modelCopy);

    return true;
  }

  public cancelChanges() {
    this.modelCopy = this.model;
    this.applyValue();

    return true;
  }
}
