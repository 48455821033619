import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, groupBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ModalDirective, TabsetComponent} from "ng-uikit-pro-standard";
import {Subscription} from "rxjs";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Table, TableCategory} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {PosService} from "../../../services/pos.service";
import {SignalRService} from "../../../services/signalr.service";

@Component({
  selector: "app-move-order-dialog",
  templateUrl: "./move-order-dialog.component.html",
  styleUrls: ["./move-order-dialog.component.scss"],
})
export class MoveOrderDialogComponent extends ModalClassComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;
  @ViewChild("modal") public modal: ModalDirective;
  @ViewChild("tableTabs") public tableTabs: TabsetComponent;

  public tableCategories: TableCategory[] = [];
  public tables: Table[] = [];
  public activeTableId: string;
  public selectedTableId: string;
  public groupedTables: any = {};
  public activeCategoryId: string;

  private moveViewModel: any;
  private tableUpdatedSubscription: Subscription;
  private fromTableId: string;

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private messageService: MessageService,
    private posService: PosService,
    private translateService: TranslateService,
    private signalRService: SignalRService) {
    super();
  }

  public ngOnInit() {
    this.modalService.register("move-dialog", this);

    this.tableUpdatedSubscription = this.signalRService.tableUpdated.subscribe(async () => {
      const activeTabIndex = this.tableTabs?.getActive() || 0;
      await this.initTables();
      setTimeout(() => this.tableTabs?.setActiveTab(activeTabIndex + 1), 50);
    });
  }

  public ngOnDestroy() {
    this.tableUpdatedSubscription.unsubscribe();
  }

  public moveItems() {
    if (!this.selectedTableId) {
      this.messageService.error(this.translateService.instant("ChooseTable"));
      return;
    }

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.moveViewModel.toTableId = this.selectedTableId;

    this.posService.moveItems(this.moveViewModel).subscribe(() => {
      this.blockUI.stop();
      this.hide();
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public hide() {
    super.hide();
    this.modal.hide();
  }

  public show(moveViewModel: any) {
    super.show(moveViewModel);
    this.moveViewModel = moveViewModel;
    this.fromTableId = moveViewModel.fromTableId;
    setTimeout(async () => {
      await this.initTables();
      this.modal.show();
    }, 10);
  }

  public selectTable(id: any) {
    this.selectedTableId = id;
  }

  private async initTables() {
    this.tableCategories = await this.localStorage.getTableCategoriesAsync();
    this.tables = (await this.localStorage.getTablesAsync()).filter(t => t.id !== this.fromTableId);
    this.groupedTables =this.groupTables();

    if(this.tableCategories.length > 0)
      this.activeCategoryId = this.tableCategories[0].id;
  }

  public groupTables(): any {
    const result = {};

    this.tableCategories.forEach((category) => {
      result[category.id] = filter(this.tables, {categoryId: category.id});
    });

    const tablesWoCats = filter(this.tables, {categoryId: null});
    if (tablesWoCats?.length > 0) {
      const nullId = null;
      result[nullId] = tablesWoCats;
    }

    return result;
  }
}
