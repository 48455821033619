<nav class="navbar navbar-expand-lg navbar-inverse bg-primary navbar-pos-order-list sub-nav">
  <div class="navbar-collapse full-width" style="display:flex;height:65px;">
    <ul class="navbar-nav navbar-header mr-auto">
      <li class="nav-item">
        <a href="javascript:void(0);" (click)="hideOverview()" style="font-size: 20px;white-space:nowrap;">
          <i class="fa fa-chevron-left"></i>
          <span>{{"Back" | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>

<div id="receipt-overview" class="receipt-overview" style="padding:1px;" *ngIf="receipt.invoiceItems?.length > 0">
  <div class="card receipt-container front">
    <div class="receipt-header" style="margin:0px;">
      <div style="font-weight:bold;font-size:17px;">
        <span>{{"InvoiceNumber" | translate}}</span>:&nbsp;
        <span>{{receipt.invoiceNumber}}</span>
      </div>
    </div>

    <table id="order-table" class="order-table">
      <thead style="border-bottom:1px dashed #ccc">
      <tr>
        <th style="text-align: right;padding-right: 13px;width: 30px;">#</th>
        <th>{{"ProductName" | translate}}</th>
        <th style="width:50px;text-align:right;padding-right:5px;">{{"Price" | translate}}</th>
      </tr>
      </thead>
      <tbody style="border-bottom:1px dashed #ccc;">
      <tr *ngFor="let item of receipt.invoiceItems"
          [class.cancelled]="item.status == orderStatus.cancelled"
          (click)="selectItem(item)"
          [hidden]="item.quantity <= 0 && cancelActivated">
        <td style="padding-left: 5px;">
          <div class="d-flex justify-content-end" style="line-height: 1">
            <span class="fa fa-times text-danger" style="font-size: 21px; width: 22px;" *ngIf="item.status == 5"></span>
            <span class="ml-2 mr-2" style="padding-top: 3px; white-space: nowrap;">
              {{
                cancelActivated && item.status !== orderStatus.cancelled && item.quantity > 0
                  ? item['selectionCount'] + "x/" + item.quantity + "x"
                  : item.quantity + "x"
              }}
            </span>
          </div>
        </td>
        <td>{{item.productName}}</td>
        <td style="text-align:right;padding-right:5px;white-space: nowrap">
          {{ item.total | otcurrency}} ({{item['taxIndex'] }})
        </td>
      </tr>
      </tbody>
      <tbody>
      <tr>
        <td colspan="2" style="padding-left:5px;">{{"Subtotal" | translate}}</td>
        <td style="text-align:right;padding-right:5px;">
          {{ receipt.totalPriceGross | otcurrency }}
        </td>
      </tr>

      <tr style="font-size:14px" *ngIf="receipt.discountAmount != 0">
        <td></td>
        <td>{{"Discount" | translate}}</td>
        <td style="text-align:right;padding-right:5px;">
          {{(-1 * receipt.discountAmount) | otcurrency}}
        </td>
      </tr>

      <tr *ngIf="receipt.tipAmount != 0">
        <td></td>
        <td>{{"TipAmount" | translate}}</td>
        <td style="text-align:right;padding-right:5px;">
          {{ receipt.tipAmount | otcurrency }}
        </td>
      </tr>

      <tr style="font-size:14px" *ngIf="receipt.cardFeeAmount != 0">
        <td></td>
        <td>{{"CardFee" | translate}}</td>
        <td style="text-align:right;padding-right:5px;">
          {{ receipt.cardFeeAmount | otcurrency }}
        </td>
      </tr>
      </tbody>
      <tbody>
      <tr>
        <td colspan="2" style="padding-left:5px;font-size:18px;">{{"TotalSum" | translate}}</td>
        <td style="text-align:right;padding-right:5px;font-size:18px;">
          {{(receipt.totalPriceGross - receipt.discountAmount) | otcurrency}}
        </td>
      </tr>
      </tbody>
      <tbody style="border-top:1px dashed #ccc;font-weight:bold;" [hidden]="cancelActivated">
      <tr *ngFor="let taxAmount of receipt.taxAmounts">
        <td></td>
        <td class="d-flex justify-content-between" style="margin-top:3px;">
          <div>
            <span>
              ({{ taxAmount['taxIndex'] }})
            </span>
            <span>
              {{taxAmount.taxRate}}%
            </span>
          </div>
          <div>
            {{taxAmount.taxValue | otcurrency}}
          </div>
        </td>
        <td style="text-align:right; padding-right:5px;">
          {{taxAmount.net | otcurrency}}
        </td>
      </tr>
      </tbody>
    </table>

    <div style="margin:0;padding:10px 0;font-size:15px;font-weight:bold;" *ngIf="receipt.paymentType == 'CombinedPayment' && !cancelActivated">
      <span>{{"PaymentMethod" | translate}}</span>&nbsp;:&nbsp;&nbsp;
      <span>{{receipt.paymentType | translate}}</span>
      <div>{{localizedCombinedTypes}}</div>
    </div>

    <div *ngIf="receipt.paymentType != 'CombinedPayment' && !cancelActivated && isEditable">
      <span>{{"PaymentMethod" | translate}}</span>&nbsp;:&nbsp;&nbsp;
      <table class="w-100 bg-white">
        <tr>
          <td class="pl-2">
            <mdb-select-2 [(ngModel)]="paymentType">
              <mdb-select-option *ngFor="let option of paymentTypes" [value]="option.name">{{ option.label }}</mdb-select-option>
            </mdb-select-2>
          </td>
          <td style="width: 40px;">
            <button mdbBtn color="primary" outline="true" rounded="true" size="sm" mdbWavesEffect (click)="saveInvoicePaymentType()">
              <span>{{"Save" | translate}}</span>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div style="margin:0;padding:0 20px;" [hidden]="!isEditable" data-html2canvas-ignore="true">
      <div style="margin-bottom:5px;">
        <button  mdbBtn color="danger" rounded="true" outline="true" mdbWavesEffect [hidden]="cancelActivated || isCombinedPayment" (click)="showCancelOrders()" class="toolbar-item" style="width:100%;background-color:white !important;">
          <i class="fa fa-times mr-1"></i>
          <span>{{"CancelOrder" | translate}}</span>
        </button>
        <a  mdbBtn color="primary" rounded="true" outline="true" mdbWavesEffect class="mt-3 w-100 toolbar-item" [hidden]="!cancelActivated" (click)="backToOverview()" style="background:white !important;">
          <i class="fa fa-chevron-left mr-1"></i>
          <span>{{"Back" | translate}}</span>
        </a>
        <button  mdbBtn color="danger" rounded="true" outline="true" mdbWavesEffect class="mt-3 w-100 toolbar-item" [hidden]="!cancelActivated" (click)="cancelOrders()" style="background: white !important;">
          <i class="fa fa-times mr-1"></i>
          <span>{{"CancelOrder" | translate}}</span>
        </button>
      </div>
    </div>
    <div style="margin:0;padding: 0 20px;" [hidden]="cancelActivated" data-html2canvas-ignore="true">
      <div style="margin-bottom:5px;">
        <button  mdbBtn color="primary" rounded="true" outline="true" mdbWavesEffect (click)="exportAsPdf()" class="toolbar-item" style="width:100%;background-color:white !important;">
          <i class="far fa-file-pdf mr-1"></i>
          <span>{{"ExportAsPdf" | translate}}</span>
        </button>
      </div>
      <div style="display:flex;" [hidden]="!showHostingSplit">
        <span style="margin-top:6px;" >{{"PrintWithDetails" | translate}}</span>
        <div class="switch round blue-white-switch">
          <label>
            <input type="checkbox" [(ngModel)]="showPrintDetails" />
            <span class="lever"></span>
          </label>
        </div>
      </div>
      <a  mdbBtn color="primary" rounded="true" outline="true" mdbWavesEffect (click)="printReceipt()" class="toolbar-item" style="width:100%;background-color:white !important;">
        <i class="fa fa-print mr-1"></i>
        <span>{{"Print" | translate}}</span>
      </a>
    </div>
  </div>
</div>
