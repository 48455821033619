import { BaseModel } from "./base.model";

export class Notification extends BaseModel {
  public notificationId: string;

  public tableId: string;

  public title: string;

  public description: string;

  public date: Date;

  public icon: string;

  public type: NotificationTypes;
}

export enum NotificationTypes {
  WaiterCalled = "waiterCalled",
  BillRequested = "billRequested",
  OrdersReady = "ordersReady",
}
