import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {LocalStorageService} from "./localstorage.service";
import {NativeAppService} from "./native-app.service";
import {SubscriptionService} from "./subscription.service";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  constructor(
    private localStorage: LocalStorageService,
    private nativeAppService: NativeAppService,
    private http: HttpClient,
    private router: Router,
    private subscriptionService: SubscriptionService) {
  }

  public logIn(loginData) {
    return this.http.post<any>("/account/login", loginData);
  }

  public logOut() {
    let deviceId = localStorage.getItem("device-id");
    if(!deviceId) deviceId = "";

    return this.http.get("/home/logout/" + deviceId + "?t=" + Date.now());
  }

  public forgetPassword(userName: string) {
    return this.http.post("/account/forgotpassword/" + userName, {});
  }

  public checkPasswordResetCode(code: string) {
    return this.http.get<boolean>("/account/checkpasswordresetcode/" + code);
  }

  public updatePassword(password: string, confirmPassword: string, code: string) {
    return this.http.post<boolean>("/account/updatepassword", {password, confirmPassword, code});
  }

  public async removeAllUserData() {
    this.localStorage.removeAll();

    const $zoho = window["$zoho"];
    if ($zoho) {
      $zoho.salesiq.floatwindow.visible("hide");
      $zoho.salesiq.visitor.name("");
      $zoho.salesiq.visitor.email("");
      $zoho.salesiq.chat.complete();
    }

    document.body.classList.remove("logged-in");
    this.nativeAppService.userLoggedOut();
    this.subscriptionService.logInStatusChanged(false);
    await this.router.navigate(["/auth/login"]);
  }
}
