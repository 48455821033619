import {BaseModel} from "./base.model";
import { OrderVariation } from "./order.variation.model";
import {Product} from "./product.model";
import {SideDish} from "./sidedish.model";

export class OrderedItem extends BaseModel {
  public orderedItemId: string;

  public quantity: number;

  public unitPrice: number;

  public extras: string;

  public extrasIds: string;

  public extrasPrice: number;

  public totalPrice: number;

  public tax: number;

  public taxPrice: number;

  public status: number;

  public tableId: string;

  public tableName: string;

  public customerId: string;

  public productId: string;

  public productName: string;

  public product: Product;

  public orderDate: Date | string;

  public selectedPriceCategory: string;

  public categoryName: string;

  public productCategoryId: string;

  public courseId: string;

  public courseName: string;

  public servedBy: string;

  public server: string;

  public selectionCount: number;

  public sideDishes: SideDish[];

  public manualExtra: string;

  public ingredientsToRemove: string[];

  public selectedExtra: string;

  public isActive: boolean;

  public orderedVariations: OrderVariation[];

  public notes: string;
}
