<div class="product-view">
  <div class="card product-info-container" style="width:100%;" (click)="orderProductWithSinglePrice()" long-press (onLongPress)="showProductDetails()">
    <div [class.product-with-picture]="showProductPicture" class="panel-body product-body p-0">
      <div *ngIf="showProductPicture && images?.length > 0" class="product-images">
        <img class="d-block" src="{{images[0].url}}" alt="Product image">
      </div>
      <div *ngIf="showProductPicture && images?.length ==0" class="product-no-image"></div>
      <label class="lbl-product-name">{{product.productName}}</label>
      <div *ngIf="showProductPrice" class="product-price">
        <label *ngIf="product.unitPrices?.length < 2" class='product-single-price'>{{ formattedUnitPrices[0] }}</label>
        <div *ngIf="product.unitPrices?.length > 1" class='table-responsive'>
          <table class='table table-bordered m-0'>
            <thead>
              <tr>
                <th *ngFor="let price of product.priceCategories">{{price}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td *ngFor="let price of formattedUnitPrices">{{ price }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <span *ngIf="orderCount > 0" class="badge badge-danger z-depth-1 lbl-order-count">{{orderCount}}</span>
    </div>
    <div class="panel-footer" style="text-align:center;padding: 0">
      <button mdbBtn mdbWavesEffect color="primary" rounded="true" size="sm" [disabled]="product.isOutOfStock" style="padding:8px 10px;display: none;">
        <i class="fa fa-plus" style="font-size: 18px; vertical-align: middle;"></i>
        <span style="vertical-align: middle;">
          {{ "Add" | translate }}
          <i *ngIf="product.priceCategories.length > 1 && !showProductPrice">({{product.priceCategories.length}})</i>
        </span>
      </button>
      <a (click)="setFavorite($event)" class="favorite-icon" style="font-size:30px; color:red; float:right; margin-right: 10px;">
        <i *ngIf="!settingFavorite"  class="fa-heart far" [class.far]="!isFavorite" [class.fas]="isFavorite" ></i>
        <div *ngIf="settingFavorite" class="css-loader css-loader-middle" style="float: right; margin-top: 5px;"></div>
      </a>
      <label *ngIf="product.isOutOfStock" class="out-of-stock">{{"OutOfStock" | translate}}</label>
    </div>
  </div>

  <div *ngIf="showPriceCategories" class="card price-category-container" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="z-index: 2000">
    <div class="" role="document">
      <div class="">
        <div class="modal-header" style="height: 50px;">
          <h4 class="modal-title w-100" style="font-size: 13px;font-weight: bold; margin-right: 0;text-align: left;">{{'ChoosePortionSize' | translate}}</h4>
          <button type="button" class="close" (click)="hidePriceCategory($event)" style="font-size: 45px;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <button mdbBtn color="primary" *ngFor="let category of product.priceCategories" (click)="orderProductWithSelectedCategory(category, $event)" class='btn-choose-category'>{{category}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
