<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;">
    <div class="modal-content">
      <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addTable()" class="html5-form" autocomplete="off">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{(table.id ? "UpdateTable" : "AddTable") | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="md-form">
            <input mdbInput #nameRef type="text" class="form-control" id="tableName" name="tableName" [(ngModel)]="table.name" required="required"/>
            <label for="tableName">{{"TableName" | translate}}</label>
          </div>
          <div class="md-form" *ngIf="vatDefinitions == null">
            <input mdbInput type="number" step="0.01" min="0" max="100" class="form-control" id="tableTax" name="tableTax" [(ngModel)]="table.tax"/>
            <label for="tableTax">{{"Tax" | translate}}</label>
          </div>
          <div class="md-form" *ngIf="vatDefinitions != null">
            <mdb-select-2 name="tax" [placeholder]="'ChooseTax' | translate" [(ngModel)]="table.tax" >
              <mdb-select-option *ngFor="let option of vatDefinitions" [value]="option.value">{{ option.label }}</mdb-select-option>
            </mdb-select-2>
          </div>
          <div class="md-form" *ngIf="isUpdate">
            <label for="category" class="active">{{'ChooseCategoryToFilter' | translate}}</label>
            <mdb-select-2 name="category" id="category"
                          [(ngModel)]="table.categoryId"
                          (ngModelChange)="getSelectedCategory($event)"
                          [highlightFirst]="false">
              <mdb-select-option *ngFor="let option of tableCategories" [value]="option.value">{{ option.label }}</mdb-select-option>

            </mdb-select-2>
          </div>

          <div class="d-flex align-items-center mb-3" *ngIf="userSettings.selfOrderDigitalQRCode">
            <a href="javascript:void(0);" class="info-icon" tabindex="4"
               [mdbPopover]="qrCodeDisplayTemplate"
               placement="right"
               triggers="focus"
               container="body"
               [containerClass]="'info-popover'">
              <i class="fa fa-info-circle"></i>
            </a>
            <div class="md-form mt-0 mb-0 flex-grow-1">
              <input mdbInput type="text" class="form-control" id="displayNumber" name="displayNumber" [(ngModel)]="table.displayNumber"/>
              <label for="displayNumber">{{"EPaperDisplayNumber" | translate}}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" class="btn btn-primary btn-rounded waves-effect waves-light toolbar-item">
            <i class="text-white" [ngClass]="{'fa fa-plus ' : !isUpdate , 'fas fa-save' : isUpdate}"></i>
            {{(isUpdate ? "Update" : "Add") | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #qrCodeDisplayTemplate>
  <div [innerHtml]="'EPaperDisplayNumber_Help' | translate"></div>
</ng-template>
