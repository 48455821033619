<div class="jumbotron w-75 ml-auto mr-auto mt-5" [hidden]="user.fiskalyOrganisationId">
  <h4 class="font-weight-bold">DSFinV-K</h4>
  <p class="lead">Die digitale Schnittstelle der Finanzverwaltung für Kassensysteme (DSFinV-K) ist in der Registrierkasse integriert und funktionsfähig.</p>
  <h4 class="font-weight-bold">TSE</h4>
  <p class="lead" style="font-weight:900">Für die Einbindung der TSE sind folgende Schritte notwendig:</p>
  <hr class="my-4">
  <div [hidden]="!user.taxId">
    <div class="d-flex align-items-center">
      <i class="fas fa-check mr-3" style="font-size: 50px; color:#02bd00"></i>
      <div class="d-flex flex-column">
        <h4>1. Tragen Sie Ihre Stammdatein ein</h4>
        <label class="ml-2">
          Sie haben Ihre Stammdaten erfolgreich eingetragen
        </label>
      </div>
    </div>
  </div>
  <div [hidden]="user.taxId">
    <div class="d-flex align-items-center">
      <i class="fas fa-times mr-3" style="font-size: 50px; color:#ff5959"></i>
      <div class="d-flex flex-column">
        <h4>1. Tragen Sie Ihre Stammdatein ein</h4>
        <label>Um die TSE zu aktivieren, müssen Sie Ihre Stammdaten eingeben. Bitte gehen Sie zu den Profileinstellungen und geben Sie alle erforderlichen Daten ein.</label>
        <a href="/#/settings/profile">Zur Profileinstellungen</a>
      </div>
    </div>
  </div>
  <hr class="my-4">
  <div [hidden]="packagePrice == 0">
    <div class="d-flex align-items-center">
      <i class="fas fa-check mr-3" style="font-size: 50px; color:#02bd00"></i>
      <div class="d-flex flex-column">
        <h4>2. Kostenpflichtiges Paket abonnieren</h4>
        <label>
          Sie haben bereits ein kostenpflichtiges Paket abonniert
        </label>
      </div>
    </div>
  </div>
  <div [hidden]="packagePrice > 0">
    <div class="d-flex align-items-center">
      <i class="fas fa-times mr-3" style="font-size: 50px; color:#ff5959"></i>
      <div class="d-flex flex-column">
        <h4>2. Kostenpflichtiges Paket abonnieren</h4>
        <label>Sie müssen ein kostenpflichtiges Paket abonnieren, bevor Sie die TSE aktivieren. Bitte gehen Sie zu den Paketeinstellungen und wählen Sie ein kostenpflichtiges Paket aus.</label>
        <a href="/#/settings/packages">Zur Paketeinstellungen</a>
      </div>
    </div>
  </div>
  <hr class="my-4">
  <button type="button" class="btn btn-primary btn-rounded" [disabled]="false" (click)="activateTSE()">TSE aktivieren</button>
</div>

<div [hidden]="!user.fiskalyOrganisationId">
  <div class="card mx-auto p-3 mt-2 datev-export-header" style="width:90%">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <i class="fas fa-check mr-2" style="font-size: 25px; color:#02bd00"></i>
        <label class="m-0 p-0">
          TSE ist bereits aktiviert und funktionstüchtig.
        </label>
      </div>
      <div>
        <b class="text-warning" style="font-weight: 900;">Wichtiger Hinweis: </b>Die TSE is noch nicht vollständig zertifiziert,  da unserem Anbieter(fiskaly) noch Teilzertifikate zur TSE fehlen.
          Daher empfehlen wir Ihnen zusätzlich, schnellstmöglich einen Antrag auf Fristverlängerung nach § 148 AO mit Ihrem Steuerberater zu besprechen, damit Sie auch nach dem 31.03.2021 OrdersTracker weiterhin nutzen können.
      </div>
      <hr class="mb-5"/>
      <div class="row">
        <div class="col-md-4 form-group row">
          <label class="col-sm-4 col-form-label" for="startingDate">{{"DatevExportStartDate"| translate}}</label>
          <div class="col-sm-8">
            <mdb-date-picker name="startingDate" id="startingDate"
                             [options]="datePickerOptions"
                             [placeholder]="'ChooseDatePlaceHolder' | translate" [(ngModel)]="startDateStr"
                             (dateChanged)="startDateUpdated($event)"
                             [locale]="currentLocale"
                             required></mdb-date-picker>
          </div>
        </div>
        <div class="col-md-4 form-group row">
          <label class="col-sm-4 col-form-label" for="endingDate">{{"DatevExportEndDate"| translate}}</label>
          <div class="col-sm-8">
            <mdb-date-picker name="endingDate" id="endingDate"
                             [options]="datePickerOptions"
                             [placeholder]="'ChooseDatePlaceHolder' | translate" [(ngModel)]="endDateStr"
                             (dateChanged)="endDateUpdated($event)"
                             [locale]="currentLocale"
                             required></mdb-date-picker>
          </div>
        </div>
        <div class="col-sm-4">
          <button type="button" class="btn btn-primary btn-rounded float-right toolbar-item" (click)="export()">
            <i class="fas fa-file-export mr-1" style="font-size:16px;"></i>
            <span>{{"Export" | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="export-list" class="mt-3 ml-auto mr-auto" style="width:90%;" [hidden]="exports.length == 0">
    <table id="tseExportTable" mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
      <thead>
      <tr>
        <th *ngFor="let head of columns; let i = index" aria-controls="tseExportTable" scope="col" [mdbTableSort]="exports" [sortBy]="columns[i].key">{{head.name | titlecase}}
          <mdb-icon fas icon="sort" [hidden]="head.sortable === false"></mdb-icon>
        </th>
      </tr>
      </thead>
      <tbody #row>
      <tr mdbTableCol *ngFor="let export of exports; let i = index">
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{export['requestDate']}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{export['startDate']}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{export['time_completed']}}</td>
        <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
          <a class="btn btn-flat p-0 m-0 mr-4" [attr.href]="export.href" *ngIf="export.href">
            <span class="text-primary mr-1">{{"Download" | translate}}</span>
            <i class="fas fa-download text-primary" style="font-size:16px;"></i>
          </a>
          <div id="loader" *ngIf="!export.href" class="css-loader margin-bottom"></div>
        </td>
      </tr>
      </tbody>
      <tfoot class="grey lighten-5 w-100">
      <tr>
        <td colspan="5">
          <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="exports"></mdb-table-pagination>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>

  <div *ngIf="exports.length == 0" class="text-info informative-text ml-auto mr-auto mt-3" role="alert" style="width:90%;;">
    {{"DatevExportNotFound" | translate}}
  </div>
</div>
