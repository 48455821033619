<div id="orderListView"
     style="margin: 0;display: inline-flex;position: absolute;top: 10px;bottom: 10px;left: 10px;right: 10px;overflow: auto;">
  <app-self-order-approve-single-table *ngFor="let orders of tableOrders"
                                       [orders]="orders"
                                       [tableOrderList]="tableOrders"
                                       (orderUpdate)="ordersUpdated()"></app-self-order-approve-single-table>
</div>

<div class="text-center" *ngIf="tableOrders.length == 0">
  <i class="far fa-check-square" style="font-size: 200px;color:#5ec16f"></i>
  <div class="text-info informative-text mt-3">
    {{"NoOrdersAvailable" | translate}}
  </div>
</div>
