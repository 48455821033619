import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {AppCommonModule} from "../common/common.module";
import { SelfOrderApproveRoutingModule } from "./self-order-approve-routing.module";
import { SelfOrderApproveSingleOrderedItemComponent } from "./self-order-approve-single-ordered-item/self-order-approve-single-ordered-item.component";
import { SelfOrderApproveSingleTableComponent } from "./self-order-approve-single-table/self-order-approve-single-table.component";
import { SelfOrderApproveComponent } from "./self-order-approve/self-order-approve.component";

@NgModule({
  declarations: [
    SelfOrderApproveComponent,
    SelfOrderApproveSingleTableComponent,
    SelfOrderApproveSingleOrderedItemComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SelfOrderApproveRoutingModule,
  ],
})
export class SelfOrderApproveModule { }
