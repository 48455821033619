<div id="pos-container" class="pos-container">
  <div class="left-container">
    <app-status-color></app-status-color>
    <app-table-category-list></app-table-category-list>
  </div>
  <app-order-list></app-order-list>
</div>

<app-menu-card [orderedItems]="tableOrderedItems"></app-menu-card>
<app-product-extras-dialog></app-product-extras-dialog>
<app-product-special-wishes-dialog></app-product-special-wishes-dialog>
<app-product-withouts-dialog></app-product-withouts-dialog>
<app-payment-dialog></app-payment-dialog>
<app-payment-discount-dialog></app-payment-discount-dialog>
<app-move-order-dialog></app-move-order-dialog>
<app-create-item-dialog></app-create-item-dialog>
<app-show-diverse-items-dialog></app-show-diverse-items-dialog>
<app-table-split-enter-name></app-table-split-enter-name>
