import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {find, findIndex} from "lodash";
import {Subscription} from "rxjs";
import {Table} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {PosService} from "../../../services/pos.service";
import {SignalRService} from "../../../services/signalr.service";

@Component({
  selector: "app-self-order-approve-single-table",
  templateUrl: "./self-order-approve-single-table.component.html",
  styleUrls: ["./self-order-approve-single-table.component.scss"],
})
export class SelfOrderApproveSingleTableComponent implements OnInit, OnDestroy {
  public orderStatus: string;
  public timeAgo: string;

  @Input()
  public orders: { orderDate, orderedItems, tableId, isNew };
  @Input()
  public tableOrderList: Array<{ orderDate, orderedItems, tableId }>;
  @Output()
  public orderUpdate = new EventEmitter();

  public table: Table;
  public temporaryCompletedStatusId = 1.5;
  public temporaryDeniedStatusId = 5.5;
  public updateMonitorSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private posService: PosService,
    private messageService: MessageService,
    private signalRService: SignalRService,
  ) {
  }

  public async ngOnInit() {
    this.timeAgo = this.getOrderTimeAgo();
    this.table = find(await this.localStorage.getTablesAsync(), {id: this.orders.tableId});

    this.updateMonitorSubscription = this.signalRService.updateMonitor.subscribe((tableId) => {
      if (tableId == this.orders.tableId) {

      }
    });
  }

  public ngOnDestroy() {
    this.updateMonitorSubscription?.unsubscribe();
  }

  public getOrderTimeAgo() {
    const dateTime: any = new Date(this.orders.orderDate);
    const now: any = new Date();
    let seconds = parseInt((((now - dateTime) / 1000)).toString());
    if (seconds < 0) {
      seconds = 0;
    }

    const hour = Math.floor(seconds / 3600);
    if (hour > 0) { seconds = Math.floor(seconds % 3600); }

    let min: any = Math.floor(seconds / 60);

    if (hour > 0 || min >= 10) { this.orderStatus = "red"; } else if (min >= 5) { this.orderStatus = "orange"; }

    if (min < 10) { min = "0" + min; }

    let sec: any = seconds % 60;
    if (sec < 10) { sec = "0" + sec; }

    if (hour === 0) { return min + ":" + sec; }
    if (hour < 10) { return "0" + hour + ":" + min + ":" + sec; }

    return hour + ":" + min + ":" + sec;
  }

  public approveAllOrders() {
    this.orders.orderedItems.forEach((item) => {
      item.status = this.temporaryCompletedStatusId;
    });
  }

  public denyAllOrders() {
    this.orders.orderedItems.forEach((item) => {
      item.status = this.temporaryDeniedStatusId;
    });
  }

  public sendAllFinishedItems() {
    const approvedItemIds = this.orders.orderedItems.filter((i) => i.status == this.temporaryCompletedStatusId).map((i) => i.orderedItemId);
    const data = {
      tableId: this.table.id,
      orderedItemIds: approvedItemIds,
      Status: 1,
    };
    this.posService.updateOrderStatus(data).subscribe(() => {
      const deniedItemIds = this.orders.orderedItems.filter((i) => i.status == this.temporaryDeniedStatusId).map((i) => i.orderedItemId);
      const data = {
        tableId: this.table.id,
        orderedItemIds: deniedItemIds,
        status: 5,
      };

      this.posService.updateOrderStatus(data).subscribe(() => {
        this.orders.orderedItems = this.orders.orderedItems.filter((i) => i.status != this.temporaryDeniedStatusId && i.status != this.temporaryCompletedStatusId);
        if (this.orders.orderedItems.length == 0) {
          const index = findIndex(this.tableOrderList, {tableId: this.orders.tableId});
          if (index > -1) {
            this.tableOrderList.splice(index, 1);
          }
        }
      }, (err) => {
        this.messageService.error(err);
      });
    }, (err) => {
      this.messageService.error(err);
    });
  }

  public loadOrders(tableId) {
    this.posService.getOrdersToBeApproved(tableId).subscribe((orderedItems) => {
      this.orders.orderedItems = orderedItems;
    }, () => {});
  }
}
