import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {TableCategory} from "../../../../models";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-import-tables-dialog",
  templateUrl: "./import-tables-dialog.component.html",
  styleUrls: ["./import-tables-dialog.component.scss"],
})
export class ImportTablesDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public category: TableCategory;
  public onTableAdded: any;

  public selectedFile: any;

  public loading: boolean;

  public importSuccess: boolean;

  public importedCount: number;

  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private messageService: MessageService,
    public translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("import-tables-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    // reset variables
    this.selectedFile = null;
    this.loading = false;
    this.importSuccess = false;
    this.importedCount = null;

    this.onTableAdded = params.onTableAdded;
    this.category = params.category;

    this.modal.show();
  }

  public importTables() {
    if (!this.selectedFile) {
      this.messageService.warning(this.translateService.instant("NoFileChosen"));
      return false;
    } else if (!this.selectedFile.name.endsWith(".xlsx")) {
      this.messageService.warning(this.translateService.instant("ChooseExcelFile"));
      return false;
    }

    this.loading = true;

    const fd = new FormData();
    fd.append("file", this.selectedFile);
    this.tableService.importTables(this.category.id, fd).subscribe((tables) => {
      this.loading = false;
      this.selectedFile = null;
      this.importSuccess = true;
      this.importedCount = tables.length;

      if (this.onTableAdded) {
        this.onTableAdded(tables);
      }
    }, (error) => {
      this.loading = false;

      if (!error) { error = this.translateService.instant("TablesImportError"); }
      this.messageService.error(error);
    });
  }

  public onFileUploaded(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
    }
  }
}
