<div class="row" style="padding-top: 20px; text-align:center;  margin-left: 0; margin-right: 0;" *ngIf="isRegistered">
  <div class="col-sm-12">
    <div class="alert alert-success alert-dismissible" role="alert"
         style="max-width:700px;margin-left:auto;margin-right:auto;color:initial;">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
        aria-hidden="true">&times;</span></button>

      <p style="text-align:left">
        {{"RegistrationActivationEmailSent" | translate }}
      </p>
      <p class="email-cont" style="font-size:18px;">
        {{userEmail }}
      </p>
      <p style="text-align:left">
        {{"RegistrationActivationLoginOnce" | translate }}
      </p>
    </div>
  </div>
</div>

<div class="row" style="padding-top: 20px; text-align:center;  margin-left: 0; margin-right: 0;">
  <div class="col-sm-12">
    <img src="/images/icons/icon-192x192.png" style="width:130px;" racker (click)="logoClicked()"/>
    <div style="margin-top:20px; font-size: 16px;">
      {{"LoginHelp" | translate }}
    </div>
  </div>
</div>
<div class="row" style="padding-top: 20px; text-align:center;  margin-left: 0; margin-right: 0;margin-bottom:15px;">
  <div class="col-sm-12">
    <div class="modal-dialog cascading-modal" role="document" style="margin-top:25px;max-width: 500px;">
      <!--Content-->
      <div class="modal-content">

        <!--Header-->
        <div class="modal-header light-blue darken-3 white-text">
          <h4 class="title">
            <i class="fa fa-person"></i>
            <span>{{"SigninTitle" | translate }}</span>
          </h4>
        </div>
        <!--Body-->
        <div class="modal-body mb-0" style="height: 350px;">
          <form validateForm #formRef="ngForm" (ngSubmit)="login()" id="loginForm" action="/account/login" method="post" class="form-horizontal login-form html5-form"
                autocomplete="off">
            <div class="md-form">
              <i class="fa fa-user prefix grey-text" style="left:0"></i>
              <input #emailRef mdbInput type="text" id="email" name="email" class="form-control" required="required"
                     style="text-transform: lowercase;" autocorrect="off" autocomplete="off" autocapitalize="off"
                     spellcheck="false"
                      [(ngModel)]="email"/>
              <label for="email">
                {{"EnterUserName" | translate }}
              </label>
            </div>
            <div class="md-form">
              <i class="fa fa-lock prefix grey-text" style="left:0"></i>
              <input #passwordRef mdbInput type="password" id="password" name="password" class="form-control" required="required"
                     autocomplete="new-password"
                      [(ngModel)]="password"/>
              <label for="password">
                {{"EnterPassword" | translate }}
              </label>
            </div>

            <div class="form-group">
              <div class="col-md-12">
                <button mdbBtn color="primary" rounded="true" size="lg" mdbWavesEffect type="submit" id="btnLogin" class="w-100">
                  {{"Login" | translate}}
                </button>
              </div>
            </div>
          </form>
          <div style="text-align:left;font-size: 14px;margin-top: 10px;">
            <a href="/#/auth/forget-password" style="padding:15px 0;">
              {{"ForgotPassword" | translate }}
            </a>
          </div>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</div>
