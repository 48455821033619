<style>
  .main-header {
    display: none;
  }

  .body-content {
    top: 0;
  }
</style>

<div id="registerContainer">
  <div class="card" style="margin: 0px auto; width: 500px; max-width: 100%;">

    <h3 class="card-header info-color white-text text-center py-4">
      <i class="fas fa-lock mr-2"></i> <strong>{{"SigninTitle" | translate}}</strong>
    </h3>

    <!--Card content-->
    <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && savePerson()" class="html5-form card-body p-0">
      <div class="alert alert-warning w-100" role="alert" style="color: #856404; text-align: left; border-radius: 0;" [innerHTML]="'SelfOrderNeedRegistration' | translate">
      </div>
      <div class="text-center" style="color: #757575; margin-left: 20px; margin-right: 20px;">
        <div class="form-row">
          <div class="col">
            <!-- First name -->
            <div class="md-form">
              <input mdbInput [(ngModel)]="name" type="text" id="name" name="name" class="form-control pb-0" required="required"/>
              <label for="name">{{"Name" | translate}}</label>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-baseline">
          <mdb-select-2 name="code-list" class="calling-code"
                        [(ngModel)]="callingCode"
                        (ngModelChange)="setCallingCode($event)">
            <mdb-select-option *ngFor="let option of countryList" [value]="option.value">
              <img [src]="option.icon" style="width:30px;margin-right:5px;"/>
              +{{ option.label }}
            </mdb-select-option>
          </mdb-select-2>
          <div class="md-form input-group mb-3">
            <div class="md-form m-0 flex-grow-1">
              <input mdbInput [(ngModel)]="telephone" type="tel" id="telephone" name="telephone" class="form-control pb-0 pl-0 pr-0" required="required"/>
              <label for="telephone">{{"PhoneNumber" | translate}}</label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn btn-primary btn-rounded my-4 waves-effect z-depth-0 w-100">
            {{"SendVerificationCode" | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
