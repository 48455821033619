import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {find} from "lodash";
import {Subscription} from "rxjs";
import {ProductCategory} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {ProductService} from "../../../services/product.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {

  public productCategories: ProductCategory[];
  public importFinishedSubscription: Subscription;
  constructor(
    private modalService: ModalService,
    private productService: ProductService,
    private localStorage: LocalStorageService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private subscriptionService: SubscriptionService,
  ) { }

  public async ngOnInit() {
    const categories = (await this.localStorage.getProductCategoriesAsync()).filter((c) => c.productCategoryId != this.utilsService.generateEmptyGuid());
    this.productCategories = categories.sort((a, b) => a.productCategoryName.localeCompare(b.productCategoryName, undefined, { numeric: true, sensitivity: "base" }));
    this.messageService.showLoading(await this.translateService.get("LoadingProducts").toPromise());

    this.refreshCategories(false);

    this.importFinishedSubscription = this.subscriptionService.importProductFinishedEvent.subscribe(() => {
      this.refreshCategories(true);
    });
  }

  public ngOnDestroy(): void {
    this.importFinishedSubscription?.unsubscribe();
  }

  public showHelp() {
    this.modalService.show("youtube-dialog", {embed: "eyG4K7HvHsU"});
  }

  public async addCategory(categories): Promise<any> {
    const allCats = await this.localStorage.getProductCategoriesAsync();
    categories.forEach((category) => {
      if (!find(allCats, {productCategoryId: category.productCategoryId})) {
        allCats.push(category);
      }
    });
    this.localStorage.setProductCategories(allCats);
  }

  public showAddCategoryDialog() {
    this.modalService.show("create-product-category-dialog", {
      category: new ProductCategory(),
      action: "add",
      addCategory: async (category) => {
        this.messageService.success(this.translateService.instant("AddCategorySuccess"));
        await this.addCategory([category]);
      },
    });
  }

  public showImportCategoriesDialog() {
    this.modalService.show("import-categories-dialog", {
      addCategory: async (categories) => {
        await this.addCategory(categories);
      },
    });
  }

  private refreshCategories(notify) {
    this.productService.loadAllProductCategories(true).subscribe((result) => {
      result.categories = result.categories.filter((c) => c.productCategoryId != this.utilsService.generateEmptyGuid());
      result.categories = result.categories.sort((a, b) => a.productCategoryName.localeCompare(b.productCategoryName, undefined, {numeric: true, sensitivity: "base"}));

      if (result.products) {
        this.localStorage.setProducts(result.products);

        if (notify) {
          this.subscriptionService.loadingProductsFinished();
        }
      }
      this.messageService.hideLoading();
      this.localStorage.setProductCategories(result.categories);
      this.productCategories = result.categories;

      this.localStorage.setProductCategoryPictures(result.productCategoryPictures);
      this.localStorage.setProductPictures(result.productPictures);
    }, () => {
      this.messageService.hideLoading();
    });
  }

  showPublishMenuDialog() {
    this.modalService.show("publish-menu-dialog", {});
  }
}
