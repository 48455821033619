import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {ModalDirective} from 'ng-uikit-pro-standard';
import { ModalComponent } from '../../../../interfaces/ModalComponent';
import { ModalService } from '../../../../services/modal.service';
import {ReportService} from '../../../../services/report.service';
import {MessageService} from '../../../../services/message.service';
import {TranslateService} from '@ngx-translate/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss']
})
export class SendEmailDialogComponent implements OnInit, ModalComponent {
  @BlockUI()
  blockUI: NgBlockUI;

  @ViewChild('modal')
  modal: ModalDirective;

  @ViewChild('inputName')
  nameInputRef: ElementRef;

  email: string;
  reportId: any;

  constructor(
    private modalService: ModalService,
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.modalService.register('datev-send-email', this);
  }

  hide() {
    this.modal.hide();
  }

  show(params: any) {
    this.reportId = params.reportId;
    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      300
    );
  }

  sendEmail() {
    if (!this.validateEmail(this.email)) {
      this.messageService.error(this.translateService.instant('EnterValidEMail'));

      return;
    }

    this.blockUI.start(this.translateService.instant('PleaseWait'));
    this.reportService.sendDatevReportViaEmail(this.reportId, this.email).subscribe(() => {
      this.messageService.success(this.translateService.instant('DatevReportSendSuccessfully'));
      this.blockUI.stop();
      this.modal.hide();
    }, err => {
      this.blockUI.stop();
      this.messageService.error(err);
    });
  }

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}
