import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../../../services/auth.service";
import {MessageService} from "../../../services/message.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  public sub: Subscription;
  public code: string;
  public error = "";
  public password: string;
  public confirmPassword: string;
public 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private messageService: MessageService,
    private authService: AuthenticationService,
  ) { }

  public ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.code = params.code;
        this.authService.checkPasswordResetCode(this.code).subscribe((res) => {
          if (res) {
            this.error = "";
          } else {
            this.error = "InvalidResetRequest";
          }
        });
      } else {
        this.error = "InvalidResetRequest";
      }
    });
  }

  public async resetPassword() {
    if (this.password !== this.confirmPassword) {
      this.messageService.error(await this.translateService.get("PasswordNotMatch").toPromise());
      return;
    }

    this.blockUI.start(await this.translateService.get("PleaseWait").toPromise());
    this.authService.updatePassword(this.password, this.confirmPassword, this.code).subscribe(async (res) => {
      this.blockUI.stop();
      if (res) {
        await this.router.navigate(["/auth/login"], {queryParams: {sc: "ResetPasswordSuccess"}});
      } else {
        this.messageService.error(await this.translateService.get("ResetPasswordError").toPromise());
      }
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
