<div class="menu-list-title" *ngIf="searchText.length == 0">{{"MenuList" | translate}}</div>
<div class="menu-list-title small" *ngIf="searchText.length > 0 && visibleCategories.length == 1">{{("MenuListSearchResultOneCategory" | translate).format(foundProductsLength)}}</div>
<div class="menu-list-title small" *ngIf="searchText.length > 0 && visibleCategories.length > 1">{{("MenuListSearchResultMultipleCategories" | translate).format(foundProductsLength, visibleCategories.length)}}</div>

<div class="self-order-category-container" *ngIf="visibleCategories.length >= 1">
    <ul class="category-direcoty-list" *ngIf="visibleCategories.length > 1">
        <li (click)="showItems(category)" [hidden]="category.hidden" class="category-folder-item overflow-hidden" *ngFor="let category of visibleCategories" [class.overflow-visible]="category.insertingProduct">
            <div [class.category-image]="!category.noCategoryImage" [class.category-no-image]="category.noCategoryImage" [style.background-image]="category.categoryPicture" style="background-color: #D9D3BD"></div>
            <div class="category-name-cont">
                <label class="category-name">{{category.productCategoryName}}</label>
                <label class="category-description" *ngIf="category.productCategoryDescription">
                  {{category.productCategoryDescription}}
                </label>
                <i class="fas fa-chevron-down product-arrow" [class.fa-chevron-down]="category.showProducts" [class.fa-chevron-up]="!category.showProducts"></i>
            </div>
            <div class="category-products" *ngIf="category.showProducts" [@slideInOut]>
                <app-self-order-category-products [category]="category" [products]="category.products" [readOnly]="readOnly"></app-self-order-category-products>
            </div>
        </li>
    </ul>
    <div class="category-products p-3" *ngIf="visibleCategories.length == 1" [@slideInOut]>
        <app-self-order-category-products [category]="visibleCategories[0]" [products]="visibleCategories[0].products" [readOnly]="readOnly"></app-self-order-category-products>
    </div>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="foundProductsLength == 0">
    <img src="/assets/images/dinner.svg" style="width: 150px;" />
    <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;">{{"SearchProductNotFound" | translate}}</div>
</div>