<form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && registerUser()" id="registerForm" action="/account/register" method="post" class="form-horizontal html5-form">
  <div class="row" style="max-width:1200px;margin:0px auto;">
    <div class="col-lg-6">
      <div>
        <div class="modal-dialog cascading-modal" role="document" style="padding-top:20px;">
          <!--Content-->
          <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
              <h4 class="title">
                <i class="fas fa-pencil-alt"></i> <span translate="RegistrationForm"></span>
              </h4>
            </div>
            <!--Body-->
            <div class="modal-body mb-0">

              <div class="row">
                <div class="col-sm-6 ">
                  <div class="md-form">
                    <input mdbInput type="text" id="firstName" name="firstName" [(ngModel)]="firstName" class="form-control"
                           required="required">
                    <label for="firstName" translate="FirstName"></label>
                  </div>
                </div>
                <div class="col-sm-6 ">
                  <div class="md-form">
                    <input mdbInput type="text" id="lastName" name="lastName" [(ngModel)]="lastName" class="form-control"
                           required="required">
                    <label for="lastName" translate="LastName"></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="md-form">
                    <input mdbInput type="text" id="userName" name="userName" [(ngModel)]="userName" class="form-control"
                           required="required" style="text-transform: lowercase;" autocorrect="off" autocomplete="off"
                           autocapitalize="off" spellcheck="false">
                    <label for="userName" translate="UserName"></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="md-form">
                    <input mdbInput type="password" name="userPasswordHash" id="password" class="form-control" required="required"
                           minlength="4" [(ngModel)]="password">
                    <label for="password" translate="Password"></label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="md-form">
                    <input mdbInput type="password" name="confirmPassword" id="confirmPassword" data-match="#password"
                           class="form-control" required="required" minlength="4" [(ngModel)]="confirmPassword">
                    <label for="confirmPassword" translate="ConfirmPassword"></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="md-form">
                    <input mdbInput type="email" id="email" name="email" [(ngModel)]="email" class="form-control"
                           required="required" style="text-transform: lowercase;" autocorrect="off" autocomplete="off"
                           autocapitalize="off" spellcheck="false">
                    <label for="email" translate="EmailAddress"></label>
                  </div>
                </div>
              </div>
              <div class="row" style="margin:0px;margin-bottom: 15px">
                <div class="col-sm-12 form-group" style="padding: 0; margin: 0;">
                  <mdb-select-2 name="country-list"
                                [(ngModel)]="selectedCountry"
                                (ngModelChange)="setCountry($event)">
                    <mdb-select-option *ngFor="let option of countryList" [value]="option.value">
                      <img [src]="option.icon" style="width:30px;margin-right:5px;"/>
                      {{ option.label }}
                    </mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
              <div class="row" style="margin:0px;margin-bottom: 15px">
                <div class="col-sm-6 form-group" style="padding: 0; margin: 0;">
                  <input type="hidden" id="language" name="language" class="form-control" [(ngModel)]="languageName"/>
                  <div style="display:flex">
                    <span translate="Language"></span>:&nbsp;
                    <span id="txtLanguage" style="font-weight:bold;">{{languageName}}</span>
                  </div>
                </div>
                <div class="col-sm-6 form-group" style="padding: 0; margin: 0;">
                  <div style="display:flex">
                    <span translate="Currency"></span>:&nbsp;
                    <span id="txtCurrency" style="font-weight:bold;">{{currencyName}} ({{currencySymbol}})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div>
        <div class="modal-dialog cascading-modal" role="document" style="padding-top:20px;">
          <!--Content-->
          <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
              <h4 class="title">
                <i class="fa fa-info-circle"></i> <span translate="PackageInfo"></span>
              </h4>
            </div>
            <!--Body-->
            <div class="modal-body mb-0">
              <div class="row p-3 pt-0">
                <div class="w-100">
                  <div class="form-group d-flex justify-content-between">
                    <label>
                      {{"PackageName" | translate}}:
                    </label>
                    <label class="ml-1 font-weight-bold">
                      {{packageInfo.name + '_Title' | translate}}
                    </label>
                  </div>
                  <div class="form-group d-flex justify-content-between" *ngIf="packageInfo.recursion">
                    <label>
                      {{"SubscriptionType" | translate}}:
                    </label>
                    <label class="ml-1 font-weight-bold">
                      {{packageInfo.recursion | translate}}
                    </label>
                  </div>
                  <div class="form-group d-flex justify-content-between">
                    <label>
                      {{"MaxOrderCount" | translate}}:
                    </label>
                    <label class="ml-1 font-weight-bold">
                      {{
                      packageInfo.maxOrderCount === 1000000
                        ? translateService.instant("UnilimitedAmount")
                        : packageInfo.maxOrderCount
                      }}
                    </label>
                  </div>
                  <div class="form-group d-flex justify-content-between">
                    <label>
                      {{"Price" | translate}}:
                    </label>
                    <label class="ml-1 font-weight-bold">
                      {{packageInfo.currency}}{{packageInfo.price.toFixed(2)}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div *ngIf="packageInfo.price > 0" id="payment-form-container" class="modal-dialog cascading-modal"
             style="padding-top:20px;" role="document">
          <!--Content-->
          <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
              <h4 class="title">
                <i class="fa fa-lock"></i>
                <span>
                {{"PaymentDetails" | translate}}
              </span>
              </h4>
            </div>
            <!--Body-->
            <div class="modal-body mb-0">
              <div class="w-100" style="text-align:center;">
                <div class="btn-group w-100" style="margin:0px;">
                  <label class="btn btn-info btn-rounded active waves-effect waves-light active"
                         style="position:inherit;padding: 13px 20px;"
                         (click)="setPaymentType('CreditCard')"
                         [class.active]="paymentType === 'CreditCard'">
                    <input class="form-check-input" type="radio" checked autocomplete="off">
                    <i class="far fa-credit-card"
                       style="font-size: 20px;vertical-align: middle;margin-right: 5px;"></i><span
                  >{{"CreditCard" | translate}}</span>
                  </label>
                  <label class="btn btn-info btn-rounded waves-effect waves-light"
                         style="position:inherit;padding: 13px 20px;"
                         (click)="setPaymentType('SEPA')"
                         [class.active]="paymentType === 'SEPA'">
                    <input class="form-check-input" type="radio" autocomplete="off">
                    <i class="far fa-money-bill-alt"
                       style="font-size: 20px;vertical-align: middle;margin-right: 5px;"></i>
                    <span>{{"SEPADirectDebit" | translate}}</span>
                  </label>
                </div>
              </div>
              <div>
                <div style="" [hidden]="paymentType !== 'CreditCard'">
                  <div class="error mt-4">{{creditCardPaymentError}}</div>
                  <div data-locale-reversible>
                    <div class="row m-0">
                      <div class="field">
                        <input id="card-holder" name="card-holder" class="input empty" type="text"
                               [class.focused]="isCardHolderFocused || cardHolder.length > 0"
                               [(ngModel)]="cardHolder"
                               (focus)="isCardHolderFocused = true"
                               (blur)="isCardHolderFocused = false">
                        <label for="card-holder" translate="NameOnCard" class="active"></label>
                        <div class="baseline"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="field">
                      <div id="card-number" class="input empty"></div>
                      <label for="card-number" data-tid="elements_examples.form.card_number_label"
                             translate="CreditCard"></label>
                      <div class="baseline"></div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="field col-6">
                      <div id="card-expiry" class="input empty"></div>
                      <label for="card-expiry" data-tid="elements_examples.form.card_expiry_label"
                             translate="CreditCardExpiration"></label>
                      <div class="baseline"></div>
                    </div>
                    <div class="field col-6">
                      <div id="card-cvc" class="input empty"></div>
                      <label for="card-cvc" data-tid="elements_examples.form.card_cvc_label">CVC</label>
                      <div class="baseline"></div>
                    </div>
                  </div>
                </div>
                <div style="" [hidden]="paymentType !== 'SEPA'">
                  <div class="error mt-4" role="alert">
                    <span class="message">{{sepaPaymentError}}</span>
                  </div>

                  <div data-locale-reversible>
                    <div class="row m-0">
                      <div class="field">
                        <input mdbInput id="iban-card-holder" name="iban-card-holder" class="input empty" type="text"
                               [(ngModel)]="ibanCardHolder"
                               [class.focused]="isCardHolderFocused || ibanCardHolder.length > 0"
                               (focus)="isCardHolderFocused = true"
                               (blur)="isCardHolderFocused = false">
                        <label for="iban-card-holder">
                          {{"NameOnCard" | translate}}
                        </label>
                        <div class="baseline"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="field">
                      <div id="iban-number" class="input empty"></div>
                      <label for="iban-number">{{"IBANNumber" | translate}}</label>
                      <div class="baseline"></div>
                    </div>
                    <div id="bank-name">{{bankName}}</div>
                  </div>
                  <div id="mandate-acceptance" [innerHTML]='"SEPADescription" | translate' style="color:#5a5a5a;font-size:12px;margin-top:30px;">
                    {{"SEPADescription" | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="max-width:1200px;margin:0px auto 20px auto; ">
    <div class="col-lg-6" style="margin-left:auto;margin-right:auto;">
      <input type="hidden" name="packageId" [value]="packageId"/>
      <div [innerHTML]='"AgreeTermsOfService" | translate' style="font-size:14px;margin-bottom: 8px;"></div>
      <button mdbBtn type="submit" color="primary" rounded="true" mdbWavesEffect id="btnUpdateForm" class="w-100" style="">
        <span translate="Register"></span>
      </button>
    </div>
  </div>
</form>
