import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

import {FormsModule} from "@angular/forms";
import {ButtonsModule, DropdownModule, MdbSelectModule, PopoverModule, RangeModule, SelectModule, TooltipModule, ModalModule} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../common/common.module";
import {PosModule} from "../pos/pos.module";
import {KdsRoutingModule} from "./kds-routing.module";
import {KdsComponent} from "./kds.component";
import {SingleTableOrderComponent} from "./single-table-order/single-table-order.component";
import {SingleTableOrdersComponent} from "./single-table-orders/single-table-orders.component";

@NgModule({
  declarations: [
    KdsComponent,
    SingleTableOrdersComponent,
    SingleTableOrderComponent,
  ],
  exports: [
    SingleTableOrdersComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    KdsRoutingModule,
    SelectModule,
    FormsModule,
    RangeModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    DropdownModule.forRoot(),
    ModalModule.forRoot(),
    PosModule,
    MdbSelectModule,
  ],
})
export class KdsModule {
}
