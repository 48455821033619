import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {UserSettings} from "../../../models";
import {LocalizationService} from "../../../services/localization.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {UserService} from "../../../services/user.service";
import { VatDefinition } from "src/app/models/vat.definition.model";
const packageInfo = require('../../../../../package.json');

@Component({
  selector: "app-common-settings",
  templateUrl: "./common-settings.component.html",
  styleUrls: ["./common-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonSettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  public userSettings: UserSettings = new UserSettings();
  public version: string;
  public currentLanguage: string;
  public showHostingSlip: boolean;
  public deviceId: string;
  public allowDeviceForTimeRecording: boolean = false;
  public vatDefinitions:any[];

  public tableColors = [
    "#000", "#444", "#666", "#999", "#ccc", "#f00",
    "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f",
    "#f0f", "#f4cccc", "#fce5cd", "#d9ead3", "#d0e0e3",
    "#cfe2f3", "#d9d2e9", "#ead1dc", "#ea9999", "#f9cb9c", "#ffe599",
    "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd", "#e06666",
    "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3",
    "#c27ba0", "#c00", "#e69138", "#f1c232", "#6aa84f", "#45818e",
    "#3d85c6", "#674ea7", "#a64d79", "#900", "#b45f06", "#bf9000",
    "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47", "#600",
    "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"];

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private localeService: LocalizationService,
    private userService: UserService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService
  ) {
    this.deviceId = window.localStorage.getItem("device-id");
  }

  public async ngOnInit() {
    this.localStorage.getUserSettingsAsync().then(res => {
      this.userSettings = res;
    });

    if(!this.userSettings.deviceIdsForTimeRecording) {
      this.userSettings.deviceIdsForTimeRecording = [];
    }

    this.allowDeviceForTimeRecording = this.userSettings.deviceIdsForTimeRecording.indexOf(this.deviceId) > -1;

    this.version = packageInfo.version;

    this.localeService.getCurrentLanguage().then((lang) => {
      this.currentLanguage = lang || this.localeService.getBrowserLanguage();
    });

    this.localStorage.getUserAsync().then(res => {
      this.showHostingSlip = res.country === "DE";
    });

    this.localStorage.getVatDefinitions().then((res: VatDefinition[]) => {
      if(res != null ) {
        this.vatDefinitions = [];
        res.forEach(entry => {
          if(this.vatDefinitions.indexOf(entry.percentage) == -1)
            this.vatDefinitions.push(entry.percentage);
        });
      }
    });
  }

  public setCulture(code: string, name: string) {
    this.currentLanguage = name;
    this.localeService.setCulture(code);
    this.localeService.setCurrentLanguage(name);
    //location.reload();
  }

  public saveSettings() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));

    if(this.allowDeviceForTimeRecording){
      if(this.userSettings.deviceIdsForTimeRecording.indexOf(this.deviceId) === -1) {
        this.userSettings.deviceIdsForTimeRecording.push(this.deviceId);
      }
    } else {
      const index = this.userSettings.deviceIdsForTimeRecording.indexOf(this.deviceId);
      if(index > -1) {
        this.userSettings.deviceIdsForTimeRecording.splice(index, 1);
      }
    }

    this.userService.saveSettings(this.userSettings).subscribe((settings) => {
      this.blockUI.stop();
      this.userSettings = settings;
      this.localStorage.setUserSettings(settings);
      this.subscriptionService.settingsUpdated();
      this.messageService.success(this.translateService.instant("SettingsSavedSuccessfully"))
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
