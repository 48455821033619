import { CookieService } from 'src/app/services/cookie.service';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import {HttpClient} from "@angular/common/http";
import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { Router } from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {MessageService} from "../../../services/message.service";
import {SelfOrderService} from "../../../services/self.order.service";
import countryList from "../../../data/county.list";
import { sortBy } from "lodash";

@Component({
  selector: "app-self-order-register",
  templateUrl: "./self-order-register.component.html",
  styleUrls: ["./self-order-register.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderRegisterComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  public callingCode: string;
  public name: string;
  public telephone: string;
  public countryList = [];

  constructor(
    private translateService: TranslateService,
    private selfOrderService: SelfOrderService,
    private messageService: MessageService,
    private storage: LocalStorageService,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
  ) {
    countryList.forEach((c) => {
      if(c.callingCodes.length > 0) {
        this.countryList.push({
          value: c.callingCodes[0],
          label: parseInt(c.callingCodes[0]),
          icon: c.flag,
        });
      }
    });

    this.countryList = sortBy(this.countryList, 'label');
  }

  public ngOnInit(): void {
    this.retrieveCountry();
  }

  public savePerson() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));

    let tel = this.telephone;
    if (tel.startsWith("0")) { tel = tel.substring(1); }
    tel = this.callingCode + tel;

    this.selfOrderService.saveCustomer({name: this.name, telephone: tel}).subscribe(async (selfOrderUser: any) => {
      this.blockUI.stop();
      this.storage.setSelfOrderUser(selfOrderUser);
      this.cookieService.setCookie("UserId", selfOrderUser.id, 365 * 10);
      await this.router.navigate(["/so/verify"]);
    }, (err) => {
      this.messageService.error(err);
    });
  }

  private retrieveCountry() {
    fetch("https://api.ipdata.co?api-key=3b82be81911a0b15c76af599ea334ff91637a624d3ca32151ded5d39")
    .then(response => response.json())
    .then((res) => {
      this.callingCode = res["calling_code"];
    });
  }

  setCallingCode($event: any) {

  }
}
