import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {ForgetPasswordComponent} from "./forget-password/forget-password.component";
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";

const routes: Routes = [
  { path: "", component: LoginComponent},
  { path: "login", component: LoginComponent},
  { path: "register", component: RegisterComponent},
  { path: "register/:packageId", component: RegisterComponent},
  { path: "forget-password", component: ForgetPasswordComponent},
  { path: "reset-password", component: ResetPasswordComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
