import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {find} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {StripeService} from "ngx-stripe";
import countryList from "../../../data/county.list";
import {PackageInfo} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {CreateSourceData, StripeElements, StripeElementsOptions} from "@stripe/stripe-js";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {
  public elements: StripeElements;

  public packageId: string;
  public packageInfo: PackageInfo = {
    id: "",
    currency: "",
    customerId: "",
    featured: false,
    maxOrderCount: 0,
    name: "",
    price: 0,
    recursion: "",
    stripePlanId: "",
    paypalPlanId: "",
    url: "",
    isActive: true
  };
  public paymentType = "CreditCard";
  public firstName: string;
  public lastName: string;
  public userName: string;
  public password: string;
  public confirmPassword: string;
  public email: string;
  public languageName: string;
  public languageCode: string;
  public currency: string;
  public currencyName: string;
  public currencySymbol: string;
  public countryList = [];
  public selectedCountry = "";
  public sepaPaymentError: string;
  public creditCardPaymentError: string;
  public bankName: string;
  public cardHolder = "";
  public ibanCardHolder = "";
  public isCardHolderFocused = false;

  @BlockUI() public blockUI: NgBlockUI;

  private cardNumber: any;
  private iban: any;
  private sub: any;
  private stripeToken;
  private elementsOptions: StripeElementsOptions = {
    locale: this.getStripeLocale(this.translateService.currentLang),
  };

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    public translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient,
    private stripeService: StripeService) {

    countryList.forEach((c) => {
      this.countryList.push({
        value: c.alpha2Code,
        label: c.translations[translateService.currentLang] || c.translations.en || c.nativeName,
        icon: c.flag,
      });
    });

    this.retrieveCountry();
  }

  public ngOnInit() {
    this.sub = this.route.params.subscribe(async (params) => {
      this.packageId = params.packageId;
      if (!this.packageId) {
        const packages = await this.localStorage.getPackagesInfoAsync();

        const freePackage = find(packages, {price: 0});
        if (!freePackage) { this.packageId = "26D1229D-B66D-4F19-9029-24AD11DF024F"; } else { this.packageId = freePackage.id; }
      }

      this.packageInfo = find(await this.localStorage.getPackagesInfoAsync(), {id: this.packageId});
    });
  }

  public ngAfterViewInit() {
    if (this.packageInfo.price > 0) {
      this.initStripe();
    }
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public setPaymentType(paymentType: string) {
    this.paymentType = paymentType;
  }

  public retrieveCountry() {
    const countryCode = localStorage.getItem("CountryCode");
    if(countryCode){
      const country = find(this.countryList, {value: countryCode});
      if (country) {
        this.selectedCountry = country.value;
        this.setCountry(countryCode);
      }

      return;
    }

    const that = this;
    this.http.get("https://api.ipdata.co?api-key=3b82be81911a0b15c76af599ea334ff91637a624d3ca32151ded5d39").subscribe(function(res) {
      const countryCode = res["country_code"];
      const country = find(that.countryList, {value: countryCode});
      if (country) {
        that.selectedCountry = country.value;
        that.setCountry(countryCode);
      }
    });
  }

  public setCountry(countryCode: any) {
    const country = find(countryList, {alpha2Code: countryCode});
    if (country) {
      const currencyInfo = country.currencies[0];
      const localizedName = currencyInfo.translations[this.translateService.currentLang];
      this.currencyName = localizedName ? localizedName : currencyInfo.name;
      this.currency = currencyInfo.code;
      this.currencySymbol = currencyInfo.symbol;

      const languageInfo = country.languages[0];
      this.languageName = languageInfo.nativeName;
      this.languageCode = languageInfo.iso639_1 || "en";
    }
  }

  public loadStripe() {
    if (window["Stripe"]) {
      this.initStripe();
      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://js.stripe.com/v3";

    document.body.appendChild(script);

    const that = this;
    script.onload = function() {
      that.initStripe();
    };
  }

  public initStripe() {
    const elementOptions = {
      style:  {
        base: {
          "::placeholder": {color: "#CFD7DF"},
          ":-webkit-autofill": {color: "#e39f48"},
        },
      },
      classes: {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      },
    };

    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.cardNumber) {
        // create card number element and bind it
        this.cardNumber = this.elements.create("cardNumber", elementOptions);
        this.cardNumber.mount("#card-number");

        // create card expiry element element and bind it
        const cardExpiry = elements.create("cardExpiry", elementOptions);
        cardExpiry.mount("#card-expiry");

        // create cvc element and bind it
        const cardCvc = elements.create("cardCvc", elementOptions);
        cardCvc.mount("#card-cvc");

        this.registerCreditCardElements([this.cardNumber, cardExpiry, cardCvc]);
      }

      if (!this.iban) {
        this.iban = elements.create("iban", {
          ... elementOptions,
          supportedCountries: ["SEPA"],
        });

        // Add an instance of the iban Element into the `iban-element` <div>.
        this.iban.mount("#iban-number");

        this.registerSepaElements([this.iban]);
      }
    });
  }

  public registerSepaElements(elements) {
    const that = this;

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach(function(element, idx) {
      element.on("change", function(event) {
        if (event.error) {
          savedErrors[idx] = event.error.message;
          that.sepaPaymentError = event.error.message;
        } else {
          that.bankName = event.bankName;

          savedErrors[idx] = null;

          // Loop over the saved errors and find the first one, if any.
          that.sepaPaymentError = Object.keys(savedErrors).sort().reduce(function(maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);
        }
      });
    });
  }

  public registerCreditCardElements(elements) {
    const that = this;

    // Listen for errors from each Element, and show error messages in the UI.
    const savedErrors = {};
    elements.forEach(function(element, idx) {
      element.on("change", function(event) {
        if (event.error) {
          savedErrors[idx] = event.error.message;
          that.creditCardPaymentError = event.error.message;
        } else {
          that.bankName = event.bankName;

          savedErrors[idx] = null;

          // Loop over the saved errors and find the first one, if any.
          that.creditCardPaymentError = Object.keys(savedErrors).sort().reduce(function(maybeFoundError, key) {
            return maybeFoundError || savedErrors[key];
          }, null);
        }
      });
    });
  }

  public registerUser() {
    const that = this;
    this.blockUI.start(that.translateService.instant("PleaseWait"));
    if (this.packageInfo.price === 0) {
      this.saveUser();
    } else {
      if (this.paymentType == "CreditCard") {
        this.saveCreditCardInfo();
      } else {
        this.saveSEPAInfo();
      }
    }
  }

  public saveCreditCardInfo() {
    const that = this;
    return this.stripeService.stripe.createToken(this.cardNumber, {name: this.cardHolder}).subscribe((result) => {
      if (result.error) {
        that.messageService.error(result.error.message);

        that.blockUI.stop();
      } else {
        that.stripeToken = result.token.id;
        that.saveUser();
      }
    });
  }

  public saveSEPAInfo() {
    const additionalData = {
      type: "sepa_debit",
      currency: "eur",
      owner: {
        name: this.ibanCardHolder,
        email: this.email,
      },
      mandate: {
        notification_method: "email",
      },
    } as CreateSourceData;

    const that = this;
    this.stripeService.stripe.createSource(this.iban, additionalData).subscribe((result) => {
      if (result.error) {
        that.messageService.error(result.error.message);

        that.blockUI.stop();
      } else {
        that.stripeToken = result.source.id;
        that.saveUser();
      }
    });
  }

  public getStripeLocale(currentLang: string): "de" | "en" | "es" | "fr" | "auto" {
    if (currentLang === "de" || currentLang === "en" || currentLang === "es" || currentLang === "fr") {
      return currentLang;
    }

    return "auto";
  }

  private saveUser() {
    const user = {
      firstName: this.firstName,
      lastName: this.lastName,
      userName: this.userName,
      userPasswordHash: this.password,
      confirmPassword: this.confirmPassword,
      email: this.email,
      country: this.selectedCountry,
      language: this.languageCode,
      currency: this.currency,
      currencySymbol: this.currencySymbol,
      packageId: this.packageId,
      stripeToken: this.stripeToken,
    };

    const that = this;
    this.http.post<any>("/account/register", user, {
      headers: new HttpHeaders().set("timeout", "20000"),
    }).subscribe(async  (res) => {
      that.blockUI.stop();

      if (res?.client_secret) {
        this.stripeService.handleCardPayment(res.client_secret, {}).subscribe(async (result) => {
          this.blockUI.stop();
          if (result.error) {
            this.messageService.error(result.error.message);
          } else {
            await that.router.navigate(["/auth/login"], { queryParams: { ls: "1", em: this.email } });
          }
        });
      } else {
        await that.router.navigate(["/auth/login"], { queryParams: { ls: "1", em: this.email } });
      }

    }, (error) => {
      that.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
