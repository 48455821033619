import {Component, Input} from "@angular/core";
import {ProductCategory} from "../../../../models";

@Component({
  selector: "app-product-category-list",
  templateUrl: "./product-category-list.component.html",
  styleUrls: ["./product-category-list.component.scss"],
})
export class ProductCategoryListComponent {

  @Input()
  public productCategories: ProductCategory[] = [];

  constructor(
  ) {
  }
}
