<div style="margin-bottom:20px;">
  <div class="card testimonial-card">
    <!-- Bacground color -->
    <div class="card-up indigo lighten-1">
    </div>

    <!-- Avatar -->
    <div class="avatar mx-auto white">
      <img [src]="image" class="rounded-circle">
    </div>

    <div class="card-body" style="padding:10px;">
      <!-- Name -->
      <h4 class="card-title">{{employee.firstName + ' ' + employee.lastName}}</h4>
      <h6 class="card-title">{{employee.role | translate}}</h6>
    </div>
    <div class="card-footer">
      <div class="dropdown" mdbDropdown dynamicPosition="true">
        <a href="javascript:void(0)" mdbDropdownToggle rounded="true" class="dropdown-toggle waves-light w-100 btn-table-action" mdbWavesEffect>
          {{"Actions" | translate}}
        </a>

        <div class="dropdown-menu">
          <a href="javascript:void(0);" class="dropdown-item" (click)="showUpdateEmployee()">
            <i class="fas fa-pencil-alt mr-1" style="font-size: 18px;color:inherit"></i>
            <span>{{"EditEmployee" | translate}}</span>
          </a>
          <a href="javascript:void(0);" class="dropdown-item danger-text" (click)="showDeleteEmployeeConfirmation()">
            <span class="far fa-trash-alt mr-1" style="font-size: 18px;color:inherit"></span>
            <span>{{"DeleteEmployee" | translate}}</span>
          </a>
          <a href="javascript:void(0);" class="dropdown-item" (click)="showSendMessageDialog()">
            <i class="far fa-comment-dots mr-1" style="font-size: 18px;color:inherit"></i>
            <span>{{"SendMessage" | translate}}</span>
          </a>
          <a href="javascript:void(0);" class="dropdown-item" (click)="showQrCodeDialog()">
            <i class="fas fa-qrcode mr-1" style="font-size: 18px;color:inherit"></i>
            <span>{{"GenerateQrCode" | translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Card -->
