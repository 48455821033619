import { Observable } from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {PackageInfo} from "../models";
import { MenuInfo } from "../models/menu.info";
import {LocalStorageService} from "./localstorage.service";

@Injectable({
  providedIn: "root",
})
export class HomeService {

  constructor(private localStorage: LocalStorageService,
              private http: HttpClient) {
  }

  public async loadPackages() {
    let packages = await this.localStorage.getPackagesInfoAsync();
    if (packages?.length > 0) { return packages; }

    packages = await this.http.get<PackageInfo[]>("/home/packages").toPromise();
    this.localStorage.setPackagesInfo(packages);

    return packages;
  }

  public sendContactMessage(contactData) {
    return this.http.post("/home/sendmessage", contactData);
  }

  public getMenuInfo(menuId: string): Observable<any> {
    return this.http.get<any>("/m/data/" + menuId);
  }

  public getRestaurantInfo(menuId: string): Observable<any> {
    return this.http.get<any>("/m/info/" + menuId, {withCredentials: true});
  }

  public getSelfOrderInfo(code: string): Observable<any> {
    return this.http.get<any>("/so/data/" + code, {withCredentials: true});
  }
}
