import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "./auth.service";
import {CookieService} from "./cookie.service";
import {LocalStorageService} from "./localstorage.service";
const appVersion = require('../../../package.json').version;

@Injectable({
  providedIn: "root",
})
export class ThirdPartyService {
  constructor(
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private localStorage: LocalStorageService,
  ) {
  }

  public async addPageRecordingTool() {
    window["__lo_site_id"] = 154337;

    (function() {
      const wa = document.createElement("script");
      wa.type = "text/javascript";
      wa.defer = true;
      wa.src = "https://d10lpsik1i8c69.cloudfront.net/w.js";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(wa, s);
      window["_loq"] = window["_loq"] || []; // ensure queue available
    })();

    const user = await this.localStorage.getUserAsync();

    if (user) {
      window["_loq"].push(["custom", {
        email: user.email,
        name: user.firstName + " " + user.lastName,
        userName: user.userName,
        version: appVersion
      }]);
      window["_loq"].push(["tag", user.userName]);

    }
  }

  public addTest() {
    (function() {
      window['otcid'] = 'fbc8b378-e76f-48f8-a155-fe5f6e52caa9';
      window['otbq'] = '#ot-reservation';
      const wa = document.createElement("script");
      wa.type = "text/javascript";
      wa.defer = true;
      wa.src = "https://api.orderstracker.com/js/res.js";
      //wa.src = "http://192.168.1.5:9000/js/res.js";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(wa, s);
    })();
  };

  public addChatClient() {
    const that = this;
    // Add zoho chat client
    window["$zoho"] = window["$zoho"] || {};
    const $zoho = window["$zoho"];
    $zoho.salesiq = $zoho.salesiq || {
      widgetcode: "30a9d319063f4871b56e16ef75b8b5a7339af219406302c216da87e91e699df6056df69edee5a8e52589c7c02ef21572",
      values: {},
      async ready() {
        const user = await that.localStorage.getUserAsync();

        if (user) {
          $zoho.salesiq.visitor.name(user.firstName + " " + user.lastName);
          $zoho.salesiq.visitor.email(user.email);
        }

        $zoho.salesiq.language(that.translateService.getDefaultLang());
        $zoho.salesiq.visitor.info({culture: that.translateService.getDefaultLang()});
        $zoho.salesiq.tracking.on();
      },
    };
    const d = document;
    const h = d.getElementsByTagName("head")[0];
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.id = "zsiqscript";
    s.defer = true;
    s.src = "https://salesiq.zoho.eu/widget";
    h.appendChild(s);
  }

  public addGuidedTourTool() {
    (function(w, d, s) {
      const a = d.getElementsByTagName("head")[0];
      const r = d.createElement("script");
      r.defer = true;
      r.src = s;
      r.setAttribute("id", "usetifulScript");
      r.dataset.token = "c73936ee84526a2c4a2ae729a6b16efd";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");
  }

  public async initialize(): Promise<any> {
    const selfOrder = localStorage.getItem("is-self-order");
    if (selfOrder != "true") {
      this.addChatClient();
    } else {
      // this.addWebPushNotification();
    }

    const preferences = this.cookieService.getCookie("cookieControlPrefs");
    if (preferences && preferences.indexOf("analytics") !== -1) {
      await this.addPageRecordingTool();
    }

    //this.addTest();
    // this.addGuidedTourTool();
  }

  private addWebPushNotification() {
    const wa = document.createElement("script");
    wa.type = "text/javascript";
    wa.async = true;
    wa.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
    wa.onload = () => {
      const OneSignal = window["OneSignal"] || [];
      this.translateService.get("SelfOrderWebPushConfirm").subscribe(() => {
        OneSignal.push(async () => {
          OneSignal.init({
            // angular
            appId: "129d0557-b1ba-4774-9c86-4b4aaa6c2609",
            // local
            // appId: "5ef48ec6-60c6-42c0-bed9-235a5346a112",
            // subdomainName: "orderstracker",
            promptOptions: {
              actionMessage: this.translateService.instant("SelfOrderWebPushConfirm"),
              acceptButtonText: this.translateService.instant("SelfOrderWebPushAccept"),
              cancelButtonText: this.translateService.instant("SelfOrderWebPushReject"),
            },
            welcomeNotification: {
              disable: true,
            },
            notifyButton: {
              enable: true,
            },
          });

          OneSignal.showSlidedownPrompt();

          OneSignal.deleteTags(["test11s"]);
          const table = JSON.parse(await this.localStorage.getValueAsync("table"));
          OneSignal.sendTag("tableId", table.id);
        });

      });
    };
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(wa, s);
  }
}
