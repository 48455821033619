<div class="table-responsive" style="padding:15px 15px 0 15px;" [hidden]="receipts.length == 0">
  <table id="receiptsTable" mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
    <thead>
    <tr>
      <th *ngFor="let head of columns; let i = index" aria-controls="customersTable" scope="col" [mdbTableSort]="receipts" [sortBy]="columns[i].key">{{head.name | titlecase}}
        <mdb-icon fas icon="sort"></mdb-icon>
      </th>
    </tr>
    </thead>
    <tbody #row>
    <tr mdbTableCol *ngFor="let receipt of receipts; let i = index">
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">
        <a [attr.href]="receipt.link" class="btn btn-flat p-0 m-0">
          <span class="text-primary">{{receipt.number}}</span>
        </a>
      </td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{dayjs(receipt.creationDate).format("L")}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{receipt.subtotal.toFixed(2)}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{receipt.tax.toFixed(2)}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{receipt.total.toFixed(2)}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{receipt.currency | translate}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{receipt.status | translate}}</td>
    </tr>
    </tbody>
    <tfoot class="grey lighten-5 w-100">
    <tr>
      <td colspan="7">
        <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="receipts"></mdb-table-pagination>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="receipts.length == 0">
  <img src="/images/receipt.svg" style="width: 250px;"/>
  <div class="text-info informative-text m-3">{{"NoOTReceipts" | translate}}</div>
</div>
