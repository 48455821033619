import {Component, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {groupBy, minBy, sortBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {OrderedItem} from "../../../models";
import {MessageService} from "../../../services/message.service";
import {PosService} from "../../../services/pos.service";
import {SignalRService} from "../../../services/signalr.service";

@Component({
  selector: "app-self-order-approve",
  templateUrl: "./self-order-approve.component.html",
  styleUrls: ["./self-order-approve.component.scss"],
})
export class SelfOrderApproveComponent implements OnInit, OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;

  public tableOrders: Array<{orderDate, orderedItems, tableId}> = [];
  public updateMonitorSubscription: Subscription;

  constructor(
    private posService: PosService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private signalRService: SignalRService,
  ) { }

  public ngOnInit(): void {
    this.loadOrders();

    this.updateMonitorSubscription = this.signalRService.updateMonitor.subscribe((tableId) => {
      this.loadOrders();
    });
  }

  public ngOnDestroy() {
    this.updateMonitorSubscription.unsubscribe();
  }

  public loadOrders() {
    this.blockUI.start(this.translateService.instant("LoadingOrders"));
    this.posService.getOrdersToBeApproved().subscribe((orderedItems) => {
      this.blockUI.stop();

      const groupedItems = groupBy(orderedItems, function(i) { return i.tableId; });
      const orders = [];
      const orderedResult = [];
      for (const tableId in groupedItems) {
        if (groupedItems.hasOwnProperty(tableId)) {
          const items: OrderedItem[] = groupedItems[tableId];
          const firstItem = minBy(items, "orderDate");
          orderedResult.push({
            tableId,
            orderDate: firstItem.orderDate,
            orderedItems: items,
          });
        }
      }

      this.tableOrders = sortBy(orderedResult, "orderDate");
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public ordersUpdated() {

  }
}
