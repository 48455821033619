import {registerLocaleData} from "@angular/common";
import localeDE from "@angular/common/locales/de";
import localeEN from "@angular/common/locales/en";
import localeES from "@angular/common/locales/es";
import localeFR from "@angular/common/locales/fr";
import localeTR from "@angular/common/locales/tr";
import {Injectable} from "@angular/core";
import * as dayjs from "dayjs";
import {LocalStorageService} from "./localstorage.service";

import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  public supportedCultures = ["en", "de", "tr", "es", "fr"];
  public supportedLanguages = ["English", "Deutsch", "Türkçe", "Español", "Français"];
  public defaultCulture = "en";

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
  ) {
  }

  public getBrowserLanguage(): string {
    let culture = window.navigator["userLanguage"] || window.navigator.language;

    let index;
    if (!culture) {
      index = this.supportedCultures.indexOf(this.defaultCulture);
      return this.supportedLanguages[index];
    }

    if (culture.indexOf("-") > -1) {
      culture = culture.substring(0, culture.indexOf("-"));
    }

    if (this.supportedCultures.indexOf(culture) > -1) {
      index = this.supportedCultures.indexOf(culture);
      return this.supportedLanguages[index];
    }

    index = this.supportedCultures.indexOf(this.defaultCulture);
    return this.supportedLanguages[index];
  }

  public getBrowserCulture(): string {
    let culture = window.navigator["userLanguage"] || window.navigator.language;
    if (!culture) { return this.defaultCulture; }

    if (culture.indexOf("-") > -1) {
      culture = culture.substring(0, culture.indexOf("-"));
    }

    if (this.supportedCultures.indexOf(culture) > -1) { return culture; }

    return this.defaultCulture;
  }

  public setInitialCulture() {
    const culture = localStorage.getItem("current-culture") || this.getBrowserCulture();
    this.setCulture(culture);
  }

    public setCulture(culture) {
    if (this.supportedCultures.indexOf(culture) === -1) {
      culture = this.defaultCulture;
    }
    if (culture === "en") {
        registerLocaleData(localeEN , "en");
      } else if (culture === "tr") {
        registerLocaleData(localeTR , "tr");
        require("dayjs/locale/tr");
      } else if (culture === "es") {
        registerLocaleData(localeES , "es");
        require("dayjs/locale/es");
      } else if (culture === "fr") {
        registerLocaleData(localeFR , "fr");
        require("dayjs/locale/fr");
      } else {
        registerLocaleData(localeDE , "de");
        require("dayjs/locale/de");
      }

    this.translateService.addLangs(this.supportedCultures);
    this.translateService.setDefaultLang(culture);
    this.translateService.use(culture);
    this.translateService.currentLang = culture;

    dayjs.locale(culture);

    localStorage.setItem("current-culture", culture);
  }

  public async getCulture(): Promise<string> {
    return localStorage.getItem("current-culture") || this.getBrowserCulture();
  }

  public setCurrentLanguage(name: string) {
    this.localStorage.setValue("current-language", name);
  }

  public async getCurrentLanguage(): Promise<string> {
    return await this.localStorage.getValueAsync("current-language");
  }
}
