import {BaseModel} from './base.model';

export class ProductPicture extends BaseModel {
  productId: string;

  originalFileId: string;

  dimensions: string;

  url: string;

  fileName: string;

  contentType: string;

  size: number;

}
