import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {find} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {v4 as uuid} from "uuid";
import countryList from "../../../data/county.list";
import {User} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  public user: User = new User();
  public languageName: string;
  public currency: string;
  public countryList = [];
  public selectedCountry = "";

  public dropzoneConfig = {
    url: `/api/v1/account/addlogo`,
    maxFiles: 1,
    uploadMultiple: false,
    maxFilesize: 10,
    acceptedFiles: "image/*",
    height: 150,
  };
  public fileUploader: any;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private userService: UserService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  public async ngOnInit() {
    this.user = await this.localStorage.getUserAsync();
    this.selectedCountry = this.user.country;
    const country = find(countryList, {alpha2Code: this.user.country});
    this.languageName = country.languages[0].name;
    this.currency = country.currencies[0].name + "(" + country.currencies[0].symbol + ")";

    countryList.forEach((c) => {
      this.countryList.push({
        value: c.alpha2Code,
        label: c.translations[this.translateService.currentLang] || c.translations.en || c.nativeName,
        icon: c.flag,
      });
    });
  }

  public initDropzone(uploader: any) {
    this.fileUploader = uploader;
    if (this.user.logoUrl) {
      const mockFile = {name: "Logo", id: uuid()};
      uploader.emit("addedfile", mockFile);
      uploader.emit("thumbnail", mockFile, this.user.logoUrl);
      uploader.files.push(mockFile);

      mockFile["previewElement"].classList.add("dz-success");
      mockFile["previewElement"].classList.add("dz-complete");
    }
  }

  public onUploadSuccess(file: any) {
    this.user.logoUrl = JSON.parse(file.xhr.response);
    this.localStorage.setUser(this.user);

    this.subscriptionService.logInStatusChanged(true);
  }

  public resetImage() {
    const confirmModel = {
      message: this.translateService.instant("DeleteImageConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.doResetImage();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public setCountry(countryCode: any) {
    const country = find(countryList, {alpha2Code: countryCode});
    if (country) {
      this.languageName = country.languages[0].name;
      this.currency = country.currencies[0].name + "(" + country.currencies[0].symbol + ")";

      this.user.currency = country.currencies[0].name;
      this.user.currencySymbol = country.currencies[0].symbol;

      this.user.country  = countryCode;
      this.user.language = country.languages[0].iso639_1 || "en";
    }
  }

  public saveUser() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.userService.saveUser(this.user).subscribe((user) => {
      this.blockUI.stop();
      this.localStorage.setUser(user);
      this.messageService.success(this.translateService.instant("PersonalInfoUpdateSuccess"));
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  private doResetImage() {
    this.userService.resetLogo().subscribe(() => {
      this.user.logoUrl = "";
      this.localStorage.setUser(this.user);
      this.fileUploader.removeAllFiles();
      this.subscriptionService.logInStatusChanged(true);
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
