import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DayEndReportStatistic} from "../../../../models";
import {InvoiceModel} from "../../../../models/invoice.model";
import {LocalStorageService} from "../../../../services/localstorage.service";

import * as dayjs from "dayjs";
import {Subscription} from "rxjs";
import {SubscriptionService} from "../../../../services/subscription.service";

const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

@Component({
  selector: "app-receipt-single-item",
  templateUrl: "./receipt-single-item.component.html",
  styleUrls: ["./receipt-single-item.component.scss"],
})
export class ReceiptSingleItemComponent implements OnInit, OnDestroy {
  @Input()
  public receipt: InvoiceModel = new InvoiceModel();

  public isLocked: boolean;
  public isActive: boolean;
  public receiptDate: string;
  public dayEndStatistic: DayEndReportStatistic;
  public receiptSelectedSubscription: Subscription;
  public receiptUpdatedSubscription: Subscription;
  public paymentTypeLabel: string;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService
  ) { }

  public async ngOnInit() {
    this.dayEndStatistic = await this.localStorage.getDayEndStatisticAsync();
    this.isLocked = this.isReceiptLocked();
    this.receiptDate = dayjs(this.receipt.date).format("L LT");

    const paymentType = (await this.localStorage.getPaymentTypesAsync()).find(p => p.name == this.receipt.paymentType);
    if(paymentType) this.paymentTypeLabel = paymentType.label;
    else this.paymentTypeLabel = this.translateService.instant(this.receipt.paymentType);

    this.receiptSelectedSubscription = this.subscriptionService.receiptSelectedEvent.subscribe((receipt) => {
      this.isActive = this.receipt.id == receipt?.id;
    });

    this.receiptUpdatedSubscription = this.subscriptionService.receiptUpdatedEvent.subscribe((receipt) => {
      if (this.receipt.id == receipt?.id) {
        this.receipt = receipt;
      }
    });
  }

  public ngOnDestroy(): void {
    this.receiptSelectedSubscription?.unsubscribe();
    this.receiptUpdatedSubscription?.unsubscribe();
  }

  public showReceipt(receipt: InvoiceModel) {
    this.subscriptionService.receiptSelected(receipt);
  }

  public isReceiptLocked() {
    if (!this.dayEndStatistic) { return false; }

    const orderDate = dayjs(this.receipt.date);
    return orderDate.isBefore(this.dayEndStatistic.lastReportDate) || this.receipt.totalPriceGross < 0;
  }
}
