<div mdbModal #modal="mdbModal" [config]="{backdrop: true, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && sendEmail()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"SendEmail" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="md-form">
            <input #inputName mdbInput type="email" id="email" name="email" class="form-control" required="required"
                   [attr.placeholder]='"EnterEmail" | translate'
                   [(ngModel)]="email"/>
            <label for="email">
              {{"Email" | translate }}
            </label>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="modal.hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" data-dismiss="modal">
            {{"Send" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
