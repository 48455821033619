import {Component, OnInit, ViewChild} from "@angular/core";
import {reject, sortBy} from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {OrderedItem, Product} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: "app-product-withouts-dialog",
  templateUrl: "./product-withouts-dialog.component.html",
  styleUrls: ["./product-withouts-dialog.component.scss"],
})
export class ProductWithoutsDialogComponent extends ModalClassComponent implements OnInit {

  @ViewChild("modal")
  public modal: ModalDirective;

  public item: OrderedItem = {product: {} as Product} as OrderedItem;
  public ingredients: any[] = [];
  public onCloseDialog: any;

  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("product-withouts", this);
  }

  public async toggleSelect(ingredient, event): Promise<any> {
    event.preventDefault();
    ingredient.selected = !ingredient.selected;
    await this.saveWithouts();
  }

  public hide() {
    super.hide();
    if (this.onCloseDialog) { this.onCloseDialog(this.item); }

    this.item = ({product: {} as Product} as OrderedItem);
    this.ingredients = [];
    this.modal.hide();
  }

  public show(args) {
    super.show(args);
    this.onCloseDialog = args.onCloseDialog;
    this.item = args.item;
    let ingredientsList = this.item.product.ingredients.split(",");
    this.ingredients = [];

    ingredientsList = sortBy(ingredientsList);
    ingredientsList.forEach((ingredient) => {
      this.ingredients.push({
        name: ingredient,
        selected: this.item.ingredientsToRemove?.indexOf(ingredient) > -1,
      });
    });

    this.modal.show();
  }

  public async saveWithouts(): Promise<any> {
    const ingredientsToRemove = [];
    this.ingredients.forEach(function(ingredient) {
      if (ingredient.selected) {
        ingredientsToRemove.push(ingredient.name);
      }
    });

    let withoutItems;
    if (ingredientsToRemove.length > 0) {
      this.item.ingredientsToRemove = ingredientsToRemove;
      withoutItems = "(-) " + ingredientsToRemove.join(",");
    } else {
      withoutItems = "";
      this.item.ingredientsToRemove = [];
    }

    const arr = [];
    if (this.item.manualExtra) { arr.push(this.item.manualExtra); }
    if (this.item.selectedExtra) { arr.push(this.item.selectedExtra); }
    if (withoutItems) { arr.push(withoutItems); }

    this.item.extras = arr.join("\n");

    const orderedItems = reject(await this.localStorage.getOrderedItemsAsync(), {orderedItemId: this.item.orderedItemId});
    orderedItems.push(this.item);
    this.localStorage.setOrderedItems(orderedItems);
  }
}
