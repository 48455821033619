import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {CommonSettingsComponent} from "./common-settings/common-settings.component";
import {OTReceiptsComponent} from "./ot-receipts/ot-receipts.component";
import {PackageSettingsComponent} from "./package-settings/package-settings.component";
import {PaymentSettingsComponent} from "./payment-settings/payment-settings.component";
import {PrinterSettingsComponent} from "./printer-settings/printer-settings.component";
import {ProfileComponent} from "./profile/profile.component";
import {ReceiptSettingsComponent} from "./receipt-settings/receipt-settings.component";
import { TSESettingsComponent } from "./tse-settings/tse-settings.component";

const routes: Routes = [
  { path: "profile", component: ProfileComponent},
  { path: "receipt", component: ReceiptSettingsComponent},
  { path: "packages", component: PackageSettingsComponent},
  { path: "payment", component: PaymentSettingsComponent},
  { path: "common", component: CommonSettingsComponent},
  { path: "printer", component: PrinterSettingsComponent},
  { path: "myreceipts", component: OTReceiptsComponent},
  { path: "tse", component: TSESettingsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule { }
