import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import { ModalService } from "../../../../services/modal.service";

@Component({
  selector: "app-create-category-dialog",
  templateUrl: "./create-category-dialog.component.html",
  styleUrls: ["./create-category-dialog.component.scss"],
})
export class CreateCategoryDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("inputName")
  public nameInputRef: ElementRef;

  public name: string;
  public addCatCallback: any;
  public isUpdate = false;

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("create-table-category-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.name = params.name ?? "";
    this.isUpdate = params.isUpdate ?? false;
    this.addCatCallback = params.addCategory;
    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      300,
    );
  }

  public addCategory() {
    this.nameInputRef.nativeElement.focus();
    if (this.addCatCallback) {
      this.addCatCallback(this.name);
      this.name = "";
    }
  }
}
