import { OrderVariation } from './../../../models/order.variation.model';
import { OrderedItem } from './../../../models/ordered.item.model';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SideDish } from './../../../models/sidedish.model';
import { ProductCategory } from './../../../models/product.category.model';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { ProductPicture } from './../../../models/product.picture.model';
import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { Extra, Product } from "../../../models";
import { SubscriptionService } from "../../../services/subscription.service";
import { filter } from "lodash";
import { LocalCurrencyPipe } from '../../common/pipes/local.currency.pipe';
import { ProductExtras } from 'src/app/models/product.extras.model';
import { ProductVariationGroup } from 'src/app/models/product.variation.group.model';
import { ProductVariation } from 'src/app/models/product.variation.model';
import { UtilsService } from 'src/app/services/utils.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: "app-self-order-product-order",
  templateUrl: "./self-order-product-order.component.html",
  styleUrls: ["./self-order-product-order.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderProductOrderComponent implements OnInit, OnDestroy {
  public show: boolean;
  public searchText: string;
  public selfOrderMenuEventSubscription: Subscription;
  public updateOrderItemSubscription: Subscription;
  public orderedItem: OrderedItem = null;
  public product: Product = null;
  public productCategories: ProductCategory[] = [];
  public productCategory: ProductCategory = null;
  public images: ProductPicture[];
  public ingredients: string[] = [];
  public ingredientsToRemove = {};
  public extras: Extra[] = [];
  public sideDishes: SideDish[] = [];
  public variationGroups: ProductVariationGroup[] = [];
  public variations: ProductVariation[] = [];
  public selectedVariations = {};
  public maximumReachedGroups = {};
  public invalidVariationGrups: string[] = [];
  public formattedUnitPrices: string[] = [];
  public currencyPipe: LocalCurrencyPipe;
  public selectedPortionSizeIndex: number;
  public totalPrice: number;
  public extrasPrice: number;
  public manualExtra: string;
  private productPictures: ProductPicture[] = [];

  @HostBinding("style.--so-secondary-color") soSecondaryColor: string = '';
  @HostBinding("style.--so-secondary-inverted-color") soSecondaryInvertedColor: string = '';  

  constructor(
    private subscriptionService: SubscriptionService,
    private storageService: LocalStorageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private utilsService: UtilsService,
    private scroller: ViewportScroller
  ) {
    this.productCategories = JSON.parse(localStorage.getItem("categories"));
  }

  public async ngOnInit() {
    this.selfOrderMenuEventSubscription = this.subscriptionService.selfOrderProductOrderEvent.subscribe(async (event) => {
      this.show = true;
      this.product = event.product;
      this.product["orderCount"] = 1;
      this.orderedItem = null;
      this.soSecondaryColor = this.product.color ?? event.category.color;
      this.soSecondaryInvertedColor = this.utilsService.invertColor(this.soSecondaryColor, true);
      this.selectedVariations = {};
      this.maximumReachedGroups = {};
      this.showProductInformation();
    });

    this.updateOrderItemSubscription = this.subscriptionService.updateOrderItemEvent.subscribe((item: OrderedItem) => {
      this.show = true;
      this.orderedItem = item;
      this.product = item.product;
      this.showProductInformation();

    });
  }

  showProductInformation() {
    this.selectedPortionSizeIndex = this.orderedItem?.product.extrasInfo?.selectedPriceCategory ? this.orderedItem?.product.priceCategories.indexOf(this.orderedItem?.product.extrasInfo.selectedPriceCategory) : 0;
    this.sideDishes = this.product.sideDishes || [];

    const selectedExtraIdArry = this.orderedItem?.product?.extrasInfo?.extrasId ? this.orderedItem?.product?.extrasInfo?.extrasId?.split(",") : [];
    const selectedExtraIds = {};
    for(var id of selectedExtraIdArry) {
        const info = id.split("*");
        if(info.length == 2) {
          selectedExtraIds[info[1]] = info[0];
        }
     }

    this.extras = [];
    this.productCategory = this.productCategories.find(cat => cat.productCategoryId == this.product.productCategoryId);

    if (this.productCategory) {
      this.extras = this.productCategory.extras;
      for (const extra of this.extras) {
        extra.count = selectedExtraIds[extra.id] ? selectedExtraIds[extra.id] : 0;
      }

      this.sideDishes = this.sideDishes.concat(...this.productCategory.sideDishes);
      this.sideDishes.forEach(sd => {
        sd.isSelected = false;
      });
    }

    if (this.orderedItem?.product.extrasInfo?.sideDishes) {
      for (const sideDish of this.orderedItem?.product.extrasInfo.sideDishes) {
        const sd = this.sideDishes.find(s => s.name == sideDish.name);
        if (sd) {
          sd.isSelected = true;
        }
      }
    }
    
    this.variationGroups = this.product.variationGroups != null && this.product.variationGroups.length > 0 ? this.product.variationGroups : this.productCategory?.variationGroups;
    if(this.variationGroups == null) {
      this.variationGroups = [];
    }

    this.variations = [];
    for(let group of this.variationGroups) {
      this.variations.push(...group.variations);
    }

    const preSelectedvariations = this.variations.filter(v => v.preselect);
    for(const variation of preSelectedvariations) {
      this.selectedVariations[variation.id] = 1;
    }

    this.manualExtra = this.orderedItem?.product?.extrasInfo?.manualExtra;
    this.product["orderCount"] = this.product["orderCount"] > 1 ? this.product["orderCount"] : 1;

    if (this.product.ingredients) {
      this.ingredients = this.product.ingredients.split(",");
    }
    this.ingredientsToRemove = {};
    if(this.orderedItem?.product.extrasInfo?.ingredientsToRemove) {
      for(var ing of this.orderedItem?.product.extrasInfo.ingredientsToRemove ) {
        this.ingredientsToRemove[ing] = true;
      }
    }

    this.calculateTotalPrice();

    this.productPictures = JSON.parse(localStorage.getItem("product-pictures"));
    this.images = filter(this.productPictures, { productId: this.product?.productId || this.orderedItem?.product.productId, dimensions: "original" });
    if (this.images.length === 0) { this.images = [{ url: "/assets/images/plate.png", id: "", contentType: "", dimensions: "", fileName: "", originalFileId: "", productId: "", size: 0 }]; }
  }

  public ngOnDestroy(): void {
    this.selfOrderMenuEventSubscription?.unsubscribe();
    this.updateOrderItemSubscription?.unsubscribe();
  }

  close() {
    this.show = false;
  }

  public decreaseCount(product: Product, $event) {
    const count = product["orderCount"];
    if (count > 1) {
      product["orderCount"] = count - 1;
      this.calculateTotalPrice();
    }
  }

  public increaseCount(product: Product, $event) {
    product["orderCount"] = product["orderCount"] + 1;
    this.calculateTotalPrice();
  }

  public decreaseExtraCount(extra: Extra, $event) {
    if (extra.count > 0) {
      extra.count = extra.count - 1;
      this.calculateTotalPrice();
    }
  }

  public increaseExtraCount(extra: Extra, $event) {
    extra.count = parseInt(extra.count + "") + 1;
    this.calculateTotalPrice();
  }

  public increaseVariationCount(variationGroup: ProductVariationGroup, variation: ProductVariation) {
    var count = this.selectedVariations[variation.id];
    if(!count) {
      count = 1;
    } else {
      count ++;
    }
    this.selectedVariations[variation.id] = count;

    this.maximumReachedGroups[variationGroup.id] = this.isMaximumVariationReached(variationGroup);

    this.calculateTotalPrice();
  }

  public decreaseVariationCount(variationGroup: ProductVariationGroup, variation: ProductVariation) {
    var count = this.selectedVariations[variation.id];
    if(count && count > 0) {
      count --;
      this.selectedVariations[variation.id] = count;
    }

    this.maximumReachedGroups[variationGroup.id] = this.isMaximumVariationReached(variationGroup);

    this.calculateTotalPrice();
  }

  public toggleVariation(variationGroup: ProductVariationGroup, variation: ProductVariation) {
    if(variationGroup.min == 1 && variationGroup.max == 1) {
      for(let varit of variationGroup.variations) {
        if(varit.id != variation.id) { 
          this.selectedVariations[varit.id] = 0;
        }
      }
    }

    if(!this.selectedVariations[variation.id]) {
      this.selectedVariations[variation.id] = 1;
    } else {
      this.selectedVariations[variation.id] = 0;
    }

    this.maximumReachedGroups[variationGroup.id] = this.isMaximumVariationReached(variationGroup);

    this.calculateTotalPrice();
  }

  public variationClicked(variationGroup: ProductVariationGroup, variation: ProductVariation) {
    if(this.maximumReachedGroups[variationGroup.id] && (variationGroup.allowOptionQuantity || !this.selectedVariations[variation.id])) {
      return;
    }

    if(variationGroup.allowOptionQuantity) {
      this.increaseVariationCount(variationGroup, variation);
    } else {
      this.toggleVariation(variationGroup, variation); 
    }

    this.calculateTotalPrice();
  }
  
  public getVariationPrice(variation: ProductVariation) {
    var portionName = this.orderedItem?.product.extrasInfo?.selectedPriceCategory ?? this.product.priceCategories[this.selectedPortionSizeIndex];
    if(this.selectedPortionSizeIndex <variation.prices.length) {
      var price = variation.prices.find(p => p.name == portionName);
      return price?.price ?? 0;
    }

    return 0;
  }



  public calculateTotalPrice() {
    this.extrasPrice = 0;
    for (const extra of this.extras) {
      this.extrasPrice += extra.count * extra.price;
    }

    for (const sideDish of this.sideDishes) {
      if (sideDish.isSelected) {
        this.extrasPrice += sideDish.price;
      }
    }
    
    var keys = Object.keys(this.selectedVariations);
    for (const variationId of keys) {
      const variation = this.variations.find(v => v.id == variationId);
      if(variation) {
        const price = this.getVariationPrice(variation);
        this.extrasPrice += price * this.selectedVariations[variationId];
      }
    }

    this.totalPrice = this.product["orderCount"] * (parseFloat(this.product.unitPrices[this.selectedPortionSizeIndex]) + this.extrasPrice);
  }

  public replaceDashes(id: string) {
    return id.replace(/-/g, '');
  }

  selectPortionSize(index: number) {
    this.selectedPortionSizeIndex = index;
    this.calculateTotalPrice();
  }

  sideDishSelected(sideDish: SideDish) {
    var isSelected = sideDish.isSelected;
    this.sideDishes.forEach(sd => {
      sd.isSelected = false;
    });
    sideDish.isSelected = isSelected;
    this.calculateTotalPrice();
  }

  orderProduct() {
    if (!this.checkSideDish()) {
      this.showSelectSideDishDialog();
      return;
    }

    if (!this.validateOptionGroups()) {
      const element = document.getElementById(this.replaceDashes(this.invalidVariationGrups[0]));
      if(element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
      setTimeout(() => this.showSelectVariationDialog(), 600);
      return;
    }

    const keys = Object.keys(this.ingredientsToRemove);
    const withouts = [];
    for (var key of keys) {
      if (this.ingredientsToRemove[key]) {
        withouts.push(key);
      }
    }

    const extras = [];
    if (this.manualExtra) {
      extras.push(this.manualExtra);
    }

    const selectedExtras = [];
    const selectedExtraIds = [];
    for (const sideDish of this.sideDishes) {
      if (sideDish.isSelected) {
        selectedExtras.push(sideDish.name);
      }
    }
    for (const extra of this.extras) {
      if (extra.count > 0) {
        selectedExtras.push((extra.count > 1 ? extra.count + "x " : "") + extra.name);
        selectedExtraIds.push(extra.count + "*" + extra.id);
      }
    }

    const selectedVariationIds = Object.keys(this.selectedVariations);
    for(const variantId of selectedVariationIds) {
      if(this.selectedVariations[variantId] > 0) {
        const variation = this.variations.find(v => v.id == variantId);
        if(variation) {
          selectedExtras.push((this.selectedVariations[variantId] > 1 ? this.selectedVariations[variantId] + "x " : "") + variation.label);        
        }
      }
    }
    if (selectedExtras.length > 0) {
      extras.push("(+)" + selectedExtras.join(", "));
    }

    if (withouts.length > 0) {
      extras.push("(-)" + withouts.join(", "));
    }

    this.product.extrasInfo = {
      sideDishes: this.sideDishes.filter(s => s.isSelected),
      selectedExtra: selectedExtras.length > 0 ? "(+) " + selectedExtras.join(", ") : "",
      selectedPriceCategory: this.product.priceCategories[this.selectedPortionSizeIndex],
      extrasPrice: this.extrasPrice,
      ingredientsToRemove: withouts,
      manualExtra: this.manualExtra,
      extras: extras.join("\n"),
      extrasId: selectedExtraIds.join(","),
      orderedVariations: this.getOrderVariations(),
    } as ProductExtras;

    this.product.unitPrice = parseFloat(this.product.unitPrices[this.selectedPortionSizeIndex]);

    if(this.orderedItem) {
      this.orderedItem.product = this.product;
      this.orderedItem.orderedVariations = this.getOrderVariations();
      this.orderedItem.notes = this.manualExtra;
      this.subscriptionService.orderedItemUpdatedEvent.emit(this.orderedItem);
      this.show = false;
    }
    else {
      this.subscriptionService.productOrderedEvent.emit(this.product);

      const confirmModel = {
        message: this.translateService.instant("ProductAddedSuccessfully") + "<br /><br />" + this.translateService.instant("GotoOrderList"),
        icon: "check",
        type: "info",
        confirmed: () => {
          this.subscriptionService.openSelfOrderList();
          this.show = false;
          this.subscriptionService.closeSelfOrderMenu();
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    }
  }  

  getOrderVariations(): OrderVariation[] {
    var ids = Object.keys(this.selectedVariations);

    const result: OrderVariation[] = [];
    for(var id of ids) {
      if(this.selectedVariations[id] > 0) {
        const variation = this.variations.find(v => v.id == id);
        if(variation) {
          result.push({
            variationId: variation.id,
            variationName: variation.label,
            quantity: this.selectedVariations[id],
            price: this.getVariationPrice(variation),
          } as OrderVariation)
        }
      }
    }

    return result;    
  }

  validateOptionGroups() : boolean {
    this.invalidVariationGrups = [];
    for(const group of this.variationGroups) {
      let quantity = 0;
      for(const variation of group.variations) {
        quantity += this.selectedVariations[variation.id] > 0 ? this.selectedVariations[variation.id] : 0;      
      }

      if(quantity < group.min) {
        this.invalidVariationGrups.push(group.id);             
      }
    }

    if(this.invalidVariationGrups.length > 0) {
      return false;
    }

    return true;
  }


  showSelectSideDishDialog() {
    const confirmModel = {
      title: this.translateService.instant("Warning"),
      message: this.translateService.instant("MustChooseAtLeastOneSideDish"),
      type: "warning",
      icon: "exclamation",
      hideCancelButton: true,
      confirmButtonTitle: "Close",
      confirmed: () => {
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  showSelectVariationDialog() {
    const confirmModel = {
      title: this.translateService.instant("Warning"),
      message: this.translateService.instant("MustChooseAtLeastOneVariation"),
      type: "warning",
      icon: "exclamation",
      hideCancelButton: true,
      confirmButtonTitle: "Close",
      confirmed: () => {
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  checkSideDish(): boolean {
    if (this.sideDishes.length > 0 && !this.sideDishes.find(s => s.isSelected)) {
      return false;
    }

    return true;
  }

  public getSourceUrl(productPicture: ProductPicture, dimension: string) {
    var picture = this.productPictures.find(p => p.originalFileId == productPicture.id && p.dimensions == dimension);
    if(picture) {
      return picture.url;
    }

    return "";
  }

  private isMaximumVariationReached(variationGroup: ProductVariationGroup): any {
    if(variationGroup.max == 0) {
      return true;
    }

    if(!variationGroup.max) {
      return false;
    }

    if(variationGroup.min == 1 && variationGroup.max == 1) {
      return false;
    }

    let count = 0;
    for(let variation of variationGroup.variations) {
      count += this.selectedVariations[variation.id] ?? 0;
    }

    return count >= variationGroup.max;
  }
}
