import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { findIndex } from "lodash";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PrinterSettings } from "../../../../models";
import { AccountService } from "../../../../services/account.service";
import { LocalStorageService } from "../../../../services/localstorage.service";
import { MessageService } from "../../../../services/message.service";
import { ModalService } from "../../../../services/modal.service";
import { NativeAppService } from "../../../../services/native-app.service";

@Component({
  selector: "app-wlan-printer-settings",
  templateUrl: "./wlan-printer-settings.component.html",
  styleUrls: ["./wlan-printer-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class WlanPrinterSettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  @Input()
  public settings: PrinterSettings = new PrinterSettings();

  @Input()
  public allSettings: PrinterSettings[] = [];

  public selectedCategories: any[] = [];
  public productCategories: any[] = [];
  public printerTypes: any[] = [];
  public codePages: any[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private messageService: MessageService,
    private nativeAppService: NativeAppService,
  ) { }

  public ngOnInit() {
    this.localStorage.getProductCategoriesAsync().then(categories => {
      categories.forEach((category) => {
        this.productCategories.push({
          value: category.productCategoryId,
          label: category.productCategoryName,
        });

        if (this.settings.categories?.indexOf(category.productCategoryId) > -1) {
          this.selectedCategories.push(category.productCategoryId);
        }
      });
    });

    this.printerTypes = [
      { value: "35/46", label: "58mm - 35/46" },
      { value: "48/64", label: "80mm - 48/64" },
      { value: "31/42", label: "58mm - 31/42" },
      { value: "42/60", label: "80mm - 42/60" },
    ];

    this.codePages = [
      { value: "0", label: "PC437 (USA: Standard Europe)" },
      { value: "19", label: "PC858 (Euro)" },
      { value: "37", label: "PC864 (Arabic)" },
      { value: "14", label: "PC737 (Greek)" },
      { value: "36", label: "PC862 (Hebrew)" },
      { value: "17", label: "PC866 (Cyrillic)" },
      { value: "13", label: "PC857 (Turkish)" },
      { value: "3", label: "PC860 (Portuguese)" },
      { value: "255:Windows-874", label: "PC874 (Thai)" },
      {value: "21", label: "Thai(2)"},
      { value: "-1", label: "GB18030 (Simplified Chinese)" },
    ];
  }

  public deleteSettings() {
    if (this.settings["partitionKey"]) {
      const confirmModel = {
        message: this.translateService.instant("DeletePrinterSettingsConfirm"),
        type: "danger",
        icon: "times",
        confirmed: () => {
          this.doDeleteSettings();
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    } else {
      const index = findIndex(this.allSettings, { id: this.settings.id });
      if (index > -1) {
        this.allSettings.splice(index, 1);
        this.localStorage.setPrinterSettings(this.allSettings);
      }
    }
  }

  public async testConnection(): Promise<any> {
    this.blockUI.start(this.translateService.instant("PleaseWait"));

    const invoice = {
      date: "2020-02-07T13:04:51.147582Z",
      invoiceNumber: 1,
      tableName: "Table1",
      paymentType: "CashPayment",
      employeeName: "Test Employee",
      currency: "EUR",
      discountPercentage: 0,
      discountAmount: 0,
      totalPriceGross: 7.5,
      totalPriceNet: 6.52,
      totalTaxAmount: 0.98,
      cardFeeAmount: 0,
      taxAmounts: [{ taxRate: 10, gross: 3.5, net: 3.18, taxValue: 0.32 }, { taxRate: 20, gross: 4, net: 3.33, taxValue: 0.67 }],
      billAmount: 0,
      billRemainder: 0,
      combinedPaymentTypes: {},
      invoiceItems: [{
        quantity: 1,
        productName: "Product1",
        unitPriceGross: 3.5,
        unitPriceNet: 3.18,
        extras: "",
        extrasPrice: 0,
        total: 3.5,
        taxRate: 10,
        discount: 0,
        cancellation: "",
        status: 4,
        priceCategory: "Salate",
      }, { quantity: 1, productName: "Product 2", unitPriceGross: 4, unitPriceNet: 3.33, extras: "", extrasPrice: 0, total: 4, taxRate: 20, discount: 0, cancellation: "", status: 4, priceCategory: "Suppen" }],
    };
    await this.nativeAppService.printInvoice(invoice, [this.settings]);

    this.blockUI.stop();
  }

  public updateSelectedCategories(selectedCategories: string[]) {
    this.settings.categories = selectedCategories.join(",");
  }

  public setSelectedCategories(category: any) {
    if (category.selected && this.selectedCategories.indexOf(category.value) === -1) {
      this.selectedCategories.push(category.value);
    }
    if (!category.selected) {
      const index = this.selectedCategories.indexOf(category.value);
      if (index > -1) {
        this.selectedCategories.splice(index, 1);
      }
    }

    this.settings.categories = this.selectedCategories.join(",");
  }

  private doDeleteSettings() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.accountService.deletePrinterSettings(this.settings.id).subscribe(() => {
      this.blockUI.stop();
      const index = findIndex(this.allSettings, { id: this.settings.id });
      if (index > -1) {
        this.allSettings.splice(index, 1);
      }
      this.messageService.success(this.translateService.instant("DeletePrinterSettingsSuccess"));
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
