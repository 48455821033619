import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep, findIndex} from "lodash";
import {Table} from "../../../../models";
import {TableCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-single-table",
  templateUrl: "./single-table.component.html",
  styleUrls: ["./single-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SingleTableComponent implements OnInit {

  @Input()
  public table: Table;

  @Input()
  public category: TableCategory;

  @Input()
  public allTables: Table[];

  public allowSelfOrdering: boolean;

  constructor(
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private tableService: TableService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  public async ngOnInit() {
    this.allowSelfOrdering = (await this.localStorage.getUserSettingsAsync()).allowSelfOrdering;
  }

  public getDesc() {
    if (this.table.description) { return "(" + this.table.description + ")"; }

    return "";
  }

  public getTax() {
    if (this.table.tax != null && this.table.tax >= 0) { return this.translateService.instant("Tax") + ": " + this.table.tax + "%"; }

    return "";
  }

  public showUpdateTable() {
    this.modalService.show("create-table-dialog", {
      category: this.category,
      table: cloneDeep(this.table),
      isUpdate: true,
      onTableAdded: (table) => {
        this.table.name = table.name;
        this.table.tax = table.tax;
        this.table.categoryId = table.categoryId;
        this.table.displayNumber = table.displayNumber;
        this.table.macAddress = table.macAddress;

        this.localStorage.updateTable(table);

        if (this.table.categoryId != this.category.id) {
          this.subscriptionService.tableMoved(this.table.categoryId);
          const index = findIndex(this.allTables, {id: table.id});
          if (index > -1) {
            this.allTables.splice(index, 1);
          }
        }
      },
    });
  }

  public showSelfOrderingSettings() {
    this.modalService.show("selforder-settings-dialog", {table: this.table});
  }

  public showDeleteTableConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("DeleteTableConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {this.deleteTable(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  private deleteTable() {
    this.tableService.deleteTable(this.table.id).subscribe( () => {
      const index = findIndex(this.allTables, {id: this.table.id});
      this.allTables.splice(index, 1);
      this.localStorage.removeTable(this.table);
      this.messageService.success(this.translateService.instant("DeleteTableSuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
