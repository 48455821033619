import {BaseModel} from "./base.model";

export class PackageInfo extends BaseModel {
  public name: string;

  public customerId: string;

  public maxOrderCount: number;

  public price: number;

  public stripePlanId: string;

  public paypalPlanId: string;

  public recursion: string;

  public url: string;

  public featured: boolean;

  public currency: string;

  public isActive: boolean;
}
