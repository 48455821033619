import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep, filter, findIndex} from "lodash";
import {v4 as uuid} from "uuid";
import {Product} from "../../../../models";
import {ProductCategory, ProductPicture} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";
import {LocalCurrencyPipe} from "../../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-single-product",
  templateUrl: "./single-product.component.html",
  styleUrls: ["./single-product.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SingleProductComponent implements OnInit {

  @Input()
  public product: Product;

  @Input()
  public category: ProductCategory;

  @Input()
  public allProducts: Product[];

  public images: ProductPicture[] = [];
  public formattedUnitPrices: string[] = [];

  private currencyPipe: LocalCurrencyPipe;

  constructor(
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private productService: ProductService,
    private messageService: MessageService,
  ) {
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public ngOnInit() {
    this.getFormattedPrices();

    if(this.product)
      this.getImages().then();
  }

  public async getImages(): Promise<any> {
    const productImages = await this.localStorage.getProductPicturesAsync();
    this.images = filter(productImages, { productId: this.product.productId, dimensions: "thumbnail" });
    if (this.images.length === 0) { this.images = filter(productImages, { productId: this.product.productId, dimensions: "original" }); }
    if (this.images.length === 0) { this.images = [{ url: "/assets/images/plate.png", id: "", contentType: "", dimensions: "", fileName: "", originalFileId: "", productId: "", size: 0 }]; }

    this.images.forEach(image => {
      image["thumbnailUrl"] = this.getThumbnail(image.url);
    });
  }

  public showUpdateProductDialog() {
    this.modalService.show("create-product-dialog", {
      category: this.category,
      product: cloneDeep(this.product),
      action: "update",
      onProductAdded: async (product) => {
        this.product = product;
        await this.getImages();
        this.getFormattedPrices();
        this.messageService.success(this.translateService.instant("UpdateProductSuccess"));
        this.localStorage.updateProduct(product);
      },
      onDialogHide: async () => {
        await this.getImages();
      }
    });
  }

  public showDeleteProductConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("DeleteProductConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {this.deleteProduct(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public showCloneProductDialog() {
    const clonedProduct = cloneDeep(this.product);
    clonedProduct.id = clonedProduct.productId = uuid();
    clonedProduct["rowKey"] = clonedProduct.id;
    this.modalService.show("create-product-dialog", {
      category: this.category,
      product: clonedProduct,
      onProductAdded: async (product) => {
        await this.getImages();
        this.getFormattedPrices();
        this.allProducts.push(product);
        this.localStorage.addProduct(product);
      },
    });
  }

  public getThumbnail(url: string) {
    if (url.endsWith("plate.png")) { return url; }

    const lastIndex = url.lastIndexOf("/");
    let before = url.substring(0, lastIndex);

    if(!before.endsWith("/150"))
      before += "/150";

    before += url.substring(lastIndex);

    return before;
  }

  private deleteProduct() {
    this.productService.deleteProduct(this.product.productId).subscribe( () => {
      const index = findIndex(this.allProducts, {id: this.product.productId});
      this.allProducts.splice(index, 1);
      this.localStorage.removeProduct(this.product);
      this.messageService.success(this.translateService.instant("DeleteProductSuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }

  private getFormattedPrices() {
    const prices = [];
    this.product.unitPrices.forEach((price) => {
      prices.push(this.currencyPipe.transform(parseFloat(price)));
    });
    this.formattedUnitPrices = prices;
  }
}
