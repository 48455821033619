import {Component, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep, filter, find, findIndex, orderBy, reject} from "lodash";
import {ConnectionService} from "ngx-connection-service";
import {Subscription} from "rxjs";
import {v4 as uuid} from "uuid";
import {OrderStatus} from "../../../enums/order.status.enum";
import {PaymentModel} from "../../../enums/payment.model.enum";
import {NotificationTypes, OrderedItem, Product, Table, User, UserSettings} from "../../../models";
import {InvoiceModel} from "../../../models/invoice.model";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {NativeAppService} from "../../../services/native-app.service";
import {PosService} from "../../../services/pos.service";
import {ProductService} from "../../../services/product.service";
import {SignalRService} from "../../../services/signalr.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {PaymentViewModel} from "../../../view-model/payment.view.model";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit, OnDestroy {
  public loading = false;
  public table: Table;
  public tableId: string;
  public tableName: string;
  public allowCourses: boolean;
  public isServeButtonVisible: boolean;
  public isSendButtonVisible: boolean;
  public courses: any;
  public allowAddProducts: boolean;
  public isPaymentButtonVisible: boolean;
  public isSeparatePaymentActive: boolean;
  public isSeparateMoveActive: boolean;
  public separatePayment = PaymentModel.separate;
  public totalPayment = PaymentModel.total;
  public totalQuantity = 0;
  public subTotal = 0;
  public menuState = "default";
  public orderedItems: OrderedItem[];
  public orderedItemUpdatedEventSubscription: Subscription;

  private isConnected = true;
  private isAdmin: boolean;
  private user: User;
  private userSettings: UserSettings;
  private activeCourse: any;
  private connectionSubscription: Subscription;
  private tableClickSubscription: Subscription;
  private tableRefreshSubscription: Subscription;
  private productOrderedSubscription: Subscription;
  private itemReturnedSubscription: Subscription;
  private productOrderRemovedSubscription: Subscription;
  private signalRTableRefreshSubscription: Subscription;

  private setItemsServedSubscription: Subscription;
  private sendItemsToKitchenSubscription: Subscription;
  private getTableOrderedItemsSubscription: Subscription;
  private loadProductSubscription: Subscription;

  constructor(
    private connectionService: ConnectionService,
    private localStorage: LocalStorageService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService,
    private nativeAppService: NativeAppService,
    private posService: PosService,
    private signalRService: SignalRService,
    private productService: ProductService,
    private modalService: ModalService) {
  }

  public ngOnInit() {
    setTimeout(async () => {
      this.user = await this.localStorage.getUserAsync();
      this.userSettings = await this.localStorage.getUserSettingsAsync();
      this.isAdmin = this.user?.role === "Administrator" || this.user?.role === "AdminHelper";
      this.allowCourses = this.userSettings?.allowCourses;
      this.allowAddProducts = this.userSettings?.canWaiterAddProducts;
    }, 100);

    this.connectionSubscription = this.connectionService.monitor().subscribe((currentState) => {
      this.isConnected = currentState.hasNetworkConnection && currentState.hasInternetAccess;
    });

    this.tableClickSubscription = this.subscriptionService.tableClickEvent.subscribe(async (tableId) => {
      this.isSeparatePaymentActive = false;
      this.isSeparateMoveActive = false;
      if(tableId) {
        this.tableId = tableId;
        await this.refreshOrderedItems();
      }
    });

    this.tableRefreshSubscription = this.subscriptionService.tableRefreshEvent.subscribe(async (tableId) => {
      if (tableId === this.tableId) {
        await this.refreshUI();
      }
    });

    this.productOrderedSubscription = this.subscriptionService.productOrderedEvent.subscribe(async (product) => {
      await this.orderProduct(product);
      await this.refreshUI();
    });

    this.productOrderRemovedSubscription = this.subscriptionService.removeOrderedItemEvent.subscribe(async (item) => {
      await this.removeOrderedItem(item);
      this.isSendButtonVisible = find(await this.localStorage.getOrderedItemsAsync(), (i) => {
        return i.tableId === this.tableId && i.status === OrderStatus.new;
      }) !== undefined;
      await this.refreshUI();
    });

    this.orderedItemUpdatedEventSubscription = this.subscriptionService.orderedItemUpdatedEvent.subscribe(() => {
      this.totalQuantity = this.getTotalQuantity();
      this.subTotal = this.getSubTotal();
    });

    this.signalRTableRefreshSubscription = this.signalRService.tableRefresh.subscribe(async (result) => {
      if (result.tableId === this.tableId) {
        await this.refreshUI();
      }

      this.subscriptionService.tableRefresh(result.tableId);

      if (result.tableOrders.filter((i) => i.status === OrderStatus.ready).length === 0) {
        this.localStorage.removeTableNotifications(result.tableId, NotificationTypes.OrdersReady);
        this.subscriptionService.refreshNotifications();
      }
    });

    this.signalRTableRefreshSubscription = this.signalRService.ordersReady.subscribe(async (result) => {
      if (result.tableId === this.tableId) {
        await this.refreshUI();
      }

      this.subscriptionService.tableRefresh(result.tableId);
    });

    this.itemReturnedSubscription = this.signalRService.itemReturned.subscribe((tableId) => this.signalRItemReturned(tableId));
  }

  public ngOnDestroy() {
    this.orderedItems = null;
    this.userSettings = null;
    this.courses = null;

    this.connectionSubscription.unsubscribe();
    this.tableClickSubscription.unsubscribe();
    this.productOrderedSubscription.unsubscribe();
    this.productOrderRemovedSubscription.unsubscribe();
    this.signalRTableRefreshSubscription.unsubscribe();
    this.itemReturnedSubscription.unsubscribe();
    this.setItemsServedSubscription?.unsubscribe();
    this.sendItemsToKitchenSubscription?.unsubscribe();
    this.getTableOrderedItemsSubscription?.unsubscribe();
    this.loadProductSubscription?.unsubscribe();
    this.orderedItemUpdatedEventSubscription.unsubscribe();
  }

  public closeItemList() {
    this.menuState = "closed";
  }

  public addCourse() {
    const courseIndex = this.courses.length + 1;
    const courseId = uuid();
    const course = this.createCourse(courseIndex, courseId, "");
    this.courses.push(course);

    this.setActiveCourse(course);
  }

  public showMenu(course) {
    this.setActiveCourse(course);
    this.modalService.show("menu-card", { orderedItems: this.orderedItems, tableId: this.tableId });
  }

  public createItemAtRunTime(dropdown) {
    dropdown.hide();
    this.modalService.show("create-item-dialog", null);
  }

  public showItemsCreatedAtRunTime(dropdown) {
    dropdown.hide();
    this.modalService.show("show-diverse-items-dialog", null);
  }

  public serveItems() {
    this.loading = true;
    this.setItemsServedSubscription = this.posService.setItemsServed(this.tableId).subscribe(async () => {
      const allOrderedItems = await this.localStorage.getOrderedItemsAsync();
      allOrderedItems.forEach((item) => {
        if (item.tableId === this.tableId && item.status === OrderStatus.ready) {
          item.status = OrderStatus.served;
        }
      });
      this.localStorage.setOrderedItems(allOrderedItems);

      this.orderedItems = filter(allOrderedItems, {tableId: this.tableId});

      this.isServeButtonVisible = false;
      this.isPaymentButtonVisible = this.isAdmin || this.userSettings?.canWaiterProcessPayment;
      this.subscriptionService.tableRefresh(this.tableId);

      this.loading = false;
    }, (error) => {
      this.messageService.error(error);
      this.loading = false;
    });
  }

  public async sendItems() {
    this.loading = true;

    const insertedItems = filter(this.orderedItems, (item) => {
      return item.status === OrderStatus.new;
    });
    let itemsToPrint = this.getItemsForPrint(insertedItems);
    const mergedItems = await this.mergeItems(insertedItems);

    const userId = this.user.userId;
    this.sendItemsToKitchenSubscription = this.posService.sendItemsToKitchen(this.tableId, userId, mergedItems).subscribe((orderedItems) => {
      this.updateOrderedItems(orderedItems);
      this.updateUI();

      this.printNewItems(itemsToPrint);
      // this.updateLastSoldItems(insertedItems);
    }, (error) => {
      this.loading = false;
      if (typeof error.responseJSON === "object") {
        const orderedItems = error.responseJSON;
        this.updateOrderedItems(orderedItems);
        this.updateUI();

        itemsToPrint = reject(itemsToPrint, (ip) => {
          return find(orderedItems, (i) => {
            return i.orderedItemId === ip.orderedItemId && i.status === OrderStatus.new;
          });
        });
        this.printNewItems(itemsToPrint);

        this.messageService.error(this.translateService.instant("ErrorSendingItems"));
      } else {
        if (!error) { error = this.translateService.instant("ErrorSendingItems"); }
        this.messageService.error(error);
      }
    });
  }

  public toggleSlide(course) {
    if (!course.active) {
      if (course.collapsed) {
        course.collapsed = !course.collapsed;
      }
    } else {
      course.collapsed = !course.collapsed;
    }

    this.setActiveCourse(course);
  }

  public getTotalQuantity(): number {
    const items = this.orderedItems;
    return items.reduce((acc, b) => acc + b.quantity, 0);
  }

  public getSubTotal(onlyServed = false): number {
    let price = 0;

    let items = this.orderedItems;
    if (onlyServed) {
      items = filter(items, {status: OrderStatus.served});
      if (this.isSeparatePaymentActive) {
        items = filter(items, (i) => i.selectionCount > 0);
      }
    }

    items.forEach((item) => {
      let itemPrice;
      if (onlyServed && this.isSeparatePaymentActive) {
        if (item.status === OrderStatus.served) {
          itemPrice = item.totalPrice;
          if (this.isSeparatePaymentActive) { itemPrice = itemPrice / item.quantity * item.selectionCount; }

          price += itemPrice;
        }
      } else {
        price += item.totalPrice;
      }
    });

    return price;
  }

  public activateSeparateMove() {
    this.isSeparateMoveActive = true;
  }

  public cancelSeparatePayment() {
    this.isSeparatePaymentActive = false;
    this.isSeparateMoveActive = false;
  }

  public activateSeparatePayment() {
    this.isSeparatePaymentActive = true;
  }

  public async loadItems() {
    this.menuState = "opened";
    this.loading = true;

    this.orderedItems = [];
    this.courses = this.getCourseFromItems();

    if (window.innerWidth <= 768) { // IPAD Portrait Mode
      setTimeout(async () => {
        if (!this.isConnected) {
          await this.showOfflineItems();
        } else {
          this.doLoadItems();
        }
      }, 400);
    } else {
      if (!this.isConnected) {
        await this.showOfflineItems();
      } else {
        this.doLoadItems();
      }
    }
  }

  public async showOfflineItems() {
    const newItems = filter(await this.localStorage.getOrderedItemsAsync(), (item) => {
      return item.tableId === this.tableId && item.status === OrderStatus.new;
    });
    this.isSendButtonVisible = newItems?.length > 0;
    this.isSeparatePaymentActive = false;
    this.isSeparateMoveActive = false;

    let offlineItems = filter(await this.localStorage.getOrderedItemsAsync(), {tableId: this.tableId});

    for (const item of offlineItems) {
      item.isActive = false;
      item.tax = await this.getItemTaxAsync(item);
      item.taxPrice = this.getItemTaxPrice(item);
      item.totalPrice = item.quantity * (item.unitPrice + item.extrasPrice);
    }

    offlineItems = offlineItems.concat(newItems);

    this.orderedItems = offlineItems;
    this.courses = this.getCourseFromItems();
    this.setActiveCourse(this.courses[0]);

    let exists = find(offlineItems, {status: OrderStatus.ready});
    this.isServeButtonVisible = exists !== undefined;

    exists = find(offlineItems, {status: OrderStatus.served});
    this.isPaymentButtonVisible = exists !== undefined && (this.isAdmin || this.userSettings?.canWaiterProcessPayment);

    this.loading = false;
  }

  public doLoadItems() {
    this.getTableOrderedItemsSubscription = this.posService.getTableOrderedItems(this.tableId).subscribe(async (orderedItems) => {
      if (!orderedItems) { orderedItems = []; }

      let otherItems: OrderedItem[] = (await this.localStorage.getOrderedItemsAsync()).filter((item) => {
        return (item.tableId !== this.tableId || item.status === OrderStatus.new) &&
          !find(orderedItems, {orderedItemId: item.orderedItemId });
      });

      otherItems = otherItems.concat(orderedItems);
      this.localStorage.setOrderedItems(otherItems);

      this.loading = false;

      await this.refreshUI();
      this.subscriptionService.tableRefresh(this.tableId);
    }, async (error) => {
      this.loading = false;
      this.messageService.error(error);
      await this.showOfflineItems();
    });
  }

  public createCourse(index, id, title) {
    const courseId = id || uuid();
    const courseIndex = index || 1;
    title = title || this.translateService.instant("MenuCourse") + " #" + courseIndex;
    return {
      active: false,
      id: courseId,
      index: courseIndex,
      items: orderBy(filter(this.orderedItems, (item) => {
        return (!item.courseName && courseIndex === 1) || item.courseName === title;
      }), "orderDate"),
      title: title,
      visible: this.allowCourses,
    };
  }

  public removeCoursesWithNoItems() {
    this.courses = reject(this.courses, (course) => {
      return !find(this.orderedItems, {courseName: course.title});
    });

    if (this.courses.length === 0) {
      this.courses.push(this.createCourse(1, null, null));
    }
  }

  public setActiveCourse(course) {
    this.courses.forEach((c) => {
      c.active = false;
    });

    this.activeCourse = course;
    this.activeCourse.active = true;
  }

  public async getItemTaxAsync(product): Promise<number> {
    const table = find(await this.localStorage.getTablesAsync(), { id: this.tableId });
    console.log("table tax ==== ", table?.tax);
    if (table?.tax != null && table.tax >= 0) { return table.tax; }

    if (product) {
      const cat = find(await this.localStorage.getProductCategoriesAsync(), (c) => {
        return c.productCategoryId === product.productCategoryId;
      });

      console.log("product tax ==== ", cat?.tax);
      if (cat?.tax != null && cat.tax >= 0) { return cat.tax; }
    }

    console.log("userSettings tax ==== ", this.userSettings?.tax);
    return this.userSettings?.tax;
  }

  public getItemTaxPrice(item) {
    const tax = item.tax >= 0 ? item.tax : this.userSettings?.tax;

    let taxValue = 0;
    if (tax > 0) {
      if (this.userSettings?.isTaxInPriceIncluded) {
        taxValue = item.totalPrice - item.totalPrice / (1 + tax / 100);
      } else {
        taxValue = item.totalPrice * tax / 100;
      }
    }

    return taxValue;
  }

  public getTaxPrices() {
    let items = this.orderedItems;
    if (this.isSeparatePaymentActive) { items = filter(this.orderedItems, (i) => {
      return i.selectionCount > 0;
    });
    }

    const res = {};
    items.forEach((item) => {
      if (item.status === OrderStatus.served) {
        const tax = item.tax >= 0 ? item.tax : this.userSettings?.tax;

        let taxValue = 0;
        if (tax > 0) {
          if (this.userSettings?.isTaxInPriceIncluded) {
            taxValue = item.totalPrice - item.totalPrice / (1 + tax / 100);
          } else {
            taxValue = item.totalPrice * tax / 100;
          }
        }

        if (this.isSeparatePaymentActive) { taxValue = taxValue / item.quantity * item.selectionCount; }

        if (res[tax]) { res[tax] += taxValue; } else { res[tax] = taxValue; }
      }
    });

    return res;
  }

  public getUnitPrice(item) {
    if (item.unitPrice) { return item.unitPrice; }

    if (!item.product) { return 0; }

    let unitPrice = item.product.unitPrice;
    if (item.selectedPriceCategory) {
      const index = item.product.priceCategories.indexOf(item.selectedPriceCategory);
      unitPrice = item.product.unitPrices[index];
    } else if (item.product.unitPrices.length === 1) {
      unitPrice = item.product.unitPrices[0];
    }

    return unitPrice;
  }

  public showPaymentDialog(paymentModel: PaymentModel) {

    if (this.isSeparatePaymentActive) {
      const selectedItems = this.orderedItems.filter((i) => i.selectionCount > 0);
      if (selectedItems.length === 0) {
        this.messageService.error(this.translateService.instant("ChooseAtLeastOneItem"));
        return false;
      }
    }

    const subTotal = this.getSubTotal(true);
    const tipAmount = subTotal * this.userSettings.tipToIncludeInPrice / 100;

    let servedItems = cloneDeep(this.orderedItems.filter((item) => item.status === OrderStatus.served));
    if (this.isSeparatePaymentActive) {
      servedItems = servedItems.filter((i) => i.selectionCount > 0);
    }

    const server = this.user.firstName + " " + this.user.lastName;

    servedItems.forEach(async (item) => {
      item.tax = await this.getItemTaxAsync(item.product);
      console.log("item.taxx == ", item.tax);
      item.unitPrice = this.getUnitPrice(item);

      let totalPrice = item.totalPrice;
      if (this.isSeparatePaymentActive) {
        totalPrice = totalPrice / item.quantity * item.selectionCount;
        item.quantity = item.selectionCount;
      }

      item.totalPrice = totalPrice;

      item.tableName = this.tableName;

      item.servedBy = server;
      // item.isPaid = true;
    });

    const paymentViewModel = {
      itemsToBePaid: servedItems,
      invoiceId: uuid(),
      tableId: this.tableId,
      paymentModel: paymentModel,
      paymentType: "CashPayment",
      cardPaymentValue: 0,
      cashPaymentValue: 0,
      voucherPaymentValue: 0,
      subPaymentType: "CashPayment",
      subPayments: {},
      tipAmount: tipAmount,
      subTotal: subTotal,
      grandTotal: subTotal + tipAmount,
      discount: 0,
      discountType: "",
      billAmount: 0,
      billRemainder: 0,
      cardFeeAmount: 0,
      isSeparatePaymentActive: this.isSeparatePaymentActive,
      paymentFinished: (result) => this.paymentFinished(result),
      retailMode: false,
    } as PaymentViewModel;
    this.modalService.show("payment-dialog", paymentViewModel);
  }

  public showMoveDialog() {
    const itemsToMove = [];
    if (this.isSeparateMoveActive) {
      const selectedItems: OrderedItem[] = filter(this.orderedItems, (i) => {
        return i.selectionCount > 0;
      });
      if (selectedItems.length === 0) {
        this.messageService.error(this.translateService.instant("ChooseAtLeastOneItem"));
        return false;
      }

      selectedItems.forEach((item) => {
        for (let i = 0; i < item.selectionCount; i++) {
          itemsToMove.push(item.orderedItemId);
        }
      });
    }

    const moveViewModel = {
      fromTableId: this.tableId,
      orderedItems: itemsToMove,
    };

    this.modalService.show("move-dialog", moveViewModel);
  }

  public getGrandTotal(paidOnly, paymentViewModel) {
    let total = this.getSubTotal() + this.getTipPrice() - paymentViewModel.discount;

    const fee = paymentViewModel.cardFee;
    if (fee) { total += fee; }

    return total;
  }

  public getTipPrice() {
    return this.getSubTotal() * this.userSettings.tipToIncludeInPrice / 100;
  }

  public async paymentFinished(result) {
    let paidItems = this.orderedItems.filter((i) => i.status === OrderStatus.served);
    if (this.isSeparatePaymentActive) {
      paidItems = paidItems.filter((i) =>  i.selectionCount > 0);
    }

    for (const item of paidItems) {
      if (!this.isSeparatePaymentActive || item.quantity === item.selectionCount) {
        const index = findIndex(this.orderedItems, {orderedItemId: item.orderedItemId});
        if (index > -1) {
          this.orderedItems.splice(index, 1);
        }

        this.localStorage.removeOrderedItem(item);
        this.isSendButtonVisible = find(await this.localStorage.getOrderedItemsAsync(), (i) => {
          return i.tableId === this.tableId && i.status === OrderStatus.new;
        }) !== undefined;
      } else {
        item.quantity = item.quantity - item.selectionCount;
        item.selectionCount = 0;
        item.totalPrice = item.quantity * (item.unitPrice + item.extrasPrice);
        this.localStorage.updateOrderedItem(item);
      }
    }

    this.removeCoursesWithNoItems();

    const exists = find(this.orderedItems, {status: OrderStatus.served});
    this.isSeparatePaymentActive = exists !== undefined;
    this.isPaymentButtonVisible = exists !== undefined && (this.isAdmin || this.userSettings?.canWaiterProcessPayment);

    this.subscriptionService.tableRefresh(this.table.id);

    this.modalService.hide("payment-dialog");

    if (result.isTableDeleted) {
      this.subscriptionService.deleteSplittedTable(this.table.id);
    }

    try {
      if(result.shouldPrintReceipt || this.userSettings.printReceiptAlways)
        await this.printInvoice(result.invoice, result.printDetails);
    } catch (e) {}

    if (this.orderedItems.length === 0 && this.table.macAddress && this.userSettings?.selfOrderDigitalQRCode) {
      this.nativeAppService.refreshDisplay(this.table);
    }
  }

  private async printInvoice(invoice, printDetails: boolean): Promise<any> {
    if (!invoice) { return; }

    if ((await this.localStorage.getPrinterSettingsAsync()).length === 0) { return; }

    await this.localizePaymentTypes(invoice);
    await this.nativeAppService.printInvoice(invoice, null, printDetails);
  }

  private async localizePaymentTypes(invoice: InvoiceModel) {
    const paymentTypes = await this.localStorage.getPaymentTypesAsync();

    if (invoice.paymentType == "CombinedPayment") {
      const types = Object.keys(invoice.combinedPaymentTypes);
      types.forEach(type => {
        const paymentType = paymentTypes.find(p => p.name == type);
        if (paymentType) {
          invoice.combinedPaymentTypes[paymentType.label] = invoice.combinedPaymentTypes[type];
          delete invoice.combinedPaymentTypes[type];
        }
      });
    } else {
      const paymentType = paymentTypes.find(p => p.name == invoice.paymentType);
      if (paymentType) invoice.paymentType = paymentType.label;
    }
  }

  private getCourseFromItems() {
    if (this.orderedItems?.length === 0) {
      const course = this.createCourse(1, uuid(), null);
      course.active = true;
      return [course];
    } else {
      const courses = [];
      const courseNames = [];
      const newCourseName = this.translateService.instant("MenuCourse") + " #1";

      this.orderedItems.forEach((item) => {
        if (!item.courseName) { item.courseName = newCourseName; }

        if (item.courseName && courseNames.indexOf(item.courseName) === -1) {
          courseNames.push(item.courseName);
          courses.push(this.createCourse(courses.length + 1, item.courseId, item.courseName));
        }
      });

      return orderBy(courses, "title");
    }
  }

  private async createOrderedItemFromProduct(product: Product): Promise<OrderedItem> {
    let productCategoryName = "";
    const productCategory = find(await this.localStorage.getProductCategoriesAsync(), {productCategoryId: product.productCategoryId});
    if (productCategory) { productCategoryName = productCategory.productCategoryName; }

    const orderedItem = {
      id: uuid(),
      customerId: "",
      orderedItemId: uuid(),
      tableId: this.tableId,
      tableName: this.tableName,
      productId: product.productId,
      productName: product.productName,
      courseId: this.activeCourse.id,
      courseName: this.activeCourse.title,
      categoryName: productCategoryName,
      quantity: 1,
      unitPrice: product.unitPrice,
      totalPrice: product.unitPrice + (product.extrasInfo ? product.extrasInfo.extrasPrice : 0),
      product,
      sideDishes: product.extrasInfo?.sideDishes,
      extrasIds: product.extrasInfo?.extrasId,
      extras: product.extrasInfo?.extras,
      manualExtra: product.extrasInfo?.manualExtra,
      extrasPrice: product.extrasInfo?.extrasPrice,
      selectedPriceCategory: product.extrasInfo?.selectedPriceCategory,
      ingredientsToRemove: product.extrasInfo?.ingredientsToRemove,
      selectedExtra: product.extrasInfo?.selectedExtra,
      orderedVariations: product.extrasInfo?.orderedVariations,
      status: OrderStatus.new,
      orderDate: new Date().toISOString(),
      isActive: true,
      tax: await this.getItemTaxAsync(product),
      taxPrice: 0,
      selectionCount: 0,
      server: "",
      servedBy: "",
      productCategoryId: "",
    } as OrderedItem;

    orderedItem.taxPrice = this.getItemTaxPrice(orderedItem);

    return orderedItem;
  }

  private async orderProduct(product): Promise<any> {
    const orderedItem = await this.createOrderedItemFromProduct(product);
    this.orderedItems.push(orderedItem);
    this.orderedItems.map((item) => item.isActive = false);
    this.courses = this.getCourseFromItems();

    this.localStorage.addOrderedItem(orderedItem);

    this.isSendButtonVisible = true;
    const message = this.translateService.instant("ProductAddedSuccessfully");
    this.messageService.info(message, 1000);
    this.nativeAppService.vibrate();

    this.subscriptionService.tableRefresh(this.tableId);
  }

  private async removeOrderedItem(item: OrderedItem) {
    const index = findIndex(this.orderedItems, {orderedItemId: item.orderedItemId});
    this.orderedItems.splice(index, 1);

    const itemsArs = Object.values(this.courses);
    itemsArs.forEach((course: any) => {
      const itemIndex = findIndex(course.items, {orderedItemId: item.orderedItemId});
      if (itemIndex > -1) {
        course.items.splice(itemIndex, 1);
      }
    });

    this.localStorage.setOrderedItems((await this.localStorage.getOrderedItemsAsync()).filter((i) => i.orderedItemId !== item.orderedItemId));
    this.subscriptionService.tableRefresh(this.tableId);
  }

  private async refreshOrderedItems(): Promise<any> {
    this.table = find(await this.localStorage.getTablesAsync(), {id: this.tableId});
    this.tableName = this.table?.name;
    await this.loadItems();
  }

  private async mergeItems(newItems) {
    const result = [];
    const removedOrderedIds = [];
    newItems.forEach((item) => {
      const existingItem = find(result, (i) => {
        return i.productId === item.productId &&
          i.selectedPriceCategory === item.selectedPriceCategory && i.status === item.status &&
          i.extras === item.extras && i.courseName === item.courseName;
      });

      if (!existingItem) {
        result.push(item);
      } else {
        existingItem.quantity += item.quantity;
        existingItem.totalPrice += item.totalPrice;

        removedOrderedIds.push(item.orderedItemId);
      }
    });

    if (removedOrderedIds.length > 0) {
      const removedMergedItems = reject(await this.localStorage.getOrderedItemsAsync(), (i) => {
        return removedOrderedIds.indexOf(i.orderedItemId) > -1;
      });

      this.localStorage.setOrderedItems(removedMergedItems);
    }

    if (newItems.length > 0) {
      this.orderedItems = (await this.localStorage.getOrderedItemsAsync()).filter((i) => i.tableId === newItems[0].tableId);
      this.courses = this.getCourseFromItems();
    }

    return result;
  }

  private getItemsForPrint(items: any) {
    const res = [];

    items.forEach(async (item) => {
      res.push({
        orderedItemId: item.orderedItemId,
        quantity: this.isSeparatePaymentActive ? item.selectionCount : item.quantity,
        priceCategories: item.priceCategories || item.product.priceCategories,
        selectedPriceCategory: item.selectedPriceCategory,
        extras: item.extras,
        extrasPrice: item.extrasPrice,
        unitPrice: this.getUnitPrice(item),
        productName: item.productName || item.product.productName,
        productCategoryId: item.productCategoryId || item.product.productCategoryId,
        tax: await this.getItemTaxAsync(item.product),
        unitPrices: item.unitPrices || item.product.unitPrices,
        courseName: this.allowCourses ? item.courseName : "",
      });
    });

    return res;
  }

  private async updateOrderedItems(orderedItems: OrderedItem[]): Promise<any> {
    let allItems: OrderedItem[] = (await this.localStorage.getOrderedItemsAsync()).filter((item: OrderedItem) => {
      return !find(orderedItems, (i) => {
        return i.orderedItemId === item.orderedItemId;
      });
    });

    allItems = allItems.concat(orderedItems);
    this.localStorage.setOrderedItems(allItems);

    this.orderedItems = filter(allItems, {tableId: this.tableId});
    this.courses = this.getCourseFromItems();
  }

  private async printNewItems(itemsToPrint: any[]): Promise<any> {
    if (this.userSettings?.printOrderedItemAlways === false) {
      return;
    }

    this.nativeAppService.printOrderedItems(itemsToPrint, this.tableName, await this.localStorage.getPrinterSettingsAsync());
  }

  private updateUI() {
    this.isSendButtonVisible = find(this.orderedItems, {status: OrderStatus.new}) !== undefined;
    this.subscriptionService.tableRefresh(this.tableId);
    this.loading = false;

    const exists = this.orderedItems.filter((i) => i.status === OrderStatus.served);
    this.isPaymentButtonVisible = exists.length > 0 && (this.isAdmin || this.userSettings?.canWaiterProcessPayment);
  }

  private signalRItemReturned(tableId: string) {
    if (this.tableId === tableId) {
      this.doLoadItems();
    }

    this.subscriptionService.tableRefresh(tableId);
  }

  private async refreshUI(): Promise<any> {
    const newItems = filter(await this.localStorage.getOrderedItemsAsync(), (item) => {
      return item.tableId === this.tableId && item.status === OrderStatus.new;
    });

    this.isSendButtonVisible = newItems.length > 0;

    this.orderedItems = filter(await this.localStorage.getOrderedItemsAsync(), {tableId: this.tableId});

    this.courses = this.getCourseFromItems();

    if (!this.activeCourse) {
      this.setActiveCourse(this.courses[0]);
    }

    let exists = find(this.orderedItems, {status: OrderStatus.ready});
    this.isServeButtonVisible = exists !== undefined;

    exists = find(this.orderedItems, {status: OrderStatus.served});
    this.isPaymentButtonVisible = exists !== undefined && (this.isAdmin || this.userSettings?.canWaiterProcessPayment);

    this.totalQuantity = this.getTotalQuantity();
    this.subTotal = this.getSubTotal();
  }
}
