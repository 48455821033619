import { DOCUMENT } from "@angular/common";
import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { find } from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import { v4 as uuid } from "uuid";
import { NotificationTypes, Table } from "./models";
import {AuthenticationService} from "./services/auth.service";
import { LocalStorageService } from "./services/localstorage.service";
import {ModalService} from "./services/modal.service";
import { SignalRService } from "./services/signalr.service";
import { SubscriptionService } from "./services/subscription.service";
import {ThirdPartyService} from "./services/third.party.service";
import { Router } from "@angular/router";
import {OneSignal} from "onesignal-ngx";
import {CookieService} from "./services/cookie.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  public title = "app";
  @ViewChild(ModalDirective)
  public modal: ModalDirective;
  public isSelfOrder: boolean = false;

  constructor(
    public translate: TranslateService,
    private thirdPartyService: ThirdPartyService,
    private authService: AuthenticationService,
    private metaTagService: Meta,
    private modalService: ModalService,
    private signalRService: SignalRService,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private router: Router,
    private oneSignal: OneSignal,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.localStorage.getUserAsync().then((user) => {
      if (user) {
        document.body.classList.add("logged-in");
      }
    });

    this.document.documentElement.lang = this.translateService.currentLang;

    router.events.subscribe(() => {
      this.modalService.hideAll();
    });

    var appId = window.localStorage.getItem("onesignal-app-id");
    if (appId) {
      this.oneSignal.init({
        appId: appId,
        notifyButton: {
          enable: true
        },
        serviceWorkerParam: {
          scope: '/onesignal'
        },
        serviceWorkerPath: '/'
      }).then(() => {
        const externalId = window.localStorage.getItem("web-push-id");
        console.log("externalId = ", externalId);
        if (externalId) {
          this.oneSignal.setExternalUserId(externalId).then(() => console.log("set external id successfully"));
        }
      });
    }
  }

  public async ngOnInit() {
    this.isSelfOrder = localStorage.getItem("is-self-order") == "true";
    //await this.thirdPartyService.initialize();

    const description = await this.translate.get("MetaDeta_Description").toPromise();
    this.metaTagService.addTags([
      {name: "keywords", content: "orderstracker,order,tracker,POS, Point of Sale, Kitchen Display System, KDS, Küche Monitor,Kassensystem, Bestellsystem,waiter,kellner"},
      {name: "description", content: description},
      {name: "robots", content: "index, follow"},
      {charset: "UTF-8"},
    ]);

    //this.modalService.show("cookie-law-banner-dialog", {});

    this.listenToSignalRMessages();

    this.subscriptionService.authEvent.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.listenToSignalRMessages();
      }
    });
  }

  private listenToSignalRMessages() {
    if (this.signalRService.hubConnection) {
      this.signalRService.billRequested.subscribe(async (args) => {
        const table: Table = find(await this.localStorage.getTablesAsync(), {id: args.tableId});
        if (table) {
          const notification = {
            notificationId: args.notificationId,
            tableId: args.tableId,
            id: uuid(),
            title: this.translateService.instant("SelfOrderBillRequested"),
            description: table.name,
            date: new Date(),
            icon: "fas fa-receipt",
            type: NotificationTypes.BillRequested,
          };
          this.localStorage.insertNotification(notification);
          this.subscriptionService.refreshNotifications();
        }
      });

      this.signalRService.waiterCalled.subscribe(async (args) => {
        const table: Table = find(await this.localStorage.getTablesAsync(), {id: args.tableId});
        if (table) {
          const notification = {
            notificationId: args.notificationId,
            tableId: args.tableId,
            id: uuid(),
            title: this.translateService.instant("SelfOrderWaiterCalled"),
            description: table.name,
            date: new Date(),
            icon: "far fa-hand-paper",
            type: NotificationTypes.WaiterCalled,
          };
          this.localStorage.insertNotification(notification);
          this.subscriptionService.refreshNotifications();
        }
      });

      this.signalRService.ordersReady.subscribe(async (result) => {
        const table: Table = find(await this.localStorage.getTablesAsync(), {id: result.tableId});
        if (table) {
          const notification = {
            notificationId: result.notificationId,
            tableId: result.tableId,
            id: uuid(),
            title: this.translateService.instant("OrdersReadyForTable"),
            description: table.name,
            date: new Date(),
            icon: "far fa-check-circle",
            type: NotificationTypes.OrdersReady,
          };
          this.localStorage.removeTableNotifications(result.tableId, NotificationTypes.OrdersReady);
          this.localStorage.insertNotification(notification);
          this.subscriptionService.refreshNotifications();
        }
      });
    }
  }
}
