import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../interfaces/ModalClassComponent";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  public hideCancelButton = false;
  public confirmButtonTitle: string;

  public dialogModel: {
    icon: string;
    type: string;
    title: string
    message: string;
    confirmed: any;
    denied: any;
  } = {
    icon: "question",
    title: "",
    message: "",
    type: "info",
    confirmed() {},
    denied() {},
  };

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit() {
    this.modalService.register("confirm-dialog", this);
    this.translateService.get("ConfirmTitle").subscribe(res => {
      this.dialogModel.title = res;
    });
  }

  public hide() {
    super.hide();
    this.modal.hide();

    if (this.dialogModel.denied) {
      this.dialogModel.denied();
    }
  }

  public show(dialogModel: any) {
    super.show(dialogModel);

    if (dialogModel.title) {
      this.dialogModel.title = dialogModel.title;
    }
    if (dialogModel.type) {
      this.dialogModel.type = dialogModel.type;
    }
    if (dialogModel.icon) {
      this.dialogModel.icon = dialogModel.icon;
    }

    this.dialogModel.message = dialogModel.message;
    this.dialogModel.confirmed = dialogModel.confirmed;
    this.dialogModel.denied = dialogModel.denied;
    this.hideCancelButton = dialogModel.hideCancelButton;
    this.confirmButtonTitle = dialogModel.confirmButtonTitle || "Yes";

    this.modal.show();
  }

  public confirmed() {
    this.hide();
    this.dialogModel.confirmed();
  }
}
