import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {find} from "lodash";
import {v4 as uuid} from "uuid";
import {TableCategory} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {TableService} from "../../../services/table.service";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit {

  public tableCategories: TableCategory[] = [];
  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private localStorage: LocalStorageService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) { }

  public async ngOnInit() {
    this.tableCategories = (await this.localStorage.getTableCategoriesAsync()).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }));

    const tablesWoCats = find(this.localStorage.getTablesAsync(), { categoryId: null });
    if (tablesWoCats) {
      this.tableCategories.unshift({ id: null, name: this.translateService.instant("TablesWithoutCategories") });
    }

    this.messageService.showLoading(await this.translateService.get("LoadingTables").toPromise());
    this.tableService.getAllTablesAndCategories().subscribe((result) => {
      result.tableCategories = result.tableCategories.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }));

      this.messageService.hideLoading();
      this.localStorage.setTableCategories(result.tableCategories);
      this.localStorage.setTables(result.tables);

      const tablesWoCategories = find(this.localStorage.getTablesAsync(), { categoryId: null });
      if (tablesWoCategories) {
        result.tableCategories.unshift({ id: null, name: this.translateService.instant("TablesWithoutCategories") });
      }
      this.tableCategories = result.tableCategories;
    }, () => {
      this.messageService.hideLoading();
    });
  }

  public showHelp() {
    this.modalService.show("youtube-dialog", { embed: "yYG8UlwmMBI" });
  }

  public addCategory(name) {
    const category = {
      id: uuid(),
      name,
    };

    this.tableService.addCategory(category).subscribe(async () => {
        const allCats = await this.localStorage.getTableCategoriesAsync();
        allCats.push(category);
        this.localStorage.setTableCategories(allCats);
    });
  }

  public showAddCategoryDialog() {
    this.modalService.show("create-table-category-dialog", {
      addCategory: (name) => {
        this.addCategory(name);
      },
    });
  }
}
