import { InjectionToken } from "@angular/core";
import * as _dayjs from "dayjs";
const localeData = require("dayjs/plugin/localeData");
_dayjs.extend(localeData);
// @ts-ignore
const dayjsLocaleData = _dayjs.localeData();

export const LOCALE_CONFIG = new InjectionToken<LocaleConfig>("daterangepicker.config");
/**
 *  LocaleConfig Interface
 */
export interface LocaleConfig {
    direction?: string;
    separator?: string;
    weekLabel?: string;
    applyLabel?: string;
    cancelLabel?: string;
    clearLabel?: string;
    customRangeLabel?: string;
    daysOfWeek?: string[];
    monthNames?: string[];
    firstDay?: number;
    format?: string;
    displayFormat?: string;
}

// @ts-ignore
const firstDay = _dayjs().localeData().firstDayOfWeek();
/**
 *  DefaultLocaleConfig
 */
export const DefaultLocaleConfig: LocaleConfig = {
    direction: "ltr",
    separator: " - ",
    weekLabel: "W",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    clearLabel: "Clear",
    customRangeLabel: "Custom range",
    daysOfWeek: dayjsLocaleData.weekdaysMin(),
    monthNames: dayjsLocaleData.monthsShort(),
    firstDay,
};
