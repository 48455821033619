import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({selector: "[long-press]"})
export class LongPressDirective {

  @Input() public duration = 1000;

  @Output() public onLongPress: EventEmitter<any> = new EventEmitter();
  @Output() public onLongPressing: EventEmitter<any> = new EventEmitter();
  @Output() public onLongPressEnd: EventEmitter<any> = new EventEmitter();

  private pressing: boolean;
  private longPressing: boolean;
  private timeout: any;
  private mouseX = 0;
  private mouseY = 0;

  @HostBinding("class.press")
  get press() {
    return this.pressing;
  }

  @HostBinding("class.longpress")
  get longPress() {
    return this.longPressing;
  }

  @HostListener("touchstart", ["$event"])
  @HostListener("mousedown", ["$event"])
  public onMouseDown(event) {
    if (event.which !== 1 && event.which !== 0) {
      return;
    }

    this.mouseX = event.clientX || event.pageX;
    this.mouseY = event.clientY || event.pageY;
    this.pressing = true;
    this.longPressing = false;

    this.timeout = setTimeout(() => {
      this.longPressing = true;
      this.onLongPress.emit(event);
      this.loop(event);
    }, this.duration);

    this.loop(event);
  }

  @HostListener("touchmove", ["$event"])
  @HostListener("mousemove", ["$event"])
  public onMouseMove(event) {
    if (this.pressing && !this.longPressing) {
      const posX = event.clientX || event.pageX;
      const posY = event.clientY || event.pageY;
      const xThres = (posX - this.mouseX) > 5;
      const yThres = (posY - this.mouseY) > 5;
      if (xThres || yThres) {
        this.endPress();
      }
    }
  }

  public loop(event) {
    if (this.longPressing) {
      this.timeout = setTimeout(() => {
        this.onLongPressing.emit(event);
        this.loop(event);
      }, 50);
    }
  }

  public endPress() {
    clearTimeout(this.timeout);
    this.longPressing = false;
    this.pressing = false;
    this.onLongPressEnd.emit(true);
  }

  @HostListener("touchend")
  @HostListener("mouseleave")
  @HostListener("mouseup")
  public onMouseUp() {
    this.endPress();
  }

}
