<mdb-card class="m-2">
  <mdb-card-body style="text-align: center;">
    <mdb-card-title>
      <h4>{{category.name}}</h4>
    </mdb-card-title>

    <mdb-card-text>
      {{categoryTables.length}} {{"TablesCount" | translate}}
    </mdb-card-text>
  </mdb-card-body>
  <mdb-card-footer class="d-flex justify-content-between pl-0 pr-0">
    <button mdbBtn color="info" outline="true" rounded="true" mdbWavesEffect class="btn-cat-action" style="background-color:white !important;" (click)="showTables()">{{"Tables" | translate}}</button>

    <div class="dropdown" mdbDropdown *ngIf="category.id">
      <a href="javascript:void(0);" mdbDropdownToggle type="button" class="btn dropdown-toggle waves-light btn-cat-action"
              mdbWavesEffect>
        {{"Actions" | translate}}
      </a>

      <div class="dropdown-menu">
        <a class="dropdown-item" href="javascript:void(0);" (click)="showEditCategory()">
          <i class="fas fa-pencil-alt mr-1" style="font-size: 18px;"></i>
          <span>{{"EditCategory" | translate}}</span>
        </a>
        <a class="dropdown-item danger-text" href="javascript:void(0);" (click)="showDeleteCategoryConfirmation()">
          <i class="far fa-trash-alt mr-1" style="font-size: 18px;"></i>
          <span>{{"DeleteCategory" | translate}}</span>
        </a>
      </div>
    </div>

  </mdb-card-footer>
</mdb-card>
