import {animate, state, style, transition, trigger} from "@angular/animations";
import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {filter, find, orderBy} from "lodash";
import {Subscription} from "rxjs";
import {Product} from "../../../models";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-self-order-categories",
  templateUrl: "./self-order-categories.component.html",
  styleUrls: ["./self-order-categories.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      state("in", style({height: "*"})),
      transition("* => void", [
        style({height: "*"}),
        animate(250, style({height: 0})),
      ]),
      transition("void => *", [
        style({height: "0"}),
        animate(250, style({height: "*"})),
      ]),
    ]),
  ],
})
export class SelfOrderCategoriesComponent implements OnInit, OnDestroy {
  public _categories: any;
  @Input() set categories(value: any) {
    this._categories = value;
    this.updateCategories();
    this.visibleCategories = this.categories.filter(c => !c.excludeFromSelfOrdering);
    this.visibleCategories = orderBy(this.visibleCategories, "sortingId");
  }

  get categories(): any {
    return this._categories;
  }

  public _products: Product[] = [];
  @Input() set products(value: Product[]) {
    this._products = value;

    this.categories.forEach((cat) => {
      cat.products = this._products.filter((p) => p.productCategoryId === cat.productCategoryId);
    });
  }

  get products(): Product[] {
    return this._products;
  }

  @Input()
  readOnly: boolean;

  public visibleCategories: any;
  public searchText: String = "";
  public foundProductsLength: number;
  public categoryPicture: string;
  public selfOrderMenuEvent: Subscription;
  public searchStartedSubscription: Subscription;

  constructor(
    private subscriptionService: SubscriptionService,
  ) {
  }

  public ngOnInit(): void {
    this.categories.forEach(async (cat) => {
      let catPictures = JSON.parse(localStorage.getItem("category-pictures"));
      let productPictures = JSON.parse(localStorage.getItem("product-pictures"));

      let catPictureObj = find(catPictures, {categoryId: cat.productCategoryId, dimensions: "original"});
      if(catPictureObj) {
        let thumbnailImage = find(productPictures, {originalFileId: catPictureObj.id, dimensions: "450"});
        if(!thumbnailImage) thumbnailImage = find(productPictures, {originalFileId: catPictureObj.id, dimensions: "300"});
        if(!thumbnailImage) thumbnailImage = find(productPictures, {originalFileId: catPictureObj.id, dimensions: "thumbnail"});
        if(thumbnailImage) {
          catPictureObj = thumbnailImage;
        } else {
          thumbnailImage = catPictures.find(p =>  p.originalFileId == catPictureObj.id && p.dimensions == "1024");
          if(!thumbnailImage) thumbnailImage = catPictures.find(p =>  p.originalFileId == catPictureObj.id && p.dimensions == "450");
          if(!thumbnailImage) thumbnailImage = catPictures.find(p =>  p.originalFileId == catPictureObj.id && p.dimensions == "300");
          if(!thumbnailImage) thumbnailImage = catPictures.find(p =>  p.originalFileId == catPictureObj.id && p.dimensions == "thumbnail");
          if(thumbnailImage) {
            catPictureObj = thumbnailImage;
          }
        }
      }

      if (catPictureObj) {
        cat.noCategoryImage = false;
        cat.categoryPicture = "url(" + catPictureObj.url + ")";
      } else {
        cat.noCategoryImage = true;
        cat.categoryPicture = "url('/assets/images/product-cats.jpg')";
      }

      cat.showProducts = false;
      cat.products = this.products.filter((p) => p.productCategoryId === cat.productCategoryId);
    });
    this.foundProductsLength = this.visibleCategories.reduce((accumulator, cat) => accumulator + cat.products.length, 0);

    this.searchStartedSubscription = this.subscriptionService.searchStartedEvent.subscribe((searchText) => {
      this.searchText = searchText;
      if (!searchText) {
        this.categories.forEach((cat) => {
          cat.products = this.products.filter((p) => p.productCategoryId === cat.productCategoryId);
          cat.hidden = cat.products.length == 0;
          cat.showProducts = true;
        });
      } else {
        this.searchCategories(searchText);
      }
      this.visibleCategories = this.categories.filter(c => !c.hidden);
    });
  }

  public ngOnDestroy() {
    this.searchStartedSubscription.unsubscribe();
  }

  public showItems(category: any) {
    category.showProducts = !category.showProducts;
  }

  private searchCategories(searchText: string) {
    this.categories.forEach((cat) => {
      const catProducts = this.products.filter((p) => p.productCategoryId === cat.productCategoryId);
      // @ts-ignore
      const textLower = searchText.toLowerCase().replaceSpecialChars();
      const categoryName = cat.productCategoryName.toLowerCase().replaceSpecialChars();
      if (categoryName.indexOf(textLower) > -1) {
        cat.products = catProducts;
        cat.hidden = cat.products.length === 0;
        cat.showProducts = cat.products.length > 0
      } else {
        cat.products = this.searchProducts(catProducts, textLower);
        cat.hidden = cat.products.length === 0;
        cat.showProducts = cat.products.length > 0
      }
    });

    this.visibleCategories = this.categories.filter(c => !c.hidden);
    this.foundProductsLength = this.visibleCategories.reduce((accumulator, cat) => accumulator + cat.products.length, 0);
  }

  private searchProducts(products: Product[], searchText: string) {
    if (searchText) {
      const categoryProducts = filter(products, {pluCode: searchText});
      if (categoryProducts?.length > 0) {
        return categoryProducts;
      } else {
        const result: Product[] = [];
        products.forEach((product) => {
          // @ts-ignore
          const productName = product.productName?.toLowerCase().replaceSpecialChars();
          // @ts-ignore
          const productDescription = product.productDescription?.toLowerCase().replaceSpecialChars();
          const found = productName?.indexOf(searchText) > -1 ||
            productDescription?.indexOf(searchText) > -1 ||
            product.barcode?.toLowerCase().indexOf(searchText) > -1;

          if (found) {
            result.push(product);
          }
        });

        return result;
      }
    }
  }

  private updateCategories() {
    this.categories.forEach(async (cat) => {
      let catPictures = JSON.parse(localStorage.getItem("category-pictures"));
      const catPictureObj = find(catPictures, {categoryId: cat.productCategoryId, dimensions: "original"});

      if (catPictureObj) {
        cat.categoryPicture = "url(" + catPictureObj.url + ")";
      } else {
        cat.categoryPicture = "url(/images/product-cats.png)";
      }

      cat.showProducts = false;
    });
  }
}
