<div class="panel panel-default card order-view"
     [class.highlight]="orders.isNew">
  <div class="panel-heading" style="padding: 0; background:#71c175;"
       [class.order-status-orange]="orderStatus == 'orange'"
       [class.order-status-red]="orderStatus == 'red'">
    <table style="width: 100%">
      <tr>
        <td class="order-count">{{orders.orderedItems.length}}x</td>
        <td style="text-align: center;">
          <span class="table-name">{{table.name}}</span><br />
          <time class="order-date">{{timeAgo}}</time>
        </td>
        <td style="width: 95px; padding: 5px; white-space: nowrap;">
          <a class="btn-floating btn-white" href="javascript:void(0)" (click)="approveAllOrders()" [attr.title]="'OrdersReady_Tooltip' | translate">
            <i class="fas fa-check text-primary"></i>
          </a>
          <a class="btn-floating btn-danger" href="javascript:void(0)" (click)="denyAllOrders()" [attr.title]="'OrdersReady_Tooltip' | translate">
            <i class="fas fa-times"></i>
          </a>
          <a class="btn-floating btn-white" href="javascript:void(0)" (click)="sendAllFinishedItems()" [attr.title]="'SendFinishedItems_Tooltip' | translate">
            <i class="fas fa-paper-plane text-primary"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>

  <div class="panel-body" style="padding: 0; overflow: auto;">
    <div class="accordion md-accordion course-list" id="accordion-gangs" role="tablist" aria-multiselectable="true">
      <!-- ko foreach: Courses -->
      <div class="card">
        <div class="collapse show" role="tabpanel">
          <div class="card-body p-0">
            <div class="pos-orders-list">
              <div class="orders-list">
                <app-self-order-approve-single-ordered-item *ngFor="let item of orders.orderedItems" [item]="item"></app-self-order-approve-single-ordered-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /ko -->
    </div>
  </div>
</div>
