<div class="row printer-settings-container">
  <div class="d-flex justify-content-between w-100 action-toolbar">
    <a href="javascript:void(0);" class="btn btn-outline-danger btn-rounded background-white toolbar-item"
      (click)="deleteSettings()">
      <i class="far fa-trash-alt"></i>
      <span>{{'DeleteSettings' | translate}}</span>
    </a>
    <a href="javascript:void(0);" class="btn btn-outline-info btn-rounded background-white toolbar-item"
      (click)="testConnection()">
      <i class="fa fa-print"></i>
      <span>{{'TestSettings' | translate}}</span>
    </a>
  </div>
  <div class="row p-3">
    <div class="col-md-6 left-part">
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'PrinterName_Description' | translate" triggers="focus"
          containerClass="info-popover"></i>
        <div class="md-form flex-grow-1 mb-0">
          <input mdbInput type="text" id="printerName-{{settings.id}}" class="form-control printer-setting-field"
            required="required" [attr.placeholder]="'PrinterName' | translate" [(ngModel)]="settings.printerName" />

          <label class="printer-settings-label" for="printerName-{{settings.id}}">{{"PrinterName" | translate}}</label>
        </div>
      </div>
      <div class="form-group form-inline printer-category-container" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'Categories_Description' | translate" triggers="focus"
          containerClass="info-popover"></i>
        <div class="md-form mb-0 select-container">
          <label class="printer-settings-label active">
            {{"Categories" | translate}}
          </label>
          <mdb-select-2 name="category-select"
                        [(ngModel)]="selectedCategories"
                        [highlightFirst]="false"
                        [multiple]="true"
                        [placeholder]="'ChooseCategoryToFilter' | translate">
            <mdb-select-all-option>{{"SelectAll" | translate}}</mdb-select-all-option>
            <mdb-select-option *ngFor="let option of productCategories" [value]="option.value"
              (selectionChange)="setSelectedCategories($event)">
              {{ option.label }}
            </mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
      <div class="form-group form-inline" style="margin-bottom:3px;margin-top:15px;justify-content: space-between;">
        <label class="printer-settings-label" style="width: auto !important;">
          <i class="info-icon fa fa-info-circle" [mdbPopover]="'ForCacheRegister_Description' | translate"
            triggers="focus" containerClass="info-popover"></i>
          <span>{{"ForCacheRegister" | translate}}</span>
        </label>

        <div class="switch round blue-white-switch">
          <label>
            <input type="checkbox" [(ngModel)]="settings.cahceRegister" />
            <span class="lever"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-6 right-part">
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'IpAddress_Description' | translate" triggers="focus"
          placement="left" containerClass="info-popover"></i>
        <div class="md-form mb-0 flex-grow-1">
          <input mdbInput type="text" class="form-control printer-setting-field" required="required"
            id="ipAddress-{{settings.id}}" [(ngModel)]="settings.ipAddress" placeholder="192.168..." />
          <label class="printer-settings-label" for="ipAddress-{{settings.id}}">{{"IpAddress" | translate}}</label>
        </div>
      </div>
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'Port_Description' | translate" triggers="focus"
          containerClass="info-popover"></i>
        <div class="md-form mb-0 flex-grow-1">
          <input mdbInput type="number" class="form-control printer-setting-field" required="required"
            id="port-{{settings.id}}" [(ngModel)]="settings.port" placeholder="9100" />
          <label class="printer-settings-label" for="port-{{settings.id}}">{{"Port" | translate}}</label>
        </div>
      </div>
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'PaperWidth_Description' | translate" triggers="focus"
          containerClass="info-popover"></i>
        <div class="md-form mb-0 select-container flex-grow-1">
          <label class="printer-settings-label">{{"PaperWidth" | translate}}</label>
          <mdb-select [options]="printerTypes" [highlightFirst]="false" [placeholder]="'ChoosePaperWidth' | translate"
            [(ngModel)]="settings.columnCounts"></mdb-select>
        </div>
      </div>
      <div class="form-group form-inline" style="margin-bottom:3px;">
        <i class="info-icon fa fa-info-circle" [mdbPopover]="'PrinterCoding_Description' | translate" triggers="focus"
          containerClass="info-popover"></i>
        <div class="md-form mb-0 select-container flex-grow-1">
          <label class="printer-settings-label">{{"PrinterCoding" | translate}}</label>
          <mdb-select [options]="codePages" [highlightFirst]="false" [placeholder]="'ChoosePrinterCoding' | translate"
            [(ngModel)]="settings.coding"></mdb-select>
        </div>
      </div>
    </div>
  </div>
</div>
