import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ButtonsModule, DropdownModule, IconsModule, InputsModule, LocaleService, MdbSelectModule, ModalModule, PopoverModule, SelectModule, TableModule, TabsModule, WavesModule, StepperModule, MDB_DATE_OPTIONS, DatepickerModule} from "ng-uikit-pro-standard";
import {ColorCircleModule} from "ngx-color/circle";
import {ColorCompactModule} from "ngx-color/compact";
import {DropzoneModule} from "ngx-dropzone-wrapper";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../../../environments/environment";
import {AppCommonModule} from "../common/common.module";
import {CommonSettingsComponent} from "./common-settings/common-settings.component";
import {OTReceiptsComponent} from "./ot-receipts/ot-receipts.component";
import {PackageSettingsComponent} from "./package-settings/package-settings.component";
import {PaymentSettingsComponent} from "./payment-settings/payment-settings.component";
import {BluetoothPrinterSettingsComponent} from "./printer-settings/bluetooth-printer-settings/bluetooth-printer-settings.component";
import {PrinterSettingsComponent} from "./printer-settings/printer-settings.component";
import {WlanPrinterSettingsComponent} from "./printer-settings/wlan-printer-settings/wlan-printer-settings.component";
import {ProfileComponent} from "./profile/profile.component";
import {ReceiptSettingsComponent} from "./receipt-settings/receipt-settings.component";
import {SettingsRoutingModule} from "./settings-routing.module";
import { PaymentSettingsDialogComponent } from "./payment-settings-dialog/payment-settings-dialog.component";
import { PaymentTypesDialogComponent } from "./payment-types-dialog/payment-types-dialog.component";
import { LocalizationService } from "../../services/localization.service";
import { NgxDaterangepickerMd } from "../daterangepicker";
import { TSESettingsComponent } from "./tse-settings/tse-settings.component";

@NgModule({
  declarations: [
    ProfileComponent,
    ReceiptSettingsComponent,
    PackageSettingsComponent,
    PaymentSettingsComponent,
    CommonSettingsComponent,
    PrinterSettingsComponent,
    WlanPrinterSettingsComponent,
    BluetoothPrinterSettingsComponent,
    OTReceiptsComponent,
    PaymentSettingsDialogComponent,
    PaymentTypesDialogComponent,
    TSESettingsComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SettingsRoutingModule,
    ButtonsModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    DatepickerModule,
    WavesModule,
    SelectModule,
    DropdownModule.forRoot(),
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    DropzoneModule,
    FormsModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    ColorCircleModule,
    ColorCompactModule,
    IconsModule,
    TableModule,
    MdbSelectModule,
    StepperModule
  ],
  providers: [
    LocalizationService,
    { provide: MDB_DATE_OPTIONS, useValue: { showTodayBtn: true, locale: "de" } },
  ],
})
export class SettingsModule {
  constructor() {
  }
}
