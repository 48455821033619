import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    KeyValueDiffers,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewContainerRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import * as dayjs from "dayjs";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DaterangePickerLocaleService } from "./daterangepicker-locale.service";
import { DaterangepickerComponent } from "./daterangepicker.component";
import { LocaleConfig } from "./daterangepicker.config";

@Directive({
    selector: "input[ngxDaterangepickerMd]",
    host: {
        "(keyup.esc)": "hide()",
        "(blur)": "onBlur()",
        "(click)": "open()",
        "(keyup)": "inputChanged($event)",
        "autocomplete": "off",
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DaterangepickerDirective),
            multi: true,
        },
    ],
})
export class DaterangepickerDirective implements OnInit, OnChanges, OnDestroy {
    @Input() set locale(value) {
        this._locale = { ...this._localeService.config, ...value };
    }
    get locale(): any {
        return this._locale;
    }
    @Input() set startKey(value) {
        if (value !== null) {
            this._startKey = value;
        } else {
            this._startKey = "startDate";
        }
    }
    @Input() set endKey(value) {
        if (value !== null) {
            this._endKey = value;
        } else {
            this._endKey = "endDate";
        }
    }

    get value() {
        return this._value || null;
    }
    set value(val) {
        this._value = val;
        this._onChange(val);
        this._changeDetectorRef.markForCheck();
    }

    @Input()
    public minDate: dayjs.Dayjs;
    @Input()
    public maxDate: dayjs.Dayjs;
    @Input()
    public autoApply: boolean;
    @Input()
    public alwaysShowCalendars: boolean;
    @Input()
    public showCustomRangeLabel: boolean;
    @Input()
    public linkedCalendars: boolean;
    @Input()
    public dateLimit: number = null;
    @Input()
    public singleDatePicker: boolean;
    @Input()
    public showWeekNumbers: boolean;
    @Input()
    public showISOWeekNumbers: boolean;
    @Input()
    public showDropdowns: boolean;
    @Input()
    public showClearButton: boolean;
    @Input()
    public customRangeDirection: boolean;
    @Input()
    public ranges = {};
    @Input()
    public opens: "left" | "center" | "right" = "center";
    @Input()
    public drops: "up" | "down" = "down";
    public firstMonthDayClass: string;
    @Input()
    public lastMonthDayClass: string;
    @Input()
    public emptyWeekRowClass: string;
    @Input()
    public firstDayOfNextMonthClass: string;
    @Input()
    public lastDayOfPreviousMonthClass: string;
    @Input()
    public keepCalendarOpeningWithRange: boolean;
    @Input()
    public showRangeLabelOnInput: boolean;
    @Input()
    public showCancel = false;
    @Input()
    public lockStartDate = false;
    @Input()
    public timePicker = false;
    @Input()
    public timePicker24Hour = false;
    @Input()
    public timePickerIncrement = 1;
    @Input()
    public timePickerSeconds = false;
    @Input() public closeOnAutoApply = true;
    public _locale: LocaleConfig = {};
    public notForChangesProperty: string[] = ["locale", "endKey", "startKey"];

    @Output("change") public onChange: EventEmitter<{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }> = new EventEmitter();
    @Output("rangeClicked") public rangeClicked: EventEmitter<{ label: string; dates: [dayjs.Dayjs, dayjs.Dayjs] }> = new EventEmitter();
    @Output("datesUpdated") public datesUpdated: EventEmitter<{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }> = new EventEmitter();
    @Output() public startDateChanged: EventEmitter<{ startDate: dayjs.Dayjs }> = new EventEmitter();
    @Output() public endDateChanged: EventEmitter<{ endDate: dayjs.Dayjs }> = new EventEmitter();

    public destroy$ = new Subject();
    private _onChange = Function.prototype;
    private _onTouched = Function.prototype;
    private _validatorChange = Function.prototype;
    private _value: any;
    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<DaterangepickerComponent>;
    @Input()
    private _endKey = "endDate";
    private _startKey = "startDate";

    constructor(
        public viewContainerRef: ViewContainerRef,
        public _changeDetectorRef: ChangeDetectorRef,
        private _el: ElementRef,
        private differs: KeyValueDiffers,
        private _localeService: DaterangePickerLocaleService,
        private elementRef: ElementRef,
        private overlay: Overlay,
    ) {}
    @Input()
    public isInvalidDate = (date: dayjs.Dayjs) => false
    @Input()
    public isCustomDate = (date: dayjs.Dayjs) => false
    @Input()
    public isTooltipDate = (date: dayjs.Dayjs) => null

    public ngOnInit(): void {
        this._buildLocale();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const change in changes) {
            if (changes.hasOwnProperty(change)) {
                if (this.componentRef && this.notForChangesProperty.indexOf(change) === -1) {
                    this.componentRef[change] = changes[change].currentValue;
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    public onBlur(): void {
        this._onTouched();
    }

    public open(): void {
        if (this.overlayRef) {
            this.hide();
        }

        let originX, overlayX;
        switch (this.opens) {
            case "left":
                originX = "start";
                overlayX = "end";
                break;
            case "center":
                originX = "center";
                overlayX = "center";
                break;
            case "right":
                originX = "end";
                overlayX = "start";
                break;
        }

        // TO-DO: implement this.drops and this.opens!
        this.overlayRef = this.overlay.create({
            backdropClass: "cdk-overlay-transparent-backdrop",
            hasBackdrop: true,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically(),
        });
        const dateRangePickerPortal = new ComponentPortal(DaterangepickerComponent);
        this.componentRef = this.overlayRef.attach(dateRangePickerPortal);

        // Assign all inputs
        this.componentRef.instance.minDate = this.minDate;
        this.componentRef.instance.maxDate = this.maxDate;
        this.componentRef.instance.autoApply = this.autoApply;
        this.componentRef.instance.alwaysShowCalendars = this.alwaysShowCalendars;
        this.componentRef.instance.showCustomRangeLabel = this.showCustomRangeLabel;
        this.componentRef.instance.linkedCalendars = this.linkedCalendars;
        this.componentRef.instance.dateLimit = this.dateLimit;
        this.componentRef.instance.singleDatePicker = this.singleDatePicker;
        this.componentRef.instance.showWeekNumbers = this.showWeekNumbers;
        this.componentRef.instance.showISOWeekNumbers = this.showISOWeekNumbers;
        this.componentRef.instance.showDropdowns = this.showDropdowns;
        this.componentRef.instance.showClearButton = this.showClearButton;
        this.componentRef.instance.customRangeDirection = this.customRangeDirection;
        this.componentRef.instance.ranges = this.ranges;
        this.componentRef.instance.firstMonthDayClass = this.firstMonthDayClass;
        this.componentRef.instance.lastMonthDayClass = this.lastMonthDayClass;
        this.componentRef.instance.emptyWeekRowClass = this.emptyWeekRowClass;
        this.componentRef.instance.firstDayOfNextMonthClass = this.firstDayOfNextMonthClass;
        this.componentRef.instance.lastDayOfPreviousMonthClass = this.lastDayOfPreviousMonthClass;
        this.componentRef.instance.keepCalendarOpeningWithRange = this.keepCalendarOpeningWithRange;
        this.componentRef.instance.showRangeLabelOnInput = this.showRangeLabelOnInput;
        this.componentRef.instance.showCancel = this.showCancel;
        this.componentRef.instance.lockStartDate = this.lockStartDate;
        this.componentRef.instance.timePicker = this.timePicker;
        this.componentRef.instance.timePicker24Hour = this.timePicker24Hour;
        this.componentRef.instance.timePickerIncrement = this.timePickerIncrement;
        this.componentRef.instance.timePickerSeconds = this.timePickerSeconds;
        this.componentRef.instance.closeOnAutoApply = this.closeOnAutoApply;
        this.componentRef.instance.locale = this.locale;

        this.componentRef.instance.isInvalidDate = this.isInvalidDate;
        this.componentRef.instance.isCustomDate = this.isCustomDate;
        this.componentRef.instance.isTooltipDate = this.isTooltipDate;

        // Set the value
        this.setValue(this.value);

        const localeDiffer = this.differs.find(this.locale).create();
        if (localeDiffer) {
            const changes = localeDiffer.diff(this.locale);
            if (changes) {
                this.componentRef.instance.updateLocale(this.locale);
            }
        }

        // Subscribe to all outputs
        this.componentRef.instance.startDateChanged
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((itemChanged: { startDate: dayjs.Dayjs }) => {
                this.startDateChanged.emit(itemChanged);
            });

        this.componentRef.instance.endDateChanged
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((itemChanged) => {
                this.endDateChanged.emit(itemChanged);
            });

        this.componentRef.instance.rangeClicked
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((range) => {
                this.rangeClicked.emit(range);
            });

        this.componentRef.instance.datesUpdated
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((range) => {
                this.datesUpdated.emit(range);
            });

        this.componentRef.instance.chosenDate
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((chosenDate) => {
                if (chosenDate) {
                    const { endDate, startDate } = chosenDate;
                    this.value = { endDate, startDate };
                    this.onChange.emit(this.value);
                    if (typeof chosenDate.chosenLabel === "string") {
                        this._el.nativeElement.value = chosenDate.chosenLabel;
                    }

                    this.hide();
                }
            });

        this.componentRef.instance.closeDateRangePicker
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.hide();
            });

        // Close the DateRangePicker when the backdrop is clicked
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.hide();
            });
    }

    public hide(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.destroy$.next();
            this.overlayRef = null;
            this.componentRef = null;
        }
    }

    public toggle(): void {
        if (this.overlayRef) {
            this.hide();
        } else {
            this.open();
        }
    }

    public clear(): void {
        if (this.componentRef) {
            this.componentRef.instance.clear();
        }
    }

    public writeValue(value: { startDate: dayjs.Dayjs | string; endDate: dayjs.Dayjs | string } | dayjs.Dayjs): void {
        if (dayjs.isDayjs(value)) {
            this.value = { startDate: value };
        } else if (value) {
            this.value = { startDate: dayjs(value.startDate), endDate: dayjs(value.endDate) };
        } else {
            this.value = null;
        }
        this.setValue(this.value);
    }

    public registerOnChange(fn): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn): void {
        this._onTouched = fn;
    }

    public inputChanged(e): void {
        if (e.target.tagName.toLowerCase() !== "input") {
            return;
        }

        if (!e.target.value.length) {
            return;
        }

        if (this.componentRef) {
            const dateString = e.target.value.split(this.componentRef.instance.locale.separator);
            let start = null,
                end = null;
            if (dateString.length === 2) {
                start = dayjs(dateString[0], this.componentRef.instance.locale.format);
                end = dayjs(dateString[1], this.componentRef.instance.locale.format);
            }
            if (this.singleDatePicker || start === null || end === null) {
                start = dayjs(e.target.value, this.componentRef.instance.locale.format);
                end = start;
            }
            if (!start.isValid() || !end.isValid()) {
                return;
            }
            this.componentRef.instance.setStartDate(start);
            this.componentRef.instance.setEndDate(end);
            this.componentRef.instance.updateView();
        }
    }

    public calculateChosenLabel(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs): string {
        const format = this.locale.displayFormat ? this.locale.displayFormat : this.locale.format;

        if (this.singleDatePicker) {
            return startDate.format(format);
        }

        if (startDate && endDate) {
            return startDate.format(format) + this.locale.separator + endDate.format(format);
        }

        return null;
    }

    private setValue(value: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }): void {
        if (this.componentRef) {
            if (value) {
                if (value[this._startKey]) {
                    this.componentRef.instance.setStartDate(value[this._startKey]);
                }
                if (value[this._endKey]) {
                    this.componentRef.instance.setEndDate(value[this._endKey]);
                }
                this.componentRef.instance.calculateChosenLabel();
                if (this.componentRef.instance.chosenLabel) {
                    this._el.nativeElement.value = this.componentRef.instance.chosenLabel;
                }
            } else {
                this.componentRef.instance.clear();
            }
        }

        this._el.nativeElement.value = value ? this.calculateChosenLabel(value.startDate, value.endDate) : null;
    }

    /**
     *  build the locale config
     */
    private _buildLocale() {
        this.locale = { ...this._localeService.config, ...this.locale };
        if (!this.locale.format) {
            if (this.timePicker) {
                // @ts-ignore
              this.locale.format = dayjs.localeData().longDateFormat("lll");
            } else {
                // @ts-ignore
              this.locale.format = dayjs.localeData().longDateFormat("L");
            }
        }
    }
}
