<div id="pos-container">
  <div id="menu-container" class="left-container">
    <div [hidden]="productCategories.length == 0">
      <div class="blue-grey lighten-5 navbar navbar-light p-2 justify-content-end" style="margin-bottom: 10px;">
        <div class="search-form mr-2">
          <div class="form-group has-feedback mb-0" [class.has-value]="searchText.length > 0">
            <input mdbInput [(ngModel)]="searchText"
                   (keyup.enter)="searchItems()"
                   [attr.placeholder]='"Search" | translate'
                   type="text" class="form-control" name="search" id="product-search" style="padding-top:3px;">
            <span class="fa fa-search form-control-feedback" (mousedown)="focusElement()"></span>
            <button type="button" class="close pos-search-reset" aria-label="Close" (click)="resetSearch()">
              <span aria-hidden="true" style="font-size:36px;display:block;">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <app-menu-categories [retailMode]="true"></app-menu-categories>
        <div class="row menu-category-container" style="height: calc(100% - 66px);">
          <app-menu-category-products
            [category]="firstCategory"
            [retailMode]="true" class="w-100"></app-menu-category-products>
        </div>
      </div>
    </div>
    <div id="alert-box" class="alert color-block warning-color z-depth-1" role="alert" [hidden]="productCategories.length > 0" style="margin: 10px;">
      {{"MenuIsEmpty" | translate}}
    </div>
  </div>

  <div id="ordersList" class="right-container">
    <app-retail-orders-list></app-retail-orders-list>
  </div>
</div>

<app-product-sidedish-dialog></app-product-sidedish-dialog>
<app-product-extras-dialog></app-product-extras-dialog>
<app-product-special-wishes-dialog></app-product-special-wishes-dialog>
<app-product-withouts-dialog></app-product-withouts-dialog>
<app-payment-dialog></app-payment-dialog>
<app-payment-discount-dialog></app-payment-discount-dialog>

