<div id="receiptContainer" style="position:absolute;width: 100%; top: 0; bottom: 0;">
  <app-receipt-toolbar (onFilterUpdated)="dateUpdated($event)"></app-receipt-toolbar>
  <div class="row receipt-main-container" style="top: 90px;">
    <div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="showReceiptsWarning">
      <img src="/images/receipt.svg" style="width: 150px;" />
      <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;">
        {{"NoReceiptsFound" | translate}}
      </div>
    </div>

    <div class="left-container receipts-list" style="z-index: 10">
      <app-receipt-list [receipts]="receipts"></app-receipt-list>
    </div>
    <div class="right-container"
         *ngIf="!showReceiptsWarning"
         [class.right-container-slide-left]="showReceipt"
         [class.right-container-slide-right]="!showReceipt"
         style="padding: 0px;background: white;">
      <app-receipt-overview></app-receipt-overview>
    </div>
  </div>
</div>
