<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade top" tabindex="-1" role="dialog" [style.z-index]="zIndex+1"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-{{dialogModel.type}}"  role="document">
    <!--Content-->
    <div class="modal-content text-center">
      <!--Header-->
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">{{dialogModel.title}}</p>
      </div>

      <!--Body-->
      <div class="modal-body d-flex flex-column align-items-center">
        <mdb-icon *ngIf="dialogModel.icon" fas icon="{{dialogModel.icon}}" size="4x" class="animated rotateIn mb-2"></mdb-icon>
        <label [innerHTML]="dialogModel.message"></label>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" [hidden]="hideCancelButton" mdbBtn color="{{dialogModel.type}}" outline="true" rounded="true" class="waves-effect"
           mdbWavesEffect data-dismiss="modal" (click)="hide()">{{"No" | translate}}</a>

        <a type="button" mdbBtn color="{{dialogModel.type}}" rounded="true" class="waves-effect"
           (click)="confirmed()"
           mdbWavesEffect>{{confirmButtonTitle | translate}}</a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
