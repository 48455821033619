import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import {filter, find, groupBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {v4 as uuid} from "uuid";
import {OrderStatus} from "../../enums/order.status.enum";
import {DayEndReportStatistic, NotificationTypes, OrderedItem} from "../../models";
import {DayEndReport} from "../../models/day.end.report.model";
import {LocalStorageService} from "../../services/localstorage.service";
import {MessageService} from "../../services/message.service";
import {ModalService} from "../../services/modal.service";
import {NativeAppService} from "../../services/native-app.service";
import {PosService} from "../../services/pos.service";
import {ProductService} from "../../services/product.service";
import {ReportService} from "../../services/report.service";
import {SignalRService} from "../../services/signalr.service";
import {SubscriptionService} from "../../services/subscription.service";
import {UtilsService} from "../../services/utils.service";
import {Router} from "@angular/router";
import {CookieService} from "src/app/services/cookie.service";

@Component({
  selector: "app-pos",
  templateUrl: "./pos.component.html",
  styleUrls: ["./pos.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class PosComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  public tableOrderedItems: OrderedItem[] = [];
  private refreshInterval: NodeJS.Timeout;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
    private posService: PosService,
    private reportService: ReportService,
    private modalService: ModalService,
    private nativeAppService: NativeAppService,
    private signalRService: SignalRService,
    private productService: ProductService,
    private utilsService: UtilsService,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  public ngOnInit() {
    this.loadProducts().then();
  }

  public ngOnDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public async loadOrderedItemsAsync(): Promise<any> {
    this.messageService.showLoading(await this.translateService.get("LoadingOrders").toPromise());
    this.posService.getOrderedItems().subscribe(async (orderedItems) => {
      this.messageService.hideLoading();

      if (!orderedItems) {
        orderedItems = [];
      }

      const newItems = filter(await this.localStorage.getOrderedItemsAsync(), {status: 0});
      const allItems = orderedItems.concat(newItems);
      this.localStorage.setOrderedItems(allItems);

      this.subscriptionService.orderedItemsReloaded();

      this.showCloseDayIfNecessary();

      await this.refreshNotifications();

      this.refreshInterval = setInterval(() => this.refreshOrderedItemsAsync(), 60000);
    }, (error) => {
      this.messageService.hideLoading();
      this.messageService.error(error);
    });
  }

  public refreshOrderedItemsAsync() {
    this.posService.getOrderedItems().toPromise().then(async (orderedItems) => {
      if (!orderedItems) {
        orderedItems = [];
      }

      const newItems = filter(await this.localStorage.getOrderedItemsAsync(), {status: 0});
      const allItems = orderedItems.concat(newItems);
      this.localStorage.setOrderedItems(allItems);

      this.subscriptionService.orderedItemsReloaded();

      await this.refreshNotifications();
    }).catch(error => {
    });
  }

  public newOrdersUpdated(orderedItems: OrderedItem[]) {
    this.tableOrderedItems = orderedItems;
  }

  private async loadProducts() {
    const loadingProductText = await this.translateService.get("LoadingProducts").toPromise();
    this.messageService.showLoading(loadingProductText);
    this.productService.loadAllProductCategories(true).subscribe(async (result) => {
      result.categories = result.categories.filter((c) => c.productCategoryId !== this.utilsService.generateEmptyGuid());
      result.categories = result.categories.sort((a, b) => a.productCategoryName.localeCompare(b.productCategoryName, undefined, {numeric: true, sensitivity: "base"}));

      if (result.products) {
        this.localStorage.setProducts(result.products);
      }

      this.localStorage.setProductCategories(result.categories);
      this.messageService.hideLoading();
      await this.loadOrderedItemsAsync();

    }, () => {
      this.messageService.hideLoading();
    });
  }

  private async refreshNotifications(): Promise<any> {
    // remove notifications which are no more valid
    (await this.localStorage.getNotificationsAsync()).forEach((n) => {
      const not = find(this.localStorage.getOrderedItemsAsync(), {tableId: n.tableId, status: OrderStatus.ready});
      if (!not) {
        this.localStorage.removeNotification(n);
      }
    });

    const readyOrders = (await this.localStorage.getOrderedItemsAsync()).filter((i) => i.status == OrderStatus.ready);
    const tableItems = groupBy(readyOrders, "tableId");
    for (const tableId in tableItems) {
      const not = find(this.localStorage.getNotificationsAsync(), {tableId, type: NotificationTypes.OrdersReady});
      if (not) {
        continue;
      }

      const items = tableItems[tableId];
      const notification = {
        notificationId: uuid(),
        tableId,
        id: uuid(),
        title: this.translateService.instant("OrdersReadyForTable"),
        description: items[0].tableName,
        date: new Date(),
        icon: "far fa-check-circle",
        type: NotificationTypes.OrdersReady
      };
      this.localStorage.insertNotification(notification);
    }

    this.subscriptionService.refreshNotifications();
  }

  private showCloseDayIfNecessary() {
    this.reportService.getLastZReportDate().subscribe((date) => {
      const lastCreateDate = dayjs(date);
      const now = dayjs();
      const differenceInDays = now.diff(lastCreateDate, "day");

      if (differenceInDays > 1) {
        const confirmModel = {
          message: this.translateService.instant("CloseDayConfirm"),
          type: "info",
          confirmed: () => {
            this.doCloseDay();
          }
        };
        this.modalService.show("confirm-dialog", confirmModel);
      } else {
        this.showTSEWarningIfNecessary();
      }
    });
  }

  private showTSEWarningIfNecessary() {
    const user = this.localStorage.getUserSync();

    let counter = parseInt(this.cookieService.getCookie("tse-warning-count"));
    if (isNaN(counter)) {
      counter = 0;
    }
    if (counter > 2) {
      return;
    }

    const lastDate = dayjs(this.cookieService.getCookie("tse-warning-last-date"));
    const differenceInHour = dayjs(new Date()).diff(lastDate, "hour");
    if (lastDate.isValid() && differenceInHour < 24) {
      return;
    }

    if (user.country == "DE" && !user.fiskalyOrganisationId) {
      this.localStorage.getProductsAsync().then(res => {
        if (res.length > 0) {
          const confirmModel = {
            hideCancelButton: true,
            confirmButtonTitle: this.translateService.instant("GotoSettings"),
            message: this.translateService.instant("TSENotActivatedWarning"),
            type: "danger",
            icon: "times",
            confirmed: () => {
              counter++;
              this.cookieService.setCookie("tse-warning-last-date", new Date().toISOString(), 365);
              this.cookieService.setCookie("tse-warning-count", counter.toString(), 365);
              this.router.navigate(["/settings/tse"]);
            }
          };
          this.modalService.show("confirm-dialog", confirmModel);
        }
      });
    }
  }

  private doCloseDay() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.reportService.createZReport().subscribe(async (result: { zreport: DayEndReport, reportInfo: DayEndReportStatistic }) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("CloseDaySuccess"));
      this.localStorage.setDayEndStatistic(result.reportInfo);
      this.nativeAppService.printZReport(result.zreport.id, await this.localStorage.getPrinterSettingsAsync(), true);
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
