<div id="orderListContainer" style="position: absolute;top: 0px; bottom:0px; width: 100%;background:#efefef;padding:10px;">
  <div id="orderContainer" class="order-container">
    <div id="categoryToolbar" class="navbar" style="margin:0px;padding:0px;background:white;">
      <div class="row d-flex" style="padding:3px 0;">

        <div class="col-8 pl-4 category-list-container" style="margin-top:8px; text-align: left;">
          <mdb-select-2 [(ngModel)]="selectedCategories"
                        [highlightFirst]="false"
                        [multiple]="true"
                        [placeholder]="'ChooseCategoryToFilter' | translate">
            <mdb-select-all-option>{{"SelectAll" | translate}}</mdb-select-all-option>
            <mdb-select-option *ngFor="let option of categories" [value]="option.value" (selectionChange)="setSelectedCategories($event)">{{ option.label }}</mdb-select-option>

          </mdb-select-2>
        </div>

        <div class="col-4 d-flex justify-content-end">
          <button  type="button" mdbBtn color="info" mdbWavesEffect style="margin: 5px;padding:5px 15px;"
                   (click)="volumeModal.show()">
            <i class="fa" style="font-size:37px;"
                [class.fa-volume-off]="volumeValue == 0"
                [class.fa-volume-down]="volumeValue < 50"
                [class.fa-volume-up]="volumeValue >= 50"></i>
          </button>

          <button type="button" mdbBtn color="info" mdbWavesEffect style="margin: 5px;padding:5px 15px;"
              (click)="callWaiters()">
            <i class="fa fa-bell" style="font-size:33px;"></i>
          </button>

          <button type="button" mdbBtn color="info" mdbWavesEffect style="margin: 5px;padding:5px 15px;"
             (click)="doRefresh()">
            <i class="fas fa-sync" style="font-size:37px;"
            [class.fa-spin]="refreshingOrders"></i>
          </button>
        </div>
      </div>
    </div>

    <div id="orderListView"
         style="margin: 0;display: inline-flex;position: absolute;top: 80px;bottom: 10px;left: 10px;right: 10px;overflow: auto;">
      <app-single-table-orders *ngFor="let orders of tableOrders"
                               [orders]="orders"
                               [tableOrderList]="tableOrders"
                               (orderUpdate)="ordersUpdated()"></app-single-table-orders>
    </div>

    <div class="text-center" *ngIf="!existingVisibleOrderedItems">
      <i class="far fa-check-square" style="font-size: 200px;color:#5ec16f"></i>
      <div class="text-info informative-text mt-3">
        {{"NoOrdersAvailable" | translate}}
      </div>
    </div>
  </div>
</div>
<app-product-details-dialog></app-product-details-dialog>

<div mdbModal #volumeModal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade top" tabindex="-1" role="dialog" style="z-index:4000"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="volumeModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h6 class="modal-title w-100" id="myModalLabel">
          {{"AdjustVolume_Tooltip" | translate}}
        </h6>
      </div>
      <div class="modal-body" style="padding-top:40px;">
        <mdb-range-input id="volumeRange" min="0" max="100"
                         [(ngModel)]="volumeValue"
                         [value]="volumeValue"
                         (rangeValueChange)="onVolumeChange($event)"></mdb-range-input>
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="primary" rounded="true" class="waves-light" aria-label="Close" (click)="volumeModal.hide()" mdbWavesEffect>{{"Close" | translate}}</button>
      </div>
    </div>
  </div>
</div>

