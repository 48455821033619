import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CookieService {

  constructor() {
  }

  public dropCookie(name): void {
    this.setCookie(name, "", -1);
  }

  public setCookie(name: string, value: string, expiry_days: number): string {
    const d = new Date();
    d.setTime(d.getTime() + (expiry_days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
    return this.getCookie(name);
  }

  public getCookie(name): string {
    const cookie_name = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(cookie_name) === 0) {
        return c.substring(cookie_name.length, c.length);
      }
    }
    return "";
  }
}
