<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" [style.z-index]="zIndex+1">
    <div class="modal-dialog discount-dialog modal-info" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="heading" id="myModalLabel">{{"AddProduct" | translate}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
            </div>
            <div class="modal-body">
                <table style="width: 100%;border: 1px solid #dedede;" *ngIf="products.length > 0">
                    <thead class="grey lighten-2">
                        <tr style="border-bottom: 1px solid #dedede;">
                            <th class="p-3" style="width: 60px;"></th>
                            <th>{{"ProductName" | translate}}</th>
                            <th>{{"Price" | translate}}</th>
                            <th style="width: 60px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products" style="border-bottom: 1px solid #dedede;">
                            <td>
                                <button type="button" class="btn btn-outline-danger btn-rounded btn-sm" style="width: 39px;padding: 7px;" (click)="showDeleteProductConfirmation(product)">
                <i class="far fa-trash-alt" style="font-size: 20px;"></i>
              </button>
                            </td>
                            <td style="padding-right: 5px;">{{product.productName}}</td>
                            <td style="padding-right: 5px;">{{product.unitPrice | otcurrency}}</td>
                            <td>
                                <button type="button" class="btn btn-outline-primary btn-rounded btn-md d-flex p-2" (click)="addProduct(product)">
                <i class="fa fa-plus" style="font-size: 17px;margin-right: 5px;"></i>
                <span>{{"Add" | translate}}</span>
              </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="text-align: center" *ngIf="products.length == 0">
                    <img src="/assets/images/dinner.svg" style="width: 200px;" />
                    <div class="text-info informative-text m-3" role="alert">{{"NoDiverseProduct" | translate}}</div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-lg btn-outline-info btn-rounded" (click)="hide()">
          <span>{{"Close" | translate}}</span>
        </button>
            </div>
        </div>
    </div>
</div>