import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";
import {SubscriptionService} from "../../../../services/subscription.service";

@Component({
  selector: "app-import-products-dialog",
  templateUrl: "./import-products-dialog.component.html",
  styleUrls: ["./import-products-dialog.component.scss"],
})
export class ImportProductsDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public selectedFile: any;
  public loading: boolean;
  public notImportedProductNames: string[] = [];
  public importFinished: boolean;

  constructor(
    private modalService: ModalService,
    private productService: ProductService,
    private messageService: MessageService,
    public translateService: TranslateService,
    private subscriptionService: SubscriptionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("import-products-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    // reset variables
    this.selectedFile = null;
    this.loading = false;
    this.notImportedProductNames = [];
    this.importFinished = false;
    this.modal.show();
  }

  public importProducts() {
    if (!this.selectedFile) {
      this.messageService.warning(this.translateService.instant("NoFileChosen"));
      return false;
    } else if (!this.selectedFile.name.endsWith(".xlsx")) {
      this.messageService.warning(this.translateService.instant("ChooseExcelFile"));
      return false;
    }

    this.loading = true;

    const fd = new FormData();
    fd.append("file", this.selectedFile);
    this.productService.importProducts(fd).subscribe((notImportedProductNames) => {
      this.loading = false;
      this.selectedFile = null;
      this.importFinished = true;
      this.notImportedProductNames = notImportedProductNames;

      this.subscriptionService.importProductFinished();
    }, (error) => {
      this.loading = false;
      this.messageService.error(error);
    });
  }

  public onFileUploaded(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
    }
  }
}
