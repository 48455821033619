import {BaseModel} from "./base.model";

export class Employee extends  BaseModel {
  public userId: string;

  public userName: string;

  public firstName: string;

  public lastName: string;

  public userPasswordHash: string;

  public role: string;

  public country: string;

  public language: string;

  public currency: string;
}
