import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ReceiptSettings, User, UserSettings} from "../../../models";
import {AccountService} from "../../../services/account.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {NativeAppService} from "../../../services/native-app.service";

@Component({
  selector: "app-receipt-settings",
  templateUrl: "./receipt-settings.component.html",
  styleUrls: ["./receipt-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReceiptSettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  public user: User = new User();
  public userSettings: UserSettings = new UserSettings();
  public receiptSettings: ReceiptSettings = new ReceiptSettings();
  public logoLoading: boolean;
  public todayDate = dayjs().format("L");
  public paymentTypes = [];

  public dropzoneConfig = {
    url: `/api/v1/account/receiptlogo`,
    maxFiles: 1,
    multiple: false,
    uploadMultiple: false,
    maxFilesize: 10,
    acceptedFiles: "image/*",
    height: 150,
  };
  public fileUploader: any;

  public printerSize = 4;
  public printerSizes = [
    {value: 1, label: "58mm - 31/42"},
    {value: 2, label: "58mm - 35/46"},
    {value: 3, label: "80mm - 42/60"},
    {value: 4, label: "80mm - 48/64"},
  ];
  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private messageService: MessageService,
    private nativeAppService: NativeAppService
  ) { }

  public async ngOnInit() {
    this.userSettings = await this.localStorage.getUserSettingsAsync();
    this.user = await this.localStorage.getUserAsync();
    this.receiptSettings = await this.localStorage.getReceiptSettingsAsync();
    this.paymentTypes = await this.localStorage.getPaymentTypesAsync();

    if(this.fileUploader){
      this.initDropzone(this.fileUploader);
    }
  }

  public chooseFile() {

  }

  public saveSettings() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.accountService.saveReceiptSettings(this.receiptSettings).subscribe((settings) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("ReceiptSettingsUpdateSuccess"));

      this.receiptSettings = settings;
      this.localStorage.setReceiptSettings(settings);
      this.nativeAppService.updateReceiptSettings(settings);
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public async printSettings(): Promise<any> {
    const itemsToPrint = [];
    itemsToPrint.push({
      quantity: 2,
      productName: "Espresso",
      unitPrice: 2.20,
      tax: 7,
    });

    itemsToPrint.push({
      quantity: 1,
      productName: "Pizza Fungi",
      unitPrice: 7.50,
      tax: 7,
    });

    const paymentViewModel = {
      subTotal: 11.12,
      grandTotal: 11.90,
      taxPrices: {7: 0.78},
      paymentType: "CashPayment",
      billAmount: 11.90,
      billRemainder: 0,
    };

    this.nativeAppService.printReceipt(1, itemsToPrint, paymentViewModel, "Table 1", await this.localStorage.getPrinterSettingsAsync(), this.receiptSettings);
  }
  public removeLogo() {
    const confirmModel = {
      message: this.translateService.instant("DeleteImageConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.doRemoveLogo();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public initDropzone(uploader: any) {
    this.fileUploader = uploader;
    uploader.removeAllFiles();

    if (this.receiptSettings.logo) {
      const mockFile = {name: "logo", size: 0, type: "", id: "1"};
      uploader.emit("addedfile", mockFile);
      uploader.emit("thumbnail", mockFile, this.receiptSettings.logo);
      uploader.files.push(mockFile);

      mockFile["previewElement"].classList.add("dz-success");
      mockFile["previewElement"].classList.add("dz-complete");
      const details = mockFile["previewElement"].querySelector(".dz-details");
      details?.parentElement.removeChild(details);
    }
  }

  public onUploadSuccess(file: any) {
    if (file.status === "success") {
      this.receiptSettings.logo = JSON.parse(file.xhr.response);
      this.localStorage.setReceiptSettings(this.receiptSettings);
    } else {
      const categoryPictures = document.getElementById("product-category-pictures");
      categoryPictures.querySelectorAll(".dz-error .dz-error-message").forEach((el: HTMLElement) => {
        el.style.display = "none";
      });

      const removeBtnDocument = new DOMParser().parseFromString("<a class='btn-remove-image'><i class='far fa-times-circle fa-2x text-danger'></i></a>", "text/html");
      const removeBtn = removeBtnDocument.body.firstElementChild;
      removeBtn.addEventListener("click", () => {
        this.fileUploader.removeFile(file);
      });
      categoryPictures.querySelectorAll(".dz-error").forEach((el: HTMLElement) => {
        el.appendChild(removeBtn);
      });
    }
  }

  private doRemoveLogo() {
    this.fileUploader.removeAllFiles();
    this.receiptSettings.logo = "";
  }

  showPaymentTypes() {
    this.modalService.show("payment-types-dialog", {});
  }
}
