import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {filter, find, findIndex, sortBy} from "lodash";
import {Subscription} from "rxjs";
import {OrderStatus} from "../../../../enums/order.status.enum";
import {OrderedItem, Product} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {UtilsService} from "../../../../services/utils.service";

@Component({
  selector: "app-quick-access",
  templateUrl: "./quick-access.component.html",
  styleUrls: ["./quick-access.component.scss"],
})
export class QuickAccessComponent implements OnInit, OnDestroy {
  @Input()
  public newItems: OrderedItem[];

  public mostSoldProducts: Product[] = [];
  public lastSoldProducts: Product[] = [];
  public favoriteProducts: Product[] = [];
  public utilsService: UtilsService;
  private favoriteProductSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private utils: UtilsService,
    private subscriptionService: SubscriptionService,
  ) {
  }

  public async ngOnInit() {
    this.utilsService = this.utils;
    this.utilsService.showAsList = await this.localStorage.getValueAsync<boolean>("show-product-as-list");

    this.mostSoldProducts = sortBy(await this.getMostSoldProductsAsync(), [product => product.productName.toLowerCase()]);
    this.lastSoldProducts = sortBy(await this.getLastProductsAsync(), [product => product.productName.toLowerCase()]);
    this.favoriteProducts = sortBy(await this.getFavoriteProducts(), [product => product.productName.toLowerCase()]);

    this.favoriteProductSubscription = this.subscriptionService.favoriteProductEvent.subscribe(async (product) => {
      if ((await this.localStorage.getFavoriteProductsAsync()).indexOf(product.productId) === -1) {
        const index = findIndex(this.favoriteProducts, {productId: product.productId});
        if (index > -1) {
          this.favoriteProducts.splice(index, 1);
        }
      } else {
        this.favoriteProducts.push(product);
      }
    });
  }

  public ngOnDestroy(): void {
    this.newItems = null;
    this.mostSoldProducts = null;
    this.lastSoldProducts = [];
    this.favoriteProducts = null;
    this.favoriteProductSubscription?.unsubscribe();
  }

  public changeListType(type: string) {
    this.utilsService.showAsList = type === "list";
    this.localStorage.setValue("show-product-as-list", this.utilsService.showAsList);
  }

  public async getMostSoldProductsAsync(): Promise<any> {
    const favorites = [];
    const allProducts = await this.localStorage.getProductsAsync();
    (await this.localStorage.getMostSoldProductIdsAsync()).forEach(function(productId) {
      const product = find(allProducts, { productId });
      if (product) { favorites.push(product); }
    });

    return favorites;
  }

  public async getLastProductsAsync(): Promise<any> {
    const favorites = [];
    const allProducts = await this.localStorage.getProductsAsync();
    (await this.localStorage.getLastSoldProductIdsAsync()).forEach(function(productId) {
      const product = find(allProducts, { productId });
      if (product) { favorites.push(product); }
    });

    return favorites;
  }

  public async getFavoriteProducts(): Promise<any> {
    const favorites = [];
    const allProducts = await this.localStorage.getProductsAsync();
    (await this.localStorage.getFavoriteProductsAsync()).forEach(function(productId) {
      const product = find(allProducts, { productId });
      if (product) { favorites.push(product); }
    });

    return favorites;
  }

  public calculateOrderCount(newItems: OrderedItem[], product: Product) {
    const orderedItems = filter(newItems, (item) => {
      return item.status === OrderStatus.new &&
        item.product.productId === product.productId;
    });

    return orderedItems.reduce((acc, item) => acc + item.quantity, 0);
  }
}
