import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as dayjs from "dayjs";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ModalDirective } from "ng-uikit-pro-standard";
import { Subscription } from "rxjs";
import { ModalClassComponent } from "../../interfaces/ModalClassComponent";
import { Notification, NotificationTypes } from "../../models";
import { LocalStorageService } from "../../services/localstorage.service";
import { MessageService } from "../../services/message.service";
import { ModalService } from "../../services/modal.service";
import { PosService } from "../../services/pos.service";
import { SubscriptionService } from "../../services/subscription.service";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

@Component({
  selector: "app-notifications-dialog",
  templateUrl: "./notifications-dialog.component.html",
  styleUrls: ["./notifications-dialog.component.scss"],
})
export class NotificationsDialogComponent extends ModalClassComponent implements OnInit, OnDestroy {
  @ViewChild("modal")
  public modal: ModalDirective;
  @BlockUI()
  public blockUI: NgBlockUI;

  public refreshNotificationsSubscription: Subscription;

  public notifications: Notification[] = [];
  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private posService: PosService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
    this.modalService.register("notification-dialog", this);
  }

  public ngOnInit(): void {
    this.refreshNotificationsSubscription = this.subscriptionService.refreshNotificationsEvent.subscribe(async () => {
      this.notifications = await this.localStorage.getNotificationsAsync();
    });
  }

  public ngOnDestroy() {
    this.refreshNotificationsSubscription.unsubscribe();
  }

  public hide() {
    super.hide();
    this.modal.hide();
  }

  public async show(args) {
    args.onBackdropClick = () => {
      console.log("backdrop clicked");
      this.hide();
    };

    super.show(args);
    this.modal.show();

    this.notifications = await this.localStorage.getNotificationsAsync();
    this.notifications.forEach((n) => {
      // @ts-ignore
      n.fromNow = dayjs(n.date).fromNow();
    });
  }

  public deleteAllNotifications() {
    this.localStorage.removeAllNotifications();
  }

  public async setTableItemsServed(notification: Notification) {
    this.blockUI.start(await this.translateService.get("PleaseWait").toPromise());

    this.posService.setItemsServed(notification.tableId).subscribe(() => {
      this.blockUI.stop();
      this.localStorage.removeTableNotifications(notification.tableId, NotificationTypes.OrdersReady);
      this.subscriptionService.refreshNotifications();

      this.subscriptionService.tableRefresh(notification.tableId);
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }
}
