import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {
    ButtonsModule,
    CarouselModule,
    DropdownModule,
    IconsModule,
    InputsModule, MdbSelectModule,
    ModalModule, NavbarModule, PopoverModule, SelectModule, TabsModule, WavesModule
} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../common/common.module";
import {CreateItemDialogComponent} from "./create-item-dialog/create-item-dialog.component";
import {MenuCardComponent} from "./menu-card/menu-card.component";
import {MenuCategoriesComponent} from "./menu-card/menu-categories/menu-categories.component";
import {MenuCategoryProductsComponent} from "./menu-card/menu-category-products/menu-category-products.component";
import {MenuSingleProductComponent} from "./menu-card/menu-single-product/menu-single-product.component";
import {QuickAccessComponent} from "./menu-card/quick-access/quick-access.component";
import {MoveOrderDialogComponent} from "./move-order-dialog/move-order-dialog.component";
import {OrderListComponent} from "./order-list/order-list.component";
import {PaymentDialogComponent} from "./payment-dialog/payment-dialog.component";
import {PaymentDiscountDialogComponent} from "./payment-discount-dialog/payment-discount-dialog.component";
import {PosRoutingModule} from "./pos-routing.module";
import {PosComponent} from "./pos.component";
import {ProductDetailsDialogComponent} from "./product-details-dialog/product-details-dialog.component";
import {ProductExtrasDialogComponent} from "./product-extras-dialog/product-extras-dialog.component";
import {ProductSidedishDialogComponent} from "./product-sidedish-dialog/product-sidedish-dialog.component";
import {ProductSpecialWishesDialogComponent} from "./product-special-wishes-dialog/product-special-wishes-dialog.component";
import {ProductWithoutsDialogComponent} from "./product-withouts-dialog/product-withouts-dialog.component";
import {ShowDiverseItemsDialogComponent} from "./show-diverse-items-dialog/show-diverse-items-dialog.component";
import {SingleOrderItemComponent} from "./single-order-item/single-order-item.component";
import {StatusColorComponent} from "./status-color/status-color.component";
import {TableCategoryListComponent} from "./table-category-list/table-category-list.component";
import {CreateCategoryDialogComponent} from "./table-split-enter-name/table-split-enter-name.component";
import {TableComponent} from "./table/table.component";

@NgModule({
    declarations: [
        CreateItemDialogComponent,
        ProductSidedishDialogComponent,
        ProductSpecialWishesDialogComponent,
        ProductDetailsDialogComponent,
        ProductExtrasDialogComponent,
        ProductWithoutsDialogComponent,
        PaymentDialogComponent,
        PaymentDiscountDialogComponent,
        MoveOrderDialogComponent,
        PosComponent,
        StatusColorComponent,
        TableCategoryListComponent,
        TableComponent,
        OrderListComponent,
        SingleOrderItemComponent,
        MenuCardComponent,
        MenuCategoriesComponent,
        MenuCategoryProductsComponent,
        MenuSingleProductComponent,
        QuickAccessComponent,
        ShowDiverseItemsDialogComponent,
        CreateCategoryDialogComponent
    ],
  exports: [
    ProductDetailsDialogComponent,
    MenuCategoriesComponent,
    MenuCategoryProductsComponent,
    SingleOrderItemComponent,
    ProductExtrasDialogComponent,
    ProductSpecialWishesDialogComponent,
    ProductWithoutsDialogComponent,
    PaymentDialogComponent,
    PaymentDiscountDialogComponent,
    ProductSidedishDialogComponent,
    MenuCardComponent,
  ],
    imports: [
        PosRoutingModule,
        AppCommonModule,
        CarouselModule.forRoot(),
        WavesModule.forRoot(),
        NavbarModule,
        ButtonsModule.forRoot(),
        PopoverModule.forRoot(),
        TabsModule.forRoot(),
        SelectModule,
        DropdownModule.forRoot(),
        ModalModule.forRoot(),
        IconsModule,
        FormsModule,
        InputsModule,
        MdbSelectModule
    ]
})
export class PosModule {
}
