import {Component, Input, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "lodash";
import {find, findIndex} from "lodash";
import { Table, TableCategory } from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-table-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {

  @Input()
  public category: TableCategory;

  @Input()
  public categoryList: TableCategory[] = [];

  public categoryTables: Table[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private tableService: TableService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService) {
  }

  public async ngOnInit() {
    this.categoryTables = filter(await this.localStorage.getTablesAsync(), {categoryId: this.category.id});
    this.subscriptionService.tableMovedEvent.subscribe(async (categoryId) => {
      if (this.category.id === categoryId) {
        this.categoryTables = filter(await this.localStorage.getTablesAsync(), {categoryId: this.category.id});
      }
    });
  }

  public showTables() {
    this.modalService.show("show-table-list-dialog", {
      category: this.category,
      tables: this.categoryTables,
    });
  }

  public showEditCategory() {
    this.modalService.show("create-table-category-dialog", {
      name: this.category.name,
      isUpdate: true,
      addCategory: (name) => {this.updateCategory(name); },
    });
  }

  public showDeleteCategoryConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("DeleteTableCategoryConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {this.deleteCategory(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public deleteCategory() {
    this.tableService.deleteCategory(this.category.id).subscribe( () => {
      const index = findIndex(this.categoryList, {id: this.category.id});
      this.categoryList.splice(index, 1);
      this.localStorage.setTableCategories(this.categoryList);
      this.messageService.success(this.translateService.instant("DeleteCategorySuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }

  private updateCategory(name: string) {
    const cat = {
      id: this.category.id,
      name,
    };

    this.tableService.updateCategory(cat).subscribe( () => {
      this.category.name = name;

      const cat = find(this.categoryList, {id: this.category.id});
      cat.name = name;

      this.localStorage.setTableCategories(this.categoryList);

      this.messageService.success(this.translateService.instant("UpdateCategorySuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }
}
