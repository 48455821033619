import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {groupBy, minBy, sortBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {OrderedItem} from "../../models";
import {LocalStorageService} from "../../services/localstorage.service";
import {MessageService} from "../../services/message.service";
import {ModalService} from "../../services/modal.service";
import {PosService} from "../../services/pos.service";

@Component({
  selector: "app-pos-delivery",
  templateUrl: "./pos-delivery.component.html",
  styleUrls: ["./pos-delivery.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PosDeliveryComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;
  public tableOrders: Array<{orderDate, orderedItems, tableId, isNew, visible}> = [];
  public existingVisibleOrderedItems: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private posService: PosService,
    private messageService: MessageService,
    private modalService: ModalService,
  ) { }

  public ngOnInit(): void {
    this.loadOrders();
  }

  public loadOrders() {
    this.blockUI.start(this.translateService.instant("LoadingOrders"));
    this.posService.getAllOrderedItems().subscribe((orderedItems) => {
      this.blockUI.stop();

      const groupedItems = groupBy(orderedItems, function(i) { return i.tableId; });
      const orders = [];
      for (const tableId in groupedItems) {
        if (groupedItems.hasOwnProperty(tableId)) {
          const items: OrderedItem[] = groupedItems[tableId];
          const firstItem = minBy(items, "orderDate");
          orders.push({
            tableId,
            orderDate: firstItem.orderDate,
            orderedItems: items,
            visible: items,
          });
        }
      }

      this.tableOrders = sortBy(orders, "orderDate");
      this.existingVisibleOrderedItems = this.tableOrders.length > 0;
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public showMenu() {
    this.modalService.show("menu-card", { orderedItems: [] });
  }
}
