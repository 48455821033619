import {HttpClient} from "@angular/common/http";
import {Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {UserRoles} from "src/app/enums/user.roles.enum";
import {AuthenticationService} from "../../../services/auth.service";
import {CookieService} from "../../../services/cookie.service";
import {HomeService} from "../../../services/home.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {NativeAppService} from "../../../services/native-app.service";
import {ProductService} from "../../../services/product.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {TableService} from "../../../services/table.service";
const appVersion = require('../../../../../package.json').version;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnDestroy {
  public isRegistered: boolean;
  public userEmail: string;
  public clickCount = 0;
  public email: string;
  public password: string;

  @ViewChild("emailRef", {read: ElementRef}) public emailRef: ElementRef;
  @ViewChild("passwordRef", {read: ElementRef}) public passwordRef: ElementRef;
  @ViewChild("formRef", {read: ElementRef}) public formRef: ElementRef;
  @BlockUI() public blockUI: NgBlockUI;

  private token: string;
  private sub: Subscription;

  constructor(
    private translateService: TranslateService,
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
    private nativeAppService: NativeAppService,
    private authService: AuthenticationService,
    private tableService: TableService,
    private productService: ProductService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService) {

    this.homeService.loadPackages().then();

    this.sub = this.route.queryParams.subscribe((params) => {
      if (params.sc) {
        this.translateService.get(params.sc).subscribe((res) => {
          this.messageService.success(res);
        });
      } else if (params.wn) {
        this.translateService.get(params.wn).subscribe((res) => {
          this.messageService.warning(res);
        });
      } else if (params.ls === "1") {
        this.isRegistered = true;
        this.userEmail = params.em;
      }
    });

    this.localStorage.getUserAsync().then( async (user) => {
      if (user) {
        await this.navigateToInitialRoute();
        await this.nativeAppService.userLoggedIn();
      }
    });
  }

  public ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  public logoClicked() {
    if (this.clickCount >= 5) {
      const url = prompt("Url");
      this.nativeAppService.updateServerUrl(url);
    }

    this.clickCount++;
  }

  public async login() {
    if(!this.formRef.nativeElement.checkValidity()){
      const list = this.formRef.nativeElement.querySelectorAll(':invalid');
      for (let item of list) {
      }
      return;
    }

    this.emailRef.nativeElement.blur();
    this.passwordRef.nativeElement.blur();
    const tryToLoginText = await this.translateService.get("TryToLogin").toPromise();
    this.blockUI.start(tryToLoginText);
    const loginData = {
      email: this.email,
      password: this.password,
      deviceId: localStorage.getItem("device-id")
    };

    this.authService.logIn(loginData).subscribe(async (result) => {
      this.localStorage.setUser(result.user);
      this.localStorage.setValue("jwt_token", result.token);
      this.localStorage.setValue("token_expiration", result.expiration);
      this.token = result.token;

      this.blockUI.update(await this.translateService.get("DownloadUserSettings").toPromise());
      this.downloadUserSettings();
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private downloadUserSettings() {
    this.http.get<any>("/api/v1/account/mysettings").subscribe(async (settings) => {
      this.localStorage.setUserSettings(settings.userSettings);
      this.localStorage.setReceiptSettings(settings.receiptSettings);
      this.localStorage.setPrinterSettings(settings.printerSettings);
      this.localStorage.setDayEndStatistic(settings.dayEndStat);
      this.localStorage.setPaymentTypes(settings.paymentTypes);
      this.localStorage.setVatDefinitions(settings.vatDefinitions);

      this.blockUI.update(await this.translateService.get("DownloadTables").toPromise());
      this.downloadTables();
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private downloadTables() {
    this.tableService.getAllTablesAndCategories().subscribe(async (result) => {
      this.localStorage.setTables(result.tables);

      result.tableCategories = result.tableCategories.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: "base"}));
      this.localStorage.setTableCategories(result.tableCategories);

      this.blockUI.update(await this.translateService.get("DownloadingMenu").toPromise());
      this.downloadMenu();
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private downloadMenu() {
    this.productService.loadAllProductCategories().subscribe((result) => {
      result.categories = result.categories.sort((a, b) => a.productCategoryName.localeCompare(b.productCategoryName, undefined, {numeric: true, sensitivity: "base"}));
      this.localStorage.setProductCategories(result.categories);
      this.localStorage.setProductCategoryPictures(result.productCategoryPictures);

      this.downloadProducts();
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private downloadProducts() {
    this.productService.loadAllProducts().subscribe((result) => {
      result.products = result.products.sort((a, b) => a.productName.localeCompare(b.productName, undefined, {numeric: true, sensitivity: "base"}));
      this.localStorage.setProducts(result.products);
      this.localStorage.setProductPictures(result.productPictures);
      this.localStorage.setFavoriteProducts(result.favoriteProductIds);
      this.localStorage.setMostSoldProductIds(result.mostSoldProductIds);
      this.localStorage.setLastSoldProductIds(result.lastSoldProductIds);

      this.loadCustomers();
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private loadCustomers() {
    this.http.get<any>("/api/v1/customers").subscribe(async (customers) => {
      this.localStorage.setCustomers(customers);

      const user = await this.localStorage.getUserAsync();
      if (user.role === "Administrator" || user.role === "AdminHelper") {
        this.loadEmployees();
      } else {
        await this.finalizeLogin();
      }
    }, (error) => {
      this.resetDownload(error);
    });
  }

  private resetDownload(error) {
    this.blockUI.stop();

    this.localStorage.removeAll();
    this.messageService.error(error);
  }

  private loadEmployees() {
    this.http.get<any>("/api/v1/employees").subscribe(async (employees) => {
      employees = employees.sort((a, b) => a.timestamp.localeCompare(b.timestamp, undefined, {numeric: true, sensitivity: "base"}));
      this.localStorage.setEmployees(employees);
      await this.finalizeLogin();
    }, (error) => this.resetDownload(error));
  }

  private async finalizeLogin(): Promise<any> {
    this.blockUI.stop();

    const user = await this.localStorage.getUserAsync();
    localStorage.setItem("customerId", user["partitionKey"]);
    localStorage.setItem("currencySymbol", user.currencySymbol);

    await this.nativeAppService.userLoggedIn();
    this.subscriptionService.logInStatusChanged(true);

    document.body.classList.add("logged-in");

    const $zoho = window["$zoho"];
    if ($zoho?.salesiq?.visitor) {
      $zoho.salesiq.visitor.name(user.firstName + " " + user.lastName);
      $zoho.salesiq.visitor.email(user.email);
    }

    const preferences = this.cookieService.getCookie("cookieControlPrefs");
    if (preferences && preferences.indexOf("analytics") !== -1) {
      const loq = window["_loq"] || [];
      loq.push(["custom", {
        email: user.email,
        name: user.firstName + " " + user.lastName,
        userName: user.userName,
        version: appVersion
      }]);
      loq.push(["tag", user.userName]);
    }

    await this.navigateToInitialRoute();
  }

  private async navigateToInitialRoute(): Promise<any> {
    const user = await this.localStorage.getUserAsync();

    if (user.role === UserRoles.CookBarman.toString()) {
      this.subscriptionService.navigatedTo({data: {title: "KitchenDisplay"}});
      await this.router.navigate(["/kds"]);
    } else {
      this.subscriptionService.navigatedTo({data: {title: "POS_Title"}});
      await this.router.navigate(["/pos"]);
    }
  }
}
