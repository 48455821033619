<div class="row" style="padding-top: 100px; margin-left: 0; margin-right: 0; z-index: 10;">
  <div class="col-sm-12">
    <section style="max-width: 500px; margin-left: auto; margin-right: auto;">

    </section>

    <div class="modal-dialog cascading-modal" role="document" style="margin-top:25px;">
      <!--Content-->
      <div class="modal-content">

        <!--Header-->
        <div class="modal-header light-blue darken-3 white-text">
          <h4 class="title">
            <i class="fas fa-pencil-alt"></i>
            <span>
              {{"ResetPassword" | translate}}
            </span>
          </h4>
        </div>
        <!--Body-->
        <div class="modal-body mb-0">
          <div *ngIf="passwordSent">
            <div class="alert alert-success" role="alert" style="margin-bottom: 20px;color:initial;">
              {{"PasswordResetSuccess" | translate}}
            </div>

            <a href="/#/auth/login" class="btn btn-outline-info btn-rounded">
              <span class="fa fa-chevron-left"></span>
              <span>
                {{"BackToLogin" | translate}}
              </span>
            </a>
          </div>

          <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && resetPassword()" [hidden]="passwordSent" id="forgetPasswordForm" action="/account/forgotpassword" method="post" class="form-horizontal login-form html5-form" autocomplete="off">
            <p style="font-size:13px">{{"ResetPasswordHelp" | translate}}</p>
            <div class="md-form">
              <i class="fa fa-user prefix grey-text" style="left:0"></i>
              <input mdbInput type="text" name="userName" id="userName" class="form-control" required="required" [(ngModel)]="userName"/>
              <label for="userName">
                {{"UserName" | translate}}
              </label>
            </div>
            <div class="row">
              <div class="col-md-6">
                <a href="/#/auth/login" class="btn btn-outline-info btn-rounded" style="width:100%;padding-left:20px;padding-right:20px;">
                  <span class="fa fa-chevron-left"></span>
                  <span>
                    {{"BackToLogin" | translate}}
                  </span>
                </a>
              </div>
              <div class="col-md-6">
                <button type="submit" class="btn btn-primary btn-rounded" style="width: 100%;margin-bottom:10px;">
                  {{"Submit" | translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
