<div mdbModal #modal="mdbModal" [config]="{ backdrop: false, keyboard: false, ignoreBackdropClick: true }" class="modal fade category-dialog" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-info modal-md cascading-modal" role="document" style="margin-top: 35px !important;max-width: 600px;">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center mb-0">
        <label class="heading">{{'EnterCardInformation_Title' | translate}}</label>
        <button type="button" class="close mr-0" style="top: -15px;" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body d-flex flex-column align-items-center">
        <app-payment-settings (onPaymentDone)="onPaymentDone()" [paypalPlanId]="paypalPlanId" class="w-100"></app-payment-settings>
      </div>
    </div>
  </div>
</div>
