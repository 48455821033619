import {AfterViewInit, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ProductCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {SignalRService} from "../../../../services/signalr.service";
import {SubscriptionService} from "../../../../services/subscription.service";

@Component({
  selector: "app-menu-categories",
  templateUrl: "./menu-categories.component.html",
  styleUrls: ["./menu-categories.component.scss"],
})
export class MenuCategoriesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public retailMode: boolean;

  public categories: ProductCategory[] = [];
  public productsLength: number;
  public searching: boolean;
  public selectedCategoryId: string;
  public categoryTabsShown = true;
  public searchStarted: boolean;
  public quickAccessCategory: any = {};
  public addedProductsCategory: any = {};
  public searchCategory: any = {};
  private searchSubscriber: Subscription;
  private updateProductSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private signalRService: SignalRService) {
  }

  public async ngOnInit() {
    this.addedProductsCategory = {
      productCategoryId: "1",
      allowSendToKitchen: undefined,
      priceCategories: [],
      extras: [],
      sideDishes: [],
      id: "",
      productCategoryDescription: "",
      products: [],
      sortingId: 0,
      tax: undefined,
      productCategoryName: "AddedProducts",
      selected: false,
    };

    this.quickAccessCategory = {
      allowSendToKitchen: undefined,
      priceCategories: [],
      extras: [],
      sideDishes: [],
      id: "",
      productCategoryDescription: "",
      products: [],
      sortingId: 0,
      tax: undefined,
      productCategoryId: "2",
      productCategoryName: "QuickAccess",
      selected: true,
    };

    this.searchCategory = {
      allowSendToKitchen: undefined,
      priceCategories: [],
      extras: [],
      sideDishes: [],
      id: "",
      productCategoryDescription: "",
      products: [],
      sortingId: 0,
      tax: undefined,
      productCategoryId: "3",
      productCategoryName: "SearchResult",
      selected: false,
    };

    this.searchSubscriber = this.subscriptionService.searchStartedEvent.subscribe((searchText) => {
      this.searchStarted = searchText.length > 0;

      if (!this.searchStarted) {
        if (this.retailMode) { this.categoryClicked(this.categories[0]); } else { this.categoryClicked(this.quickAccessCategory); }
      } else {
        this.categoryClicked(this.searchCategory);
      }
    });

    this.updateProductSubscription = this.signalRService.updateProductCategory.subscribe(async () => {
      this.categories = await this.localStorage.getProductCategoriesAsync();
    });

    this.categories = await this.localStorage.getProductCategoriesAsync();
    this.productsLength = (await this.localStorage.getProductsAsync()).length;
    this.categories.map((c) => c.selected = false);

    if (!this.retailMode) {
      this.categoryClicked(this.quickAccessCategory);
    } else if (this.categories.length > 0) {
      this.categoryClicked(this.categories[0]);
    }
  }

  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    this.searchSubscriber?.unsubscribe();
    this.updateProductSubscription?.unsubscribe();
    this.categories = null;
  }

  public categoryClicked(category) {
    this.quickAccessCategory.selected = false;
    this.addedProductsCategory.selected = false;
    this.searchCategory.selected = false;

    this.categories.map((c) => c.selected = false);
    category.selected = true;
    this.categoryTabsShown = false;
    this.subscriptionService.menuCardCategorySelected(category);
  }

  public openCategoryMenu() {
    this.categoryTabsShown = true;
  }
}
