import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public sendMessage(userId, message) {
    return this.http.get(`/api/v1/pushnotification/sendmessage/${userId}/${message}`);
  }
}
