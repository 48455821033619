<style>
  .main-header {
    display: none;
  }

  .body-content {
    top: 0;
  }
</style>

<div id="registerContainer">
  <div class="card" style="margin: 0px auto; width: 500px; max-width: 100%;">

    <h3 class="card-header info-color white-text text-center py-4">
      <i class="fas fa-lock mr-2"></i>
      <strong>{{"CodeVerification_Title" | translate}}</strong>
    </h3>

    <!--Card content-->
    <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && verifyCode()" class="html5-form card-body p-0 pb-5">
      <div class="alert alert-warning w-100" role="alert" style="color: #856404; text-align: left; border-radius: 0;">
        {{"CodeVerification_Desc" | translate}}
      </div>
      <div class="text-center" style="color: #757575; margin-left: 15px; margin-right: 15px; margin-top: 50px;">

        <!-- Verification Code -->
        <div class="md-form">
          <input mdbInput [(ngModel)]="code" type="tel" id="verificationCode" name="verificationCode" class="form-control pb-0" required="required" />
          <label for="verificationCode">{{"VerificationCode" | translate }}</label>
        </div>

        <div class="d-flex justify-content-between">
          <a class="btn btn-info btn-rounded toolbar-item" style="padding: 15px 25px;" (click)="resendCode()">
            <i class="fas fa-sync"></i>
            <span>{{"Resend" | translate}}</span>
          </a>
          <button id="btnStart" class="btn btn-primary btn-rounded toolbar-item" style="padding: 15px 25px;">
            <i class="fas fa-check"></i>
            <span>{{"Verify" | translate}}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
