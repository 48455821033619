import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-self-order-menu-not-existing',
  templateUrl: './self-order-menu-not-existing.component.html',
  styleUrls: ['./self-order-menu-not-existing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderMenuNotExistingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
