
<div class="row category-list" style="margin: 0px;">
  <app-product-category *ngFor="let category of productCategories"
    class="col-lg-4 col-sm-6 col-xs-12" style="margin-bottom:30px;"
    [category]="category"
    [categoryList]="productCategories"></app-product-category>
</div>

<div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="productCategories?.length == 0">
  <img src="/images/menu.svg" style="width: 200px;" />
  <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;">{{"NoCategories" | translate}}</div>
</div>
