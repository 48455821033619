import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  public showAsList: boolean;

  constructor(
    private translateService: TranslateService) {
  }

  public toFloat(value: number | string) {
    // Return the value as-is if it's already a number:
    if (typeof value === "number") { return value; }

    let decimal = this.getDecimalSeparator();
    if (value.indexOf(decimal) === -1) {
      if (value.indexOf(".") !== -1) {
        decimal = ".";
      }
    }

    // Build regex to strip out everything except digits, decimal point and minus sign:
    const regex = new RegExp("[^0-9-" + decimal + "]", "g");
    const unformatted = parseFloat(
      ("" + value)
      .replace(/\((?=\d+)(.*)\)/, "-$1") // replace bracketed values with negatives
      .replace(regex, "")         // strip out any cruft
      .replace(decimal, "."),      // make sure decimal point is standard
    );

    // This will fail silently which may cause trouble, let's wait and see:
    return !isNaN(unformatted) ? unformatted : 0;
  }

  public getDecimalSeparator(): string {
    if (!Intl || !Intl.NumberFormat(this.translateService.currentLang).formatToParts) { return this.getDecimalSeparatorV2(); }

    const numberWithGroupAndDecimalSeparator = 1000.1;
    const parts = Intl.NumberFormat(this.translateService.currentLang).formatToParts(numberWithGroupAndDecimalSeparator);

    let separator = "";
    parts.forEach(function(part) {
      if (part.type === "decimal") { separator = part.value; }
    });

    return separator;
  }

  public generateEmptyGuid() {
    return "00000000-0000-0000-0000-000000000000";
  }

  public invertColor(hex: string, bw: boolean) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if(hex.length == 8) {
        hex = hex.substring(0, 6);
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    
    const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    const strR = (255 - r).toString(16);
    const strG = (255 - g).toString(16);
    const strB = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + this.padZero(strR) + this.padZero(strG) + this.padZero(strB);
  }

  private padZero(str: string, len?: number) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }

  private getDecimalSeparatorV2() {
    // fallback
    let decSep = ".";

    try {
      // this works in FF, Chrome, IE, Safari and Opera
      const sep = (3 / 2).toLocaleString(this.translateService.currentLang).substring(1, 2);
      if (sep === "." || sep === ",") {
        decSep = sep;
      }
    } catch (e) {}

    return decSep;
  }
}
