import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-kds",
  templateUrl: "./kitchen-module.component.html",
  styleUrls: ["./kitchen-module.component.scss"],
})
export class KitchenModuleComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
