<div class="order-row" (click)="setActive()" [attr.id]="item.orderedItemId" [class.active]="item.isActive">
  <table class="w-100">
    <tr>
      <td style="width: 40px;">
        <div class="order-counter-vertical" *ngIf="item.status == 0">
          <button (click)="increaseAmount()" class="btn btn-flat" style="padding: 5px 10px;">
            <i class="fa fa-plus" style="font-size: 21px;"></i>
          </button>
          <span type="text">{{item.quantity}}x</span>
          <button (click)="decreaseAmount()" class="btn btn-flat" style="padding: 5px 10px;">
            <i class="fa fa-minus" style="font-size: 21px;"></i>
          </button>
        </div>
        <div class="static-order-counter" *ngIf="item.status != 0">
          <div *ngIf="item.status === 3 && isSeparatePaymentActive || isSeparateMoveActive" style="border-bottom: 1px solid #dedede">
            {{item.selectionCount}}x
          </div>
          <div>{{item.quantity}}x</div>
        </div>
      </td>
      <td style="vertical-align: top; padding:5px;">
        <div class="product-name">
          {{productName}}
        </div>
        <div style="display: flex;">
          <div class="extras" *ngIf="item.extras" [innerHTML]="item.extras | extrasHtmlNormalizer">
          </div>
        </div>
      </td>
      <td style="width: 55px; padding-bottom: 5px; padding-right: 5px; text-align: right; vertical-align: top;">
        <div style="font-size: 12px;">
          {{item.totalPrice | otcurrency}}
        </div>
        <img [attr.src]="statusImage" style="width: 25px; height: 25px;"/>
      </td>
    </tr>
    <tr *ngIf="item.isActive">
      <td colspan="3" style="padding:0;" class="action-buttons-container">
        <div *ngIf="item.status == 0" style="display: flex; margin:5px;">
          <div class="d-flex justify-content-between w-100">
            <div>
              <button (click)="deleteOrder()" class="btn btn-outline-danger btn-rounded toolbar-item" style="padding: 5px 10px;">
                <i class="fa fa-trash-alt" style="font-size: 21px;"></i>
                <span>{{"Delete" | translate}}</span>
              </button>
            </div>

            <div class="d-flex">
              <button *ngIf="showEditPrice" (click)="editPrice()" class="btn btn-outline-warning btn-rounded toolbar-item" style="padding: 5px 10px; margin-top: 5px; margin-right: 0px;">
                <i class="fas fa-pencil-alt"></i>
                <span>{{"Price" | translate}}</span>
              </button>
              <button *ngIf="item.product.ingredients" (click)="addWithouts()" class="btn btn-outline-danger btn-rounded toolbar-item" style="padding: 5px 10px; float: right; margin-top: 5px; margin-right: 0px;">
                <i class="fa fa-minus"></i>
                <span>{{"Without" | translate}}</span>
              </button>
              <button (click)="addExtras()" class="btn btn-outline-primary btn-rounded toolbar-item" style="padding: 5px 10px; float: right; margin-top: 5px; margin-right: 0px;">
                <i class="fa fa-plus"></i>
                <span>{{"Extra" | translate}}</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="item.status != 0">
          <button *ngIf="showCancelButton && item.status != 5" (click)="showReturnItemConfirmationModal()" class="btn btn-outline-danger btn-rounded toolbar-item" style="padding: 5px 10px;">
            <i class="fa fa-minus-circle" style="font-size: 21px;"></i>
            {{"ReturnItem" | translate}}
          </button>
          <button *ngIf="item.status == 5" (click)="deleteItem()" class="btn btn-outline-danger btn-rounded toolbar-item" style="padding: 5px 10px;">
            <i class="fa fa-minus-circle" style="font-size: 21px;"></i>
            <span>{{"Delete" | translate}}</span>
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>

