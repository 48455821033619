<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && sendMessage()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"SendMessage" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-center">
            <div class="mr-2" style="font-size: 13px;">{{"SendTo" | translate}}: </div>
            <div class="chip m-0">
              {{to}}
            </div>
          </div>
          <div class="md-form">
            <textarea type="text" id="message" name="message" [(ngModel)]="message" class="md-textarea form-control" mdbInput required="required"></textarea>
            <label for="message">{{"Message"  | translate}}</label>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" data-dismiss="modal">
            {{"Send" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

