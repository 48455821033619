<div class="container pl-0 pr-0">
  <div class="row flex-nowrap" style="height:45px; padding-left:0px; padding-top: 7px;  border-bottom:2px solid #dedede;margin:0;width:100%;justify-content:space-around !important"
       *ngIf="userSettings?.allowSendToKitchen">
    <div class="color-status-container">
      <div class="color-status" [style.backgroundColor]="userSettings?.freeTableColor"></div>
      <label translate="FreeTable"></label>
    </div>
    <div class="color-status-container">
      <div class="color-status" [style.backgroundColor]="userSettings?.orderedTableColor"></div>
      <label translate="OrderedTable"></label>
    </div>

    <div class="color-status-container">
      <div class="color-status" [style.backgroundColor]="userSettings?.readyTableColor"></div>
      <label translate="ReadyTable"></label>
    </div>

    <div class="color-status-container">
      <div class="color-status" [style.backgroundColor]="userSettings?.servedTableColor"></div>
      <label translate="ServedTable"></label>
    </div>
  </div>
</div>
