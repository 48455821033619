import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {OrderedItem, Product, User} from "../../../models";
import {SubscriptionService} from "../../../services/subscription.service";
import { sortBy } from "lodash";
import { getItem } from "localforage";

@Component({
  selector: "app-self-order-menu",
  templateUrl: "./self-order-menu.component.html",
  styleUrls: ["./self-order-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderMenuComponent implements OnInit, OnDestroy {
  @Input()
  public show: boolean;
  @Input()
  readOnly = false;
  @Input()
  showBackButton = true;

  public searchText: string;
  public orderedItems: OrderedItem[] = [];
  public selfOrderMenuEventSubscription: Subscription;
  public productCategories: any[] = [];
  public products: Product[] = [];
  public userLogo: string;
  public restaurantName: string;
  public productOrderedEventSubscription: Subscription;
  public orderCount = 0;

  constructor(
    private subscriptionService: SubscriptionService
  ) { }

  public async ngOnInit() {
    this.productCategories = sortBy(JSON.parse(localStorage.getItem("categories")), 'productCategoryName');
    this.products = sortBy(JSON.parse(localStorage.getItem("products")), 'productName');
    this.userLogo = localStorage.getItem("user-logo");
    if(this.userLogo == "null") {
      this.userLogo = null;
    }
    this.restaurantName = localStorage.getItem("restaurant-name");
    if(this.restaurantName == "null") {
      this.restaurantName = null;
    }

    this.selfOrderMenuEventSubscription = this.subscriptionService.selfOrderMenuEvent.subscribe((items) => {
      this.show = true;
      this.orderedItems = items;
      this.orderCount = 0;
    });

    this.selfOrderMenuEventSubscription = this.subscriptionService.closeSelfOrderMenuEvent.subscribe((close) => {
      this.show = false;
    });

    this.productOrderedEventSubscription = this.subscriptionService.productOrderedEvent.subscribe((product) => {
      setTimeout(() => {
        this.orderCount += product["orderCount"];
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.selfOrderMenuEventSubscription?.unsubscribe();
    this.productOrderedEventSubscription?.unsubscribe();
  }

  public closeItemList() {
    this.show = false;

    setTimeout(() => this.hideProducts(), 1000);
  }

  public searchItems() {
    this.subscriptionService.searchStarted(this.searchText);
  }

  private hideProducts() {
    this.productCategories.forEach((cat) => {
      cat.showProducts = false;
    });
  }
}
