<div class="classic-tabs">
  <mdb-tabset [buttonClass]="'menu-products-tab table-category-list'" [contentClass]="'menu-products-tab'">
    <mdb-tab [heading]='"Top20Products" | translate'>
      <mdb-navbar SideClass="navbar navbar-light blue-grey lighten-5 p-0 pl-2">
        <ul class="navbar-nav mr-auto">
          <li></li>
        </ul>
        <div class="btn-group" data-toggle="buttons" style="margin:10px;">
          <label [class.active]="!utilsService.showAsList" class="btn btn-info btn-rounded active"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('')">
            <input class="form-check-input" type="radio" checked autocomplete="off">
            <i class="fa fa-th-large"></i>
          </label>
          <label [class.active]="utilsService.showAsList" class="btn btn-info btn-rounded"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('list')">
            <input class="form-check-input" type="radio" autocomplete="off">
            <i class="fa fa-list"></i>
          </label>
        </div>
      </mdb-navbar>
      <div [class.pos-product-list]="utilsService.showAsList">
        <div class="row">
          <app-menu-single-product *ngFor="let product of mostSoldProducts"
                                   [product]="product"
                                   [newItems]="newItems">
          </app-menu-single-product>
        </div>

        <div *ngIf="mostSoldProducts.length == 0" class="text-warning alert-no-product" style="margin-top: 10px;">
          {{"NoProductToChoose" | translate}}
        </div>
      </div>
    </mdb-tab>
    <mdb-tab [heading]='"Last20Products" | translate'>
      <mdb-navbar SideClass="navbar navbar-light blue-grey lighten-5 p-0 pl-2">
        <ul class="navbar-nav mr-auto">
          <li></li>
        </ul>
        <div class="btn-group" data-toggle="buttons" style="margin:10px;">
          <label [class.active]="!utilsService.showAsList" class="btn btn-info btn-rounded active"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('')">
            <input class="form-check-input" type="radio" checked autocomplete="off">
            <i class="fa fa-th-large"></i>
          </label>
          <label [class.active]="utilsService.showAsList" class="btn btn-info btn-rounded"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('list')">
            <input class="form-check-input" type="radio" autocomplete="off">
            <i class="fa fa-list"></i>
          </label>
        </div>
      </mdb-navbar>
      <div [class.pos-product-list]="utilsService.showAsList">
        <div class="row">
          <app-menu-single-product *ngFor="let product of lastSoldProducts"
                                   [product]="product"
                                   [newItems]="newItems">
          </app-menu-single-product>
        </div>

        <div *ngIf="lastSoldProducts.length == 0" class="text-warning alert-no-product" style="margin-top: 10px;">
          {{"NoProductToChoose" | translate}}
        </div>
      </div>
    </mdb-tab>
    <mdb-tab [heading]='"MyFavoriteProducts" | translate'>
      <mdb-navbar SideClass="navbar navbar-light blue-grey lighten-5 p-0 pl-2">
        <ul class="navbar-nav mr-auto">
          <li></li>
        </ul>
        <div class="btn-group" data-toggle="buttons" style="margin:10px;">
          <label [class.active]="!utilsService.showAsList" class="btn btn-info btn-rounded active"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('')">
            <input class="form-check-input" type="radio" checked autocomplete="off">
            <i class="fa fa-th-large"></i>
          </label>
          <label [class.active]="utilsService.showAsList" class="btn btn-info btn-rounded"
                 style="padding: 10px 20px;height: 38px;" (click)="changeListType('list')">
            <input class="form-check-input" type="radio" autocomplete="off">
            <i class="fa fa-list"></i>
          </label>
        </div>
      </mdb-navbar>
      <div [class.pos-product-list]="utilsService.showAsList">
        <div class="row">
          <app-menu-single-product *ngFor="let product of favoriteProducts"
                                   [product]="product"
                                   [newItems]="newItems">
          </app-menu-single-product>
        </div>

        <div *ngIf="favoriteProducts.length == 0" class="text-warning alert-no-product" style="margin-top: 10px;">
          {{"NoProductToChoose" | translate}}
        </div>
      </div>
    </mdb-tab>
  </mdb-tabset>
</div>
