<style type="text/css">
  .section {
    width: 70%;
    margin-left: 15%;
    padding: 10px;
    font-size: 16px;
  }

  .highlight-ot {
    font-weight: bold;
    color: #0172ac
  }
</style>

<div class="section section-header" style="text-align:center;font-size:40px;font-weight:bold;">
  <span>
    -{{"Reporting_Title" | translate}}-
  </span>
</div>

<div class="section" [innerHTML]='"WaitTime" | translate'></div>

<div class="section" [innerHTML]='"HotProducts" | translate'></div>

<div class="section" [innerHTML]='"PeakHours" | translate'></div>

<div class="section" [innerHTML]='"Answer" | translate'></div>

<div class="section" style="text-align:center;">
  <label style="font-weight:bold;" [innerHTML]='"WaitTime" | translate'>
  </label>
</div>
<div class="section" style="text-align:center;">
  <div class="youtube embed-responsive embed-responsive-16by9 z-depth-3" embed="auq2zfmz-w0" youTubeVideo>
    <div class="play-button"></div>
  </div>
</div>
