import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../services/auth.service";
import { LocalStorageService } from "../services/localstorage.service";
import { MessageService } from "../services/message.service";
import { ModalService } from "../services/modal.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private authService: AuthenticationService,
    private router: Router) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => {
      if (err.status == 200) {
        console.log("in ErrorInterceptor but status is 200");
        return;
      }

      if (err.status === 401) {
        if (this.localStorage.getUserSync()) {
          const confirmModel = {
            title: this.translateService.instant("SessionExpired_Title"),
            message: this.translateService.instant("SessionExpired_Message"),
            type: "danger",
            icon: "sign-out-alt",
            hideCancelButton: true,
            confirmed: () => {
              this.authService.logOut().subscribe(async () => {
                await this.authService.removeAllUserData();
                await this.router.navigate(["/auth/login"]);
              });
            },
          };
          this.modalService.show("confirm-dialog", confirmModel);
        } else {
          location.href = "/home/so";
        }

        return throwError(this.translateService.instant("AccessDenied"));
      } else if (err.status === 403) {
        return throwError(this.translateService.instant("AccessDenied"));
      } else {
        let message = "";

        if (err) {
          message = err.message;
          if (err.error != null) {
            if (typeof err.error === "string") {
              message = err.error;
            } else if (typeof err.error === "object") {
              message = err.error.message;
              if (err.error.errors) {
                message = err.error.errors.join("<br />");
              }
            }
          }
        }

        return throwError(message);
      }
    }));
  }
}
