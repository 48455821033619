import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep, filter, findIndex} from "lodash";
import {Product, ProductCategory} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";

@Component({
  selector: "app-product-category",
  templateUrl: "./product-category.component.html",
  styleUrls: ["./product-category.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductCategoryComponent implements OnInit {
  @Input()
  public category: ProductCategory;
  @Input()
  public categoryList: ProductCategory[] = [];

  public categoryProducts: Product[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private productService: ProductService,
    private messageService: MessageService) {
  }

  public async ngOnInit() {
    this.categoryProducts = filter(await this.localStorage.getProductsAsync(), {productCategoryId: this.category.productCategoryId});
  }

  public showProducts() {
    this.modalService.show("show-product-list-dialog", {
      category: this.category,
      products: this.categoryProducts,
    });
  }

  public showEditCategory() {
    this.modalService.show("create-product-category-dialog", {
      category: cloneDeep(this.category),
      action: "update",
      addCategory: (category) => {
        this.messageService.success(this.translateService.instant("UpdateCategorySuccess"));
        this.updateCategory(category);
      },
    });
  }

  public showDeleteCategoryConfirmation() {
    const confirmModel = {
      message: this.translateService.instant("DeleteCategoryConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {this.deleteCategory(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public deleteCategory() {
    this.productService.deleteCategory(this.category.productCategoryId).subscribe( () => {
      const index = findIndex(this.categoryList, {productCategoryId: this.category.productCategoryId});
      this.categoryList.splice(index, 1);
      this.localStorage.setProductCategories(this.categoryList);
      this.messageService.success(this.translateService.instant("DeleteCategorySuccess"));
    }, (error) => {
      this.messageService.error(error);
    });
  }

  private updateCategory(category: ProductCategory) {
    this.category = category;
    this.localStorage.updateProductCategory(category);
  }
}
