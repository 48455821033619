import { SelfOrderSingleOrderItemComponent } from './self-order-single-order-item/self-order-single-order-item.component';
import { SelfOrderProductDetailsComponent } from './self-order-product-details/self-order-product-details.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonsModule, CarouselModule, IconsModule, InputsModule, MdbSelectModule, SelectModule, WavesModule} from "ng-uikit-pro-standard";
import { UiSwitchModule } from "ngx-ui-switch";
import {AppCommonModule} from "../common/common.module";
import {PosModule} from "../pos/pos.module";
import { SelfOrderCategoriesComponent } from "./self-order-categories/self-order-categories.component";
import { SelfOrderCategoryProductsComponent } from "./self-order-category-products/self-order-category-products.component";
import { SelfOrderListComponent } from "./self-order-list/self-order-list.component";
import { SelfOrderMenuComponent } from "./self-order-menu/self-order-menu.component";
import { SelfOrderProductOrderComponent } from "./self-order-product-order/self-order-product-order.component";
import { SelfOrderRegisterComponent } from "./self-order-register/self-order-register.component";
import {SelfOrderRoMenuComponent} from "./self-order-ro-menu/self-order-ro-menu.component";
import {SelfOrderRoutingModule} from "./self-order-routing.module";
import { SelfOrderSmsVerifyComponent } from "./self-order-sms-verify/self-order-sms-verify.component";
import { SelfOrderComponent } from "./self-order.component";
import { SelfOrderMenuNotExistingComponent } from './self-order-menu-not-existing/self-order-menu-not-existing.component';
import { SelfOrderWithoutRefreshComponent } from './self-order-without-refresh/self-order-without-refresh.component';
import { SelfOrderScanAgainComponent } from './self-order-scan-again/self-order-scan-again.component';

@NgModule({
  declarations: [
    SelfOrderComponent,
    SelfOrderListComponent,
    SelfOrderCategoriesComponent,
    SelfOrderMenuComponent,
    SelfOrderRoMenuComponent,
    SelfOrderCategoryProductsComponent,
    SelfOrderRegisterComponent,
    SelfOrderSmsVerifyComponent,
    SelfOrderProductOrderComponent,
    SelfOrderProductDetailsComponent,
    SelfOrderSingleOrderItemComponent,
    SelfOrderMenuNotExistingComponent,
    SelfOrderWithoutRefreshComponent,
    SelfOrderScanAgainComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SelfOrderRoutingModule,
    TranslateModule,
    FormsModule,
    PosModule,
    AppCommonModule,
    InputsModule,
    SelectModule,
    ButtonsModule,
    WavesModule,
    IconsModule,
    MdbSelectModule,
    UiSwitchModule,
    CarouselModule.forRoot(),
  ],
  bootstrap: [SelfOrderComponent],
})
export class SelfOrderModule {
  constructor() {
  }
}
