import {CommonModule} from "@angular/common";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {PopoverModule} from "ng-uikit-pro-standard";
import {InlineEditorComponent} from "../../components/inline-editor/inline-editor.component";
import {SanitizeHtmlPipe} from "../../pipes/sanitize.html.pipe";
import {SanitizeUrlPipe} from "../../pipes/sanitize.url.pipe";
import {FormValidationDirective} from "./directives/form.validation.directive";
import {LongPressDirective} from "./directives/long.press.directive";
import {YoutubeDirective} from "./directives/youtube.directive";
import {ExtrasHtmlNormalizerPipe} from "./pipes/extras.html.normalizer.pipe";
import {LocalCurrencyPipe} from "./pipes/local.currency.pipe";

@NgModule({
  declarations: [
    LocalCurrencyPipe,
    ExtrasHtmlNormalizerPipe,
    FormValidationDirective,
    LongPressDirective,
    YoutubeDirective,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    InlineEditorComponent,
  ],
  exports: [
    CommonModule,
    LocalCurrencyPipe,
    FormValidationDirective,
    LongPressDirective,
    YoutubeDirective,
    TranslateModule,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    InlineEditorComponent,
    ExtrasHtmlNormalizerPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
  ],
})
export class AppCommonModule {
  public static forRoot(): ModuleWithProviders<AppCommonModule> {
    return {
      ngModule: AppCommonModule,
      providers: [],
    };
  }
}
