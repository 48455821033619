import { Component } from "@angular/core";

@Component({
  selector: "block-temp",
  styles: [`
    :host {
      text-align: center;
      color: #1976D2;
    }
    .block-ui-template{
      width:200px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius:10px;
      cursor: wait;
    }
    .block-ui-template .inner {
      color:white;
    }
  `],
  template: `
    <div class="block-ui-template">
      <div class='css-loader margin-bottom m-auto'></div>
      <div class="inner" style="padding:7px 0 0 5px;">{{message}}</div>
    </div>
  `,
})
export class BlockTemplateComponent {
  public message: any;
}
