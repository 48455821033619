<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right modal-scrolling" id="modalAdd" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-top-right modal-info modal-md" role="document" style="width: 750px;">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">{{"ImportCategories" | translate}}</p>
      </div>
      <div class="modal-body">
        <div id='loading' [hidden]="!loading">
          <div class='d-flex justify-content-center'>
            <div class='css-loader margin-bottom' style='font-size:40px;'></div>
            <label style='margin-left: 15px;margin-top: 10px;'>{{"Loading" | translate}}</label></div>
        </div>
        <div *ngIf="importSuccess">
          <i class='fa fa-check-circle-o' style='color:green; font-size:40px;'></i>
          <br/>
          {{("CategoriesImportSuccess" | translate).format(importedCount)}}
        </div>
        <label id='lblUpload' class='upload-area' [hidden]="loading || importedCount" style='width:100%;text-align:center;cursor:pointer;' for='categoryUpload'>
          <input id='categoryUpload' name='categoryUpload' class="d-none" type='file' (change)="onFileUploaded($event)">
          <i class='fas fa-cloud-upload-alt fa-4x'></i>
          <br/>
          <label [innerHTML]='("Categories_ChooseFile" | translate).format("/templates/" + translateService.currentLang + "/categories.xlsx")'>

          </label>
        </label>
        <div class='col-xs-12 file-list' *ngIf="selectedFile"><b>{{selectedFile.name}}</b></div>
        <br/>
        <span style='margin-left:5px !important;' id='fileList' [hidden]="loading || importedCount"></span>
      </div>
      <div class="modal-footer justify-content-between">
        <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
          {{"Close" | translate}}
        </a>
        <button type="submit" class="btn btn-primary btn-rounded waves-effect waves-light" (click)="importCategories()">
          {{"Import" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
