<div class="right-container" id="menuModal" style="width: 100%;" [class.right-container-slide-left]="show === true" [class.right-container-slide-right]="show === false" [class.no-logo-restaunrant-name]="!userLogo && !restaurantName">
    <div class="circle"></div>
    <div class="navbar-collapse full-width d-flex justify-content-between" [class.first-screen]="!showBackButton" style="display:flex;height:65px;">
        <ul class="navbar-nav navbar-header mr-auto" *ngIf="showBackButton">
            <li class="nav-item">
                <a href="javascript:void(0);" (click)="closeItemList()" class="back-link">
                    <i class="fas fa-arrow-left" style="margin-right: 6px;"></i>
                    <span>{{"Back" | translate}}{{orderCount == 0 ? '' : '(' + orderCount + ')'}}</span>
                </a>
            </li>
        </ul>
        <div class="logo-container" *ngIf="!showBackButton">
            <img [src]="userLogo" class="user-logo" *ngIf="userLogo" />
            <div class="restaurant-name">{{restaurantName}}</div>
        </div>
    </div>

    <form class="form-inline form-sm" style="color: white;">
        <div class="search-field-group">
            <span class="input-group-text border-0" id="search-addon"><i class="fas fa-search" style="color:var(--so-secondary-color)"></i></span>
            <input #searchField type="search" name="searchText" class="form-control rounded" placeholder="{{'SearchMenuList' | translate}}" aria-label="Search" aria-describedby="search-addon" [(ngModel)]="searchText" (ngModelChange)="searchItems()" (keyup.enter)="searchField.blur()"
            />
        </div>
    </form>

    <div style="width: 100%;">
        <app-self-order-categories [categories]="productCategories" [products]="products" [readOnly]="readOnly"></app-self-order-categories>
    </div>
</div>
