<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog discount-dialog modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{"AddProduct" | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="html5-form">
          <div class="row" style="margin:0">
            <div class="md-form col-8 pl-0">
              <input mdbInput type="text" class="form-control" id="product" [(ngModel)]="name" required/>
              <label>{{"ProductName" | translate}}</label>
            </div>
            <div class="md-form col-4 pl-0">
              <input mdbInput type="number" class="form-control" [(ngModel)]="price" step="0.01" min="0" style="padding-left: 10px;" required>
              <label>{{"Price" | translate}}</label>
            </div>
          </div>
          <div style="display:flex;">
            <span class="mt-1 mr-2">{{"SaveForNextUse" | translate}}</span>
            <div class="switch round blue-white-switch">
              <label>
                <input type="checkbox" [(ngModel)]="saveForNext" />
                <span class="lever"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-info btn-rounded" (click)="saveItem()">
          <i class="fas fa-plus pr-1"></i>
          <span>{{"AddProduct" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
