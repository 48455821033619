import { Component, OnInit } from "@angular/core";
import {UserSettings} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";

@Component({
  selector: "app-status-color",
  templateUrl: "./status-color.component.html",
  styleUrls: ["./status-color.component.scss"],
})
export class StatusColorComponent implements OnInit {

  public userSettings: UserSettings = {} as UserSettings;
  constructor(private localStorage: LocalStorageService) {
  }

  public async ngOnInit() {
    this.userSettings = await this.localStorage.getUserSettingsAsync();
  }

}
