<div class="order-row" (click)="setActive()" [attr.id]="item.orderedItemId" [class.active]="item.isActive">
    <table class="w-100">
        <tr>
            <td style="vertical-align: top; padding:5px;">
                <div class="product-name">
                    {{item.quantity > 1 ? (item.quantity + 'x') : ''}}&nbsp;{{productName}}
                </div>
                <div style="display: flex;">
                    <div class="extras" *ngIf="item.extras" [innerHTML]="item.extras | extrasHtmlNormalizer">
                    </div>
                </div>
            </td>
            <td style="width: 55px; padding-bottom: 5px; padding-right: 5px; text-align: right; vertical-align: top;">
                <div class="price-label mb-3">
                    {{item.totalPrice | otcurrency}}
                </div>
                <img [attr.src]="statusImage" *ngIf="statusImage" style="width: 25px; height: 25px;" class="mb-3" />
            </td>
        </tr>
        <tr *ngIf="item.status == 0">
            <td colspan="3" class="action-buttons-container">
                <a (click)="deleteOrder()" class="toolbar-item text-danger float-left">
                    <i class="fa fa-trash-alt"></i>
                    <span>{{"Delete" | translate}}</span>
                </a>

                <a (click)="editOrder()" class="toolbar-item text-primary float-right">
                    <i class="fas fa-pen"></i>
                    <span>{{"UpdateOrderedItem" | translate}}</span>
                </a>
            </td>
        </tr>
    </table>
</div>