import {BaseModel} from "./base.model";

export class User extends BaseModel {
  public restaurantName: string;

  public userId: string;

  public userName: string;

  public firstName: string;

  public lastName: string;

  public address: string;

  public city: string;

  public state: string;

  public zip: string;

  public country: string;

  public language: string;

  public currency: string;

  public currencySymbol: string;

  public taxId: string;

  public phoneNumber: string;

  public email: string;

  public userPasswordHash: string;

  public packageId: string;

  public stripeCustomerId: string;

  public stripeClientSecret: string;

  public isActivated: boolean;

  public isLoggedIn: boolean;

  public resetCode: string;

  public resetDate: Date | string | null;

  public role: string;

  public affiliateId: string;

  public latitude: number;

  public longitude: number;

  public logoUrl: string;

  public fiskalyOrganisationId: string;
}
