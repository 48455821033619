import {Component, OnInit, ViewChild} from "@angular/core";
import {cloneDeep, sortBy} from "lodash";
import {CarouselComponent, ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Extra, Product, SideDish} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-product-sidedish-dialog",
  templateUrl: "./product-sidedish-dialog.component.html",
  styleUrls: ["./product-sidedish-dialog.component.scss"],
})
export class ProductSidedishDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("carouselRef", { static: true })
  public carouselRef: CarouselComponent;

  public product: Product = new Product();
  public selectedPortionSize: string;
  public manualExtra: string;

  public extras: Extra[] = [];
  public ingredients: Array<{name, selected}> = [];
  public showPrevButton: boolean;
  public showNextButton: boolean;
  public canAddProduct: boolean;
  public sideDishes: SideDish[] = [];
  public onProductAdded: any;

  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
  ) {
    super();
    this.modalService.register("sidedish-dialog", this);
  }

  public ngOnInit(): void {
  }

  public hide() {
    if(!this.modal) return;

    super.hide();
    this.modal.hide();

    this.product = new Product();

    this.sideDishes.map((s) => s.isSelected = false);
    this.sideDishes = [];

    this.extras = [];
    this.ingredients = [];

    this.selectedPortionSize = null;
    this.manualExtra = "";

    this.showPrevButton = false;
    this.showNextButton = false;
    this.canAddProduct = false;

    this.carouselRef.slides.map((s) => s.active = false);
    if (this.carouselRef.slides.length > 0) {
      this.carouselRef.slides[0].active = true;
    }
    this.carouselRef.selectSlide(0);
  }

  public show(args) {
    super.show(args);
    this.product = args.product;
    this.onProductAdded = args.onProductAdded;

    if (args.category) {
      this.extras = args.category.extras;
      this.sideDishes = this.product.sideDishes.concat(args.category.sideDishes);
    }
    this.extras = sortBy(this.extras, [extra => extra.name.toLowerCase()]);
    this.extras.map((extra) => extra.count = 0);
    this.manualExtra = "";

    if (this.product.ingredients) {
      let ingredientList = this.product.ingredients.split(",");
      ingredientList = sortBy(ingredientList);
      this.ingredients = [];
      ingredientList.forEach((name) => {
        this.ingredients.push({
          name,
          selected: false,
        });
      });
    }
    this.canAddProduct = this.sideDishes.length == 0 && this.product.priceCategories.length == 1;
    this.modal.show();
  }

  public portionSizeSelected(category: string) {
    this.selectedPortionSize = category;
    this.canAddProduct = this.sideDishes.length == 0;
    setTimeout(() => { this.showNextSlide(); }, 200);
  }

  public sideDishSelected() {
    this.canAddProduct = this.sideDishes.filter((s) => s.isSelected).length > 0;
  }

  public showNextSlide() {
    if (this.carouselRef.getCurrentSlideIndex() < this.carouselRef.slides.length - 1) {
      this.carouselRef.nextSlide();
    }
  }

  public showPrevSlide() {
    if (this.carouselRef.getCurrentSlideIndex() > 0) {
      this.carouselRef.previousSlide();
    }
  }

  public showSpecialWishes() {
    this.modalService.show("special-wishes-dialog", {
      product: this.product,
      manualExtras: this.manualExtra,
      onClose: (extras) => {
        if (!extras) { return; }
        if (!this.manualExtra) {
          this.manualExtra = extras;
        } else {
          this.manualExtra = "," + extras;
        }
      },
    });
  }

  public increaseCount(extra: Extra) {
    extra.count++;
  }

  public decreaseCount(extra: Extra) {
    if (extra.count > 0) {
      extra.count--;
    }
  }

  public orderProduct() {
    this.product.unitPrice = this.getUnitPrice();

    this.product.extrasInfo = {
      extras: "",
      extrasId: "",
      extrasPrice: 0,
      ingredientsToRemove: [],
      manualExtra: this.manualExtra,
      selectedExtra: "",
      sideDishes: this.sideDishes.filter((s) => s.isSelected),
      orderedVariations: [],
      selectedPriceCategory: this.selectedPortionSize,
    };
    this.setExtra();

    const product = cloneDeep(this.product);
    this.subscriptionService.productOrdered(product);

    this.hide();

    if(this.onProductAdded)
      this.onProductAdded(this.product);
  }

  public onActiveSlideChange() {
    this.showPrevButton = false;
    this.showNextButton = false;
    if (this.carouselRef.getCurrentSlideIndex() > 0) {
      this.showPrevButton = true;
    }

    if (this.carouselRef.getCurrentSlideIndex() < this.carouselRef.slides.length - 1) {
      this.showNextButton = true;
    }
  }

  public getUnitPrice() {
    if (this.product.unitPrices.length === 1) {
      return parseFloat(this.product.unitPrices[0]);
    }

    const priceIndex = this.product.priceCategories.indexOf(this.selectedPortionSize);
    if (priceIndex > -1) {
      return parseFloat(this.product.unitPrices[priceIndex]);
    }

    return 0;
  }

  public setExtra() {
    const selectedExtras = [];
    const selectedExtraIds = [];
    let extrasPrice = 0;

    const sideDishes = this.sideDishes.filter((s) => s.isSelected);
    sideDishes.forEach((sideDish) => {
      selectedExtras.push(sideDish.name);

      extrasPrice += sideDish.price;
    });

    this.extras.forEach((extra) => {
      if (extra.count > 1) { selectedExtras.push(extra.count + "x " + extra.name); }
      else if (extra.count === 1) { selectedExtras.push(extra.name); }

      if (extra.count > 0) {
        selectedExtraIds.push(extra.count + "*" + extra.id);
      }

      extrasPrice += extra.count * extra.price;
    });

    this.product.extrasInfo.extrasPrice = extrasPrice;
    this.product.extrasInfo.extrasId = selectedExtraIds.join(",");
    this.product.extrasInfo.selectedExtra = selectedExtras.length > 0 ? "(+) " + selectedExtras.join(", ") : "";

    this.product.extrasInfo.ingredientsToRemove = this.ingredients.filter((i) => i.selected).map((i) => i.name);

    const arr = [];
    if (this.manualExtra) { arr.push(this.manualExtra); }
    if (this.product.extrasInfo.selectedExtra) { arr.push(this.product.extrasInfo.selectedExtra); }
    if (this.product.extrasInfo.ingredientsToRemove?.length > 0) { arr.push("(-) " + this.product.extrasInfo.ingredientsToRemove.join(",")); }

    this.product.extrasInfo.extras = arr.join("\n");
  }

  public toggleSelect(ingredient, event) {
    event.preventDefault();
    ingredient.selected = !ingredient.selected;
  }
}
