import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ModalDirective} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {Employee} from "../../../../models/employee.model";
import {EmployeeService} from "../../../../services/employee.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";

@Component({
  selector: "app-create-employee-dialog",
  templateUrl: "./create-employee-dialog.component.html",
  styleUrls: ["./create-employee-dialog.component.scss"],
})
export class CreateEmployeeDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @BlockUI() public blockUI: NgBlockUI;

  public employee: Employee = new Employee();
  public onEmployeeAdded: any;
  public roles: any[];
  public isPasswordUpdated: boolean;
  public originalPassword: string;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private employeeService: EmployeeService,
    private messageService: MessageService,
  ) {
    super();
  }

  public async ngOnInit() {
    this.modalService.register("create-employee-dialog", this);

    this.roles = [
      {value: "Administrator", label: await this.translateService.get("Administrator").toPromise()},
      {value: "AdminHelper", label: await this.translateService.get("AdminHelper").toPromise()},
      {value: "Waiter", label: await this.translateService.get("Waiter").toPromise()},
      {value: "CookBarman", label: await this.translateService.get("CookBarman").toPromise()},
    ];
  }

  public async addEmployee() {
    this.blockUI.start(await this.translateService.get("PleaseWait").toPromise());

    if (!this.employee.userId) {
      this.employee.userId = uuid();
    }

    this.employeeService.updateEmployee(this.employee).subscribe(async (employee: Employee) => {
      this.messageService.success(await this.translateService.get("AddEmployeeSuccess").toPromise());
      this.hide();

      if (this.onEmployeeAdded) {
        this.onEmployeeAdded(employee);
      }

      this.blockUI.stop();
    }, (err) => {
      this.blockUI.stop();
      this.messageService.error(err || this.translateService.instant("AddEmployeeError"));
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.employee = params.employee;
    this.onEmployeeAdded = params.onEmployeeAdded;
    this.originalPassword = this.employee.userPasswordHash;
    this.modal.show();
  }

  public emptyPassword() {
    if (this.isPasswordUpdated) {
      return;
    } else {
      this.employee.userPasswordHash = "";
    }
  }

  public resetPassword() {
    if (this.isPasswordUpdated) { return; }

    this.employee.userPasswordHash = this.originalPassword;
  }

  public passwordUpdated() {
    this.isPasswordUpdated = true;
  }
}
