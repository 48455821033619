import {Component, Input, OnInit} from "@angular/core";
import {TableCategory} from "../../../../models";

@Component({
  selector: "app-table-category-list",
  templateUrl: "./category-list.component.html",
  styleUrls: ["./category-list.component.scss"],
})
export class CategoryListComponent implements OnInit {

  @Input()
  public tableCategories: TableCategory[] = [];

  constructor() {
  }

  public ngOnInit(): void {
  }
}
