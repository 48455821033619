<div mdbModal #modal="mdbModal" [config]="{ backdrop: false, keyboard: false, ignoreBackdropClick: true }" class="modal fade category-dialog" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addCategory()" class="html5-form mh-100 overflow-auto" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md cascading-modal" role="document" style="margin-top: 35px !important; ">
      <div class="modal-content">
        <div class="modal-c-tabs">
          <mdb-tabset #tabs (getActiveTab)="onGetActiveTab($event)" [buttonClass]="'nav md-tabs tabs-2 tabs-cyan darken-3'" [contentClass]="''" class="tab-content">
            <!--Panel 1-->
            <mdb-tab [heading]='"Basics" | translate' class="tab-pane fade in show active" role="tabpanel">
              <div class="row">
                <div class="col-12">
                  <div class="md-form">
                    <input mdbInput #nameInputRef type="text" class="form-control" id="catName" name="catName" [(ngModel)]="category.productCategoryName" (keydown.enter)="stopEventPropagation($event)" required/>
                    <label for="catName">{{"CategoryName" | translate}}</label>
                  </div>
                  <div class="md-form">
                    <textarea mdbInput class="md-textarea form-control p-0" id="description" name="description" rows="3" [(ngModel)]="category.productCategoryDescription" (keydown.enter)="stopEventPropagation($event)"></textarea>
                    <label for="description">{{"ProductDescription" | translate}}</label>
                  </div>
                  <div class="md-form" *ngIf="vatDefinitions == null" >
                    <input mdbInput type="number" step="0.01" class="form-control" id="tax" name="text" min="0" max="100" [(ngModel)]="category.tax" (keydown.enter)="stopEventPropagation($event)"/>
                    <label for="tax">{{"Tax" | translate}}</label>
                  </div>
                  <div class="md-form" *ngIf="vatDefinitions != null">
                    <mdb-select-2 name="tax" [placeholder]="'ChooseTax' | translate" [(ngModel)]="category.tax" >
                      <mdb-select-option *ngFor="let option of vatDefinitions" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <label class="pt-1 pr-2" for="allowSendToKitchen">{{"SetOrdersReadyAfterOrder" | translate}}</label>
                    <div class="switch round blue-white-switch">
                      <label>
                        <input type="checkbox" name="allowSendToKitchen" id="allowSendToKitchen" value="true" [(ngModel)]="setItemsAsReady"/>
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                  <div id="product-category-pictures" class="form-group">
                    <div>{{"Picture" | translate}} <label *ngIf="categoryPictures.length > 0">(<a class="text-danger" (click)="resetImage()">{{"Delete" | translate}}</a>)</label></div>
                    <dropzone [config]="dropzoneConfig" [message]="'ClickToUpload' | translate" (init)="initDropzone($event)" (complete)="onUploadSuccess($event)"></dropzone>
                  </div>
                </div>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"PriceCategories" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <table style="width: 100%;">
                      <tr>
                        <td class="md-form">
                          <input #txtPriceCatRef mdbInput type="text" id="txtPriceCat" name="txtPriceCat" [(ngModel)]="newPriceCategory" [placeholder]="'PriceCategory_Tooltip' | translate" (keydown.enter)="addPriceCategory($event)" class="form-control"
                                 style="width: 100%;margin-bottom: 0"/>
                          <label for="txtPriceCat">{{"PriceCategory" | translate}}</label>
                        </td>
                        <td style="width: 30px; text-align: right; font-size: 26px;">
                          <a href="javascript:void(0);" class="btn btn-floating primary-color" style="position:relative;: rela">
                            <i class="fa fa-plus white-text" style="font-size: 23px;" (click)="addPriceCategory($event)"></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                    <table style="width: 100%;">
                      <tbody id="priceCatBody">
                      <tr *ngFor="let cat of category.priceCategories; let i = index;trackBy:trackByIdx">
                        <td style="width: 30px; text-align: left; font-size: 26px;">
                          <a href="javascript:void(0);" class="btn btn-floating danger-color ml-0" style="position:relative;" (click)="deletePriceCategory(cat)">
                            <i class="far fa-trash-alt" style="font-size: 18px;"></i>
                          </a>
                        </td>
                        <td class="md-form">
                          <input mdbInput type="text" name="category" [name]="'category' + i" class="form-control w-100 mb-0" [(ngModel)]="category.priceCategories[i]" (keydown.enter)="stopEventPropagation($event)" required="required"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"Extras" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="row">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <div class="md-form m-0">
                        <input mdbInput #txtExtraNameRef type="text" id="txtExtraName" name="txtExtraName" [(ngModel)]="newExtraName" (keydown.enter)="addExtra($event)" class="form-control" style="width: 100%"/>
                        <label for="txtExtraName">{{"ExtraName" | translate}}</label>
                      </div>
                    </td>

                    <td style="width: 80px;">
                      <div class="md-form m-0">
                        <input mdbInput type="number" step="0.01" id="txtExtraPrice" name="txtExtraPrice" [(ngModel)]="newExtraPrice" (keydown.enter)="addExtra($event)" class="form-control" style="width: 100%"/>
                        <label for="txtExtraPrice">{{"Price" | translate}}</label>
                      </div>
                    </td>

                    <td style="width: 30px; text-align: right; font-size: 23px;">
                      <a href="javascript:void(0);" class="btn btn-floating primary-color">
                        <i class="fa fa-plus white-text" (click)="addExtra($event)" style="font-size: 25px;"></i>
                      </a>
                    </td>
                  </tr>
                </table>

                <h4 style="margin-top: 20px;">{{"AvailableExtras" | translate}}</h4>
                <table style="width: 100%;">
                  <tbody id="extrasBody">
                  <tr *ngFor="let extra of category.extras">
                    <td style="width: 30px; text-align: left;">
                      <a href="javascript:void(0);" (click)="deleteExtra(extra)" class="btn btn-floating danger-color">
                        <i class="far fa-trash-alt" style="font-size: 25px"></i>
                      </a>
                    </td>
                    <td class="md-form">
                      <input mdbInput type="text" class="form-control" style="width: 100%" required="required" [name]="'txtExtraName' + extra.id" [(ngModel)]="extra.name" (keydown.enter)="stopEventPropagation($event)"/>
                    </td>
                    <td style="width: 80px;" class="md-form">
                      <input mdbInput type="number" step="0.01" class="form-control" style="width: 100%" [name]="'txtExtraPrice' + extra.id" [(ngModel)]="extra.price" (keydown.enter)="stopEventPropagation($event)" />
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div *ngIf="category.extras.length  == 0" class="text-warning mb-3">
                  {{"NoExtras" | translate}}
                </div>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"SideDishes" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="row">
                <table style="width: 100%;">
                  <tr>
                    <td>
                      <div class="md-form m-0">
                        <input mdbInput #txtSideDishNameRef type="text" id="txtSideDishName" name="txtSideDishName" [(ngModel)]="newSideDishName" (keydown.enter)="addSideDish($event)" class="form-control" style="width: 100%"/>
                        <label for="txtSideDishName">{{"ExtraName" | translate}}</label>
                      </div>
                    </td>

                    <td style="width: 80px;">
                      <div class="md-form m-0">
                        <input mdbInput type="number" step="0.01" id="txtSideDishPrice" name="txtSideDishPrice" [(ngModel)]="newSideDishPrice" (keydown.enter)="addSideDish($event)" class="form-control" style="width: 100%"/>
                        <label for="txtSideDishPrice">{{"Price"}}</label>
                      </div>
                    </td>

                    <td style="width: 30px; text-align: right; font-size: 23px;">
                      <a href="javascript:void(0);" class="btn btn-floating primary-color">
                        <i class="fa fa-plus text-white" (click)="addSideDish($event)" style="font-size: 25px;"></i>
                      </a>
                    </td>

                  </tr>
                </table>

                <h4 style="margin-top: 20px;">{{"AvailableSideDishes" | translate}}</h4>
                <table style="width: 100%;">
                  <tbody>
                  <tr *ngFor="let sideDish of category.sideDishes">
                    <td style="width: 30px; text-align: left;">
                      <a href="javascript:void(0);" (click)="deleteSideDish(sideDish)" class="btn btn-floating danger-color">
                        <i class="far fa-trash-alt text-white" style="font-size: 25px;"></i>
                      </a>
                    </td>
                    <td class="md-form">
                      <input mdbInput type="text" class="form-control w-100" required="required" [name]="'sideDishName' + sideDish.id" [(ngModel)]="sideDish.name" (keydown.enter)="stopEventPropagation($event)"/>
                    </td>
                    <td style="width: 80px;">
                      <div class="md-form m-0">
                        <input mdbInput type="text" class="form-control w-100" required="required" [name]="'sideDishPrice' + sideDish.id" [(ngModel)]="sideDish.price" (keydown.enter)="stopEventPropagation($event)"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div *ngIf="category.sideDishes.length == 0" class="text-warning mb-3">{{"NoSideDishes" | translate}}</div>
              </div>
            </mdb-tab>
          </mdb-tabset>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" class="toolbar-item">
            <i class="text-white" [ngClass]="{'fa fa-plus ' : action == 'add' , 'fas fa-save' : action == 'update' }"></i>
            {{(action == 'update' ? "Save" : "Add") | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
