<div class="row z-depth-1 p-3 dashboard-toolbar" style="margin: 0 0 15px 0;">
  <div class="col-sm-12 col-md-6 col-lg-4 pl-0 mb-1">
    <div class="d-flex align-items-center p-2 border border-light cal-control">
      <i class="far fa-calendar-alt" style="font-size: 20px; margin-right: 5px;"></i>
      <input type="text"
             ngxDaterangepickerMd
             [locale]="{applyLabel: applyLabel, format: 'LL', customRangeLabel: customRangeLabel}"
             [(ngModel)]="selectedDates"
             [showCustomRangeLabel]="true"
             [alwaysShowCalendars]="true"
             [ranges]="ranges"
             [linkedCalendars]="true"
             [showClearButton]="false"
             (change)="datesUpdated($event)"
             readonly="readonly"
             class="border-0 w-100"/>
      <span class="date-range-toggle" style=""></span>
    </div>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-8">
    <mdb-select-2 [(ngModel)]="selectedPaymentType" (ngModelChange)="filterInvoices($event)">
      <mdb-select-option value=""></mdb-select-option>
      <mdb-select-option *ngFor="let option of paymentTypes" [value]="option.name">{{ option.label }}</mdb-select-option>
    </mdb-select-2>
  </div>
</div>
