<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade top" tabindex="-1" role="dialog" [style.z-index]="zIndex+1"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <form validateForm #formRef="ngForm" (ngSubmit)="savePaymentTypes()">
    <div class="modal-dialog modal-notify modal-info" role="document">
      <div class="modal-content text-center">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"PaymentTypes" | translate}}</p>
        </div>
        <div class="modal-body d-flex flex-column align-items-center">
          <a tpye="button" mdbBtn outline="true" rounded="true" color="primary" (click)="addPaymentType()">
            <i class="fas fa-plus text-primary"  style="font-size:15px; margin-right: 3px;"></i>
            <span>{{"PaymentTypes.AddNewPayment" | translate}}</span>
          </a>
          <table>
            <thead>
            <tr>
              <th>{{"PaymentTypes.Name" | translate}}</th>
              <th>{{"PaymentTypes.Label" | translate}}</th>
              <th>{{"PaymentTypes.IsActive" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let paymentType of paymentTypes; let i = index;">
              <td>
                <input mdbInput type="text" [name]="'name' + i" class="form-control w-100 mb-0" [(ngModel)]="paymentType.name" (keydown.enter)="stopEventPropagation($event)" required="required"/>
              </td>
              <td>
                <input mdbInput type="text" [name]="'label' + i" class="form-control w-100 mb-0" [(ngModel)]="paymentType.label" (keydown.enter)="stopEventPropagation($event)" required="required"/>
              </td>
              <td>
                <div class="switch round blue-white-switch">
                  <label>
                    <input type="checkbox" [name]="'active' + i" value="true" [(ngModel)]="paymentType.isActive"/>
                    <span class="lever"></span>
                  </label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" outline="true" rounded="true" class="waves-effect"
             (click)="hide()"
             mdbWavesEffect>{{"Close" | translate}}</a>
          <button mdbBtn color="primary" rounded="true" class="waves-effect" mdbWavesEffect>{{"Save" | translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>
