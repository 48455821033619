import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, without, cloneDeep} from "lodash";
import {Subscription} from "rxjs";
import {OrderStatus} from "../../../../enums/order.status.enum";
import {OrderedItem, Product, ProductCategory, ProductPicture, SideDish} from "../../../../models";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {ProductService} from "../../../../services/product.service";
import {SubscriptionService} from "../../../../services/subscription.service";
import {LocalCurrencyPipe} from "../../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-menu-single-product",
  templateUrl: "./menu-single-product.component.html",
  styleUrls: ["./menu-single-product.component.scss", "./menu-single-product.component.media.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MenuSingleProductComponent implements OnInit, OnDestroy {
  @Input()
  public product: Product;
  @Input()
  public newItems: OrderedItem[] = [];
  @Input()
  public retailMode: boolean;

  public showPriceCategories = false;
  public orderCount = 0;
  public showProductPicture: boolean;
  public images: ProductPicture[];
  public showProductPrice: boolean;
  public currencyPipe: LocalCurrencyPipe;
  public allowFavorite = true;
  public isFavorite: boolean;
  public settingFavorite: boolean;
  public formattedUnitPrices: string[] = [];
  public productCategory: ProductCategory;
  public bottomSheetOption = {
    title: "Angular Bottom Sheet",
    backgroundColor: "#ffffff",
    fontColor: "#363636"
  };

  private categorySideDishes: SideDish[];
  private orderMenuEventSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService,
    private productService: ProductService,
    private messageService: MessageService,
    private modalService: ModalService
  ) {
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public ngOnInit() {
    this.localStorage.getUserSettingsAsync().then(userSettings => {
      this.showProductPicture = !userSettings.hideProductPicture;
      this.showProductPrice = !userSettings.hideProductPrice;
    });

    this.localStorage.getFavoriteProductsAsync().then(favorites => {
      this.isFavorite = favorites.indexOf(this.product.productId) > -1;
    });

    this.getImages().then();

    this.localStorage.getProductCategoriesAsync().then(categories => {
      this.productCategory = find(categories, {productCategoryId: this.product.productCategoryId});
      this.categorySideDishes = this.productCategory ? this.productCategory.sideDishes : [];
    });

    if (!this.retailMode) {
      this.orderCount = this.getOrderCount(this.newItems);
    }

    this.orderMenuEventSubscription = this.subscriptionService.orderMenuEvent.subscribe(() => {
      this.orderCount = this.getOrderCount(this.newItems);
    });

    const prices = [];
    for (const price of this.product.unitPrices) {
      prices.push(this.formatCurrency(price));
    }
    this.formattedUnitPrices = prices;
  }

  public ngOnDestroy(): void {
    this.product = null;
    this.images = null;
    this.formattedUnitPrices = null;
    this.categorySideDishes = null;
    this.orderMenuEventSubscription?.unsubscribe();
  }

  public orderProductWithSinglePrice() {
    if (this.product.isOutOfStock) {
      return;
    }

    if (this.categorySideDishes.length + this.product.sideDishes?.length > 0) {
      this.modalService.show("sidedish-dialog", {
        product: this.product,
        category: this.productCategory,
        onProductAdded: () => {
          this.orderCount++;
        }
      });
    } else {
      if (this.product.unitPrices?.length > 1) {
        this.showPriceCategories = true;
      } else {
        this.product.unitPrice = parseFloat(this.product.unitPrices[0])

        const product = cloneDeep(this.product);
        this.subscriptionService.productOrdered(product);

        if (!this.retailMode) {
          this.orderCount++;
        }
      }
    }
  }

  public orderProductWithSelectedCategory(category: string, $event) {
    $event.stopImmediatePropagation();
    this.showPriceCategories = false;
    if (this.product.isOutOfStock) {
      return;
    }

    this.product.unitPrice = this.getUnitPrice(category);

    this.product.extrasInfo = {
      extras: "",
      extrasId: "",
      extrasPrice: 0,
      ingredientsToRemove: [],
      manualExtra: "",
      selectedExtra: "",
      sideDishes: [],
      selectedPriceCategory: category,
      orderedVariations: [],
    };

    const product = cloneDeep(this.product);
    this.subscriptionService.productOrdered(product);

    if (!this.retailMode) {
      this.orderCount++;
    }
  }

  public async showProductDetails() {
    this.modalService.show("product-details", {product: this.product, productPictures: await this.localStorage.getProductPicturesAsync()});
  }

  public async getImages(): Promise<any> {
    this.images = filter(await this.localStorage.getProductPicturesAsync(), {productId: this.product.productId, dimensions: "thumbnail"});
    if (this.images.length === 0) {
      this.images = filter(await this.localStorage.getProductPicturesAsync(), {productId: this.product.productId, dimensions: "original"});
    }
  }

  public setFavorite(event) {
    event.stopPropagation();
    if (!this.product?.productId) {
      return;
    }

    this.settingFavorite = true;
    this.productService.setFavorite(this.product.productId).subscribe(async (result) => {
      this.isFavorite = result.isFavorite;

      const favorites = await this.localStorage.getFavoriteProductsAsync();
      if (this.isFavorite) {
        favorites.push(this.product.productId);
        this.localStorage.setFavoriteProducts(favorites);
      } else {
        this.localStorage.setFavoriteProducts(without(favorites, this.product.productId));
      }
      this.subscriptionService.favoriteProductUpdated(this.product);

      this.settingFavorite = false;
    }, (error) => {
      this.messageService.error(error);
      this.settingFavorite = false;
    });
  }

  public formatCurrency(currency: string) {
    return this.currencyPipe.transform(parseFloat(currency));
  }

  public getUnitPrice(selectedPriceCategory) {
    if (this.product.unitPrices.length === 1) {
      return parseFloat(this.product.unitPrices[0]);
    }

    const priceIndex = this.product.priceCategories.indexOf(selectedPriceCategory);
    if (priceIndex > -1) {
      return parseFloat(this.product.unitPrices[priceIndex]);
    }

    return 0;
  }

  public getThumbnail(url: string) {
    if (url.endsWith("plate.png")) {
      return url;
    }

    const lastIndex = url.lastIndexOf("/");
    const firstPart = url.substring(0, lastIndex);
    if (firstPart.endsWith("150")) {
      return url;
    }

    return firstPart + "/150" + url.substring(lastIndex);
  }

  private getOrderCount(newItems: OrderedItem[]) {

    const orderedItems = filter(newItems, (item) => {
      return item.status === OrderStatus.new &&
        item.product?.productId === this.product?.productId;
    });

    return orderedItems.reduce((acc, item) => acc + item.quantity, 0);
  }

  hidePriceCategory($event) {
    $event.stopImmediatePropagation();
    this.showPriceCategories = false;
  }
}
