import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: "app-payment-settings-dialog",
  templateUrl: "./payment-settings-dialog.component.html",
  styleUrls: ["./payment-settings-dialog.component.scss"],
})
export class PaymentSettingsDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public package: string;
  public paypalPlanId: string;

  private paymentFinished: any;
  constructor(
    private modalService: ModalService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("payment-settings-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public async show(params: any): Promise<any> {
    this.package = params.packageInfo;
    this.paymentFinished = params.paymentFinished;
    this.paypalPlanId = ""; //params.packageInfo.paypalPlanId;
    super.show(params);
    this.modal.show();
  }

  onPaymentDone() {
    this.paymentFinished && this.paymentFinished(this.package);

    this.hide();
  }
}
