import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {groupBy, minBy, sortBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {OrderedItem} from "../../models";
import {LocalStorageService} from "../../services/localstorage.service";
import {MessageService} from "../../services/message.service";
import {PosService} from "../../services/pos.service";
import {SignalRService} from "../../services/signalr.service";
import {SoundService} from "../../services/sound.service";
import {SubscriptionService} from "../../services/subscription.service";
import {TableService} from "../../services/table.service";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: "app-kds",
  templateUrl: "./kds.component.html",
  styleUrls: ["./kds.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class KdsComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  public categories: Array<{ value, label }> = [];
  public tableOrders: Array<{ orderDate, orderedItems, tableId, isNew, visible }> = [];
  public tableIds = [];

  public selectedCategories: string[] = [];
  public volumeValue = 50;
  public existingVisibleOrderedItems = false;
  public refreshingOrders = false;

  private refreshInterval: any;
  private updateMonitorSubscription: Subscription;
  private updateProductCategorySubscription: Subscription;
  private updateProductSubscription: Subscription;
  private tableUpdatedSubscription: Subscription;
  private ordersInitiallyLoaded = false;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private posService: PosService,
    private tableService: TableService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
    private signalRService: SignalRService,
    private soundService: SoundService,
    private utilsService: UtilsService
  ) {
  }

  public ngOnInit() {
    this.localStorage.getProductCategoriesAsync().then(categories => {
      const productCategories = categories;

      this.categories.push({
        value: this.utilsService.generateEmptyGuid(),
        label: "Diverse"
      });
      productCategories.forEach((category) => {
        this.categories.push({
          value: category.productCategoryId,
          label: category.productCategoryName
        });
      });

      this.localStorage.getValueAsync<string[]>("visibleCategories").then(categories => {
        let savedCategories = categories;

        if (typeof savedCategories === "string") {
          savedCategories = JSON.parse(savedCategories);
        }

        this.selectedCategories = savedCategories ?
          savedCategories :
          productCategories.map((i) => i.productCategoryId);

        this.loadOrders().then();
      });
    });

    this.localStorage.getValueAsync<number>("volume").then(volume => {
      if (!isNaN(volume)) {
        this.volumeValue = volume;
      }
    });

    this.updateMonitorSubscription = this.signalRService.updateMonitor.subscribe(async (tableId) => {
      if(!this.ordersInitiallyLoaded) return;

      const tableIndex = this.tableIds.indexOf(tableId);
      if (tableIndex === -1)
      {
        this.tableIds.push(tableId);
        this.updateTableOrders(tableId);
      }
    });

    this.updateProductCategorySubscription = this.signalRService.updateProductCategory.subscribe(() => {
    });
    this.updateProductSubscription = this.signalRService.updateProduct.subscribe(() => {
    });
    this.tableUpdatedSubscription = this.signalRService.tableUpdated.subscribe(() => {
    });
    this.refreshInterval = setInterval(() => {
      if(this.refreshingOrders) return;

      this.refreshingOrders = true;
      this.refreshOrders();
    }, 60000);
  }

  public ngOnDestroy(): void {
    this.updateMonitorSubscription?.unsubscribe();
    this.updateProductCategorySubscription?.unsubscribe();
    this.updateProductSubscription?.unsubscribe();
    this.tableUpdatedSubscription?.unsubscribe();

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public doRefresh() {
    this.refreshingOrders = true;

    this.refreshOrders();
  }

  public refreshOrders() {
    this.tableService.getAllTables().subscribe((tables) => {
      this.localStorage.setTables(tables);
      this.downloadOrderedItems();
    }, (err) => {
      console.error("Error loading tables manually, error = ", err);
      this.downloadOrderedItems();
    });
  }

  public async loadOrders() {
    this.blockUI.start(await this.translateService.get("LoadingOrders").toPromise());
    this.posService.getAllOrderedItems().subscribe((orderedItems) => {
      this.blockUI.stop();

      const groupedItems = groupBy(orderedItems, function(i) {
        return i.tableId;
      });
      const orders = [];
      for (const tableId in groupedItems) {
        if (groupedItems.hasOwnProperty(tableId)) {
          const items: OrderedItem[] = groupedItems[tableId];
          const firstItem = minBy(items, "orderDate");
          orders.push({
            tableId,
            orderDate: firstItem.orderDate,
            orderedItems: items,
            visible: this.filterItems(items).length > 0
          });
        }
      }

      this.tableOrders = sortBy(orders, "orderDate");
      this.tableIds = this.tableOrders.map(t => t.tableId);
      this.existingVisibleOrderedItems = this.tableOrders.filter((i) => i.visible).length > 0;
      this.ordersInitiallyLoaded = true;
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public onVolumeChange($event: any) {
    this.localStorage.setValue("volume", $event.value);
  }

  public addSelectedCategory(category: any) {
    if (!category || this.selectedCategories.indexOf(category) > -1) {
      return;
    }
    this.selectedCategories.push(category);
    this.localStorage.setValue("visibleCategories", this.selectedCategories);
    this.subscriptionService.visibleCategoriesUpdated(this.selectedCategories);

    this.setExistingVisibleOrderedItems();
  }

  public setSelectedCategories(category: any) {
    if (category.selected && this.selectedCategories.indexOf(category.value) === -1) {
      this.selectedCategories.push(category.value);
    }
    if (!category.selected) {
      const index = this.selectedCategories.indexOf(category.value);
      if (index > -1) {
        this.selectedCategories.splice(index, 1);
      }
    }

    this.localStorage.setValue("visibleCategories", this.selectedCategories);
    this.subscriptionService.visibleCategoriesUpdated(this.selectedCategories);

    this.setExistingVisibleOrderedItems();
  }

  public refreshSlider() {
    setTimeout(() => {
      this.volumeValue++;
    }, 300);
    setTimeout(() => {
      this.volumeValue--;

      const volumeRange = document.getElementById("volumeRange");
      if (volumeRange) {
        const textTransformElements = volumeRange.parentElement.getElementsByClassName("text-transform");
        let textTransformElement = null;
        if (textTransformElements.length > 0) {
          textTransformElement = textTransformElements[0];
        }

        if (textTransformElement) {
          textTransformElement.innerHTML = this.volumeValue.toString();
        }

        volumeRange.addEventListener("input", () => {
          if (textTransformElement) {
            textTransformElement.innerHTML = this.volumeValue.toString();
          }
        });

        volumeRange.addEventListener("change", () => {
          if (textTransformElement) {
            textTransformElement.innerHTML = this.volumeValue.toString();
          }
        });
      }
    }, 350);

  }

  public ordersUpdated() {
    this.setExistingVisibleOrderedItems();
    this.tableIds = this.tableOrders.map(t => t.tableId);
  }

  public callWaiters() {
    this.posService.callAllWaiters();
  }

  private setExistingVisibleOrderedItems() {
    let existingVisibleItems = false;
    this.tableOrders.forEach((order) => {
      if (order.visible) {
        existingVisibleItems = true;
      }
    });
    this.existingVisibleOrderedItems = existingVisibleItems;
  }

  private filterItems(items: OrderedItem[]) {
    const result = [];
    items.forEach((item) => {
      if (this.selectedCategories.indexOf(item.productCategoryId) > -1) {
        result.push(item);
      }
    });

    return result;
  }

  private removeFromArray(arr: any, el: any) {
    const index = arr.indexOf(el);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  private removeByTableId(arr: Array<{ orderDate, orderedItems, tableId, isNew, visible }>, tableId: string) {
    const index = arr.findIndex(e => e.tableId === tableId);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  private downloadOrderedItems() {
    this.posService.getTableIdsWithOrders().subscribe((tableIds) => {
      tableIds.forEach(tableId => {
        this.signalRService.notifyAllMonitorObservers(tableId);
      });

      this.refreshingOrders = false;
    }, (err) => {
      console.error("Error loading ordered items manually, error = ", err);
      this.refreshingOrders = false;
    });
  }

  private updateTableOrders(tableId: string) {
    try {
      this.posService.getTableNewItems(tableId).then(async result => {

        const firstItem = minBy(result.orderedItems, function(o) {
          return o.orderDate;
        });

        if (firstItem) {
          if(this.tableOrders.find(to => to.tableId == tableId) === undefined)
          {
            const isVisible = this.filterItems(result.orderedItems).length > 0;
            const orders = {
              tableId,
              orderDate: firstItem.orderDate,
              orderedItems: result.orderedItems,
              isNew: true,
              visible: isVisible
            };
            
            this.tableOrders.push(orders);

            this.existingVisibleOrderedItems = this.tableOrders.filter((i) => i.visible).length > 0;

            setTimeout(() => {
              orders.isNew = false;
            }, 10000);

            if (isVisible) {
              await this.soundService.play();
            }
          }
        } else {
          this.removeFromArray(this.tableIds, tableId);
          this.removeByTableId(this.tableOrders, tableId);
        }
      }).catch(() => {
        this.removeFromArray(this.tableIds, tableId);
        this.removeByTableId(this.tableOrders, tableId);
      });
    } catch {
      this.removeFromArray(this.tableIds, tableId);
      this.removeByTableId(this.tableOrders, tableId);
    }
  }
}
