import {animate, state, style, transition, trigger} from "@angular/animations";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, findIndex, minBy, orderBy, uniqBy} from "lodash";
import {Subscription} from "rxjs";
import {v4 as uuid} from "uuid";
import {OrderStatus} from "../../../enums/order.status.enum";
import {OrderedItem, Table} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {PosService} from "../../../services/pos.service";
import {SignalRService} from "../../../services/signalr.service";
import {SoundService} from "../../../services/sound.service";
import {SubscriptionService} from "../../../services/subscription.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-single-table-orders",
  templateUrl: "./single-table-orders.component.html",
  styleUrls: ["./single-table-orders.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      state("in", style({height: "*"})),
      transition("* => void", [
        style({height: "*"}),
        animate(250, style({height: 0})),
      ]),
      transition("void => *", [
        style({height: "0"}),
        animate(250, style({height: "*"})),
      ]),
    ]),
  ],
})
export class SingleTableOrdersComponent implements OnInit, OnDestroy {
  @Input()
  public orders: { orderDate, orderedItems, tableId, isNew, visible };
  @Input()
  public tableOrderList: Array<{ orderDate, orderedItems, tableId, isNew, visible }>;
  @Input()
  public readOnly: true;

  @Output()
  public orderUpdate = new EventEmitter();

  public selectedCategories: string[] = [];

  public table: Table;
  public orderStatus: string;
  public courses: Array<{ id, index, title, active, visible, collapsed, items: OrderedItem[] }>;
  public allowCourses: boolean;
  public visible = true;
  public orderTimeAgo = "";
  public totalQuantity: number;
  public sendingOrders = false;
  public temporaryCompletedStatusId = 1.5;

  private visibleCategoriesUpdateSubscription: Subscription;
  private updateMonitorSubscription: Subscription;

  private timeInterval: any;

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService,
    private posService: PosService,
    private signalRService: SignalRService,
    private soundService: SoundService,
    private messageService: MessageService) {
  }

  public async ngOnInit() {
    this.table = find(await this.localStorage.getTablesAsync(), {id: this.orders.tableId});
    this.orders.orderedItems = uniqBy(this.orders.orderedItems, (i:OrderedItem) => i.orderedItemId);
    this.orders.orderedItems.map((i) => i.originalQuantity = i.quantity);
    this.courses = this.getCourseFromItems();
    this.allowCourses = (await this.localStorage.getUserSettingsAsync()).allowCourses;

    const productCategories = await this.localStorage.getProductCategoriesAsync();
    const savedCategories = await this.localStorage.getValueAsync<string[]>("visibleCategories");

    this.selectedCategories = savedCategories ?
      savedCategories :
      productCategories.map((i) => i.productCategoryId);
    this.setCourseVisibility();
    this.setTableOrdersVisibility();
    this.totalQuantity = this.getTotalQuantity();

    this.visibleCategoriesUpdateSubscription = this.subscriptionService.visibleCategoriesUpdateEvent.subscribe((categories) => {
      this.selectedCategories = categories;
      this.setCourseVisibility();
      this.setTableOrdersVisibility();
      this.totalQuantity = this.getTotalQuantity();
    });

    this.updateMonitorSubscription = this.signalRService.updateMonitor.subscribe(async (tableId) => {
      if (tableId === this.orders.tableId) {
        const result = await this.loadNewItems();
        if (!result) {
          setTimeout(async () => { await this.loadNewItems() }, 3000);
        }
      }
    });

    this.orderTimeAgo = this.getOrderTimeAgo();
    this.timeInterval = setInterval(() => {
      this.orderTimeAgo = this.getOrderTimeAgo();
    }, 1000);
  }

  public ngOnDestroy(): void {
    this.visibleCategoriesUpdateSubscription?.unsubscribe();
    this.updateMonitorSubscription?.unsubscribe();

    if(this.timeInterval)
      clearInterval(this.timeInterval);
  }

  public getOrderTimeAgo() {
    const dateTime: any = new Date(this.orders.orderDate);
    const now: any = new Date();
    let seconds = parseInt((((now - dateTime) / 1000)).toString());
    if (seconds < 0) {
      seconds = 0;
    }

    const hour = Math.floor(seconds / 3600);
    if (hour > 0) {
      seconds = Math.floor(seconds % 3600);
    }

    let min: any = Math.floor(seconds / 60);

    if (hour > 0 || min >= 10) {
      this.orderStatus = "red";
    } else if (min >= 5) {
      this.orderStatus = "orange";
    }

    if (min < 10) {
      min = "0" + min;
    }

    let sec: any = seconds % 60;
    if (sec < 10) {
      sec = "0" + sec;
    }

    if (hour === 0) {
      return min + ":" + sec;
    }
    if (hour < 10) {
      return "0" + hour + ":" + min + ":" + sec;
    }

    return hour + ":" + min + ":" + sec;
  }

  public setAllItemsReady() {
    this.courses.map((course) => {
      course.items.forEach((i) => {
        if (i.status != OrderStatus.cancelled && i["visible"]) {
          i.status = this.temporaryCompletedStatusId;
        }
      });
    });
  }

  public getTotalQuantity() {
    let count = 0;
    this.courses.map((course) => {
      course.items.forEach((i) => {
        if (i["visible"]) {
          count += i.quantity;
        }
      });
    });
    return count;
  }

  public createCourse(index, id, title) {
    const courseId = id || uuid();
    const courseIndex = index || 1;
    title = title || this.translateService.instant("MenuCourse") + " #" + courseIndex;
    return {
      id: courseId,
      index: courseIndex,
      title: title,
      active: false,
      collapsed: false,
      visible: true,
      items: orderBy(filter(this.orders.orderedItems, (item) => {
        return (!item.courseName && courseIndex === 1) || item.courseName === title;
      }), "orderDate"),
    };
  }

  public toggleSlide(course: any) {
    course.collapsed = !course.collapsed;
  }

  public setCourseVisibility() {
    this.courses.forEach((course) => {
      course.visible = false;
      course.items.forEach((item) => {
        item["visible"] = false;
        if (this.selectedCategories.indexOf(item.product?.productCategoryId || item.productCategoryId) > -1) {
          item["visible"] = true;
          course.visible = true;
        }
      });
    });
  }

  public callOrderWaiter() {
    this.posService.callTableWaiter(this.orders.tableId);
  }

  public async sendAllFinishedItems() {
    if (this.sendingOrders) {
      return;
    }
    const orderedItemIds = [];
    const orderedItems = [];

    const items = this.orders.orderedItems;

    items.forEach((item) => {
      if (item.status === this.temporaryCompletedStatusId) {
        orderedItemIds.push(item.orderedItemId);
        orderedItems.push(item);
      }
    });

    if (orderedItemIds.length === 0) {
      this.messageService.warning(await this.translateService.get("MarkAtLeastOneItemAsReady").toPromise());
      return;
    }

    const data = {
      OrderedItemIds: orderedItemIds,
      TableId: this.orders.tableId,
      Status: OrderStatus.ready,
    };

    this.sendingOrders = true;
      this.posService.updateOrderStatus(data).subscribe(() => {
        orderedItems.forEach((item) => {
          item.status = OrderStatus.ready;
        });
        this.removeOrderedItemsFromList(orderedItemIds);

        this.sendingOrders = false;
      }, (error) => {
          console.log("error occurred while updating status, error = ", error);
          this.sendingOrders = false;
      })
  }

  public createCourseIfNotExisting(item) {
    let course = find(this.courses, {title: item.courseName});
    if (!course) {
      course = this.createCourse(
        this.courses.length + 1,
        item.courseId,
        item.courseName);
      course.items = [item];

      this.courses.push(course);
    }
    else{
      course.items.push(item);
    }

    course.visible = course.items.some(i => i["visible"]);
    return course;
  }

  public removeOrderedItemsFromList(readyOrderedItemIds: string[]) {
    this.orders.orderedItems = this.orders.orderedItems.filter((i) => readyOrderedItemIds.indexOf(i.orderedItemId) == -1);

    this.courses.forEach((course) => {
      course.items = course.items.filter((i) => readyOrderedItemIds.indexOf(i.orderedItemId) === -1);
    });
    this.refreshList();
  }

  public refreshList() {
    this.removeCoursesWithNoItems();
    this.hideCoursesWithNoVisibleItems();
    this.setTableOrdersVisibility();
    this.totalQuantity = this.getTotalQuantity();
    if (this.courses.length == 0) {
      const index = findIndex(this.tableOrderList, {tableId: this.orders.tableId});
      if (index > -1) {
        this.tableOrderList.splice(index, 1);
        this.orderUpdate.emit();
      }
    }
  }

  public updateOrder(orderedItems) {
    let existingNewItems = false;

    orderedItems.forEach((it) => {
      // New order
      const existingElement = find(this.orders.orderedItems, {orderedItemId: it.orderedItemId});
      if (!existingElement && it.status === 1) {
        this.orders.orderedItems.push(it);

        it.isNew = true;
        it["visible"] = this.selectedCategories.indexOf(it.product?.productCategoryId || it.productCategoryId) > -1
        it.originalQuantity = it.quantity;
        existingNewItems = it["visible"];

        setTimeout(() => {
          it.isNew = false;
        }, 10000);

        this.createCourseIfNotExisting(it);
      }
    });

    // delete the non existing ones
    const notExistingItems: OrderedItem[] = [];
    this.orders.orderedItems.forEach((it) => {
      const existingElement = find(orderedItems, {orderedItemId: it.orderedItemId});

      if (!existingElement) {
        notExistingItems.push(it);
      }
    });

    notExistingItems.forEach((item) => {
      const index = findIndex(this.orders.orderedItems, {orderedItemId: item.orderedItemId});
      this.orders.orderedItems.splice(index, 1);

      const course = find(this.courses, {title: item.courseName});
      if (course) {
        const index = findIndex(course.items, {orderedItemId: item.orderedItemId});
        course.items.splice(index, 1);
      }
    });

    this.removeCoursesWithNoItems();

    if (existingNewItems) {
      this.soundService.play().then();
    }
  }

  private getCourseFromItems() {
    if (this.orders.orderedItems?.length === 0) {
      return [];
    } else {
      const courses = [];
      const courseNames = [];
      const newCourseName = this.translateService.instant("MenuCourse") + " #1";
      for (const item of this.orders.orderedItems) {
        if (!item.courseName) {
          item.courseName = newCourseName;
        }

        if (item.courseName && courseNames.indexOf(item.courseName) === -1) {
          courseNames.push(item.courseName);
          courses.push(this.createCourse(courses.length + 1, item.courseId, item.courseName));
        }
      }

      return orderBy(courses, "title");
    }
  }

  private removeCoursesWithNoItems() {
    const coursesWithNoItems = [];
    this.courses.forEach((course) => {
      if (course.items.length === 0) {
        coursesWithNoItems.push(course);
      }
    });

    coursesWithNoItems.forEach((course) => {
      const index = findIndex(this.courses, {id: course.id});

      if (index > -1) {
        this.courses.splice(index, 1);
      }
    });
  }

  private hideCoursesWithNoVisibleItems() {
    this.courses.forEach((course) => {
      course.visible = course.items.filter((i) => i["visible"]).length > 0;
    });
  }

  private async loadNewItems() {
    if (this.sendingOrders) {
      return false;
    }

    const result = await this.posService.getTableNewItems(this.orders.tableId);
    if (result.orderedItems.length > 0 && result.table) {
      this.updateOrder(result.orderedItems);

      const minOrderedItem: OrderedItem = minBy(this.orders.orderedItems, function(o) {
        return o.orderDate;
      });
      this.orders.orderDate = minOrderedItem.orderDate;
    } else {
      const index = findIndex(this.tableOrderList, {tableId: this.orders.tableId});
      if (index > -1) {
        this.tableOrderList.splice(index, 1);
        this.orderUpdate.emit();
      }
    }

    this.setTableOrdersVisibility();
    this.totalQuantity = this.getTotalQuantity();

    return true;
  }

  private setTableOrdersVisibility() {
    const visibleCourse = this.courses.some(c => c.visible);
    this.visible = !!visibleCourse;
    this.orders.visible = this.visible;

    if (!this.visible)
      this.orderUpdate.emit();
  }
}
