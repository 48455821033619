import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";
import {LocalStorageService} from "../services/localstorage.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public headers: HttpHeaders;

  constructor(
    private localStorage: LocalStorageService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.headers = new HttpHeaders({ 
      'Client-Type': 'Web',       
    });

    if(this.localStorage.getSpecialAcessToken()) {
      this.headers = this.headers.append('SpecialAccessToken', this.localStorage.getSpecialAcessToken());
    }

    request = request.clone({
      headers: this.headers,
    });

    return from(this.handle(request, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const jwtToken: any = await this.localStorage.getValueAsync("jwt_token");

    if(req.method == "HEAD" || req.url.indexOf("assets") > -1) {
      return next.handle(req).toPromise();
    }

    if(jwtToken) {
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${jwtToken}`,
        }
      });
      return next.handle(authReq).toPromise();
    } else {
      return next.handle(req).toPromise();
    }
  }
}
