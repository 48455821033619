import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {Observable, Observer} from "rxjs";
import {ModalClassComponent} from "../../interfaces/ModalClassComponent";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: "app-youtube-dialog",
  templateUrl: "./youtube-dialog.component.html",
  styleUrls: ["./youtube-dialog.component.scss"],
})
export class YoutubeDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  public embed: Observable<string | any>;
  public embedObserver: Observer<string | any>;

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {
    super();
    this.embed = new Observable<string | any>((observer) => this.embedObserver = observer);
  }

  public ngOnInit(): void {
    this.modalService.register("youtube-dialog", this);
  }

  public hide() {
    super.hide();
    this.modal.hide();
  }

  public show(args: any) {
    super.show(args);
    this.embedObserver.next(this.translateService.instant(args.embed));
    this.modal.show();
  }

  public confirmed() {
    this.hide();
  }

  public stopVideoAndHideModal() {
    this.embedObserver.next("");
    this.hide();
  }

}
