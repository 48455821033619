<div class="loading-container" *ngIf="errorMessage">
    <div class="notice notice-bill m-3 p-4 d-flex align-items-center" role="alert" style="color: #856404; width: 80%; position: absolute;left: 10%;top: calc(50% - 55px);">
        <i class="fas fa-exclamation-triangle mr-4" style="font-size: 40px; color: #b51c4a"></i>
        <h4 class="m-0">{{errorMessage}}</h4>
    </div>
</div>

<div class="right-container" id="orderModal" style="width: 100%;overflow:hidden;" [class.right-container-slide-left]="show === true" [class.right-container-slide-right]="show === false" [hidden]="errorMessage">
    <div class="circle"></div>
    <div class="w-100 h-100">
        <div>
            <div class="navbar-collapse full-width d-flex justify-content-between" style="display:flex;height:65px;">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="javascript:void(0);" (click)="closeItemList()" style="font-size: 16px;display: flex;align-items: center;">
                            <i class="fas fa-arrow-left" style="margin-right: 6px;"></i>
                            <span>{{"Back" | translate}}</span>
                        </a>
                    </li>
                </ul>
                <div class="navbar-header">{{"OrderList" | translate}}</div>
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link toolbar-item d-flex open-menu-button" (click)="showMenu()">
                            <span style="vertical-align: middle;">{{"ShowMenuCard" | translate}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="order-container" [hidden]="errorMessage">
            <div class="table-name-cont">
                {{table?.name}}
            </div>
            <div class="pos-orders-list">
                <div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="orderedItems.length == 0">
                    <img src="/assets/images/plate.png" style="width: 150px;" />
                    <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;" [innerHtml]="'SelfOrderNoOrdersYet' | translate"></div>
                    <buton class="btn bt-outline-primary btn-rounded btn-open-menucard" (click)="showMenu()">{{"OpenMenuCard" | translate}}</buton>
                </div>
                <app-self-order-single-order-item *ngFor="let item of orderedItems" [item]="item" [showEditPrice]="false" [courseItems]="orderedItems" [alwaysActive]="true" [showCancelButton]="false" [isSeparateMoveActive]="false" [isSeparatePaymentActive]="false"></app-self-order-single-order-item>
            </div>
            <div class="total-price-container">
                <div class="d-flex justify-content-between">
                    <span class="total">{{"Total" | translate}}</span>
                    <span class="price">{{totalPrice | otcurrency}}</span>
                </div>
                <button *ngIf="existingNewItems" class="btn btn-rounded btn-send-to-kitchen" (click)="sendItems()">{{"SendItemsToKitchen" | translate}}</button>
            </div>
        </div>
    </div>
</div>