import { Component, OnInit } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AuthenticationService} from "../../../services/auth.service";
import {MessageService} from "../../../services/message.service";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.scss"],
})
export class ForgetPasswordComponent implements OnInit {
  @BlockUI()
  public blockUI: NgBlockUI;

  public userName: string;
  public passwordSent: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
  }

  public async resetPassword() {
    this.blockUI.start(await this.translateService.get("PleaseWait").toPromise());
    this.authenticationService.forgetPassword(this.userName).subscribe(() => {
      this.passwordSent = true;
      this.blockUI.stop();
    }, (err) => {
      this.blockUI.stop();
      this.passwordSent = false;
      this.messageService.error(err);
    });
  }
}
