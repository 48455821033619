import { HomeService } from './../services/home.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuInfo } from '../models/menu.info';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuResolver implements Resolve<MenuInfo> {

  _homeService: HomeService;
  constructor(homeService: HomeService) {
    this._homeService = homeService;
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<MenuInfo> {
    const menuId = route.params['menuId'];
    return this._homeService.getRestaurantInfo(menuId);
  }
}
