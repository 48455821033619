import {v4 as uuid} from "uuid";
import {BaseModel} from "./base.model";
import {ProductExtras} from "./product.extras.model";
import {ProductPicture} from "./product.picture.model";
import {SideDish} from "./sidedish.model";
import { ProductVariationGroup } from "./product.variation.group.model";

export class Product extends BaseModel {

  public productId: string;

  public productCategoryId: string;

  public productName: string;

  public productDescription: string;

  public unitPrice: number;

  public productPicture: string;

  public productPictures: ProductPicture[];

  public sideDishes: SideDish[];

  public variationGroups: ProductVariationGroup[];

  public pictures: { [key: string]: string[] };

  public sortingId: number;

  public stockCount: number | null;

  public warningCount: number | null;

  public markAsOutOfStock: boolean | null;

  public ingredients: string;

  public additives: string;

  public allergenes: string;

  public specialWishes: string;

  public priceCategoriesStr: string;

  public unitPricesStr: string;

  public priceCategories: string[];

  public unitPrices: string[];

  public barcode: string;

  public isOutOfStock: boolean;

  public pluCode: string;

  public color: string;

  public excludeFromSelfOrdering: boolean;

  public extrasInfo: ProductExtras;
  constructor() {
    super();

    this.id = uuid();
    this.productId = this.id;
    this.priceCategories = [];
    this.productPictures = [];
    this.sideDishes = [];
    this.unitPrices = [];
  }
}
