<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right product-details-modal" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" [style.z-index]="zIndex+1">
    <div class="modal-dialog modal-full-height modal-right modal-info" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="heading" id="myModalLabel">{{product.productName}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
            </div>
            <div class="modal-body" style="max-height: 100%;" (swipe)="swipe($event)">
                <div *ngIf="images?.length > 1" class="product-images carousel slide" data-ride="carousel">
                    <mdb-carousel [isControls]="true" [animation]="'slide'" #carousel>
                        <mdb-carousel-item *ngFor="let image of images">
                            <img class="d-block ml-auto mr-auto mw-100 mh-100" src="{{image.url}}" alt="Product image">
                        </mdb-carousel-item>
                    </mdb-carousel>
                </div>
                <div *ngIf="images?.length ==1" class="product-images">
                    <img class="d-block ml-auto mr-auto mw-100 mh-100" src="{{images[0].url}}" alt="Product image">
                </div>


                <div class="details-title mt-3">{{"Description" | translate}}</div>
                <div class="details-desc" [innerHTML]="product.productDescription | sanitizeHtml"></div>
                <div class="no-entry" *ngIf="product.productDescription?.length == 0">
                    {{"NoEntry" | translate}}
                </div>
                <hr/>

                <div class="details-title">{{"Ingredients" | translate}}</div>
                <div class="chips chips-initial" *ngIf="product.ingredients?.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
                    <div class="chip" *ngFor="let ingredient of ingredients">{{ingredient}}</div>
                </div>
                <div class="no-entry" *ngIf="ingredients.length == 0">
                    {{"NoEntry" | translate}}
                </div>
                <hr/>

                <div class="details-title">{{"Additives" | translate}}</div>
                <div class="chips chips-initial" *ngIf="additives.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
                    <div class="chip" *ngFor="let additive of additives">{{additive}}</div>
                </div>
                <div class="no-entry" *ngIf="additives.length == 0">
                    {{"NoEntry" | translate}}
                </div>
                <hr/>

                <div class="details-title">{{"Price" | translate}}</div>
                <div class="product-price mb-3">
                    <label *ngIf="product.unitPrices?.length < 2" class='product-single-price'>{{ formattedUnitPrices[0] }}</label>
                    <div *ngIf="product.unitPrices?.length > 1" class='table-responsive'>
                        <table class='table table-bordered m-0'>
                            <thead>
                                <tr>
                                    <th *ngFor="let price of product.priceCategories">{{price}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td *ngFor="let price of formattedUnitPrices">{{ price }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>