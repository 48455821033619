<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalSelfOrder" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <p class="heading">{{"SelfOrderingSettings" | translate}} {{table.name}}</p>
      </div>
      <div class="modal-body">
        <div style="min-height:350px;">
          <label>{{"Order" | translate}}</label>
          <div class="qrcode-container row m-0">
            <div id="qrcode-image" class="qrcode-image col-5">
              <div id='css-loader' class='css-loader css-loader-big ml-auto mr-auto' style="margin-top: 95px;" *ngIf="showLoading"></div>
              <a id='download-link' [attr.download]="table.name + '.png'"></a>
              <img id='qr-code' alt='QR-Code' [attr.src]="qrCodeSrc" (load)="onLoadQrCode()"  [hidden]="showLoading"/>
              <a class="short-url" href="#" id="shortUrl" [href]="qrCodeShortUrl" target="_blank">{{qrCodeShortUrl}}</a>
            </div>
            <div class="col">
              <a id="create-new" href="javascript:void(0);" class="d-block mb-3 text-primary" (click)="showRenewQrCodeConfirmation()">
                <i class="fas fa-sync mr-1" style="color:inherit"></i>
                <span>{{"Renew" | translate}}</span>
              </a>
              <a id="download-qr" href="javascript:void(0);" class="d-block text-primary" (click)="downloadQrCode()">
                <i class="fas fa-download mr-1" style="color:inherit"></i>
                <span>{{"Download" | translate}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
          {{"Close" | translate}}
        </a>
      </div>
    </div>
  </div>
</div>
