<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right" role="dialog" aria-hidden="true" data-toggle="modal" data-backdrop="true" style="top: 0" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-full-height modal-right modal-info" role="document" style="margin-right:0;">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead mr-5" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{"Notifications" | translate}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body overflow-auto">
        <div class="notification-container" [hidden]="notifications.length == 0">
          <div class="notification" *ngFor="let notification of notifications">
            <i class="{{notification.icon}} notification-icon"></i>
            <div class="notification-inner-container flex-grow-1">
              <span class="notification-title">{{notification.title}}</span>

              <div class="notification-description">{{notification.description}}</div>
              <div class="notification-actions">
                <a class="text-primary" (click)="setTableItemsServed(notification)" *ngIf="notification.type == 'ordersReady'">{{"Served" | translate}}</a>
                <a class="text-primary" *ngIf="notification.type !== 'ordersReady'">{{"SelfOrderIDealWithRequest"}}</a>
                <span class="notification-time">{{notification.fromNow}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="no-notification-container" [hidden]="notifications.length > 0">
          <mdb-icon far icon="bell" size="8x" class="mb-3 mt-3 green-text"></mdb-icon>
          <div class="cyan-text text-center" style="font-size:20px;">{{"NoNewNotifications" | translate}}</div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer align-items-center p-2" style="position: relative;">
       <button class="btn btn-outline-danger btn-rounded" (click)="deleteAllNotifications()">{{"DeleteAll" | translate}}</button>
      </div>
    </div>
  </div>
</div>
