<div class="row" style="margin:0px;font-size:16px;font-weight:bold;" [hidden]="receipts.length == 0">
  <div class="col-sm-6 col-xs-12">
    <span>{{"TotalReceiptCount" | translate}}</span>:&nbsp;
    <span style="font-size:30px;">{{receipts.length}}</span>
  </div>
  <div class="col-sm-6 col-xs-12 total-sum-container" style="text-align:right;">
    <span>{{"TotalSum" | translate}}</span>:&nbsp;
    <span style="font-size:30px;">{{totalAmount | otcurrency}}</span>
  </div>
</div>
<ul id="receiptListView" class="list-group"
    style="margin: 0">
  <app-receipt-single-item *ngFor="let receipt of receipts" [receipt]="receipt"></app-receipt-single-item>
</ul>
