<div class="z-depth-1-half" style="height: 100%;" *ngIf="productsLength > 0">
  <a id="category-opener" class="category-opener btn btn-info btn-sm z-depth-2" (click)="openCategoryMenu()">
    <i class="fas fa-angle-right"></i>
  </a>

  <div class="menu-category-container faster animated"
       [class.category-list-hidden]="!categoryTabsShown"
       [class.category-list-visible]="categoryTabsShown">
    <ul id="categoryList" class="list-group pos-category-list">
      <li class="list-group-item white-text categories-title">
        {{"Categories" | translate}}
      </li>

      <li *ngIf="searchStarted" (click)="categoryClicked(searchCategory)"
          [class.info-color]="searchCategory.selected"
          [class.active]="searchCategory.selected"
          class="list-group-item pos-category-item">
        <div style="padding: 10px;">
          {{searchCategory.productCategoryName | translate}}
        </div>
      </li>

      <li [hidden]="retailMode" (click)="categoryClicked(addedProductsCategory)"
          [class.info-color]="addedProductsCategory.selected"
          [class.active]="addedProductsCategory.selected"
          class="list-group-item pos-category-item">
        <div style="padding: 10px;">
          {{addedProductsCategory.productCategoryName | translate}}
        </div>
      </li>

      <li [hidden]="retailMode" (click)="categoryClicked(quickAccessCategory)"
          [class.info-color]="quickAccessCategory.selected"
          [class.active]="quickAccessCategory.selected"
          class="list-group-item pos-category-item">
        <div style="padding: 10px;">
          {{quickAccessCategory.productCategoryName | translate}}
        </div>
      </li>

      <li *ngFor="let category of categories"
          (click)="categoryClicked(category)"
          [class.info-color]="category.selected"
          [class.active]="category.selected"
          class="list-group-item pos-category-item">
        <div style="padding: 10px;">
          {{category.productCategoryName}}
        </div>
      </li>
    </ul>
  </div>
</div>
