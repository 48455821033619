<div #menuModal id="menuModal" class="menu-modal menu-card-closed">
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-inverse bg-primary main-nav">
    <ul class="navbar-nav ml-2 mr-auto">
      <li class="nav-item">
        <a (click)="hide()" class="toolbar-item back-nav-item">
          <i class="fa fa-chevron-left mr-1"></i>
          <span>{{"Back" | translate}}</span>
        </a>
      </li>
    </ul>
    <div class="search-form mr-2">
      <div class="form-group has-feedback mb-0" [class.has-value]="searchText.length > 0">
        <input mdbInput [(ngModel)]="searchText"
               (keyup)="searchItems()"
               [attr.placeholder]='"Search" | translate'
               type="text" class="form-control" name="search" id="product-search">
        <span class="fa fa-search form-control-feedback"></span>
        <button type="button" class="close pos-search-reset" aria-label="Close" (click)="resetSearch()">
          <span aria-hidden="true" style="font-size:36px;display:block;">×</span>
        </button>
      </div>
    </div>
  </mdb-navbar>
  <div class="menu-body">
    <div id="table-tabs" class="menu-card-container">
      <div style="width: 100%; text-align: center; margin-top: 20px;" *ngIf="productCount == 0">
        <img src="/images/menu.svg" style="width: 200px;">
        <div class="text-info informative-text" style="margin: 30px 20px 10px 20px;">
          <span [innerHTML]="'MenuIsEmpty' | translate"></span>
        </div>
      </div>

      <app-menu-categories></app-menu-categories>
      <div class="row menu-product-container pt-0 m-0">
        <app-menu-category-products
                [category]="initialCategory"
                [orderedItems]="orderedItems"
          style="width: 100%;"></app-menu-category-products>
      </div>
    </div>


    <div id="alert-box" class="text-warning"
         *ngIf="productCategories.length == 0"
         style="margin: 10px; display: none;">{{"MenuIsEmpty" | translate}}</div>
  </div>
</div>

<app-product-details-dialog></app-product-details-dialog>
<app-product-sidedish-dialog></app-product-sidedish-dialog>
