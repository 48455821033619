import {Injectable} from "@angular/core";
import * as localForage from "localforage";
import {reject} from "lodash";
import {
  DayEndReportStatistic,
  Extra, Notification, NotificationTypes,
  OrderedItem,
  PackageInfo,
  PrinterSettings, Product,
  ProductCategory, ProductPicture,
  ReceiptSettings,
  SideDish,
  Table,
  TableCategory,
  User,
  UserSettings,
} from "../models";
import {Customer} from "../models/customer.model";
import {Employee} from "../models/employee.model";
import { ProductCategoryPicture } from "../models/product.category.picture.model";
import { PaymentTypes } from "../models/payment.types.model";
import { VatDefinition } from "../models/vat.definition.model";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private _user: User;
  private _userSettings: UserSettings;
  private _receiptSettings: ReceiptSettings;
  private _printerSettings: PrinterSettings[];
  private _tables: Table[];
  private _tableCategories: TableCategory[];
  private _productCategories: ProductCategory[];
  private _productCategoryPictures: ProductCategoryPicture[];
  private _products: Product[];
  private _productPictures: ProductPicture[];
  private _extras: Extra[];
  private _sideDishes: SideDish[];
  private _employees: Employee[];
  private _customers: Customer[];
  private _dayEndStats: DayEndReportStatistic;
  private _packagesInfo: PackageInfo[];
  private _orderedItems: OrderedItem[];
  private _favoriteProductIds: string[];
  private _mostSoldProductIds: string[];
  private _lastSoldProductIds: string[];
  private _notifications: Notification[];
  private _paymentTypes: PaymentTypes[];
  private defaultPaymentTypes = [{name: 'CashPayment', label:'Bar', isActive: true}, {name:'CardPayment', label:'Karte', isActive: true}, {name: 'VoucherPayment', label: 'Gutschein', isActive: true}];
  private _vatDefinitions: VatDefinition[];
  private _selfOrderUser: any;
  constructor() {
    this.getProductCategoriesAsync().then();
  }

  public setUser(user: User): void {
    this._user = user;
    this.setValue("user", user);
  }

  public async getUserAsync(): Promise<User> {
    if (this._user) { return this._user; }

    this._user = await this.getValueAsync("user");
    if (typeof this._user === "string") {
      this._user = JSON.parse(this._user);
    }

    return this._user;
  }

  public getUserSync(): User {
    if (!this._user) {
      const user = localStorage.getItem("user");

      if (user) {
        this._user = JSON.parse(user);
      }
    }

    return this._user;
  }

  public setUserSettings(settings: UserSettings): void {
    this._userSettings = settings;
    this.setValue("user-settings", settings);
  }

  public async getUserSettingsAsync(): Promise<UserSettings> {
    if (this._userSettings) { return this._userSettings; }

    this._userSettings = await this.getValueAsync("user-settings") || await this.getValueAsync("usersettings");
    if (typeof this._userSettings === "string") {
      this._userSettings = JSON.parse(this._userSettings);
    }

    return this._userSettings;
  }

  setSelfOrderUser(selfOrderUser: any) {
    this._selfOrderUser = selfOrderUser;
    this.setValue("self-order-user", selfOrderUser);
  }

  public async getSelfOrderUserAsync(): Promise<any> {
    if (this._selfOrderUser) { return this._selfOrderUser; }

    this._selfOrderUser = await this.getValueAsync("self-order-user");
    if (typeof this._selfOrderUser === "string") {
      this._selfOrderUser = JSON.parse(this._selfOrderUser);
    }

    return this._selfOrderUser;
  }

  public setReceiptSettings(settings: ReceiptSettings): void {
    this._receiptSettings = settings;
    this.setValue("receipt-settings", settings);
  }

  public async getReceiptSettingsAsync(): Promise<ReceiptSettings> {
    if (this._receiptSettings) { return this._receiptSettings; }

    this._receiptSettings = await this.getValueAsync("receipt-settings");
    if (typeof this._receiptSettings === "string") {
      this._receiptSettings = JSON.parse(this._receiptSettings);
    }

    return this._receiptSettings;
  }

  public setPrinterSettings(settings: PrinterSettings[]): void {
    this._printerSettings = settings;
    this.setValue("printer-settings", settings);
  }

  public async getPrinterSettingsAsync(): Promise<PrinterSettings[]> {
    if (this._printerSettings) { return this._printerSettings; }

    this._printerSettings = await this.getValueAsync("printer-settings") || [];

    if (typeof this._printerSettings === "string") {
      this._printerSettings = JSON.parse(this._printerSettings);
    }

    return this._printerSettings;
  }

  public setTables(tables: Table[]): void {
    this._tables = tables.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" }));
    this.setValue("tables", this._tables);
  }

  public async getTablesAsync(): Promise<Table[]> {
    if (this._tables) { return this._tables; }

    this._tables = await this.getValueAsync("tables") || [];
    if (typeof this._tables === "string") {
      this._tables = JSON.parse(this._tables);
    }

    return this._tables;
  }

  public addTable(table: any) {
    this._tables.push(table);
    this.setTables(this._tables);
  }

  public updateTable(table: Table) {
    const list = this._tables.filter((t) => t.id === table.id);
    if (list.length === 1) {
      list[0].name = table.name;
      list[0].description = table.description;
      list[0].tax = table.tax;
      list[0].displayNumber = table.displayNumber;
      list[0].macAddress = table.macAddress;

      const tables = this._tables.filter((t) => t.id !== table.id);
      tables.push(list[0]);
      this._tables = tables;

      this.setTables(this._tables);
    }
  }

  public removeTable(table: Table) {
    const tables = reject(this._tables, {id: table.id});
    this.setTables(tables);
  }

  public setTableCategories(tableCategories: TableCategory[]): void {
    this._tableCategories = tableCategories;
    this.setValue("table-categories", tableCategories);
  }

  public async getTableCategoriesAsync(): Promise<TableCategory[]> {
    if (this._tableCategories) { return this._tableCategories; }

    this._tableCategories = await this.getValueAsync("table-categories") || [];
    if (typeof this._tableCategories === "string") {
      this._tableCategories = JSON.parse(this._tableCategories);
    }

    return this._tableCategories;
  }

  public setProductCategories(categories: ProductCategory[]) {
    this._productCategories = categories;
    this.setValue("categories", categories);
  }

  public async getProductCategoriesAsync(): Promise<ProductCategory[]> {
    if (this._productCategories?.length > 0) { return this._productCategories; }

    this._productCategories = await this.getValueAsync("categories") || [];
    if (typeof this._productCategories === "string") {
      this._productCategories = JSON.parse(this._productCategories);
    }

    return this._productCategories;
  }

  public getProductCategories(): ProductCategory[] {
    return this._productCategories;
  }

  public updateProductCategory(category: ProductCategory) {
    const list = this._productCategories.filter((p) => p.productCategoryId !== category.productCategoryId);
    list.push(category);
    this.setProductCategories(list);
  }

  public setProductCategoryPictures(categories: ProductCategoryPicture[]) {
    this._productCategoryPictures = categories;
    this.setValue("category-pictures", this._productCategoryPictures);
  }

  public async getProductCategoryPicturesAsync(): Promise<ProductCategoryPicture[]> {
    if (this._productCategoryPictures) { return this._productCategoryPictures; }

    this._productCategoryPictures = await this.getValueAsync("category-pictures") || [];
    if (typeof this._productCategoryPictures === "string") {
      this._productCategoryPictures = JSON.parse(this._productCategoryPictures);
    }

    return this._productCategoryPictures;
  }

  public setProducts(products: Product[]): void {
    this._products = products;
    this.setValue("products", products);
  }

  public async getProductsAsync(): Promise<Product[]> {
    if (this._products) { return this._products; }

    this._products = await this.getValueAsync<Product[]>("products")  || [];
    if (typeof this._products === "string") {
      this._products = JSON.parse(this._products);
    }

    return this._products;
  }

  public addProduct(product: Product) {
    this._products.push(product);
    this.setProducts(this._products);
  }

  public updateProduct(product: Product) {
    const list = this._products.filter((p) => p.productId !== product.productId);
    list.push(product);
    this.setProducts(list);
  }

  public removeProduct(product: Product) {
    const list = this._products.filter((p) => p.productId !== product.productId);
    this.setProducts(list);
  }

  public setProductPictures(pictures: ProductPicture[]): void {
    this._productPictures = pictures;
    this.setValue("product-pictures", pictures);
  }

  public async getProductPicturesAsync(): Promise<ProductPicture[]> {
    if (this._productPictures) { return this._productPictures; }

    this._productPictures = await this.getValueAsync("product-pictures") || [];
    if (typeof this._productPictures === "string") {
      this._productPictures = JSON.parse(this._productPictures);
    }

    return this._productPictures;
  }

  public removeProductPicture(pictureId: string): void {
    const filesToDelete = this._productPictures.filter(p => p["rowKey"] === pictureId);
    let fileToDelete = null;
    if(filesToDelete.length == 0) return;
    else fileToDelete = filesToDelete[0];

    let files = this._productPictures.filter(p => p["rowKey"] !== pictureId);
    if(!fileToDelete.originalFileId){
      files = files.filter(p => p.originalFileId !== pictureId);
    }
    else{
      files = files.filter(p => p.originalFileId !== fileToDelete.originalFileId);
      files = files.filter(p => p["rowKey"] !== fileToDelete.originalFileId);
    }
    this.setProductPictures(files);
  }


  public setExtras(extras: Extra[]): void {
    this._extras = extras;
    this.setValue("extras", extras);
  }

  public async getExtrasAsync(): Promise<Extra[]> {
    if (this._extras) { return this._extras; }

    this._extras = await this.getValueAsync("extras") || [];
    if (typeof this._extras === "string") {
      this._extras = JSON.parse(this._extras);
    }

    return this._extras;
  }

  public setSideDishes(sideDishes: SideDish[]): void {
    this._sideDishes = sideDishes;
    this.setValue("side-dishes", sideDishes);
  }

  public async getSideDishesAsync(): Promise<SideDish[]> {
    if (this._sideDishes) { return this._sideDishes; }

    this._sideDishes = await this.getValueAsync("side-dishes") || [];
    if (typeof this._sideDishes === "string") {
      this._sideDishes = JSON.parse(this._sideDishes);
    }

    return this._sideDishes;
  }

  public setEmployees(employees: Employee[]): void {
    this._employees = employees;
    this.setValue("employees", employees);
  }

  public async getEmployeesAsync(): Promise<Employee[]> {
    if (this._employees) { return this._employees; }

    this._employees = await this.getValueAsync("employees") || [];
    if (typeof this._employees === "string") {
      this._employees = JSON.parse(this._employees);
    }

    return this._employees;
  }

  public setCustomers(customers: Customer[]): void {
    this._customers = customers;
    this.setValue("customers", customers);
  }

  public async getCustomersAsync(): Promise<Customer[]> {
    if (this._customers) { return this._customers; }

    this._customers = await this.getValueAsync("customers") || [];
    if (typeof this._customers === "string") {
      this._customers = JSON.parse(this._customers);
    }

    return this._customers;
  }

  public setDayEndStatistic(dayEndStats: DayEndReportStatistic): void {
    this._dayEndStats = dayEndStats;
    this.setValue("day-end-stats", dayEndStats);
  }

  public async getDayEndStatisticAsync(): Promise<DayEndReportStatistic> {
    if (this._dayEndStats) { return this._dayEndStats; }

    this._dayEndStats = await this.getValueAsync("day-end-stats");
    if (typeof this._dayEndStats === "string") {
      this._dayEndStats = JSON.parse(this._dayEndStats);
    }

    return this._dayEndStats;
  }

  public setPackagesInfo(packages: PackageInfo[]): void {
    this._packagesInfo = packages;
    this.setValue("packages-info", this._packagesInfo);
  }

  public async getPackagesInfoAsync(): Promise<PackageInfo[]> {
    if (this._packagesInfo) { return this._packagesInfo; }

    this._packagesInfo = await this.getValueAsync("packages-info") || [];
    if (typeof this._packagesInfo === "string") {
      this._packagesInfo = JSON.parse(this._packagesInfo);
    }

    return this._packagesInfo;
  }

  public setOrderedItems(items: OrderedItem[]): void {
    this._orderedItems = items;
    this.setValue("ordered-items", this._orderedItems);
  }

  public async getOrderedItemsAsync(): Promise<OrderedItem[]> {
    if (this._orderedItems) { return this._orderedItems; }

    this._orderedItems = await this.getValueAsync("ordered-items") || [];
    if (typeof this._orderedItems === "string") {
      this._orderedItems = JSON.parse(this._orderedItems);
    }

    return this._orderedItems;
  }

  public addOrderedItem(orderedItem: OrderedItem) {
    this._orderedItems.push(orderedItem);
    this.saveOrderedItems();
  }

  public removeOrderedItem(item: OrderedItem) {
    this.setOrderedItems(reject(this._orderedItems, {orderedItemId: item.orderedItemId}));
  }

  public updateOrderedItem(item: OrderedItem) {
    const items: OrderedItem[] = this._orderedItems.filter((i) => i.orderedItemId !== item.orderedItemId);
    items.push(item);
    this.setOrderedItems(items);
  }

  public saveOrderedItems() {
    this.setOrderedItems(this._orderedItems);
  }

  public setFavoriteProducts(favorites: string[]): void {
    this._favoriteProductIds = favorites;
    this.setValue("favorite-products", this._favoriteProductIds);
  }

  public async getFavoriteProductsAsync(): Promise<string[]> {
    if (this._favoriteProductIds) { return this._favoriteProductIds; }

    this._favoriteProductIds = await this.getValueAsync("favorite-products") || [];
    if (typeof this._favoriteProductIds === "string") {
      this._favoriteProductIds = JSON.parse(this._favoriteProductIds);
    }

    return this._favoriteProductIds;
  }

  public setMostSoldProductIds(productIds: string[]): void {
    this._mostSoldProductIds = productIds;
    this.setValue("most-sold-product-ids", this._mostSoldProductIds);
  }

  public async getMostSoldProductIdsAsync(): Promise<string[]> {
    if (this._mostSoldProductIds) { return this._mostSoldProductIds; }

    this._mostSoldProductIds = await this.getValueAsync("most-sold-product-ids") || [];
    if (typeof this._mostSoldProductIds === "string") {
      this._mostSoldProductIds = JSON.parse(this._mostSoldProductIds);
    }

    return this._mostSoldProductIds;
  }

  public setLastSoldProductIds(productIds: string[]): void {
    this._lastSoldProductIds = productIds;
    this.setValue("last-sold-product-ids", this._lastSoldProductIds);
  }

  public async getLastSoldProductIdsAsync(): Promise<string[]> {
    if (this._lastSoldProductIds) { return this._lastSoldProductIds; }

    this._lastSoldProductIds = await this.getValueAsync("last-sold-product-ids") || [];
    if (typeof this._lastSoldProductIds === "string") {
      this._lastSoldProductIds = JSON.parse(this._lastSoldProductIds);
    }

    return this._lastSoldProductIds;
  }

  public setNotifications(notifications: Notification[]): void {
    this._notifications = notifications;
    this.setValue("notifications", this._notifications);
  }

  public async getNotificationsAsync(): Promise<Notification[]> {
    if (this._notifications) { return this._notifications; }

    const notifications = await this.getValueAsync<Notification[]>("notifications");
    this._notifications = notifications || [];
    if (typeof this._notifications === "string") {
      this._notifications = JSON.parse(this._notifications);
    }

    return this._notifications;
  }

  public setPaymentTypes(types: PaymentTypes[]) {
    this._paymentTypes = types?.length > 0 ? types : this.defaultPaymentTypes;
    this.setValue("payment-types", this._paymentTypes);
  }

  public async getPaymentTypesAsync(onlyActives: boolean = true): Promise<PaymentTypes[]> {
    if (this._paymentTypes) {
      if(onlyActives) return this._paymentTypes.filter(p => p.isActive);

      return this._paymentTypes;
    }

    const paymentTypes = await this.getValueAsync<PaymentTypes[]>("payment-types");
    this._paymentTypes = paymentTypes || this.defaultPaymentTypes;
    if (typeof this._paymentTypes === "string") {
      this._paymentTypes = JSON.parse(this._paymentTypes);
    }

    if(onlyActives) return this._paymentTypes.filter(p => p.isActive);

      return this._paymentTypes;
  }

  public insertNotification(notification: Notification) {
    if (!this._notifications) {
      this._notifications = [notification];
    } else {
      this._notifications.push(notification);
    }

    this.setNotifications(this._notifications);
  }

  public removeNotification(notification: Notification) {
    this.setNotifications(reject(this._notifications, { id: notification.id }));
  }

  public removeAllNotifications() {
    // pop elements instead of setting to an empty array.
    // This way notification list and counter will be updated
    while (this._notifications.length > 0) {
      this._notifications.pop();
    }
    this.setNotifications([]);
  }

  public removeTableNotifications(tableId: string, type: NotificationTypes) {
    if (!type) {
      this.setNotifications(reject(this._notifications, { tableId }));
    } else {
      this.setNotifications(reject(this._notifications, { tableId, type }));
    }
  }

  public setVatDefinitions(vatDefinitions: VatDefinition[]) {
    this._vatDefinitions = vatDefinitions;
    this.setValue("vat-definitions", this._vatDefinitions);
  }

  public async getVatDefinitions() {
    if (this._vatDefinitions) {
      return this._vatDefinitions;
    }

    this._vatDefinitions = await this.getValueAsync<VatDefinition[]>("vat-definitions");
    return this._vatDefinitions;
  }

  public getSpecialAcessToken() {
    return localStorage.getItem("special-access-token");
  }

  public removeAll() {
    this._user = null;
    this.removeValue("user");

    this._userSettings = null;
    this.removeValue("user-settings");

    this._receiptSettings = null;
    this.removeValue("receipt-settings");

    this._printerSettings = null;
    this.removeValue("printer-settings");

    this._tableCategories = null;
    this.removeValue("table-categories");

    this._tables = null;
    this.removeValue("tables");

    this._productCategories = null;
    this.removeValue("categories");

    this._products = null;
    this.removeValue("products");

    this._productPictures = null;
    this.removeValue("product-pictures");

    this._productCategoryPictures = null;
    this.removeValue("category-pictures");

    this._extras = null;
    this.removeValue("extras");

    this._customers = null;
    this.removeValue("customers");

    this._employees = null;
    this.removeValue("employees");

    this._dayEndStats = null;
    this.removeValue("day-end-stats");

    this._favoriteProductIds = null;
    this.removeValue("favorite-products");

    this._mostSoldProductIds = null;
    this.removeValue("most-sold-product-ids");

    this._lastSoldProductIds = null;
    this.removeValue("last-sold-product-ids");

    this._paymentTypes = null;
    this.removeValue("payment-types");

    this.removeValue("activeCategory");
    this.removeValue("printer-settings");
    this.removeValue("product-list-type");
    this.removeValue("settings-active-tab");
    this.removeValue("table-list-type");
    this.removeValue("jwt_token");
    this.removeValue("refresh-token");
    this.removeValue("token-expiration");
    this.removeValue("user-logged-in");

    this._orderedItems = null;
    this.removeValue("ordered-items");

    this._sideDishes = null;
    this.removeValue("side-dishes");

    // this.removeFromLocalStorage("packagesInfo");
    // document.cookie = 'PackagesLoaded=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  public async setGetCurrentCultureAsync(culture) {
    if (culture) {
      localStorage.setItem("current-culture", culture);
      return "";
    } else {
      return localStorage.getItem("current-culture") || "en";
    }
  }

  public async setGetFirstVisitAsync(firstVisit) {
    if (firstVisit === undefined) {
      return await this.getValueAsync("first-visit") !== false;
    }

    this.setValue("first-visit", firstVisit);
    return "";
  }

  public setValue(key, val) {
    localForage.setItem(key, val).then(() => {});
  }

  public async getValueAsync<T>(key): Promise<T> {
    return await localForage.getItem<T>(key);
  }

  public removeValue(key) {
    localForage.removeItem(key).then();
  }

  public getCurrencySymbol(): string {
    if (this._user) {
      return this._user.currencySymbol;
    }

    const currencySymbol = localStorage.getItem("currencySymbol");
    if (currencySymbol) {
      return currencySymbol;
    }

    return "";
  }
}
