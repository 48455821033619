import { environment } from './../../environments/environment';
import {Injectable} from "@angular/core";
import * as signalR from "@microsoft/signalr";
import {find, reject} from "lodash";
import {Observable} from "rxjs";
import {v4 as uuid} from "uuid";
import {OrderStatus} from "../enums/order.status.enum";
import {OrderedItem, Product, ProductCategory} from "../models";
import {LocalStorageService} from "./localstorage.service";
import {PosService} from "./pos.service";
import {ProductService} from "./product.service";
import {SubscriptionService} from "./subscription.service";
import {TableService} from "./table.service";

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  private static getWebSocketConnectionId() {
    let id = localStorage.getItem("websocket-id");
    if (!id) {
      id = uuid();
      localStorage.setItem("websocket-id", id);
    }

    return id;
  }

  public notificationUrl: string;
  public hubConnection: signalR.HubConnection;

  public tableRefresh = new Observable<{ tableId: string, extrasInfo: any, tableOrders: OrderedItem[] }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("updateTable", async (notificationId, sourceId, destinationId, extrasInfo) => {
      await this.updateTable(observer, notificationId, sourceId, destinationId, extrasInfo);
    });

    window["updateTable"] = (notificationId, sourceId, destinationId) => {
      this.updateTable(observer, notificationId, sourceId, "", null);
    };
  });

  public updateMonitor = new Observable<string>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.monitorSubscribers.push(observer);
    this.hubConnection.on("updateMonitor", (notificationId, sourceId, destinationId) => {
      observer.next(sourceId);

      if (destinationId) {
        observer.next(destinationId);
      }
    });
  });

  public billRequested = new Observable<{ notificationId, tableId }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("billRequested", (notificationId, tableId) => {
      observer.next({notificationId, tableId});
    });
  });

  public waiterCalled = new Observable<{ notificationId, tableId }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("waiterCalled", (notificationId, tableId) => {
      observer.next({notificationId, tableId});
    });
  });

  public waiterCallAccepted = new Observable<{ notificationId, tableId }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("waiterCallAccepted", (notificationId, tableId) => {
      observer.next({notificationId, tableId});
    });
  });

  public billRequestAccepted = new Observable<{ notificationId, tableId }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("billRequestAccepted", (notificationId, tableId) => {
      observer.next({notificationId, tableId});
    });
  });

  public ordersReady = new Observable<{ notificationId, tableId }>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("ordersReady", async (notificationId, tableId) => {
      if (await this.localStorageService.getUserAsync()) {
        const tableOrders = await this.posService.getTableOrderedItems(tableId).toPromise();
        await this.updateTableItems(tableId, tableOrders);
        observer.next({tableId, notificationId});
      } else {
        observer.next({tableId, notificationId});
      }
    });
  });

  public updateProduct = new Observable<Product>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("updateProduct", async (notificationId, productId) => {
      const isExecuted = this.executedNotificationIds.indexOf(notificationId) > -1;
      if (isExecuted) {
        return;
      } else {
        this.executedNotificationIds.unshift(notificationId);
        if (this.executedNotificationIds.length > 20) {
          this.executedNotificationIds = this.executedNotificationIds.splice(0, 20);
        }
      }

      this.productService.loadProduct(productId).subscribe(async (product) => {
        const products = reject(await this.localStorageService.getProductsAsync(), {productId});

        if (product) {
          products.push(product);
        }

        this.localStorageService.setProducts(products);

        observer.next(product ?? new Product());
      });
    });
  });

  public updateProductCategory = new Observable<ProductCategory>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("updateProductCategory", async (notificationId, productCategoryId) => {
      const isExecuted = this.executedNotificationIds.indexOf(notificationId) > -1;
      if (isExecuted) {
        return;
      } else {
        this.executedNotificationIds.unshift(notificationId);
        if (this.executedNotificationIds.length > 20) {
          this.executedNotificationIds = this.executedNotificationIds.splice(0, 20);
        }
      }

      this.productService.loadCategory(productCategoryId).subscribe(async (productCategory) => {
        const productCategories = reject(await this.localStorageService.getProductCategoriesAsync(), {productCategoryId});

        if (productCategory) {
          productCategories.push(productCategory);
        }

        this.localStorageService.setProductCategories(productCategories);

        observer.next(productCategory ?? new ProductCategory());
      });
    });
  });

  public itemReturned = new Observable<string>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    window["returnItem"] = (notificationId, itemId) => {
      observer.next(itemId);
    };

    this.hubConnection.on("itemReturned", (notificationId, tableId, itemId) => {
      if (itemId) {
        observer.next(itemId);
      }
    });
  });

  public tableUpdated = new Observable<any>((observer) => {
    if(!this.hubConnection) {
      this.startConnection();
    }

    this.hubConnection.on("tablesUpdated", () => {
      this.tableService.getAllTablesAndCategories().subscribe((result) => {
        result.tableCategories = result.tableCategories.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: "base"}));
        this.localStorageService.setTableCategories(result.tableCategories);
        this.localStorageService.setTables(result.tables);
        observer.next(result);
      });
    });
  });

  private executedNotificationIds: string[] = [];
  private monitorSubscribers: any[] = [];
  private checkSignalRInterval: any;

  constructor(
    private localStorageService: LocalStorageService,
    private posService: PosService,
    private tableService: TableService,
    private productService: ProductService,
    private subscriptionService: SubscriptionService) {

    this.startConnection();

    this.subscriptionService.authEvent.subscribe((isLoggedIn) => {
      if (isLoggedIn && !this.hubConnection) {
        this.startConnection();
      } else if (!isLoggedIn) {
        this.hubConnection.stop().then(() => {
          console.log("Connection stopped");
          clearInterval(this.checkSignalRInterval);
        });
      }
    });
  }

  public  startConnection = (forceNewConnection = false) => {

    const connectionId = SignalRService.getWebSocketConnectionId();
    let customerId = localStorage.getItem("customerId");
    if (!customerId) {
      const user = this.localStorageService.getUserAsync();
      if(user){
        customerId = user["partitionKey"];
      }
    }

    if(!customerId){
      return;
    }

    let selfOrderTableId = "";
    const isSelfOrder = localStorage.getItem("is-self-order") == "true";
    if(isSelfOrder) {
      const table = JSON.parse(localStorage.getItem("table"));
      if (table) {
        selfOrderTableId = "&selfOrderTableId=" + table.id;
      }
    }

    this.notificationUrl = environment.baseUrl + "/notifications?conid=" + connectionId + "&customerId=" + customerId + selfOrderTableId;
    if (!this.hubConnection || forceNewConnection) {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.notificationUrl)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
    }

    console.log("Stating new signalr connection");
    this.hubConnection
    .start()
    .then(() => {
      console.log("Connection started");
    });

    this.hubConnection.onclose(() => {
      console.log("Connection closed, starting new connection");
      this.startConnection(true);
    });
  };

  public notifyAllMonitorObservers(tableId: string) {
    this.monitorSubscribers.forEach((observer) => {
      observer.next(tableId);
    });
  }

  private async updateTableItems(tableId: string, tableOrders: OrderedItem[]) {
    let allOrders = (await this.localStorageService.getOrderedItemsAsync()).filter((item) => {
      return (item.tableId !== tableId || item.status === OrderStatus.new) &&
        !find(tableOrders, {orderedItemId: item.orderedItemId});
    });

    allOrders = allOrders.concat(tableOrders);
    this.localStorageService.setOrderedItems(allOrders);
  }

  private async updateTable(observer, notificationId: string, sourceId: string, destinationId: string, extrasInfo: any) {
    const isExecuted = this.executedNotificationIds.indexOf(notificationId) > -1;
    if (isExecuted) {
      return;
    } else {
      this.executedNotificationIds.unshift(notificationId);
      if (this.executedNotificationIds.length > 20) {
        this.executedNotificationIds = this.executedNotificationIds.splice(0, 20);
      }
    }

    if (await this.localStorageService.getUserAsync()) {
      const tableOrders = await this.posService.getTableOrderedItems(sourceId).toPromise();
      await this.updateTableItems(sourceId, tableOrders);
      observer.next({tableId: sourceId, extrasInfo, tableOrders});
    } else {
      observer.next({tableId: sourceId, extrasInfo, tableOrders: []});
    }

    if (destinationId) {
      if (await this.localStorageService.getUserAsync()) {
        const tableOrders = await this.posService.getTableOrderedItems(destinationId).toPromise();
        await this.updateTableItems(destinationId, tableOrders);
        observer.next({tableId: destinationId, extrasInfo, tableOrders});
      } else {
        observer.next({tableId: destinationId, extrasInfo, tableOrders: []});
      }
    }
  }
}
