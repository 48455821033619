<div *ngIf="visible" class="panel panel-default card order-view"
     [class.highlight]="orders.isNew">
  <div class="panel-heading" style="padding: 0; background:#71c175;"
       [class.order-status-orange]="orderStatus == 'orange'"
       [class.order-status-red]="orderStatus == 'red'">
    <table style="width: 100%">
      <tr>
        <td class="order-count">
          {{totalQuantity}}x
        </td>
        <td style="text-align: center;">
          <span class="table-name">
            {{this.table?.name}}
          </span><br/>
          <time class="order-date">
            {{orderTimeAgo}}
          </time>
        </td>
        <td style="width: 95px; padding: 5px; white-space: nowrap;" *ngIf="!readOnly">
          <a class="btn-floating btn-white" href="javascript:void(0)" (click)="callOrderWaiter()" [mdbTooltip]="'CallWaiter_Tooltip' | translate" placement="bottom">
            <i class="far fa-bell text-primary"></i>
          </a>
          <a class="btn-floating btn-white" href="javascript:void(0)" (click)="setAllItemsReady()" [mdbTooltip]="'OrdersReady_Tooltip' | translate" placement="bottom">
            <i class="fas fa-check text-primary"></i>
          </a>
          <a class="btn-floating btn-white" href="javascript:void(0)"
             (click)="sendAllFinishedItems()"
             [mdbTooltip]="'SendFinishedItems_Tooltip' | translate" placement="bottom"
             [attr.disabled]="sendingOrders ? '' : null">
            <i class="fas text-primary" [ngClass]="{'css-loader css-loader-middle ml-2 mt-2' : sendingOrders, 'fa-paper-plane': !sendingOrders}"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>

  <div class="panel-body" style="padding: 0; overflow: auto;">
    <div class="accordion md-accordion course-list" id="accordion-gangs" role="tablist" aria-multiselectable="true">
      <div class="card" *ngFor="let course of courses" [hidden]="!course.visible">
        <div class="card-header d-flex align-items-center info-color lighten-3 p-0 z-depth-1-half white-text"
             *ngIf="allowCourses"
             (click)="toggleSlide(course)"
             style="border-radius: 0">
          <i class="fa rotate-icon mt-2 mb-2 ml-1" style="font-size:30px;"
             [class.fa-angle-up]="!course.collapsed"
             [class.fa-angle-down]="course.collapsed"></i>

          <h6 class="text-uppercase align-middle ml-2 mb-2 mt-2">{{course.title}}</h6>

        </div>
        <div class="collapse show" role="tabpanel" *ngIf="!course.collapsed" [@slideInOut]>
          <div class="card-body p-0">
            <div class="pos-orders-list">
              <app-single-table-order class="d-block" *ngFor="let item of course.items"
                                      [item]="item"
                                      [course]="course"
                                      [orders]="orders"
                                      [selectedCategories]="selectedCategories"
                                      [readOnly]="readOnly"
                                      (onDelete)="refreshList()"
              ></app-single-table-order>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
