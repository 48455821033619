import {BaseModel} from "./base.model";

export class Table extends BaseModel {
  public name: string;

  public description: string;

  public tax: number | null;

  public test: string;

  public sortingId: number;

  public categoryId: string;

  public userIds: string;

  public selfOrderingCategories: string[];

  public splittedFrom: string;

  public displayNumber: string;

  public macAddress: string;
}
