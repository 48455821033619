<div mdbModal #modal="mdbModal" class="modal cookie-law-banner-modal fade right modal-scrolling w-auto h-auto" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true"
     [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" style="z-index:2500;">
  <div class="modal-dialog modal-side modal-bottom-right modal-notify modal-info" role="document" style="width: 490px;">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header p-2" style="height:70px">
        <p class="heading">{{"AcceptCookiesTitle" | translate}}
        </p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <p>
          {{"CookieLawBannerText" | translate}}
          <a href="#/privacy/policy">{{"MoreInfo" | translate}}</a>
        </p>

        <div id="gdpr-cookie-types" [hidden]="!customized">
          <h5 class="cookie-types-title text-primary">{{"SelectCookieTypes" | translate}}</h5>
          <ul class="list-unstyled">
            <li *ngFor="let cookieType of cookieTypes">
              <mdb-checkbox [disabled]="cookieType.disabled" [checked]="cookieType.checked" [(ngModel)]="cookieType.checked">
                {{cookieType.type | translate}}
              </mdb-checkbox>
            </li>
          </ul>
        </div>

      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button class="btn btn-primary btn-rounded" type="button" (click)="onAccept()">{{"AcceptCookies" | translate}}</button>
        <button class="btn btn-outline-info btn-rounded" [disabled]="customized" type="button" (click)="onCustomize()">{{"CustomiseCookies" | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
