import { HomeService } from './../../../services/home.service';
import {Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {OrderedItem, Product} from "../../../models";
import {SubscriptionService} from "../../../services/subscription.service";
import { sortBy } from "lodash";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-self-order-ro-menu",
  templateUrl: "./self-order-ro-menu.component.html",
  styleUrls: ["./self-order-ro-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderRoMenuComponent implements OnInit, OnDestroy {
  public show: boolean;
  public searchText: string;
  public orderedItems: OrderedItem[] = [];
  public selfOrderMenuEventSubscription: Subscription;
  public productCategories: any[] = [];
  public products: Product[] = [];
  public productOrderedEventSubscription: Subscription;
  public orderCount = 0;
  public menuNotExists = false;
  public isLoading = true;
  public logoImage: string;
  public restaurantName: string;
  private menuId: string;

  @HostBinding("style.--so-primary-color") primaryColor: string = '';
  @HostBinding("style.--so-secondary-color") secondaryColor: string = '';

  constructor(
    private subscriptionService: SubscriptionService,
    private route:ActivatedRoute,
    private homeService: HomeService
  ) {
    this.menuId = this.route.snapshot.params['menuId'];
    this.route.data.subscribe((response:any) => {
      this.logoImage = response.menu.logoUrl;
      this.restaurantName = response.menu.restaurantName;
      this.primaryColor = response.menu.primaryColor;
      this.secondaryColor = response.menu.secondaryColor;
      this.homeService.getMenuInfo(this.menuId).subscribe(menu => {
        this.saveMenuData(menu);
      });
    });
  }

  public async ngOnInit() {
    this.selfOrderMenuEventSubscription = this.subscriptionService.selfOrderMenuEvent.subscribe((items) => {
      this.show = true;
      this.orderedItems = items;
      this.orderCount = 0;
    });

    this.productOrderedEventSubscription = this.subscriptionService.productOrderedEvent.subscribe((product) => {
      setTimeout(() => {
        this.orderCount += product["orderCount"];
      }, 1000);
    });
  }

  public ngOnDestroy(): void {
    this.selfOrderMenuEventSubscription?.unsubscribe();
    this.productOrderedEventSubscription?.unsubscribe();
  }

  saveMenuData(data: any) {
    this.menuNotExists = data.menuNotExists;
    localStorage.setItem("menu-not-exists", this.menuNotExists + '');
    if (!this.menuNotExists) {
        var user = data.user;
        var userSettings = data.userSettings;
        this.logoImage = user.logoUrl;
        this.restaurantName = user.restaurantName;
        localStorage.setItem("products", JSON.stringify(data.products));
        localStorage.setItem("product-pictures", JSON.stringify(data.productPictures));
        localStorage.setItem("categories", JSON.stringify(data.categories));
        localStorage.setItem("category-pictures", JSON.stringify(data.categoryPictures));
        localStorage.setItem("customerId", user.partitionKey);
        localStorage.setItem("currencySymbol", user.currencySymbol);
        localStorage.setItem("user-logo", user.logoUrl);
        localStorage.setItem("restaurant-name", user.restaurantName);
        localStorage.setItem("user-settings", JSON.stringify(userSettings));

        this.productCategories = sortBy(data.categories, 'productCategoryName');
        this.products = sortBy(data.products, 'productName');
    }

    setTimeout(() => this.isLoading = false, 1000);
  }


  public closeItemList() {
    this.show = false;

    setTimeout(() => this.hideProducts(), 1000);
  }

  public searchItems() {
    this.subscriptionService.searchStarted(this.searchText);
  }

  private hideProducts() {
    this.productCategories.forEach((cat) => {
      cat.showProducts = false;
    });
  }

  clearSearch() {
    this.searchText = "";
    this.searchItems();
  }
}
