<section class="selforder-container h-100" [style.backgroundColor]="backgroundColor">
    <div class="header" style="text-align: center">
        <img [src]="logoUrl" style="height: 150px;" *ngIf="logoUrl" />
        <div *ngIf="restaurantName">
            <h1>{{restaurantName}}</h1>
            <h4 class="pt-2 pb-2">{{"Provides_You" | translate}}</h4>
        </div>
        <h3>{{"DigitalMenu_Title" | translate}}</h3>
        <div style="text-align:center;padding:10px;" [innerHTML]="'SelfOrder_Desc' | translate"></div>
    </div>
</section>