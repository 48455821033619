<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right" id="tableCatModal" role="dialog" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-notify modal-full-height modal-right modal-info m-0 w-100 mw-100" role="document">
    <div class="modal-content" style="min-height:500px;">
      <div class="modal-header p-0 d-flex justify-content-between align-items-center">
        <button mdbBtn type="button" flat="true" class="text-white pl-2 d-flex align-items-center" mdbWavesEffect (click)="hide()">
          <mdb-icon fas icon="chevron-left" size="2x" class="mr-1"></mdb-icon>
          <span class="text-white" style="font-size: 20px; font-weight: 500">{{"Back" | translate}}</span>
        </button>
        <h4 class="modal-title white-text mr-2" id="myModalLabel">{{category.name}}</h4>
      </div>
      <div class="modal-body" style="padding: 0; max-height: calc(100vh - 65px);">
        <div id="tableContainer" style="padding-bottom: 100px;">
          <div class="navbar action-toolbar">
            <div class="action-toolbar p-0 m-0 flex-grow-1">
              <button class="btn btn-outline-primary btn-rounded background-white toolbar-item" (click)="showAddTableDialog()">
                <i class="fa fa-plus"></i>&nbsp;
                <span>{{"AddTable" | translate}}</span>
              </button>
              <button class="btn btn-outline-primary btn-rounded background-white toolbar-item" (click)="showAddMultipleTablesDialog()">
                <i class="fa fa-clone"></i>&nbsp;
                <span>{{"BulkInsert" | translate}}</span>
              </button>
              <button class="btn btn-outline-primary btn-rounded background-white toolbar-item" (click)="showImportTablesDialog()">
                <i class="fa fa-upload"></i>&nbsp;
                <span>{{"ImportTable" | translate}}</span>
              </button>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="btn-group" data-toggle="buttons" style="margin:10px;">
                <label class="btn btn-info btn-rounded active waves-effect waves-light" [class.active]="listType != 'list'" (click)="listType=''">
                  <input class="form-check-input" type="radio" checked autocomplete="off"/>
                  <span class="fa fa-th-large white-text"></span>
                </label>
                <label class="btn btn-info btn-rounded waves-effect waves-light" [class.active]="listType == 'list'" (click)="listType='list'">
                  <input class="form-check-input" type="radio" autocomplete="off"/>
                  <span class="fa fa-list white-text"></span>
                </label>
              </div>
            </div>
          </div>

          <div style='width: 100%; text-align: center; margin-top: 20px;' *ngIf="tables.length == 0">
            <img src="/images/table.svg" style="width: 200px;"/>
            <div class="text-info informative-text m-3" role="alert">{{"NoTables" | translate}}</div>
          </div>

          <div style="display: flex;" [class.table-list]="listType == 'list'">
            <div id="tableList" class="row" style="margin: 0;text-align:center;">
              <app-single-table *ngFor="let table of tables"
                                [table]="table"
                                [category]="category"
                                [allTables]="tables"
                                class="col-lg-4 col-md-6 col-sm-12"
                                style="padding:5px"></app-single-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
