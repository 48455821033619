import {Component, Input, OnInit} from "@angular/core";
import {InvoiceModel} from "../../../../models/invoice.model";

@Component({
  selector: "app-receipt-list",
  templateUrl: "./receipt-list.component.html",
  styleUrls: ["./receipt-list.component.scss"],
})
export class ReceiptListComponent implements OnInit {
  @Input() set receipts(value: InvoiceModel[]) {
    this._receipts = value;
    this.calculateTotalSum();
  }

  get receipts(): InvoiceModel[] {
    return this._receipts;
  }

  public totalAmount: number = 0;
  private _receipts: InvoiceModel[];

  constructor() { }

  public ngOnInit(): void {
    this.calculateTotalSum();
  }

  private calculateTotalSum() {
    this.totalAmount = 0;
    this.receipts.forEach((receipt) => {
      this.totalAmount += receipt.totalPriceGross - receipt.discountAmount;
    });
  }
}
