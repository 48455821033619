<ng-container *ngIf="menuNotExists && !isLoading">
    <app-self-order-menu-not-existing></app-self-order-menu-not-existing>
</ng-container>

<ng-container *ngIf="!menuNotExists && !isLoading">
    <app-self-order-menu [readOnly]="true" [show]="true" [showBackButton]="false"></app-self-order-menu>
    <app-self-order-product-details></app-self-order-product-details>
</ng-container>

<div class="app-loading" *ngIf="isLoading">
    <img alt="" class="main-logo" id="logoImage" [src]="logoImage" />
    <div id="restaurantName" class="restaurant-name">{{restaurantName}}</div>
</div>