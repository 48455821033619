<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-info payment-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="heading" id="myModalLabel">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="tbl-payment">
          <tbody>
          <tr>
            <td style="font-weight:bold;">{{"NetTotal" | translate}}</td>
            <td style="font-weight:bold;">
              {{paymentViewModel.subTotal | otcurrency}}
            </td>
          </tr>
          <tr *ngIf="paymentViewModel.tipAmount > 0">
            <td class="subtotal-small-text">{{"Tip" | translate}}</td>
            <td class="subtotal-small-text">
              {{paymentViewModel.tipAmount | otcurrency}}
            </td>
          </tr>
          <tr *ngIf="showFee">
            <td class="card-fee-select subtotal-small-text">
              <mdb-select [options]="cardFeeOptions" class="w-100  colorful-select dropdown-info"></mdb-select>
            </td>
            <td class="subtotal-small-text">{{cardFee | otcurrency}}</td>
          </tr>
          <tr *ngIf="canDiscount">
            <td class="subtotal-small-text">
              <a href="javascript:void(0);" class="btn btn-flat p-0 m-0" style="text-transform: initial;" (click)="showDiscountDialog()">
                <i class="fas fa-plus pr-1 text-danger"></i>
                <span class="text-danger">{{"AddDiscount" | translate}}</span>
              </a>
            </td>
            <td class="subtotal-small-text text-danger">
              -{{paymentViewModel.discount | otcurrency}}
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #dedede;">
            <td style="font-weight:bold; font-size: 18px;">{{"ToBePaid" | translate}}</td>
            <td style="font-weight:bold; font-size: 18px;">
              {{paymentViewModel.grandTotal | otcurrency}}
            </td>
          </tr>
          <tr *ngIf="paymentViewModel.paymentType === 'CombinedPayment'">
            <td colspan="2">
              <table class="w-100">
                <tr *ngFor="let paymentType of paymentTypeOptions" (click)="setSubPaymentType(paymentType.value)" [class.active-sub-payment]="paymentViewModel.subPaymentType == paymentType.value">
                  <td>{{paymentType.label}}</td>
                  <td style="width:100px;margin-left:10px;">{{paymentViewModel.subPayments[paymentType.value] || 0 | otcurrency}}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="border-bottom: 1px solid #dedede;">
            <td style="font-weight:bold;">{{"Paid" | translate}}</td>
            <td style="font-weight:bold;">{{paymentViewModel.billAmount | otcurrency}}</td>
          </tr>
          <tr style="border-bottom: 1px solid #dedede;">
            <td style="font-weight:bold;">{{"Rest" | translate}}</td>
            <td style="font-weight:bold;">{{paymentViewModel.billRemainder | otcurrency}}</td>
          </tr>
          <tr>
            <td colspan="2">
              <table class="w-100">
                <tr>
                  <td class="payment-type-text">{{"PaymentType" | translate}}</td>
                  <td class="payment-type-select">
                    <div class="dropdown float-right w-100" mdbDropdown>
                      <button mdbDropdownToggle type="button" mdbBtn size="sm" class="dropdown-toggle waves-light" style="width:100%;">
                        {{paymentViewModel.paymentType | translate}}
                      </button>

                      <div class="dropdown-menu">
                        <a *ngFor="let option of paymentTypeOptions" class="dropdown-item" href="javascript:void(0);"
                           (click)="setPaymentType(option.value)">{{ option.label }}</a>
                        <a class="dropdown-item" href="javascript:void(0);"
                           (click)="setPaymentType('CombinedPayment')">{{ 'CombinedPayment' | translate }}</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="tbl-number-pad">
          <tr>
            <td><input type="button" class="btn btn-info" value="1" (click)="appendNumber(1)" /></td>
            <td><input type="button" class="btn btn-info" value="2" (click)="appendNumber(2)" /></td>
            <td><input type="button" class="btn btn-info" value="3" (click)="appendNumber(3)" /></td>
            <td><input type="button" class="btn btn-outline-info" value="+5" (click)="appendValue(5)" /></td>
          </tr>
          <tr>
            <td><input type="button" class="btn btn-info" value="4" (click)="appendNumber(4)"/></td>
            <td><input type="button" class="btn btn-info" value="5" (click)="appendNumber(5)"/></td>
            <td><input type="button" class="btn btn-info" value="6" (click)="appendNumber(6)"/></td>
            <td><input type="button" class="btn btn-outline-info" value="+10" (click)="appendValue(10)"/></td>
          </tr>
          <tr>
            <td><input type="button" class="btn btn-info" value="7" (click)="appendNumber(7)"/></td>
            <td><input type="button" class="btn btn-info" value="8" (click)="appendNumber(8)"/></td>
            <td><input type="button" class="btn btn-info" value="9" (click)="appendNumber(9)"/></td>
            <td><input type="button" class="btn btn-outline-info" value="+20" (click)="appendValue(20)"/></td>
          </tr>
          <tr>
            <td><input type="button" class="btn btn-info" [value]="decimalSeparator" (click)="appendDecimalSeparator()"/></td>
            <td><input type="button" class="btn btn-info" value="0" (click)="appendNumber(0)"/></td>
            <td><a class="btn btn-danger pt-2" value="9" (click)="deleteLastNumber()"><i class="fas fa-backspace text-white" style="font-size: 26px;"></i></a></td>
            <td><input type="button" class="btn btn-outline-info" value="+50" (click)="appendValue(50)"/></td>
          </tr>
        </table>
        <div style="display:flex; margin-bottom:10px;justify-content: space-between" *ngIf="showPrintReceipt">
          <span style="margin-top:6px;">{{"PrintReceipt" | translate}}</span>
          <div class="switch round blue-white-switch">
            <label>
              <input type="checkbox" [(ngModel)]="shouldPrintReceipt" />
              <span class="lever"></span>
            </label>
          </div>
        </div>
        <div style="display:flex; justify-content: space-between" *ngIf="showHostingSplit">
          <span style="margin-top:6px;">{{"PrintWithDetails" | translate}}</span>
          <div class="switch round blue-white-switch">
            <label>
              <input type="checkbox"  [(ngModel)]="showDetails" />
              <span class="lever"></span>
            </label>
          </div>
        </div>
        <div *ngIf="showDetails && false">
          <a href="javascript:void(0);" (click)="showCustomerList()">{{"ChooseCustomer" | translate}}</a>
          <div *ngIf="selectedCustomer" style="float: right">
            <label style="vertical-align: top;margin-top: 5px;">{{selectedCustomer.name}}</label>
            <a class="text-danger" (click)="removeCustomer()" style="font-size: 23px;margin-left: 6px;">
              <i class="far fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button mdbBtn color="info" rounded="true" class="toolbar-item"
                (click)="doPayment()">
          <i class="fas fa-check pr-1"></i>
          <span>{{"FinishPayment" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
