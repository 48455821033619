import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {OrderedItem} from "../../../models";

@Component({
  selector: "app-self-order-approve-single-ordered-item",
  templateUrl: "./self-order-approve-single-ordered-item.component.html",
  styleUrls: ["./self-order-approve-single-ordered-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderApproveSingleOrderedItemComponent implements OnInit {
  @Input()
  public item: OrderedItem;

  public productName: string;
  public extras: string;

  public temporaryCompletedStatusId = 1.5;
  public temporaryDeniedStatusId = 5.5;
  constructor() {
  }

  public ngOnInit(): void {
    this.productName = this.getProductName();
    this.extras = this.getExtras();
  }

  public getProductName() {
    let productName = this.item.productName;
    if (!productName) {
      if (this.item.product) {
        productName = this.item.product.productName;
      }
    }

    if (!productName) { return ""; }

    if (!this.item.selectedPriceCategory) { return productName; }

    return productName + " (" + this.item.selectedPriceCategory + ")";
  }

  public getExtras() {
    const extras = this.item.extras;
    if (!extras) { return ""; }

    return extras.replace(/\n/g, "<br />").replace(/\(\+\)/, "<b class='with'>(+)</b>").replace(/\(-\)/, "<b class='without'>(-)</b>");
  }

  public denyOrder() {
    this.item.status = this.temporaryDeniedStatusId;
  }

  public approveOrder() {
    this.item.status = this.temporaryCompletedStatusId;
  }
}
