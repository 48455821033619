<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addCustomer()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"AddCustomer" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="md-form">
            <input mdbInput id="name" name="name" type="text" class="form-control" [(ngModel)]="customer.name" required="required"/>
            <label for="name">{{"CompanyName" | translate}}</label>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="md-form">
                <input mdbInput type="text" id="telephone" name="telephone" class="form-control" [(ngModel)]="customer.telephone"/>
                <label for="telephone">{{"Telephone" | translate}}</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form">
                <input mdbInput type="email" name="email" id="email" class="form-control" [(ngModel)]="customer.email"/>
                <label for="email">{{"Email" | translate}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="md-form">
                <input mdbInput id="firstName" name="firstName" type="text" class="form-control" [(ngModel)]="customer.firstName"/>
                <label for="firstName">{{"FirstName" | translate}}</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form">
                <input mdbInput id="lastName" name="lastName" type="text" class="form-control" [(ngModel)]="customer.lastName"/>
                <label for="lastName">{{"LastName" | translate}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="md-form">
                <input mdbInput type="text" id="street" name="street" class="form-control" [(ngModel)]="customer.street" autocorrect="off" autocomplete="off" autocapitalize="off" spellcheck="false" />
                <label for="street">{{"Street" | translate}}</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form">
                <input mdbInput type="text" id="postalCode" name="postalCode" class="form-control" [(ngModel)]="customer.postalCode"/>
                <label for="postalCode">{{"Zip" | translate}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="md-form">
                <input mdbInput type="text" id="city" name="city" class="form-control" [(ngModel)]="customer.city"/>
                <label for="city">{{"City" | translate}}</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form">
                <input mdbInput type="text" id="country" name="country" class="form-control" [(ngModel)]="customer.country"/>
                <label for="country">{{"Country" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" data-dismiss="modal">
            {{(customer.id ? "Save" : "Add") | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
