<div class="section section-header" style="text-align:center;font-size:40px;font-weight:bold;">
  <span>
    -{{"DigitalOrders_Title" | translate}}-
  </span>
</div>

<div class="section">
  {{ "RunningBusyRestaurant" | translate }}
</div>
<div class="section">
  <p [innerHTML]='"DigitalSolution" | translate'></p>
  <p [innerHTML]='"NoMoreNeededList" | translate'></p>
</div>

<div class="section" [innerHTML]='"Solution" | translate'></div>

<div class="section" style="text-align:center;">
  <label style="font-weight:bold;">
    {{ "DigitalOrders_VideoTitle" | translate }}
  </label>
</div>
<div class="section" style="text-align:center;">
  <div class="youtube embed-responsive embed-responsive-16by9 z-depth-3" embed="RX83hZgL3u0" youTubeVideo>
    <div class="play-button"></div>
  </div>
</div>
