import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { Customer } from "../models/customer.model";
import {LocalStorageService} from "./localstorage.service";
@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  public loadAllCustomers() {
    return this.http.get<Customer[]>(`/api/v1/customers`);
  }

  public deleteCustomer(id: string) {
    return this.http.delete(`/api/v1/customers/${id}`);
  }

  public updateCustomer(customer: Customer) {
    return this.http.put<Customer>(`/api/v1/customers`, customer);
  }
}
