import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as dayjs from "dayjs";
import { User } from "../../../models";
import {IMyOptions, MdbTableDirective, MdbTablePaginationComponent} from "ng-uikit-pro-standard";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import { UserService } from "src/app/services/user.service";
import {ModalService} from "../../../services/modal.service";
import { TseService } from "../../../services/tse.service";
import { TseExport } from "src/app/models/tse.export";
import { Router } from "@angular/router";

@Component({
  selector: "app-tse-settings",
  templateUrl: "./tse-settings.component.html",
  styleUrls: ["./tse-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TSESettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;

  @ViewChild(MdbTableDirective, { static: true }) public mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) public mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("row", { static: true }) public row: ElementRef;

  public startDateStr: string;
  public startDate: any;

  public endDateStr: string;
  public endDate: any;
  public columns: any;
  public currentLocale = this.translateService.currentLang;

  public dateFormat = dayjs.Ls[this.currentLocale].formats.L.toLowerCase();
  public datePickerOptions: IMyOptions = {
    dateFormat: this.dateFormat,
  };

  public user: User = new User();
  public packagePrice = 0;

  public exports: TseExport[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private userService: UserService,
    private tseService: TseService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.user = this.localStorage.getUserSync()
    this.localStorage.getPackagesInfoAsync().then(packages => {
      const packageInfo = packages.find(p => p.id == this.user.packageId);
      if(packageInfo != null) this.packagePrice = packageInfo.price;
    });

    this.columns = [
      { key: "time_request", name: this.translateService.instant("CreateDate")},
      { key: "time_start", name: this.translateService.instant("DatevExportStartDate")},
      { key: "time_completed", name: this.translateService.instant("DatevExportEndDate")},
      { key: "action", name: "", sortable: false },
    ];

    if(this.user.fiskalyOrganisationId) {
      this.tseService.getAllExport().subscribe(exportResult => {
        this.exports = exportResult.data;
        this.exports.forEach((report) => {
          report["requestDate"] = dayjs(report.time_request * 1000).format("L LT");
          report["startDate"] = dayjs(report.time_start * 1000).format("L LT");
        });
        this.mdbTable.setDataSource(this.exports);
      }, error => {
      });
    }
  }

  public startDateUpdated($event: any) {
    this.startDate = dayjs($event.jsdate);
  }

  public endDateUpdated($event: any) {
    this.endDate = dayjs($event.jsdate);
  }

  public activateTSE() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.tseService.activateTSE().subscribe((result) => {
      this.blockUI.stop();
      this.user.fiskalyOrganisationId = result.organisationId;
      this.localStorage.setUser(this.user);
      this.localStorage.setVatDefinitions(result.vatDefinitions);

      this.messageService.success(this.translateService.instant("TSEActivatedSuccessfully"));

      this.localStorage.getUserSettingsAsync().then(userSettings => {
        if(result.vatDefinitions.find(i=> i.percentage == userSettings.tax) == null){
          const confirmModel = {
            title: this.translateService.instant("WrongTaxRate_Title"),
            message: this.translateService.instant("WrongTaxRate_Description"),
            type: "danger",
            hideCancelButton: true,
            confirmButtonTitle: this.translateService.instant("GotoSettings"),
            confirmed: () => {
              this.router.navigate(["/settings/common"]);
            },
          };
          this.modalService.show("confirm-dialog", confirmModel);
        }
      });
    }, error => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public export() {
    if (!this.startDate) {
      this.messageService.error(this.translateService.instant("ChooseStartDate"));

      return;
    }

    if (!this.endDate) {
      this.messageService.error(this.translateService.instant("ChooseEndDate"));

      return;
    }

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.tseService.export(this.startDate.unix(), this.endDate.unix()).subscribe((tseExport) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("ExportStartedSuccessfully"));

      this.exports.push(tseExport);
      this.mdbTable.setDataSource(this.exports);

      setTimeout(() => {
        this.refreshReport(tseExport);
      }, 5000);
      this.refreshReport(tseExport);
    }, error => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  refreshReport(tseExport: TseExport) {
    this.tseService.getExport(tseExport._id).subscribe(report => {
      if (report.href) {
        tseExport.href = report.href;
        tseExport.time_completed = report.time_completed;
      }
      else {
        setTimeout(() => this.refreshReport(tseExport), 5000);
      }
    });
  }
}
