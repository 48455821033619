import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {v4 as uuid} from "uuid";
import {PrinterSettings} from "../../../models";
import {AccountService} from "../../../services/account.service";
import {LocalizationService} from "../../../services/localization.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";

@Component({
  selector: "app-printer-settings",
  templateUrl: "./printer-settings.component.html",
  styleUrls: ["./printer-settings.component.scss"],
})
export class PrinterSettingsComponent implements OnInit {
  @BlockUI() public blockUI: NgBlockUI;
  public allSettings: PrinterSettings[] = [];

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private localeService: LocalizationService,
    private accountService: AccountService,
    private messageService: MessageService,
  ) {
  }

  public async ngOnInit() {
    this.allSettings = await this.localStorage.getPrinterSettingsAsync();

    await this.loadSettings();
  }

  public addWlanPrinter() {
    const settings = new PrinterSettings();
    settings.id = uuid();
    settings.type = "wlan";
    this.allSettings.push(settings);
  }

  public addBluetoothPrinter() {
    const settings = new PrinterSettings();
    settings.id = uuid();
    settings.type = "bluetooth";
    this.allSettings.push(settings);
  }

  public async  loadSettings() {
    this.messageService.showLoading(await this.translateService.get("LoadingPrinters").toPromise());
    this.accountService.loadPrinterSettings().subscribe((settings: PrinterSettings[]) => {
      this.messageService.hideLoading();
      this.allSettings = settings;
      this.localStorage.setPrinterSettings(this.allSettings);
    }, (error) => {
      this.messageService.hideLoading();
      this.messageService.error(error);
    });
  }

  public saveSettings() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.accountService.savePrinterSettings({printerSettings: this.allSettings}).subscribe((printerSettings) => {
      this.blockUI.stop();

      this.allSettings = printerSettings;
      this.messageService.success(this.translateService.instant("PrinterSettingsSaveSuccess"));
      this.localStorage.setPrinterSettings(this.allSettings);

    }, (err) => {
      if (!err) { err = this.translateService.instant("PrinterSettingsSaveError"); }
      this.messageService.error(err);
    });
  }
}
