import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Product, ProductCategory} from "../models";
import {LocalStorageService} from "./localstorage.service";
@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  public setFavorite(productId: string): Observable<any> {
    return this.http.put<any>(`/api/v1/product/favorite/${productId}`, {});
  }

  public loadAllProducts() {
    return this.http.get<any>(`/api/v1/product`);
  }

  public loadProduct(productId: string) {
    return this.http.get<Product>(`/api/v1/product/${productId}`);
  }

  public addProduct(product: Product) {
    return this.http.post<Product>(`/api/v1/product`, product);
  }

  public updateProduct(product: Product) {
    return this.http.put<Product>(`/api/v1/product`, product);
  }

  public deleteProduct(productId: string) {
    return this.http.delete<Product>(`/api/v1/product/${productId}`);
  }

  public loadAllProductCategories(loadProducts = false) {
    let url = `/api/v1/productcategory`;
    if (loadProducts) { url = `/api/v1/productcategory/list/withproducts`; }

    return this.http.get<any>(url);
  }

  public loadCategory(productCategoryId: string) {
    const url = `/api/v1/productcategory/${productCategoryId}`;

    return this.http.get<ProductCategory>(url);
  }

  public addCategory(category: ProductCategory) {
    return this.http.post<ProductCategory>(`/api/v1/productcategory`, category);
  }

  public deleteCategory(id: string) {
    return this.http.delete<any>(`/api/v1/productcategory/${id}`);
  }

  public updateCategory(category: ProductCategory) {
    return this.http.put<ProductCategory>(`/api/v1/productcategory`, category);
  }

  public resetCategoryPictures(productCategoryId: string) {
    return this.http.delete<ProductCategory>(`/api/v1/productcategory/picture/reset/${productCategoryId}`);
  }

  public importCategories(data: FormData) {
    return this.http.post<ProductCategory[]>(`/api/v1/productcategory/import`, data);
  }

  public importProducts(data: FormData) {
    return this.http.post<string[]>(`/api/v1/product/import`, data);
  }

  public deleteFile(fileId: string) {
    return this.http.delete(`/api/v1/product/deletefile/${fileId}`);
  }
}
