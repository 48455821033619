import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {assign, cloneDeep, findIndex} from "lodash";
import {MdbTableDirective, MdbTablePaginationComponent} from "ng-uikit-pro-standard";
import {Customer} from "../../../models/customer.model";
import {CustomerService} from "../../../services/customer.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTableDirective, { static: true }) public mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) public mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("row", { static: true }) public row: ElementRef;

  public customers: any[] = [];

  public columns;

  public searchText = "";
  public previous: string;
  public maxVisibleItems = 8;

  constructor(
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private customerService: CustomerService,
    private modalService: ModalService,
    private messageService: MessageService) {

  }

  @HostListener("input") public oninput() {
    this.mdbTablePagination.searchText = this.searchText;
  }

  public async ngOnInit() {
    this.columns = [
      {key: "action", name: ""},
      {key: "name", name: await this.translateService.get("CompanyName").toPromise()},
      {key: "telephone", name: await this.translateService.get("Telephone").toPromise()},
      {key: "email", name: await this.translateService.get("Email").toPromise()},
      {key: "firstName", name: await this.translateService.get("FirstName").toPromise()},
      {key: "lastName", name: await this.translateService.get("LastName").toPromise()},
    ];
  }

  public async ngAfterViewInit() {
    this.messageService.showLoading(await this.translateService.get("LoadingCustomers").toPromise());
    this.customerService.loadAllCustomers().subscribe((customers: Customer[]) => {
      this.messageService.hideLoading();
      this.customers = customers;

      this.mdbTable.setDataSource(this.customers);
      this.previous = this.mdbTable.getDataSource();
    }, () => {
      this.messageService.hideLoading();
    });

    this.mdbTablePagination.setMaxVisibleItemsNumberTo(this.maxVisibleItems);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  public insertCustomer(customer) {
    this.customers.push(customer);
    this.mdbTable.setDataSource(this.customers);
  }

  public removeCustomer(customer) {
    const index = findIndex(this.customers, {id: customer.id});
    if (index > -1) {
      this.customers.splice(index, 1);
    }
    this.mdbTable.setDataSource(this.customers);
  }

  public showAddCustomer() {
    this.modalService.show("create-customer-dialog", {
      customer: new Customer(),
      onCustomerAdded: (customer) => {
        this.insertCustomer(customer);
      },
    });
  }

  public searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.customers = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.customers = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();

    this.mdbTable.searchDataObservable(this.searchText).subscribe(() => {
      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
    });
  }

  public showEditCustomer(customer: any) {
    this.modalService.show("create-customer-dialog", {
      customer: cloneDeep(customer),
      onCustomerAdded: (cust: Customer) => {
        assign(customer, cust);
      },
    });
  }

  public async showDeleteCustomerConfirmation(customer) {
    const confirmModel = {
      message: await this.translateService.get("DeleteCustomerConfirm").toPromise(),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.deleteCustomer(customer);
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public deleteCustomer(customer: any) {
    this.customerService.deleteCustomer(customer.id).subscribe(() => {
      this.removeCustomer(customer);
    });
  }
}
