import {
  Component,
  ElementRef, Input, OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {OrderStatus} from "../../../enums/order.status.enum";
import { ModalComponent } from "../../../interfaces/ModalComponent";
import {OrderedItem, ProductCategory} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-menu-card",
  templateUrl: "./menu-card.component.html",
  styleUrls: ["./menu-card.component.scss", "./menu-card.component.media.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuCardComponent implements OnInit, OnDestroy, ModalComponent {
  @ViewChild("menuModal") public menuModal: ElementRef;
  @Input()
  public orderedItems: OrderedItem[] = [];

  public productCategories: ProductCategory[] = [];
  public productCount: number;
  public searchText = "";
  public showOrderedItemList: boolean;
  public showQuickAccess: boolean;
  public initialCategory: ProductCategory = new ProductCategory();
  public tableId: string;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
  ) {

      this.initialCategory = {
        allowSendToKitchen: undefined,
        priceCategories: [],
        extras: [],
        sideDishes: [],
        variationGroups: [],
        id: "",
        productCategoryDescription: "",
        products: [],
        sortingId: 0,
        tax: undefined,
        productCategoryId: "2",
        productCategoryName: "QuickAccess",
        selected: true,
        color: ""
      };

  }

  public async ngOnInit() {
    this.productCategories = await this.localStorage.getProductCategoriesAsync();
    this.productCount = (await this.localStorage.getProductsAsync()).length;
    this.modalService.register("menu-card", this);

    this.subscriptionService.tableRefreshEvent.subscribe(async (tableId) => {
        if(tableId == this.tableId) {
          this.orderedItems = (await this.localStorage.getOrderedItemsAsync()).filter(i => i.tableId == tableId && i.status == OrderStatus.new);
        }
    });
  }

  public ngOnDestroy(): void {
    this.productCategories = null;
  }

  public resetSearch() {
    this.searchText = "";
    this.searchItems();
  }

  public searchItems() {
    this.subscriptionService.searchStarted(this.searchText);
  }

  public hide() {
    this.menuModal.nativeElement.classList = "menu-modal menu-card-closed";
  }

  public show(args) {
    this.menuModal.nativeElement.classList = "menu-modal menu-card-opened";
    this.orderedItems = args.orderedItems;
    this.tableId = args.tableId;
    this.subscriptionService.orderMenuOpened();
  }
}
