import {OverlayModule} from "@angular/cdk/overlay";
import {CommonModule} from "@angular/common";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IconsModule} from "ng-uikit-pro-standard";
import {DaterangePickerLocaleService} from "./daterangepicker-locale.service";
import {DaterangepickerComponent} from "./daterangepicker.component";
import {LOCALE_CONFIG, LocaleConfig} from "./daterangepicker.config";
import {DaterangepickerDirective} from "./daterangepicker.directive";

@NgModule({
  declarations: [DaterangepickerComponent, DaterangepickerDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    IconsModule,
  ],
  exports: [DaterangepickerComponent, DaterangepickerDirective],
})
export class NgxDaterangepickerMd {
  public static forRoot(config: LocaleConfig = {}): ModuleWithProviders<NgxDaterangepickerMd> {
    return {
      ngModule: NgxDaterangepickerMd,
      providers: [
        {provide: LOCALE_CONFIG, useValue: config},
        {provide: DaterangePickerLocaleService, useClass: DaterangePickerLocaleService, deps: [LOCALE_CONFIG]},
      ],
    };
  }
}
