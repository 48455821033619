<mdb-card class="m-2">
  <mdb-card-body style="text-align: center;">
    <mdb-card-title>
      <h4>{{category.productCategoryName}}</h4>
    </mdb-card-title>
    <div class="d-flex w-100 justify-content-around">
      <mdb-card-text>
        {{categoryProducts.length}} {{"ProductsCount" | translate}}
      </mdb-card-text>

      <mdb-card-text *ngIf="category.tax > 0">
        {{"Tax" | translate}}: {{category.tax}}%
      </mdb-card-text>
    </div>
  </mdb-card-body>
  <mdb-card-footer class="d-flex justify-content-between pl-0 pr-0">
    <button mdbBtn color="info" outline="true" rounded="true" mdbWavesEffect class="pl-4 pr-4" style="background: white !important;" (click)="showProducts()">{{"Products" | translate}}</button>

    <div class="dropdown" mdbDropdown>
      <a mdbDropdownToggle href="javascript:void(0)" class="btn dropdown-toggle waves-light pl-4 pr-4 btn-cat-action" mdbWavesEffect>
        {{"Actions" | translate}}
    </a>

      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="javascript:void(0);" (click)="showEditCategory()">
          <i class="fas fa-pencil-alt mr-1" style="font-size: 18px;"></i>
          <span>{{"EditCategory" | translate}}</span>
        </a>
        <a class="dropdown-item danger-text" href="javascript:void(0);" (click)="showDeleteCategoryConfirmation()">
          <i class="far fa-trash-alt mr-1" style="font-size: 18px;"></i>
          <span>{{"DeleteCategory" | translate}}</span>
        </a>
      </div>
    </div>
  </mdb-card-footer>
</mdb-card>

