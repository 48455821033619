<mdb-card class="table-card m-2">
  <mdb-card-body>
    <mdb-card-title>
      <h4>{{table.name}}</h4>
    </mdb-card-title>

    <mdb-card-text>
      {{ table?.tax > 0 ? ('Tax' | translate) + ':  ' + table.tax + '%' : '' }}
    </mdb-card-text>
  </mdb-card-body>
  <mdb-card-footer>
    <div class="dropdown" mdbDropdown dynamicPosition="true">
      <a href="javascript:void(0);" mdbDropdownToggle rounded="true" class="dropdown-toggle waves-light w-100 btn-table-action"
              mdbWavesEffect>
        {{"Actions" | translate}}
      </a>

      <div class="dropdown-menu">
        <a href="javascript:void(0);" class="dropdown-item" (click)="showUpdateTable()">
          <i class="fas fa-pencil-alt mr-1" style="font-size: 18px;color:inherit"></i>
          <span>{{"EditTable" | translate}}</span>
        </a>
        <a href="javascript:void(0);" class="dropdown-item" *ngIf="allowSelfOrdering" (click)="showSelfOrderingSettings()">
          <span class="fas fa-qrcode mr-1" style="font-size: 18px;color:inherit"></span>
          <span>{{"SelfOrderingSettings" | translate}} ...</span>
        </a>
        <a href="javascript:void(0);" class="dropdown-item danger-text" (click)="showDeleteTableConfirmation()">
          <i class="far fa-trash-alt mr-1" style="font-size: 18px;color:inherit"></i>
          <span>{{"DeleteTable" | translate}}</span>
        </a>
      </div>
    </div>
  </mdb-card-footer>
</mdb-card>
