<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAdd" role="dialog"  [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && splitTable()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"ConfirmTitle" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="md-form">
            <input #inputName mdbInput type="text" class="form-control" name="name" id="name" [(ngModel)]="name" required/>
            <label>{{"SplitTableEnterName" | translate}}</label>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" data-dismiss="modal" class="toolbar-item">
            {{"Split" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
