import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {DayEndReportStatistic} from "../models";
import {DatevReport} from "../models/datev.report.model";
import {DayEndReport} from "../models/day.end.report.model";
import {GobdReport} from "../models/gobd.report.model";
import {InvoiceModel} from "../models/invoice.model";
import { WaiterDayEndReport } from "../models/waiter-dayend-report.model";
import { SystemUsageLog } from "../models/system.usage.log";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(
    private http: HttpClient) {
  }

  public getReceipts(startDate, endDate, paymentType) {
    return this.http.get<InvoiceModel[]>("/api/v1/receipt/" + startDate + "/" + endDate + "/" + paymentType);
  }

  public updateReceiptPaymentType(invoiceNumber: number, date: string, paymentType: string) {
    return this.http.post<InvoiceModel[]>("/api/v1/ordereditem/updatepaymentmethod2/" + invoiceNumber + "/" + date + "/" + paymentType, null);
  }

  public cancelOrders(cancelledOrderedIds: {}, invoiceNumber: number, invoiceDate: string) {
    const data = { orderIds: cancelledOrderedIds, invoiceNumber, invoiceDate };

    return this.http.put<{newInvoice: InvoiceModel, oldInvoice: InvoiceModel}>("/api/v1/ordereditem/cancelorders" , data);
  }

  public createZReport() {
    return this.http.get<{zreport: DayEndReport, reportInfo: DayEndReportStatistic}>("/api/v1/receipt/zreport");
  }

  public getZReports(startDate, endDate) {
    return this.http.get<DayEndReport[]>("/api/v1/receipt/zreports/" + startDate + "/" + endDate);
  }

  public getLastZReportDate() {
    return this.http.get<string>("/api/v1/receipt/lastzreportdate");
  }

  public exportDatev(exportModel) {
    return this.http.post<DatevReport>("/api/v1/receipt/datev", exportModel);
  }

  public getDatevReports() {
    return this.http.get<DatevReport[]>("/api/v1/receipt/datev/reports/all");
  }

  public sendDatevReportViaEmail(reportId: string, email: string) {
    return this.http.get("/api/v1/receipt/datev/report/sendemail/" + reportId + "/" + email);
  }

  public getGobdReports() {
    return this.http.get<GobdReport[]>("/api/v1/receipt/gobd/reports/all");
  }

  public getWaiterReports(startDate: string, endDate: string) {
    return this.http.get<WaiterDayEndReport[]>(`/api/v1/receipt/waiter/reports/${startDate}/${endDate}`);
  }

  public exportWaiterDayEndReport(startDate: string, endDate: string) {
    return this.http.post<WaiterDayEndReport>(`/api/v1/receipt/waiter/report/create/${startDate}/${endDate}`, {});
  }

  public exportGobd(startDate: string, endDate: string) {
    return this.http.post<GobdReport>("/api/v1/receipt/gobd", {startDate, endDate});
  }

  public getLoginLogs(startDate, endDate) {
    return this.http.get<SystemUsageLog[]>("/api/v1/account/usagelogs/" + startDate + "/" + endDate);
  }
}
