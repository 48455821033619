import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import { Employee } from "../models/employee.model";
@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  constructor(
    private http: HttpClient) {
  }

  public loadAllEmployees() {
    return this.http.get<Employee[]>(`/api/v1/employees`);
  }

  public deleteEmployee(id: string) {
    return this.http.delete(`/api/v1/employees/${id}`);
  }

  public updateEmployee(employee: Employee) {
    return this.http.put<Employee>(`/api/v1/employees`, employee);
  }
}
