<div long-press class="single-ordered-item"
     (click)="selectItem(item, $event)"
     (onLongPress)="showProductDetails()"
     [class.item-finished]="item.status == temporaryCompletedStatusId"
     [class.item-cancelled]="item.status == 5"
     [class.highlight]="item.isNew || item.updated"
     [hidden]="selectedCategories.indexOf(item.productCategoryId) == -1">
  <div class="quantity" [innerHTML]="quantity"></div>
  <div class="product-info">
    <label class="product-name">
      {{ productName }}
    </label>
    <label class="extras" [innerHTML]="extras" *ngIf="extras">
    </label>
    <label class="d-none cancelled-label">
      {{"Cancelled" | translate}}
    </label>
  </div>
  <div class="action" *ngIf="!readOnly">
    <a href="javascript:void(0)" class="btn-floating btn-white"
       (click)="actionItemClicked(item, $event)"
       [mdbTooltip]="'OrderReady_Tooltip' | translate" placement="left">
      <i class="fas text-primary"
         [class.fa-undo]="item.status == temporaryCompletedStatusId"
         [class.fa-trash-alt]="item.status == 5"
         [class.fa-check]="item.status == cookingStatusId"
      ></i>
    </a>
  </div>
</div>
