<div class="row" style="padding-top: 100px; margin-left: 0; margin-right: 0; z-index: 10;">
  <div class="col-sm-12">
    <section style="max-width: 500px; margin-left: auto; margin-right: auto;">
    </section>

    <div class="modal-dialog cascading-modal" role="document" style="margin-top:25px;">
      <div class="modal-content">
        <div class="modal-header light-blue darken-3 white-text">
          <h4 class="title">
            <i class="fas fa-shield-alt"></i>
            <span>
              {{ "ResetPassword" | translate }}
            </span>
          </h4>
        </div>
        <div class="modal-body mb-0">
          <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && resetPassword()" *ngIf="!error" class="form-horizontal html5-form" style="background: #fff;padding: 10px;border-radius: 2px;">
              <p> {{ "ResetPasswordDescription" | translate }}</p>
              <div class="md-form">
                  <i class="fas fa-lock prefix"></i>
                  <input mdbInput type="password" name="password" id="password" [(ngModel)]="password" class="form-control" required="required" minlength="4" />
                  <label for="password">{{"Password" | translate}}</label>
              </div>
              <div class="md-form">
                  <i class="fas fa-lock prefix"></i>
                  <input mdbInput type="password" name="confirmPassword" id="confirmPassword" [(ngModel)]="confirmPassword" class="form-control" required="required" minlength="4" />
                  <label for="confirmPassword">{{"RepeatPassword" | translate}}</label>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <button type="submit" class="btn btn-primary btn-lg btn-rounded" style="width: 100%;">{{"Save" | translate}}</button>
                  </div>
              </div>
          </form>
          <div class="danger-text" *ngIf="error">
            {{error | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
