import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter, find, minBy} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {OrderStatus} from "../../../enums/order.status.enum";
import {NotificationTypes, OrderedItem, Table, UserSettings} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {PosService} from "../../../services/pos.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {LocalCurrencyPipe} from "../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-pos-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  @Input()
  public table: Table = new Table();

  public activeTableId: string;
  public backgroundColor: string;
  public tableId: string;
  public existingNewItems: boolean;
  public orderTime: string;
  public orderInfo: string;

  public currencyPipe: LocalCurrencyPipe;
  private userSettings: UserSettings = new UserSettings();
  private orderedItems: OrderedItem[] = [];
  private orderedItemSubscription: Subscription;
  private tableClickSubscription: Subscription;
  private tableRefreshSubscription: Subscription;
  private splitTableSubscription: Subscription;
  private mergeTableSubscription: Subscription;
  visible: boolean = false;

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private posService: PosService,
    private messageService: MessageService,
  ) {

    this.currencyPipe = new LocalCurrencyPipe(this.translateService);

  }

  public ngOnInit() {
    this.localStorage.getUserSettingsAsync().then(async settings => {
      this.userSettings = settings;
      await this.setTableInfos();
    });

    this.orderedItemSubscription = this.subscriptionService.orderedItemsReloadedEvent.subscribe(async () => {
      await this.setTableInfos();
    });

    this.tableClickSubscription = this.subscriptionService.tableClickEvent.subscribe((tableId) => {
      this.activeTableId = tableId;
    });

    this.tableRefreshSubscription = this.subscriptionService.tableRefreshEvent.subscribe(async (tableId) => {
      if (this.table.id === tableId) {
        await this.setTableInfos();

        if (this.orderedItems.length === 0) {
          this.localStorage.removeTableNotifications(this.table.id, null);
          this.subscriptionService.refreshNotifications();
        } else if (this.orderedItems.filter((i) => i.status === OrderStatus.ready).length === 0) {
          this.localStorage.removeTableNotifications(this.table.id, NotificationTypes.OrdersReady);
          this.subscriptionService.refreshNotifications();
        }
      }
    });
  }

  public ngOnDestroy() {
    this.table = null;
    this.userSettings = null;
    this.orderedItems = null;
    this.currencyPipe = null;

    this.orderedItemSubscription.unsubscribe();
    this.tableClickSubscription.unsubscribe();
    this.tableRefreshSubscription.unsubscribe();

    this.splitTableSubscription?.unsubscribe();
    this.mergeTableSubscription?.unsubscribe();
  }

  public async setTableInfos(): Promise<any> {
    this.orderedItems = await this.getOrderedItems();
    this.backgroundColor = this.getBackgroundColor();
    this.existingNewItems = find(this.orderedItems, {status: OrderStatus.new}) !== undefined;
    this.orderTime = this.getOrderedTime();
    this.orderInfo = await this.getOrderInfo();
    this.visible = true;
  }

  public showTableItems() {
    this.subscriptionService.tableClicked(this.table.id);
  }

  public async splitTable(event) {
    event.stopPropagation();

    const confirmModel = {
      name: await this.getNextTableSplitName(),
      splitTable: (name) => {
        this.doSplitTable(name);
      },
    };
    this.modalService.show("table-split-enter-name", confirmModel);
  }

  private async getNextTableSplitName() {
    const tables = await this.localStorage.getTablesAsync();

    let counter = 1;
    while(tables.find((element) => element.name == this.table.name + " ("+counter+")")) {
      counter++
    }

    return this.table.name + " (" + counter + ")";
  }

  public mergeTable(event) {
    event.stopPropagation();
    if (this.orderedItems.length === 0) {
      this.doMergeTables();
      return;
    }

    const confirmModel = {
      message: this.translateService.instant("MergeTableConfirm"),
      confirmed: () => {
        this.doMergeTables();
      },
    };

    this.modalService.show("confirm-dialog", confirmModel);
  }

  public doMergeTables() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.mergeTableSubscription = this.posService.mergeTable(this.table.id).subscribe(() => {
      this.blockUI.stop();
      this.localStorage.removeTable(this.table);

      const newOrders = filter(this.orderedItems, {status: OrderStatus.new});
      newOrders.forEach((item) => {
        const courseId = this.getCourseIdFromName(this.table.splittedFrom, item.courseName);
        if (courseId) {
          item.courseId = courseId;
        }

        item.tableId = this.table.splittedFrom;
      });
      this.localStorage.saveOrderedItems();

      this.subscriptionService.deleteSplittedTable(this.table.id);
      this.subscriptionService.tableRefresh(this.table.splittedFrom);
      if (window.innerWidth > 768) {
        this.subscriptionService.tableClicked(this.table.splittedFrom);
      }
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public getCourseIdFromName(tableId, courseName) {
    const item = find(this.orderedItems, {tableId, courseName});
    if (item) {
      return item.courseId;
    }

    return "";
  }

  private doSplitTable(name: string) {
    this.splitTableSubscription = this.posService.splitTable(this.table.id, name).subscribe((splittedTable) => {
      this.blockUI.stop();
      if (splittedTable) {
        this.localStorage.addTable(splittedTable);
        this.subscriptionService.tableSplitted(splittedTable);
      }
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  private getBackgroundColor() {
    const items = filter(this.orderedItems, function(item) {
      return item.status !== 0;
    });
    if (items.length === 0) {
      return this.userSettings.freeTableColor;
    }

    const readyItems = filter(items, {status: 2});
    if (readyItems.length > 0) {
      return this.userSettings.readyTableColor;
    }

    const notSyncedItems = filter(items, function(item) {
      return item.status !== 3;
    });
    if (notSyncedItems.length > 0) {
      return this.userSettings.orderedTableColor;
    }

    return this.userSettings.servedTableColor;
  }

  private async getOrderedItems(): Promise<OrderedItem[]> {
    return filter(await this.localStorage.getOrderedItemsAsync(), {tableId: this.table.id});
  }

  private getOrderedTime() {
    if (this.orderedItems.length === 0) {
      return "";
    }

    const minOrder = minBy(this.orderedItems, function(i) {
      return new Date(i.orderDate).getTime();
    });
    if (minOrder) {
      const orderDate = new Date(minOrder.orderDate);
      if (orderDate.getFullYear() > 1) {
        let hours = orderDate.getHours().toString();
        if (hours.length === 1) {
          hours = "0" + hours;
        }

        let minutes = orderDate.getMinutes().toString();
        if (minutes.length === 1) {
          minutes = "0" + minutes;
        }

        return hours + ":" + minutes;
      }

      return "";
    }
  }

  private getOrderInfo() {
    if (this.orderedItems.length === 0) {
      return "";
    }

    let total = 0;
    let count = 0;
    this.orderedItems.map((item) => {
      total += item.totalPrice;
      count += item.quantity;
    });

    return count + "x / " + this.currencyPipe.transform(total);
  }
}
