import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Table, TableCategory} from "../models";
import {LocalStorageService} from "./localstorage.service";

@Injectable({
  providedIn: "root",
})
export class TableService {
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  public addCategory(category) {
    return this.http.post("/api/v1/tablecategory", category);
  }

  public deleteCategory(id: string) {
    return this.http.delete("/api/v1/tablecategory/" + id);
  }

  public updateCategory(category: TableCategory) {
    return this.http.put("/api/v1/tablecategory", category);
  }

  public getAllTables(): Observable<Table[]> {
    return this.http.get<Table[]>(`/api/v1/table`);
  }

  public getAllTablesAndCategories(): Observable<{tables: Table[], tableCategories: TableCategory[]}> {
    return this.http.get<{tables: Table[], tableCategories: TableCategory[]}>(`/api/v1/table/all`);
  }

  public addTable(table: Table): Observable<Table> {
    return this.http.put<Table>("/api/v1/table", table);
  }

  public deleteTable(id: string) {
    return this.http.delete("/api/v1/table/" + id);
  }

  public loadTableShortUrl(id: string): Observable<string> {
    // @ts-ignore
    return this.http.get<string>("/api/v1/qrcode/shorturl/" + id, {responseType: "text"});
  }

  public addMultipleTables(param: { template: string; count: number, tax: number, categoryId: string }) {
    return this.http.post<Table[]>(`/api/v1/table/bulkinsert`, param);
  }

  public importTables(categoryId: string, data: FormData) {
    return this.http.post<Table[]>(`/api/v1/table/import/${categoryId}`, data);
  }
}
