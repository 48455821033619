<div class="right-container" id="add-product-modal" style="width: 100%;" [class.right-container-slide-left]="show === true" [class.right-container-slide-right]="show === false">
    <div class="circle"></div>
    <div>
        <div class="navbar-collapse full-width d-flex justify-content-between" style="display:flex;height:65px;">
            <ul class="navbar-nav navbar-header mr-auto">
                <li class="nav-item">
                    <a href="javascript:void(0);" (click)="close()" class="back-link">
                        <i class="fas fa-arrow-left" style="margin-right: 6px;"></i>
                        <span>{{"Back" | translate}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div style="width: 100%;padding: 0 24px;" *ngIf="product">
        <div class="product-details-modal">
            <div *ngIf="images?.length > 1" class="product-images carousel slide" data-ride="carousel">
                <mdb-carousel [isControls]="true" [animation]="'slide'" #carousel>
                    <mdb-carousel-item *ngFor="let image of images">
                        <picture>
                            <source media="(max-width: 300px)" srcset="{{getSourceUrl(image, '300')}}" />
                            <source media="(max-width: 450px)" srcset="{{getSourceUrl(image, '450')}}" />
                            <source media="(max-width: 768px)" srcset="{{images[0].url}}" />
                            <img class="d-block ml-auto mr-auto carousel-image" src="{{images[0].url}}" alt="Product image" />
                        </picture>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
        </div>
        <div *ngIf="images?.length == 1">
            <picture>
                <source media="(max-width: 300px)" srcset="{{getSourceUrl(images[0], '300')}}" />
                <source media="(max-width: 450px)" srcset="{{getSourceUrl(images[0], '450')}}" />
                <source media="(max-width: 768px)" srcset="{{images[0].url}}" />
                <img class="d-block ml-auto mr-auto single-image" src="{{images[0].url}}" alt="Product image" />
            </picture>
        </div>


        <div class="product-name-price mt-3">
            <span class="name">{{product?.productName}}</span>
            <span class="price">{{totalPrice | otcurrency}}</span>
        </div>
        <div class="d-flex justify-content-between">
            <div class="details-desc" [innerHTML]="product.productDescription | sanitizeHtml"></div>
            <div class="order-counter">
                <button class="btn btn-flat" (click)="decreaseCount(product, $event)"><i class="fas fa-minus"></i></button>
                <div>{{product["orderCount"]}}</div>
                <button class="btn btn-flat" (click)="increaseCount(product, $event)"><i class="fas fa-plus"></i></button>
            </div>
        </div>

        <div class="details-title mt-4">
            {{"PortionSize" | translate}}
        </div>
        <div class="chip-container">
            <div class="chip" *ngFor="let price of product.priceCategories; let i=index;" [class.selected]="selectedPortionSizeIndex == i" (click)="selectPortionSize(i)">{{price}}</div>
        </div>

        <div *ngIf="sideDishes.length > 0">
            <div class="details-title mt-4">
                {{"SideDishes" | translate}}*
                <div class="reuired-help">{{"ChooseSideDish" | translate}}</div>
            </div>
            <div class="ingredients-item" *ngFor="let sideDish of sideDishes">
                <div>
                    <span>{{sideDish.name}}</span>&nbsp;<span *ngIf="sideDish.price > 0" class="extra-price-label">({{sideDish.price | otcurrency}})</span>
                </div>
                <ui-switch size="small" [(ngModel)]="sideDish.isSelected" (ngModelChange)="sideDishSelected(sideDish)"></ui-switch>
            </div>
        </div>

        <div class="details-title mt-4" *ngIf="extras.length > 0">{{"ChooseProductExtras" | translate}}</div>
        <div *ngIf="extras.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div class="ingredients-item" *ngFor="let extra of extras">
                <div>
                    <span>{{extra.name}}</span>&nbsp;<span *ngIf="extra.price > 0" class="extra-price-label">({{extra.price | otcurrency}})</span>
                </div>
                <div class="order-counter small">
                    <button class="btn btn-flat" (click)="decreaseExtraCount(extra, $event)"><i class="fas fa-minus"></i></button>
                    <div>{{extra.count}}</div>
                    <button class="btn btn-flat" (click)="increaseExtraCount(extra, $event)"><i class="fas fa-plus"></i></button>
                </div>
            </div>
        </div>

        <div class="details-title mt-4" *ngIf="product.ingredients?.length > 0">{{"RemoveProductIngredients_Title" | translate}}</div>
        <div *ngIf="product.ingredients?.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div class="ingredients-item" *ngFor="let ingredient of ingredients">
                <div><span>{{ingredient}}</span></div>
                <ui-switch size="small" [(ngModel)]="ingredientsToRemove[ingredient]"></ui-switch>
            </div>
        </div>

        <div *ngIf="variationGroups.length > 0" style="padding-bottom: 0;margin-bottom: 10px;border-bottom: none;">
            <div id="{{replaceDashes(variationGroup.id)}}" *ngFor="let variationGroup of variationGroups">
                <div class="details-title mt-4" [class.invalid]="invalidVariationGrups.indexOf(variationGroup.id) != -1">{{variationGroup.label ? variationGroup.label : variationGroup.name}}</div>
                <div class="details-sub-title" [class.invalid]="invalidVariationGrups.indexOf(variationGroup.id) != -1" *ngIf="variationGroup.min > 0 || variationGroup.max">
                    <span *ngIf="variationGroup.min > 0">Min. {{variationGroup.min}}</span>
                    <span *ngIf="variationGroup.min > 0 && variationGroup.max"> - </span>
                    <span *ngIf="variationGroup.max">Max. {{variationGroup.max}}</span>
                </div>
                <div class="ingredients-item" *ngFor="let variation of variationGroup.variations">
                    <div (click)="variationClicked(variationGroup, variation)">
                        <span>{{variation.label}}</span>&nbsp;<span class="extra-price-label">({{getVariationPrice(variation) | otcurrency}})</span>
                    </div>

                    <ui-switch size="small" [disabled]="maximumReachedGroups[variationGroup.id] == true && !selectedVariations[variation.id]" *ngIf="!variationGroup.allowOptionQuantity" [checked]="selectedVariations[variation.id] > 0" (change)="toggleVariation(variationGroup, variation)"></ui-switch>

                    <div class="order-counter" *ngIf="variationGroup.allowOptionQuantity">
                        <button class="btn btn-flat" (click)="decreaseVariationCount(variationGroup, variation)"><i class="fas fa-minus"></i></button>
                        <div>{{selectedVariations[variation.id] ?? 0}}</div>
                        <button class="btn btn-flat" [disabled]="maximumReachedGroups[variationGroup.id]" (click)="increaseVariationCount(variationGroup, variation)"><i class="fas fa-plus"></i></button>
                    </div>
                </div>                
            </div>
        </div>

        <div class="details-title mt-4">{{"SpecialWishes" | translate}}</div>
        <mdb-form-control>
            <textarea mdbInput class="form-control" id="textAreaExample" rows="4" placeholder='{{"EnterSpecialWishes" | translate}}' [(ngModel)]="manualExtra"></textarea>
        </mdb-form-control>

        <div class="w-100 d-flex justify-content-center">
            <button type="button" class="btn btn-primary btn-rounded mt-4 mb-4 btn-add-to-list" (click)="orderProduct()">{{(orderedItem ? "Update" : "AddToOrderList") | translate}}</button>
        </div>
    </div>
</div>