import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {PackageInfo, UserSettings} from "../../../../models";
import {ModalService} from "../../../../services/modal.service";
import { MessageService } from "src/app/services/message.service";
import { LocalStorageService } from "src/app/services/localstorage.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-publish-menu-dialog",
  templateUrl: "./publish-menu-dialog.component.html",
  styleUrls: ["./publish-menu-dialog.component.scss"],
})
export class PublishMenuDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public userSettings: UserSettings = new UserSettings();
  public packageInfo: PackageInfo = new PackageInfo();
  public currentDate: number = Date.now();

  constructor(
    private modalService: ModalService,
    private messageService: MessageService,
    private localStorage: LocalStorageService,
    private userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("publish-menu-dialog", this);

    this.localStorage.getUserSettingsAsync().then(settings => {
      this.userSettings = settings;
    });

    this.localStorage.getPackagesInfoAsync().then(packages => {
      this.localStorage.getUserAsync().then(user => {
        this.packageInfo = packages.find(p => p.id == user.packageId);
      });
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.modal.show();
  }

  downloadQRCode() {
    fetch('/api/v1/qrcode/create/menu?width=400&height=400').
    then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'menu.png';
      document.body.appendChild(a);
      a.click();
    }).catch(error => {
      this.messageService.error(error);
    });
  }

  async updateEnableDigitalMenu($event: any) {
    this.currentDate = Date.now();
    this.userService.saveSettings(this.userSettings).subscribe(() => {
      this.localStorage.setUserSettings(this.userSettings);
    }, () => {
      this.userSettings.enableDigitalMenu = !this.userSettings.enableDigitalMenu;
    });
  }
}
