<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade" id="modalAddMultiple" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addTables()" class="html5-form" autocomplete="off">
    <div class="modal-dialog modal-notify modal-info modal-md" role="document" style="width: 750px;margin-left: auto;margin-right: auto;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{"AddTable" | translate}}</p>
        </div>
        <div class="modal-body">
          <div class="form-group" style="padding-top:20px;">
            <div style="display:flex">
              <div class="md-form">
                <input mdbInput #nameRef type="text" class="form-control" style="width: 300px;margin-right: 15px;" id="tableTemplate" name="tableTemplate" [attr.placeholder]="'TemplateTableExample' | translate" [(ngModel)]="template" required="required"/>
                <label for="tableTemplate">{{"TableTemplate" | translate}}</label>
              </div>
              <div class="md-form">
                <input mdbInput type="number" class="form-control" id="tableCount" name="tableCount" [(ngModel)]="count" style="width:150px;" required=""/>
                <label for="tableCount">{{"TableCount" | translate}}</label>
              </div>
            </div>
            <div>
              <div class="md-form">
                <input mdbInput type="number" class="form-control" id="tax" name="tax" [(ngModel)]="tax">
                <label for="tax">{{"Tax" | translate}}</label>
              </div>
            </div>
            <div style="margin-top:15px;" [innerHTML]='"BulkInsertExample" | translate'></div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" mdbWavesEffect (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="primary" rounded="true" class="toolbar-item" mdbWavesEffect>
            <i class="fa fa-plus text-white"></i>
            {{"Add" | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
