import {formatCurrency} from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: "otcurrency",
})
export class LocalCurrencyPipe implements PipeTransform {
  public currencyCode: string;
  constructor(
    private translateService: TranslateService) {
    this.currencyCode = localStorage.getItem("currencySymbol");
  }

  public transform(
    value: number,
    currencyCode: string= this.currencyCode,
    display:
      | "code"
      | "symbol"
      | "symbol-narrow"
      | string
      | boolean = "symbol",
    digitsInfo: string = ".2-2",
    locale: string = this.translateService.currentLang,
  ): string | null {
    if (value === undefined) { return null; }

    let result = formatCurrency(
      value,
      locale,
      this.currencyCode,
      currencyCode,
      digitsInfo);
    result = result.replace(/\s/g, "");
    return result;
  }
}
