import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {TranslateModule} from "@ngx-translate/core";
import {AppCommonModule} from "../common/common.module";
import {KdsModule} from "../kds/kds.module";
import {PosModule} from "../pos/pos.module";
import { PosDeliveryRoutingModule } from "./pos-delivery-routing.module";
import { PosDeliveryComponent } from "./pos-delivery.component";

@NgModule({
  declarations: [PosDeliveryComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    PosDeliveryRoutingModule,
    KdsModule,
    TranslateModule,
    PosModule,
  ],
})
export class PosDeliveryModule { }
