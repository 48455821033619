import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {UserRoles} from "../enums/user.roles.enum";
import {LocalStorageService} from "../services/localstorage.service";

@Injectable()
export class CookBarmanGuard implements CanActivate {

  constructor(
    private localStorage: LocalStorageService,
    public router: Router,
  ) {
  }

  public async canActivate() {
    const user = await this.localStorage.getUserAsync();
    if (user?.role === UserRoles.CookBarman.toString() ||
      user?.role === UserRoles.Admin.toString() ||
      user?.role === UserRoles.AdminHelper.toString()) {
      return true;
    }

    if (!user) {
      localStorage.clear();
      await this.router.navigate(["/auth/login"]);
      return false;
    } else {
      await this.router.navigate(["/access-denied"]);
    }
  }
}
