import {BaseModel} from "./base.model";

export class PrinterSettings extends BaseModel {
  public printerName: string;

  public ipAddress: string;

  public port: number;

  public bluetoothAddress: string;

  public bluetoothPrinterName: string;

  public columnCounts: string;

  public cahceRegister: boolean;

  public categories: string;

  public coding: string;

  public type: string;
}
