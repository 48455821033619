<div style="padding: 10px 0 0 0;"
     [class.item-finished]="item.status == temporaryCompletedStatusId"
     [class.item-denied]="item.status == temporaryDeniedStatusId">
  <table class="w-100">
    <tr>
      <td style="text-align: center;">{{item.quantity}}x</td>
      <td style="width: auto;">
        <div class="product-name">{{productName}}</div>
        <div class="extras" *ngIf="extras" [innerHTML]="extras | extrasHtmlNormalizer"></div>
      </td>
    </tr>
    <tr>
      <td style="background: #f3f3f3" colspan="2">
        <div class="d-flex justify-content-between">
          <button class="btn btn-danger btn-rounded toolbar-item" style="padding: 5px 10px; margin-top: 5px; margin-right: 5px;" (click)="denyOrder()">
            <i class="far fa-times-circle"></i>
            <span>{{"Deny" | translate}}</span>
          </button>

          <button class="btn btn-primary btn-rounded toolbar-item" style="padding: 5px 10px; margin-top: 5px; margin-right: 5px;" (click)="approveOrder()">
            <i class="fas fa-check"></i>
            <span>{{"Approve" | translate}}</span>
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>
