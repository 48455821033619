import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {ButtonsModule, CarouselModule, IconsModule, InputsModule, ModalModule, WavesModule} from "ng-uikit-pro-standard";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../../../environments/environment";
import {AppCommonModule} from "../common/common.module";
import {AboutComponent} from "./about/about.component";
import {HomeRoutingModule} from "./home-routing.module";
import {HomeComponent} from "./home/home.component";
import {KitchenModuleComponent} from "./kitchen-module/kitchen-module.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {ReportingComponent} from "./reporting/reporting.component";

@NgModule({
  declarations: [
    HomeComponent,
    KitchenModuleComponent,
    ReportingComponent,
    AboutComponent,
    PrivacyComponent,
  ],
  exports: [
    HomeComponent,
    KitchenModuleComponent,
    ReportingComponent,
    AboutComponent,
    PrivacyComponent,
  ],
  imports: [
    HomeRoutingModule,
    RouterModule,
    AppCommonModule,
    WavesModule,
    ButtonsModule.forRoot(),
    InputsModule,
    FormsModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeKey),
    IconsModule,
  ],
})
export class HomeModule {
}
