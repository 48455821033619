import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { find, sortBy } from "lodash";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Subscription } from "rxjs";
import { v4 as uuid } from "uuid";
import { OrderStatus } from "../../../enums/order.status.enum";
import { OrderedItem, Product, ProductCategory, Table, User, UserSettings } from "../../../models";
import { MessageService } from "../../../services/message.service";
import { ModalService } from "../../../services/modal.service";
import { SelfOrderService } from "../../../services/self.order.service";
import { SignalRService } from "../../../services/signalr.service";
import { SubscriptionService } from "../../../services/subscription.service";

@Component({
  selector: "app-self-order-list",
  templateUrl: "./self-order-list.component.html",
  styleUrls: ["./self-order-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SelfOrderListComponent implements OnInit, OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;
  @Output()
  public onOrdersUpdated = new EventEmitter();

  public table: Table = new Table();
  public user: User = new User();
  public userSettings: UserSettings;
  public customerId: string;
  public totalAmount = 0;
  public totalPrice = 0;
  public orderedItems: OrderedItem[] = [];
  public errorMessage: string;
  public currencySymbol: string;
  public show: boolean;
  public existingNewItems: boolean;
  public selfOrderListEventSubscription: Subscription;
  public productOrderedEventSubscription: Subscription;
  public signalRRefreshSubscription: Subscription;
  public removeOrderedItemSubscription: Subscription;
  public orderedItemUpdatedEventSubscription: Subscription;
  public productCategories: ProductCategory[] = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private selfOrderService: SelfOrderService,
    private signalRService: SignalRService,
    private modalService: ModalService,
  ) { }

  public ngOnInit() {
    this.table = JSON.parse(localStorage.getItem("table"));
    this.user = JSON.parse(localStorage.getItem("self-order-user"));
    this.customerId = localStorage.getItem("customerId");
    this.productCategories = JSON.parse(localStorage.getItem("categories"));

    this.orderedItems = this.loadSavedOrderedItems();
    this.existingNewItems = !!this.orderedItems.find((i) => i.status === OrderStatus.new);

    this.currencySymbol = this.user?.currencySymbol;
    this.userSettings = JSON.parse(localStorage.getItem("user-settings"));

    this.selfOrderListEventSubscription = this.subscriptionService.selfOrderListEvent.subscribe((show) => {
      this.show = show;
      this.refreshUI();
    });

    this.productOrderedEventSubscription = this.subscriptionService.productOrderedEvent.subscribe(async (product) => {
      const orderedItem = await this.createOrderedItemFromProduct(product);
      this.orderedItems.push(orderedItem);
      this.saveOrderedItems();
      this.onOrdersUpdated.emit();
      this.existingNewItems = true;
      this.refreshUI();
    });

    this.orderedItemUpdatedEventSubscription = this.subscriptionService.orderedItemUpdatedEvent.subscribe(async (orderedItem: OrderedItem) => {
      const index = this.orderedItems.findIndex(i => i.orderedItemId == orderedItem.orderedItemId);
      if(index > -1) {
        this.orderedItems.splice(index, 1);
        const item = await this.createOrderedItemFromProduct(orderedItem.product);
        item.orderedItemId = orderedItem.orderedItemId;
        this.orderedItems.splice(index, 0, item);
      }

      this.orderedItemUpdated();
      this.onOrdersUpdated.emit();
    });

    this.removeOrderedItemSubscription = this.subscriptionService.removeOrderedItemEvent.subscribe((item) => {
      this.orderedItems = this.orderedItems.filter((i) => i.orderedItemId !== item.orderedItemId);
      this.saveOrderedItems();
      this.refreshUI();
      this.onOrdersUpdated.emit();
      this.existingNewItems = this.orderedItems.filter((i) => i.status === OrderStatus.new).length > 0;
    });

    this.signalRRefreshSubscription = this.signalRService.tableRefresh.subscribe((result) => {
      this.refreshIfNecessary(result);
    });

    this.signalRRefreshSubscription = this.signalRService.ordersReady.subscribe((result) => {
      if(result.tableId == this.table.id) {
        this.showOrdersReadyDialog();
        this.refreshIfNecessary(result);
      }
    });

    this.loadOrders();
  }

  public ngOnDestroy(): void {
    this.selfOrderListEventSubscription.unsubscribe();
    this.productOrderedEventSubscription.unsubscribe();
    this.signalRRefreshSubscription.unsubscribe();
    this.removeOrderedItemSubscription.unsubscribe();
    this.orderedItemUpdatedEventSubscription.unsubscribe();
  }

  public closeItemList() {
    const newItems = this.orderedItems.filter((i) => i.status === OrderStatus.new);
    if (newItems.length > 0) {

      const confirmModel = {
        message: this.translateService.instant("SendItemsToKitchenConfirm"),
        icon: "paper-plane",
        type: "warning",
        confirmed: () => {
          this.sendItems();
        },
        denied: () => {
          this.goBackToMainMenu();
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    } else {
      this.goBackToMainMenu();
    }
  }

  public goBackToMainMenu() {
    this.show = false;
  }
  public showMenu() {
    this.subscriptionService.openSelfOrderMenu(this.orderedItems);
  }

  public sendItems() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.selfOrderService.orderItems(this.table.id, this.orderedItems).subscribe(() => {
      this.blockUI.stop();
      this.orderedItems.forEach((item) => {
        if (item.status === OrderStatus.new) {
          item.status = this.userSettings.approveSelfOrders
            ? OrderStatus.waitingForApproval
            : OrderStatus.cooking;
        }
      });

      this.saveOrderedItems();
      this.onOrdersUpdated.emit();
      this.existingNewItems = false;

      const message = !this.userSettings.approveSelfOrders
        ? "OrdersSentToKitchenSuccessfully"
        : "OrdersMusBeApproved";

      const confirmModel = {
        title: this.translateService.instant(this.userSettings.approveSelfOrders ? "Warning" : "Success"),
        message: this.translateService.instant(message),
        type: this.userSettings.approveSelfOrders ? "warning" : "success",
        icon: this.userSettings.approveSelfOrders ? "exclamation-triangle" : "check",
        hideCancelButton: true,
        confirmButtonTitle: "Close",
        confirmed: () => {
        },
      };
      this.modalService.show("confirm-dialog", confirmModel);
    }, async (err) => {
      this.blockUI.stop();
      if (err === "ScanCodeAgain") {
        this.errorMessage = await this.translateService.get(err).toPromise();
      } else {
        this.messageService.error(err);
      }
    });
  }

  public async getItemTax(product): Promise<any> {
    if (this.table?.tax != null && this.table.tax >= 0) { return this.table.tax; }

    if (product) {
      const cat = find(this.productCategories, function (c) {
        return c.productCategoryId === product.productCategoryId;
      });
      if (cat?.tax != null && cat.tax >= 0) { return cat.tax; }
    }

    return this.userSettings.tax;
  }

  public getItemTaxPrice(item) {
    const tax = item.tax >= 0 ? item.tax : this.userSettings.tax;

    let taxValue = 0;
    if (tax > 0) {
      if (this.userSettings.isTaxInPriceIncluded) {
        taxValue = item.totalPrice - item.totalPrice / (1 + tax / 100);
      } else {
        taxValue = item.totalPrice * tax / 100;
      }
    }

    return taxValue;
  }

  public orderedItemUpdated() {
    this.saveOrderedItems();
    this.refreshUI();
  }

  private loadOrders(showLoading = true) {
    if (showLoading) {
      this.blockUI.start(this.translateService.instant("PleaseWait"));
    }

    this.selfOrderService.getTableOrders(this.customerId, this.table.id).subscribe((items) => {
      this.blockUI.stop();

      this.refreshOrderedItems(items);
      this.onOrdersUpdated.emit();
    }, async (error) => {
      if (error === "ScanCodeAgain") {
        this.errorMessage = await this.translateService.get(error).toPromise();
      } else {
        this.messageService.error(error);
      }
      this.blockUI.stop();
    });
  }

  private async createOrderedItemFromProduct(product: Product): Promise<OrderedItem> {
    let productCategoryName = "";
    const productCategory = find(this.productCategories, { productCategoryId: product.productCategoryId });
    if (productCategory) { productCategoryName = productCategory.productCategoryName; }

    const quantity = product["orderCount"] ? product["orderCount"] : 1;
    const orderedItem = {
      id: uuid(),
      customerId: "",
      orderedItemId: uuid(),
      tableId: this.table.id,
      tableName: this.table.name,
      productId: product.productId,
      productName: product.productName,
      courseId: this.table.id,
      courseName: "",
      categoryName: productCategoryName,
      quantity,
      unitPrice: product.unitPrice,
      totalPrice: quantity * (product.unitPrice + (product.extrasInfo ? product.extrasInfo.extrasPrice : 0)),
      product,
      sideDishes: product.extrasInfo?.sideDishes,
      extrasIds: product.extrasInfo?.extrasId,
      extras: product.extrasInfo?.extras,
      manualExtra: product.extrasInfo?.manualExtra,
      notes: product.extrasInfo?.manualExtra,
      extrasPrice: product.extrasInfo?.extrasPrice,
      selectedPriceCategory: product.extrasInfo?.selectedPriceCategory,
      ingredientsToRemove: product.extrasInfo?.ingredientsToRemove,
      selectedExtra: product.extrasInfo?.selectedExtra,
      orderedVariations: product.extrasInfo?.orderedVariations,
      status: OrderStatus.new,
      orderDate: new Date().toISOString(),
      isActive: true,
      tax: await this.getItemTax(product),
      taxPrice: 0,
      selectionCount: 0,
      server: "",
      servedBy: "",
      productCategoryId: product.productCategoryId,
    } as OrderedItem;

    orderedItem.taxPrice = this.getItemTaxPrice(orderedItem);

    return orderedItem;
  }

  private refreshUI() {
    this.totalAmount = 0;
    this.totalPrice = 0;
    this.orderedItems.forEach((i) => {
      this.totalAmount += i.quantity;
      this.totalPrice += i.totalPrice;
    });
    const item = this.orderedItems.find(i => i.status == OrderStatus.new);
    this.existingNewItems = !!item;
  }

  private saveOrderedItems() {
    localStorage.setItem("self-orders", JSON.stringify(this.orderedItems));
  }

  private loadSavedOrderedItems() {
    const items = JSON.parse(localStorage.getItem("self-orders"));
    return items || [];
  }

  private refreshOrderedItems(items: OrderedItem[]) {
    const newItems = this.orderedItems.filter((i) => i.status === OrderStatus.new && !find(items, { orderedItemId: i.orderedItemId }));
    items = items.concat(newItems);
    this.orderedItems = sortBy(items, "orderDate");
    this.saveOrderedItems();
    this.refreshUI();
  }

  private refreshIfNecessary(result) {
    if (this.table.id === result.tableId) {
      this.loadOrders(false);

      if (result.extrasInfo) {
        let message = "";
        if (result.extrasInfo.status === OrderStatus.cooking) {
          message = "OrdersApproved";
        } else if (result.extrasInfo.status === OrderStatus.ready) {
          message = "OrdersPrepared";
        }

        if (message) {
          const confirmModel = {
            id: message,
            title: this.translateService.instant("Success"),
            message: this.translateService.instant(message),
            type: "success",
            icon: "check",
            hideCancelButton: true,
            confirmButtonTitle: "Close",
            confirmed: () => {
            },
          };
          this.modalService.show("confirm-dialog", confirmModel);
        }
      }
    }
  }

  private showOrdersReadyDialog() {
    const confirmModel = {
      title: this.translateService.instant("Oley"),
      message: this.translateService.instant("OrdersArePrepared"),
      type: "success",
      icon: "check",
      hideCancelButton: true,
      confirmButtonTitle: "Close",
      confirmed: () => {
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }
}
