import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ButtonsModule, IconsModule, InputsModule, ModalModule, TableModule, WavesModule} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../../common/common.module";
import { CreateCustomerComponent } from "./create-customer/create-customer.component";
import {CustomerRoutingModule} from "./customer-routing.module";
import { CustomerComponent } from "./customer.component";

@NgModule({
  declarations: [CustomerComponent, CreateCustomerComponent],
  imports: [
    CustomerRoutingModule,
    CommonModule,
    AppCommonModule,
    IconsModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    ButtonsModule.forRoot(),
    WavesModule,
  ],
})
export class CustomerModule {}
