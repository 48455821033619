<div
  class='{{classes}} editable'
  [mdbPopover]="popTemplate"
  triggers="click"
  [placement]="placement"
  [mdbPopoverHeader]="helpText"
  #pop="bs-mdbPopover">
  {{model || helpText}}
</div>

<ng-template #popTemplate>
  <input mdbInput [hidden]="inputType != 'text'"
         type="text"
         [(ngModel)]="modelCopy"
         class="form-control mb-3"/>

  <textarea mdbInput [hidden]="inputType != 'textarea'"
            [(ngModel)]="modelCopy"
            rows="5"
            class="form-control mb-3"></textarea>

  <div class="d-flex justify-content-end">
    <a class="btn btn-primary" style="padding: 8px 13px;" (click)="applyValue() && pop.hide()">
      <i class="fas fa-check" style="font-size: 24px;"></i>
    </a>

    <a class="btn btn-danger" style="padding: 8px 13px;" (click)="cancelChanges() && pop.hide()">
      <i class="fas fa-times" style="font-size: 24px;"></i>
    </a>
  </div>
</ng-template>
