import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {OrderedItem} from "../models";
import {LocalStorageService} from "./localstorage.service";

@Injectable({
  providedIn: "root",
})
export class SelfOrderService {
  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient) {
  }

  public getTableOrders(customerId: string, tableId: string): Observable<OrderedItem[]> {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });
    
    const url = `/api/v1/specialaccess/table/${customerId}/${tableId}/true`;
    return this.http.get<OrderedItem[]>(url, { headers });
  }

  public orderItems(tableId: string, orderedItems: OrderedItem[]) {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });

    const url = `/api/v1/specialaccess/${tableId}`;
    return this.http.put<OrderedItem[]>(url, orderedItems, { headers});
  }

  public saveCustomer(customerInfo: { name: string; telephone: string }): Observable<any> {
    const url = `/home/selforder/register`;
    return this.http.post<any>(url, customerInfo);
  }

  public verifyCode(userId: string, code: string) {
    const url = `/home/selforder/code/verify/${userId}/${code}`;
    return this.http.get<boolean>(url);
  }

  public resendCode(userId: string) {
    const url = `/home/selforder/code/renew/${userId}`;
    return this.http.get<boolean>(url);
  }

  public callWaiter(customerId: any, tableId: string, tableName: string) {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });

    return this.http.get(`/api/v1/specialaccess/callwaiters/${customerId}/${tableId}/${tableName}`, { headers});
  }

  public isWaiterAlreadyCalled(customerId: any, tableId: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });

    return this.http.get<boolean>(`/api/v1/specialaccess/iswaitercalled/${customerId}/${tableId}`, { headers });
  }

  public requestBill(customerId: any, tableId: string, tableName: string): Observable<boolean> {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });

    return this.http.get<boolean>(`/api/v1/specialaccess/requestbill/${customerId}/${tableId}/${tableName}`, { headers });
  }

  public isBillAlreadyRequested(customerId: any, tableId: string) {
    const headers = new HttpHeaders({ 'SpecialAccessToken': this.localStorage.getSpecialAcessToken() });

    return this.http.get(`/api/v1/specialaccess/isbillrequested/${customerId}/${tableId}`, { headers });
  }
}
