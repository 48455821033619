import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {find} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Subscription} from "rxjs";
import {OrderStatus} from "../../../enums/order.status.enum";
import {OrderedItem} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {PosService} from "../../../services/pos.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-single-order-item",
  templateUrl: "./single-order-item.component.html",
  styleUrls: ["./single-order-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SingleOrderItemComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  @Input()
  public item: OrderedItem;

  @Input()
  public courseItems: OrderedItem[];

  @Input()
  public showEditPrice: boolean;

  @Input()
  public showCancelButton: boolean;

  @Input()
  public isSeparatePaymentActive: boolean;

  @Input()
  public isSeparateMoveActive: boolean;

  @Input()
  public alwaysActive = false;

  public productName = "";
  public extras = "";
  public statusImage = "";

  private returningItem: boolean;
  private returnItemSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
    private messageService: MessageService,
    private posService: PosService,
    private modalService: ModalService,
  ) {
  }

  public ngOnInit(): void {
    this.item.selectionCount = 0;
    this.productName = this.getProductName();
    this.extras = this.getExtras();
    this.statusImage = this.getStatusImage();
  }

  public ngOnDestroy(): void {
    this.item = null;
    this.courseItems = null;
    this.returnItemSubscription?.unsubscribe();
  }

  public async setActive(): Promise<any> {
    const userSettings = await this.localStorage.getUserSettingsAsync();
    if (this.isSeparateMoveActive || (this.item.status == OrderStatus.served && this.isSeparatePaymentActive)) {
      if (this.item.selectionCount === this.item.quantity) {
        this.item.selectionCount = 0;
      } else {
        this.item.selectionCount++;
      }
    } else if (this.item.status === OrderStatus.new || this.item.status === OrderStatus.cooking ||
      (this.item.status === OrderStatus.ready && userSettings.canWaiterCancelReadyItems) ||
      (this.item.status === OrderStatus.served && userSettings.canWaiterCancelServedItems) ||
      (this.item.status === OrderStatus.cancelled)) {

      if (!this.alwaysActive) {
        this.courseItems.map((i) => i.isActive = false);
        this.item.isActive = true;
      }
    }
  }

  public getProductName() {
    let productName = this.item.productName;

    if (!productName) {
      productName = this.item.product && this.item.product.productName ? this.item.product.productName : this.translateService.instant("ProductDeleted");
    }

    if (!productName) { return ""; }

    if (!this.item.selectedPriceCategory) { return productName; }

    return productName + " (" + this.item.selectedPriceCategory + ")";
  }

  public getExtras() {
    const extras = this.item.extras;
    if (!extras) { return ""; }

    return extras.replace(/\n/g, "<br />").replace(/\(\+\)/, "<b class='with'>(+)</b>").replace(/\(-\)/, "<b class='without'>(-)</b>");
  }

  public async increaseAmount(): Promise<any> {
    await this.updatePrice("increase");
  }

  public async decreaseAmount(): Promise<any> {
    if (this.item.quantity > 1) {
      await this.updatePrice("decrease");
    }
  }

  public deleteOrder() {
    const confirmModel = {
      message: this.translateService.instant("DeleteItemConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.deleteItem();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public editPrice() {

  }

  public addWithouts() {
    this.modalService.show("product-withouts", {
      item: this.item,
      onCloseDialog: (item) => {
        this.item = item;
        this.extras = this.getExtras();
      },
    });
  }

  public addExtras() {
    this.modalService.show("product-extras", {
      item: this.item,
      onCloseDialog: (item) => {
        this.extras = this.getExtras();
        this.subscriptionService.orderedItemUpdated(item);
      },
    });
  }

  public showReturnItemConfirmationModal() {
    const confirmModel = {
      message: this.translateService.instant("ReturnItemConfirm"),
      type: "danger",
      icon: "times",
      confirmed: () => {
        this.returnItem();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public returnItem() {
    if (this.returningItem) { return; }

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.returningItem = true;
    this.returnItemSubscription = this.posService.returnItem(this.item.orderedItemId).subscribe((result) => {
      this.blockUI.stop();
      this.returningItem = false;
      if (this.item.quantity < 2) {
        this.deleteItem();
      } else {
        this.item.quantity--;
      }

      if (result.isTableDeleted) {
        this.subscriptionService.deleteSplittedTable(this.item.tableId);
      }
    }, (error) => {
      this.blockUI.stop();
      this.returningItem = false;
      this.messageService.error(error);
    });
  }

  public deleteItem() {
    if (this.item.status == OrderStatus.new) {
      this.subscriptionService.removeOrderedItem(this.item);
      return;
    }

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.posService.deleteCancelledItem(this.item.orderedItemId).subscribe(() => {
      this.blockUI.stop();
      this.subscriptionService.removeOrderedItem(this.item);
    }, (error) => {
      this.messageService.error(error);
      this.blockUI.stop();
    });
  }

  private getStatusImage() {
    const status = this.item.status;
    if (status === OrderStatus.cooking) {
      return "/assets/images/status/cooking.png";
    }

    if (status === OrderStatus.ready) {
      return "/assets/images/status/ready.png";
    }

    if (status === OrderStatus.waitingForApproval) {
      return "/assets/images/status/waiting.png";
    }

    if (status === OrderStatus.cancelled) {
      return "/assets/images/status/denied.png";
    }

    if (status === OrderStatus.served || status === OrderStatus.paid) {
      return "/assets/images/status/served.png";
    }

    return "/assets/images/status/neworder.png";
  }

  private async updatePrice(type): Promise<any> {
    const unitPrice = this.item.totalPrice / this.item.quantity;

    if (type == "increase") {
      this.item.quantity++;
    } else {
      this.item.quantity--;
    }

    this.item.totalPrice = this.item.quantity * unitPrice;
    this.item.taxPrice = this.item.totalPrice - this.item.totalPrice / (1 + this.item.tax * 0.01);

    const it = find(await this.localStorage.getOrderedItemsAsync(), {orderedItemId: this.item.orderedItemId});
    if (it) {
      it.quantity = this.item.quantity;
      it.totalPrice = this.item.totalPrice;
      this.localStorage.setOrderedItems(await this.localStorage.getOrderedItemsAsync());
    }

    this.subscriptionService.orderedItemUpdated(this.item);
  }
}
