<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade product-dialog" role="dialog" [style.z-index]="zIndex+1">
  <form validateForm #formRef="ngForm" (ngSubmit)="formRef.form.valid && addProduct()" class="html5-form mh-100 overflow-auto" autocomplete="off">
    <!-- Prevent implicit submission of the form -->
    <button type="submit" disabled style="display: none" aria-hidden="true"></button>

    <div class="modal-dialog modal-notify modal-info modal-md cascading-modal" role="document" style="margin-top: 35px !important; ">
      <div class="modal-content">
        <div class="modal-c-tabs">
          <mdb-tabset #tabs (getActiveTab)="onGetActiveTab($event)" [buttonClass]="'nav md-tabs tabs-2 tabs-cyan darken-3'" [contentClass]="''" class="tab-content">
            <!--Panel 1-->
            <mdb-tab [heading]='"Product" | translate' class="tab-pane fade in show active" role="tabpanel">
              <div class="row m-0">
                <div class="col-3 md-form mt-0 mb-0 pl-0">
                  <input mdbInput type="text" class="form-control" id="pluCode" name="pluCode" [(ngModel)]="product.pluCode"/>
                  <label for="pluCode">{{"PLUCode" | translate}}</label>
                </div>
                <div class="col-9 md-form mt-0 mb-0 pl-0 pr-0">
                  <input mdbInput #nameInputRef type="text" class="form-control" id="product" name="product" [(ngModel)]="product.productName" required/>
                  <label for="product">{{"ProductName" | translate}}</label>
                </div>
              </div>
              <div class="md-form">
                <textarea mdbInput class="md-textarea form-control p-0" id="productDesc" name="productDesc" rows="3" [(ngModel)]="product.productDescription"></textarea>
                <label for="productDesc">{{"ProductDescription" | translate}}</label>
              </div>
              <div class="md-form" style="margin-top: 50px">
                <label style="top: -30px;font-size: 0.8rem;">{{"IngredientsHelp" | translate}}</label>
                <mdb-material-chips [(ngModel)]="ingredients" [placeholder]="'+' + ('Ingredient' | translate)" name="ingredients"></mdb-material-chips>
              </div>
              <div class="md-form" style="margin-top: 50px">
                <label style="top: -30px;font-size: 0.8rem;">{{"AdditivesHelp" | translate}}</label>
                <mdb-material-chips [(ngModel)]="additives" [placeholder]="'+' + ('Additives' | translate)" name="additives"></mdb-material-chips>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"Advanced" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="md-form" style="margin-top: 2.5rem;">
                <label style="top: -30px;white-space:nowrap;">
                  <a href="javascript:void(0);" class="info-icon" tabindex="4"
                     [mdbPopover]="specialWishesTemp"
                     placement="right"
                     triggers="focus"
                     container="body"
                     [containerClass]="'info-popover'">
                    <i class="fa fa-info-circle"></i>
                  </a>
                  <span>{{"SpecialWishes_Label" | translate}}</span>
                </label>
                <mdb-material-chips [(ngModel)]="specialWishes" [placeholder]="'+' + ('SpecialWish' | translate)" name="specialWishes"></mdb-material-chips>
              </div>
              <div class="d-flex justify-content-between" *ngIf="showExcludeFromSelfOrdering">
                <label for="excludeFromSelfOrdering">
                  Exclude from selfordering
                </label>

                <div class="switch round blue-white-switch">
                  <label>
                    <input type="checkbox" name="excludeFromSelfOrdering" id="excludeFromSelfOrdering" value="false" [(ngModel)]="product.excludeFromSelfOrdering"/>
                    <span class="lever"></span>
                  </label>
                </div>
              </div>
              <div class="md-form mt-0">
                <input mdbInput type="text" class="form-control" id="barcode" name="barcode" [(ngModel)]="product.barcode"/>
                <label for="barcode">{{"Barcode" | translate}}</label>
              </div>
              <div class="form-group">
                <label>
                  <a href="javascript:void(0);" class="info-icon" tabindex="4"
                     [mdbPopover]="imageUploadTemp"
                     placement="right"
                     triggers="focus"
                     container="body"
                     [containerClass]="'info-popover'">
                    <i class="fa fa-info-circle"></i>
                  </a>
                  <span>{{"Picture" | translate}}</span>
                </label>
                <div id="product-pictures">
                  <dropzone [config]="dropzoneConfig" [message]="'ClickToUpload' | translate" (init)="initDropzone($event)" (complete)="onUploadSuccess($event)"></dropzone>
                </div>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"SideDishes" | translate' class="tab-pane fade in" role="tabpanel">
              <a href="javascript:void(0);" class="btn btn-outline-primary btn-rounded float-right toolbar-item" (click)="addSideDish()">
                <i class="fa fa-plus"></i>
                <span>{{"AddSideDish" | translate}}</span>
              </a>

              <div>
                <table class="table-bordered w-100 mb-5">
                  <thead class="grey white-text">
                  <tr>
                    <th style="width:40px;height: 40px;"></th>
                    <th class="pl-2">{{"Name" | translate}}</th>
                    <th class="pl-2">{{"Price" | translate}}</th>
                  </tr>
                  </thead>
                  <tbody *ngIf="product.sideDishes.length == 0">
                    <tr><td colspan="3" class="text-warning p-3" style="font-size: 20px;">{{"NoSideDishes" | translate}}</td></tr>
                  </tbody>
                  <tbody id="extrasBody" *ngIf="product.sideDishes.length > 0">
                  <tr *ngFor="let sideDish of product.sideDishes">
                    <td style="text-align: center;">
                      <a href="javascript:void(0);" (click)="deleteSideDish(sideDish)" class="btn btn-floating btn-sm danger-color p-0 m-1">
                        <i class="far fa-trash-alt" style="color: white; font-size: 18px;"></i>
                      </a>
                    </td>
                    <td class="md-form">
                      <input mdbInput type="text" class="form-control mb-0 pl-1 border-0" style="box-sizing: border-box; height: 46px;" [name]="'sideDishName' + sideDish.id" required="required" [(ngModel)]="sideDish.name"/>
                    </td>
                    <td style="width: 80px;">
                      <div class="md-form m-0">
                        <input mdbInput type="number" class="form-control mb-0 pl-1 border-0" style="box-sizing: border-box; height: 46px;" required="required" [name]="'sideDishPrice' + sideDish.id" required="required" [(ngModel)]="sideDish.price"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"Stock" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="d-flex align-items-center mb-3">
                <a href="javascript:void(0);" class="info-icon" tabindex="4"
                   [mdbPopover]="stockCountTemp"
                   placement="right"
                   triggers="focus"
                   container="body"
                   [containerClass]="'info-popover'">
                  <i class="fa fa-info-circle"></i>
                </a>
                <div class="md-form mt-0 mb-0 flex-grow-1">
                  <input mdbInput type="text" class="form-control" id="stockCount" name="stockCount" [(ngModel)]="product.stockCount"/>
                  <label for="stockCount">{{"StockCount" | translate}}</label>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <a href="javascript:void(0);" class="info-icon" tabindex="4"
                   [mdbPopover]="warningCountTemp"
                   placement="right"
                   triggers="focus"
                   container="body"
                   [containerClass]="'info-popover'">
                  <i class="fa fa-info-circle"></i>
                </a>
                <div class="md-form mt-0 mb-0 flex-grow-1">
                  <input mdbInput type="text" class="form-control" id="warningCount" name="warningCount" [(ngModel)]="product.warningCount"/>
                  <label for="warningCount" style="white-space:nowrap">
                    <span>{{"WarningCount" | translate}}</span>
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <label class="flex-grow-1" for="markAsOutOfStock" style="font-size: 13px;">
                  <a href="javascript:void(0);" class="info-icon" tabindex="4"
                     [mdbPopover]="markOutOfStockTemp"
                     placement="right"
                     triggers="focus"
                     container="body"
                     [containerClass]="'info-popover'">
                    <i class="fa fa-info-circle"></i>
                  </a>
                  <span>{{"MarkAsOutOfStock" | translate}}</span>
                </label>
                <div class="switch round blue-white-switch">
                  <label>
                    <input mdbInput type="checkbox" name="markAsOutOfStock" id="markAsOutOfStock" name="markAsOutOfStock" value="true" [(ngModel)]="product.markAsOutOfStock"/>
                    <span class="lever"></span>
                  </label>
                </div>
              </div>
              <div class="d-flex">
                <label class="flex-grow-1" for="isOutOfStock" style="font-size: 13px;">
                  <a href="javascript:void(0);" class="info-icon" tabindex="4"
                     [mdbPopover]="isOutOfStockTemp"
                     placement="right"
                     triggers="focus"
                     container="body"
                     [containerClass]="'info-popover'">
                    <i class="fa fa-info-circle"></i>
                  </a>
                  <span>{{"IsOutOfStock" | translate}}</span>
                </label>
                <div class="switch round blue-white-switch">
                  <label>
                    <input mdbInput type="checkbox" name="isOutOfStock" id="isOutOfStock" name="isOutOfStock" value="true" [(ngModel)]="product.isOutOfStock"/>
                    <span class="lever"></span>
                  </label>
                </div>
              </div>
            </mdb-tab>
            <mdb-tab [heading]='"Prices" | translate' class="tab-pane fade in" role="tabpanel">
              <div class="form-group">
                <a href="javascript:void(0);" (click)="addNewPriceCategory()" class="btn btn-outline-primary btn-rounded mb-3 float-right">
                  <i class="fa fa-plus"></i>
                  <span>{{"AddPriceCategory" | translate}}</span>
                </a>
                <table class="table-bordered w-100">
                  <thead class="grey white-text">
                  <tr>
                    <th style="width: 40px; height: 40px;"></th>
                    <th style="padding-left: 10px">{{"PortionSize" | translate}}</th>
                    <th style="padding-left: 10px;">{{"PortionPrice" | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let category of product.priceCategories; let ind = index;trackBy:trackByIdx">
                    <td style="text-align: center;">
                      <a href="javascript:void(0);"
                         class="btn btn-floating btn-sm danger-color p-0 m-1"
                         (click)="deletePriceCategory(ind)"
                         *ngIf="ind > 0">
                        <i class="far fa-trash-alt" style="font-size: 18px;"></i>
                      </a>
                    </td>
                    <td class="md-form">
                      <input mdbInput type="text"
                             class="form-control mb-0 pl-1 border-0"
                             style="box-sizing: border-box; height: 46px;"
                             [placeholder]="'Category' | translate"
                             [name]="'priceCategoryName' + ind"
                             [(ngModel)]="product.priceCategories[ind]"
                             required>
                    </td>
                    <td class="md-form">
                      <input mdbInput type="number"
                             class="form-control mb-0 pl-1 border-0"
                             style="box-sizing: border-box; height: 46px;"
                             [placeholder]="'Price' | translate"
                             [name]="'unitPrice' + ind"
                             [(ngModel)]="product.unitPrices[ind]"
                             step="0.01" min="0"
                             required>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </mdb-tab>
          </mdb-tabset>
        </div>
        <div class="modal-footer justify-content-between">
          <a type="button" mdbBtn color="danger" rounded="true" outline="true" data-dismiss="modal" (click)="hide()">
            {{"Close" | translate}}
          </a>
          <button type="submit" mdbBtn color="info" rounded="true" class="toolbar-item">
            <i class="text-white" [ngClass]="{'fa fa-plus ' : action == 'add' , 'fas fa-save' : action == 'update' }"></i>
            {{(action == 'update' ? "Save" : "Add") | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #specialWishesTemp>
  <div [innerHtml]="'SpecialWishes_Help' | translate"></div>
</ng-template>
<ng-template #imageUploadTemp>
  <div [innerHtml]="'ProductPicture_Help' | translate"></div>
</ng-template>
<ng-template #stockCountTemp>
  <div [innerHtml]="'StockCount_Help' | translate"></div>
</ng-template>
<ng-template #warningCountTemp>
  <div [innerHtml]="'WarningCount_Help' | translate"></div>
</ng-template>
<ng-template #markOutOfStockTemp>
  <div [innerHtml]="'MarkAsOutOfStock_Help' | translate"></div>
</ng-template>
<ng-template #isOutOfStockTemp>
  <div [innerHtml]="'IsOutOfStock_Help' | translate"></div>
</ng-template>
