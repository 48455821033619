import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Product} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {ProductService} from "../../../services/product.service";
import {SubscriptionService} from "../../../services/subscription.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: "app-create-item-dialog",
  templateUrl: "./create-item-dialog.component.html",
  styleUrls: ["./create-item-dialog.component.scss"],
})
export class CreateItemDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  public name: string;
  public price: number;
  public saveForNext: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private utilsService: UtilsService,
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private productService: ProductService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("create-item-dialog", this);
  }

  public hide() {
    super.hide();
    this.modal.hide();
  }

  public show(params: any) {
    super.show(params);
    this.name = "";
    this.price = undefined;
    this.modal.show();
  }

  public async saveItem(): Promise<any> {
    this.hide();

    const productId = uuid();
    const user: any = this.localStorage.getUserAsync();
    const product: Product = {
      partitionKey: user.partitionKey,
      rowKey: productId,
      id: productId,
      productCategoryId: this.utilsService.generateEmptyGuid(),
      productId,
      productName: this.name,
      productDescription: "",
      quantity: 1,
      priceCategories: ["Standard"],
      unitPrice: this.price,
      unitPrices: [this.price.toString()],
      ingredients: "",
      additives: "",
      allergenes: "",
      extrasInfo: null,
      barcode: "",
      isOutOfStock: false,
      markAsOutOfStock: false,
      pictures: null,
      pluCode: "",
      priceCategoriesStr: "",
      productPicture: "",
      productPictures: null,
      sideDishes: null,
      variationGroups: null,
      sortingId: 0,
      specialWishes: "",
      stockCount: 10000,
      unitPricesStr: "",
      warningCount: 0,
      excludeFromSelfOrdering: false,
      color: "",
    } as Product;

    this.subscriptionService.productOrdered(product);

    if (this.saveForNext) {
      const products = await this.localStorage.getProductsAsync();
      products.push(product);
      this.localStorage.setProducts(products);

      await this.saveProduct(product);
    }
  }

  private async saveProduct(product: Product) {
    await this.productService.addProduct(product).toPromise();
  }
}
