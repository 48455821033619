import {BaseModel} from "./base.model";

export class UserSettings extends BaseModel {
  public allowSendToKitchen: boolean;

  public allowSelfOrdering: boolean;

  public allowAnonymousSelfOrdering: boolean;

  public approveSelfOrders: boolean;

  public selfOrderDigitalQRCode: boolean;

  public enableDigitalMenu: boolean;

  public digitalMenuUrl: string;

  public selfOrderPrintQRCode: boolean;

  public printReceiptAlways: boolean;

  public printOrderedItemAlways: boolean;

  public allowHostingSlip: boolean;

  public allowEmployeesToChat: boolean;

  public enableRetailPos: boolean;

  public hideProductPicture: boolean;

  public hideProductPrice: boolean;

  public tax: number;

  public isTaxInPriceIncluded: boolean;

  public canWaiterDeduct: boolean;

  public canWaiterProcessPayment: boolean;

  public canWaiterAddProducts: boolean;

  public canWaiterCancelServedItems: boolean;

  public canWaiterCancelReadyItems: boolean;

  public allowCourses: boolean;

  public tipToIncludeInPrice: number;

  public eCCardFee: number;

  public creditCardFee: number;

  public freeTableColor: string;

  public orderedTableColor: string;

  public readyTableColor: string;

  public servedTableColor: string;

  public specialSettings: { [key: string]: string };

  public deviceIdsForTimeRecording:string[];

  public selfOrderMainColor: String;

  public selfOrderSecondaryColor: String;
}
