import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Product, ProductPicture} from "../../../models";
import {ModalService} from "../../../services/modal.service";
import {LocalCurrencyPipe} from "../../common/pipes/local.currency.pipe";

@Component({
  selector: "app-product-details-dialog",
  templateUrl: "./product-details-dialog.component.html",
  styleUrls: ["./product-details-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDetailsDialogComponent extends ModalClassComponent implements OnInit {

  public product: Product = {} as Product;
  public images: ProductPicture[];
  public ingredients: string[] = [];
  public additives: string[] = [];
  public formattedUnitPrices: string[] = [];
  public currencyPipe: LocalCurrencyPipe;
  public productPictures: ProductPicture[] = [];

  @ViewChild("modal")
  public modal: ModalDirective;
  @ViewChild("carousel", { static: true }) public carousel: any;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService) {
    super();
    this.currencyPipe = new LocalCurrencyPipe(this.translateService);
  }

  public ngOnInit(): void {
    this.modalService.register("product-details", this);
  }

  public formatCurrency(currency: string) {
    return this.currencyPipe.transform(parseFloat(currency));
  }

  public hide() {
    super.hide();
    this.product = ({} as Product);
    this.modal.hide();
  }

  public show(args) {
    super.show(args);
    this.product = args.product;
    this.product.productDescription = this.product.productDescription?.replace(/\n/g, "<br/>").replace(/ /g, "&nbsp;");
    this.productPictures = args.productPictures;

    if (this.product.ingredients) {
      this.ingredients = this.product.ingredients.split(",");
    }

    if (this.product.additives) {
      this.additives = this.product.additives.split(",");
    }

    const prices = [];
    this.product.unitPrices.forEach((price) => {
      prices.push(this.formatCurrency(price));
    });
    this.formattedUnitPrices = prices;

    this.images = filter(this.productPictures, { productId: this.product.productId, dimensions: "thumbnail" });
    if (this.images.length === 0) { this.images = filter(this.productPictures, { productId: this.product.productId, dimensions: "original" }); }
    if (this.images.length === 0) { this.images = [{ url: "/assets/images/plate.png", id: "", contentType: "", dimensions: "", fileName: "", originalFileId: "", productId: "", size: 0 }]; }

    this.modal.show();
  }

  public swipe($event) {
    if ($event.direction === 1) {
      this.carousel.nextSlide();
    } else if ($event.direction === 2) {
      this.carousel.previousSlide();
    }
  }
}
