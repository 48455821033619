import {Component, OnInit, ViewChild} from "@angular/core";
import {find} from "lodash";
import {reject, sortBy} from "lodash";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Extra, OrderedItem, Product} from "../../../models";
import {LocalStorageService} from "../../../services/localstorage.service";
import {ModalService} from "../../../services/modal.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-product-extras-dialog",
  templateUrl: "./product-extras-dialog.component.html",
  styleUrls: ["./product-extras-dialog.component.scss"],
})
export class ProductExtrasDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public item: OrderedItem = {product: {} as Product} as OrderedItem;
  public manualExtra: string;
  public extras: Extra[] = [];
  public quantity: number;
  public onCloseDialog: any;

  constructor(
    private subscriptionService: SubscriptionService,
    private localStorage: LocalStorageService,
    private modalService: ModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("product-extras", this);
  }

  public showSpecialWishes() {
    this.modalService.show("special-wishes-dialog", {
      product: this.item.product,
      manualExtras: this.item.manualExtra,
      onClose: async (extras) => {
        if (!extras) { return; }

        if (!this.item.manualExtra) {
          this.item.manualExtra = extras;
        } else {
          this.item.manualExtra = "," + extras;
        }
        await this.setExtra();
      },
    });
  }

  public async increaseCount(extra: Extra): Promise<any> {
    extra.count++;
    await this.setExtra();
  }

  public async decreaseCount(extra: Extra): Promise<any> {
    if (extra.count > 0) {
      extra.count--;
      await this.setExtra();
    }
  }

  public async setExtra(): Promise<any> {
    const selectedExtras = [];
    const selectedExtraIds = [];
    let extrasPrice = 0;

    this.item.sideDishes?.forEach((sideDish) => {
      selectedExtras.push(sideDish.name);

      extrasPrice += sideDish.price > 0 ? sideDish.price : 0;
    });

    this.extras.forEach(function(extra) {
      if (extra.count > 1) { selectedExtras.push(extra.count + "x " + extra.name); } else if (extra.count === 1) { selectedExtras.push(extra.name); }

      if (extra.count > 0) { selectedExtraIds.push(extra.count + "*" + extra.id); }

      extrasPrice += extra.count * extra.price;
    });
    this.item.totalPrice = this.item.quantity * (this.item.unitPrice + extrasPrice);

    this.item.extrasIds = selectedExtraIds.join(",");
    this.item.selectedExtra = selectedExtras.length > 0 ? "(+) " + selectedExtras.join(", ") : "";

    const arr = [];
    if (this.item.manualExtra) { arr.push(this.item.manualExtra); }
    if (this.item.selectedExtra) { arr.push(this.item.selectedExtra); }
    if (this.item.ingredientsToRemove && this.item.ingredientsToRemove.length > 0) { arr.push("(-) " + this.item.ingredientsToRemove.join(",")); }

    this.item.extras = arr.join("\n");

    this.item.extrasPrice = extrasPrice;

    const orderedItems = reject(await this.localStorage.getOrderedItemsAsync(), {orderedItemId: this.item.orderedItemId});
    orderedItems.push(this.item);
    this.localStorage.setOrderedItems(orderedItems);
  }

  public hide() {
    super.hide();
    this.modal.hide();

    if (this.onCloseDialog) { this.onCloseDialog(this.item); }

    this.item = ({product: {} as Product} as OrderedItem);
    this.extras = [];
  }

  public async show(args): Promise<any> {
    super.show(args);
    this.onCloseDialog = args.onCloseDialog;
    this.item = args.item;

    const productCategoryId = this.item.productCategoryId || this.item.product.productCategoryId;
    const productCategory = find(await this.localStorage.getProductCategoriesAsync(), {productCategoryId});
    this.extras = productCategory?.extras || [];
    this.extras = sortBy(this.extras, [extra => extra.name.toLowerCase()]);

    const existingExtras = {};
    if (this.item.extrasIds) {
      const addedExtras = this.item.extrasIds.split(",");
      addedExtras.forEach(function(extra) {
        const info = extra.split("*");
        if (!existingExtras[info[1]]) { existingExtras[info[1]] = info[0]; } else { existingExtras[info[1]] += info[0]; }
      });
    }

    this.extras.forEach(function(extra) {
      if (existingExtras[extra.id]) { extra.count = existingExtras[extra.id]; } else { extra.count = 0; }
    });

    this.modal.show();
  }
}
