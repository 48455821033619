<div class="module-settings">
  <div>
    <div class="panel panel-default">
      <div class="panel-body pb-3">
        <div class="card action-toolbar flex-row">
          <a href="javascript:void(0);" class="btn btn-primary btn-rounded toolbar-item" (click)="addWlanPrinter()">
            <i class="fa fa-wifi"></i>
            <span>{{"AddWlanPrinter" | translate}}</span>
          </a>

          <a href="javascript:void(0);" class="btn btn-primary btn-rounded toolbar-item" (click)="addBluetoothPrinter()" style="margin-left: 10px;">
            <i class="fab fa-bluetooth-b"></i>
            <span>{{"AddBluetoothPrinter" | translate}}</span>
          </a>
        </div>

        <div class="printer-list">
          <container-element *ngFor="let printer of allSettings" [ngSwitch]="printer.type">
            <app-wlan-printer-settings *ngSwitchCase="'wlan'" [settings]="printer" [allSettings]="allSettings"></app-wlan-printer-settings>
            <app-bluetooth-printer-settings *ngSwitchCase="'bluetooth'" [settings]="printer" [allSettings]="allSettings"></app-bluetooth-printer-settings>
          </container-element>
          <div *ngIf="allSettings.length == 0" class="d-flex flex-column justify-content-center align-items-center">
            <img src="/assets/images/printer.svg" style="width: 200px;margin-bottom: 20px;"/>
            <div class="text-warning">{{"NoPrinters" | translate}}</div>
          </div>
        </div>

        <button (click)="saveSettings()" class="btn btn-primary btn-rounded toolbar-item ml-auto mr-auto" *ngIf="allSettings.length > 0">
          <i class="fa fa-save"></i>
          <span>{{"UpdatePrinters" | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
