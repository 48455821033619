import {Component, OnInit} from "@angular/core";
import {Route, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import { ModalComponent } from "../../../interfaces/ModalComponent";
import {DayEndReportStatistic, User, UserSettings} from "../../../models";
import {DayEndReport} from "../../../models/day.end.report.model";
import {AuthenticationService} from "../../../services/auth.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {NativeAppService} from "../../../services/native-app.service";
import {ReportService} from "../../../services/report.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit, ModalComponent {

  public pages: Route[] = [];
  public isAdmin: boolean;
  public logoUrl: string;
  public fullName: string;
  public isMenuOpened = false;
  public canUseChat = false;
  public loggedInUser: User;
  public userSettings: UserSettings;

  @BlockUI()
  public blockUI: NgBlockUI;

  private user: User;

  constructor(
    private subscriptionService: SubscriptionService,
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private nativeAppService: NativeAppService,
    private modalService: ModalService,
    private reportService: ReportService,
    private router: Router) {

    this.init().then(() => {});

    this.subscriptionService.authEvent.subscribe(() => {
      this.init().then(() => {});
    });
  }

  public async ngOnInit() {
    this.modalService.register("side-nav", this);
    this.subscriptionService.settingsUpdatedEvent.subscribe(() => {
      this.init();
    });

    this.userSettings = await this.localStorage.getUserSettingsAsync();
    this.loggedInUser = await this.localStorage.getUserAsync();
    const isAdmin = this.loggedInUser && (!this.loggedInUser.role || this.loggedInUser.role === "Administrator");
    this.canUseChat = isAdmin || (this.userSettings?.allowEmployeesToChat);
  }

  public openMenu() {
    this.isMenuOpened = true;
  }

  public hideMenu() {
    this.isMenuOpened = false;
  }

  public closeDay() {
    this.hideMenu();

    const confirmModel = {
      message: this.translateService.instant("CloseDayConfirmSmall"),
      icon: "question",
      type: "info",
      confirmed: () => {this.doCloseDay(); },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public showLogoutModal() {
    this.hideMenu();

    const confirmModel = {
      message: this.translateService.instant("Logout_Confirm"),
      type: "danger",
      icon: "sign-out-alt",
      confirmed: async () => {
        await this.doLogout();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public doLogout() {
    this.blockUI.start(this.translateService.instant("LoggingOut"));

    this.authService.logOut().subscribe(async () => {
      await this.authService.removeAllUserData();
      this.blockUI.stop();
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public async navigateItem(page: Route) {
    if (!page.data.dummy) {
      this.hideMenu();

      this.setActivePage(page);
      let path = page.path;
      if (page.data.subPath) {
        path += "/" + page.data.subPath;
      }

      this.subscriptionService.navigatedTo(page);
      await this.router.navigate([path]);
    } else {
      page.data.expanded = !page.data.expanded;
    }
  }

  public startChat() {
    this.hideMenu();
    const $zoho = window["$zoho"];
    if ($zoho) {
      $zoho.salesiq.floatwindow.visible("show");
    }
  }

  public hide() {
  }

  public show(params: any) {
    this.openMenu();
  }

  public async navigateToHome() {
    if(window["isInReview"]) return;

    this.hideMenu();
    await this.router.navigate(["home"]);
  }

  private doCloseDay() {
    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.reportService.createZReport().subscribe(async (result: {zreport: DayEndReport, reportInfo: DayEndReportStatistic}) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("CloseDaySuccess"));
      this.localStorage.setDayEndStatistic(result.reportInfo);
      this.nativeAppService.printZReport(result.zreport.id, await this.localStorage.getPrinterSettingsAsync(), true);
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  private async init(): Promise<any> {
    this.user = await this.localStorage.getUserAsync();
    this.isAdmin = this.user?.role === "Administrator" || this.user?.role === "AdminHelper";
    this.logoUrl = this.user?.logoUrl || "/assets/images/logo.png";
    this.fullName = this.user?.firstName + " " + this.user?.lastName;

    if (this.user) {
      const userSettings = await this.localStorage.getUserSettingsAsync();
      this.pages = filter(this.router.config, (route) => {
        const isAllowed = route.data && route.data.roles.indexOf(this.user.role) > -1;
        if (!isAllowed) { return false; }

        if (route.path === "retail") {
          return userSettings?.enableRetailPos;
        }

        if (route.path === "approve") {
          return userSettings?.approveSelfOrders;
        }

        return isAllowed;
      });

      const settingsPage = this.pages.find(p => p.data.id == "menu-item-settings");
      if (settingsPage) {
        const tsePage = settingsPage.children.find(p => p.data.id == "menu-item-settings-tse");
        if (tsePage) tsePage.data.visible = this.user.country == "DE";
      }
    }
  }

  private setActivePage(page: Route) {
    this.pages.forEach((p) => {
      p.data.active = false;

      if (p.children) {
        p.children.forEach((s) => s.data.active = false);
      }
    });

    page.data.active = true;
  }
}
