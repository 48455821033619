import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-order-scan-again',
  templateUrl: './self-order-scan-again.component.html',
  styleUrls: ['./self-order-scan-again.component.scss']
})
export class SelfOrderScanAgainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
