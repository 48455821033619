import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Route, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {filter} from "lodash";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConnectionService} from "ngx-connection-service";
import {Subscription} from "rxjs";
import {User, UserSettings} from "../../../models";
import {AuthenticationService} from "../../../services/auth.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import {MessageService} from "../../../services/message.service";
import {ModalService} from "../../../services/modal.service";
import {NativeAppService} from "../../../services/native-app.service";
import {SubscriptionService} from "../../../services/subscription.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @BlockUI()
  public blockUI: NgBlockUI;

  public isExpanded = false;
  public isLoggedIn = false;
  public showNotifications = false;
  public hideLogout = true;
  public hideUserName = true;
  public loggedInUser: User;
  public userSettings: UserSettings;
  public currentPageTitle: string;
  public isConnected = true;
  public pages: Route[] = [];
  public notifications: any[] = [];
  public isInReview = window["isInReview"] == true || false;

  private connectionSubscription: Subscription;
  private routerSubscription: Subscription;
  private refreshNotificationsSubscription: Subscription;

  constructor(private authService: AuthenticationService,
              private localStorage: LocalStorageService,
              private subscriptionService: SubscriptionService,
              private translateService: TranslateService,
              private connectionService: ConnectionService,
              private modalService: ModalService,
              private messageService: MessageService,
              private nativeAppService: NativeAppService,
              private router: Router) {

    this.init().then(() => {});
  }

  public async ngOnInit() {
    this.subscriptionService.authEvent.subscribe((status) => {
      this.isLoggedIn = status;
      this.init();
    });

    this.routerSubscription = this.subscriptionService.navigatedToEvent.subscribe(async (route) => {
      this.currentPageTitle = await this.translateService.get(route.data.title).toPromise();
    });

    this.connectionSubscription = this.connectionService.monitor(false).subscribe((currentState) => {
      this.isConnected = currentState.hasNetworkConnection && currentState.hasInternetAccess;
    });

    this.notifications = await this.localStorage.getNotificationsAsync();

    this.refreshNotificationsSubscription = this.subscriptionService.refreshNotificationsEvent.subscribe(async () => {
      this.notifications = await this.localStorage.getNotificationsAsync();
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionService.authEvent?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.connectionSubscription?.unsubscribe();
    this.refreshNotificationsSubscription?.unsubscribe();
  }

  public collapse() {
    this.isExpanded = false;
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public showLogoutModal() {
    const confirmModel = {
      message: this.translateService.instant("Logout_Confirm"),
      type: "danger",
      icon: "sign-out-alt",
      confirmed: async () => {
        await this.doLogout();
      },
    };
    this.modalService.show("confirm-dialog", confirmModel);
  }

  public doLogout() {
    this.blockUI.start(this.translateService.instant("LoggingOut"));

    this.authService.logOut().subscribe(async () => {
      await this.authService.removeAllUserData();
      this.blockUI.stop();
    }, (error) => {
      this.blockUI.stop();
      this.messageService.error(error);
    });
  }

  public toggleMenu() {
    this.modalService.show("side-nav", null);
  }

  public openNotificationDialog() {
    this.modalService.show("notification-dialog", {});
  }

  private async init(): Promise<any> {
    this.loggedInUser = await this.localStorage.getUserAsync();
    this.isLoggedIn = this.loggedInUser !== null;
    this.userSettings = await this.localStorage.getUserSettingsAsync();

    this.showNotifications = false;
    if (this.loggedInUser) {
      this.pages = filter(this.router.config, (route) => {
        return route.data && route.data.roles.indexOf(this.loggedInUser.role) > -1;
      });

      this.showNotifications = this.loggedInUser.role !== "CookBarman";
    }

    this.hideLogout = !this.isLoggedIn || this.pages.length > 1;
    this.hideUserName = !this.hideLogout || this.showNotifications;

    this.refreshNotificationsSubscription = this.subscriptionService.refreshNotificationsEvent.subscribe(async () => {
      this.notifications = await this.localStorage.getNotificationsAsync();
    });
  }
}
