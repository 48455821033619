import { Component, OnInit } from "@angular/core";
import {ProductCategory} from "../../models";
import {LocalStorageService} from "../../services/localstorage.service";
import {SubscriptionService} from "../../services/subscription.service";

@Component({
  selector: "app-pos-retail",
  templateUrl: "./pos-retail.component.html",
  styleUrls: ["./pos-retail.component.scss"],
})
export class PosRetailComponent implements OnInit {
  public selectedCategory: ProductCategory = new ProductCategory();
  public productCategories: ProductCategory[] = [];
  public firstCategory: ProductCategory = new ProductCategory();
  public searchText = "";

  constructor(
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService,
  ) { }

  public async ngOnInit() {
    this.productCategories = (await this.localStorage.getProductCategoriesAsync()).filter((c) => c.productCategoryId !== "1" && c.productCategoryId !== "2");

    if (this.productCategories.length > 0) {
      this.firstCategory = this.productCategories[0];
      this.firstCategory.selected = true;
    }
  }

  public resetSearch() {
    this.searchText = "";
    this.searchItems();
  }

  public focusElement() {

  }

  public searchItems() {
    this.subscriptionService.searchStarted(this.searchText);
  }

  public showPaymentView() {

  }
}
