<div class="mt-4 common-settings-tab">
  <mdb-tabset [buttonClass]="'classic-tabs tabs-cyan d-flex justify-content-center'" class="classic-tabs">
    <mdb-tab [heading]='"GeneralPosSettings" | translate'>
      <div class="row m-0">
        <div class="col-12">
          <div id="general-settings" class="card-settings">
            <table class="table settings-table">
              <tr>
                <td>{{"AppVersion" | translate}}</td>
                <td style="text-align: right;">{{version}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <span class="float-left">{{"Language" | translate}}</span>

                  <div class="dropdown float-right" mdbDropdown>
                    <button mdbDropdownToggle type="button" mdbBtn color="primary" class="dropdown-toggle waves-light" mdbWavesEffect>
                      {{currentLanguage}}
                    </button>

                    <div class="dropdown-menu">
                      <a class="dropdown-item flag-lang flag-en" href="javascript:void(0);"
                         (click)="setCulture('en', 'English')">English</a>
                      <a class="dropdown-item flag-lang flag-de" href="javascript:void(0);"
                         (click)="setCulture('de', 'Deutsch')">Deutsch</a>
                      <a class="dropdown-item flag-lang flag-tr" href="javascript:void(0);"
                         (click)="setCulture('tr', 'Türkçe')">Türkçe</a>
                      <a class="dropdown-item flag-lang flag-es" href="javascript:void(0);"
                         (click)="setCulture('es', 'Español')">Español</a>
                      <a class="dropdown-item flag-lang flag-fr" href="javascript:void(0);"
                         (click)="setCulture('fr', 'Français')">Français</a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{"TaxInPercentage" | translate}}</td>
                <td *ngIf="vatDefinitions == null" class="md-form" style="text-align: right; white-space: nowrap;">
                  <input type="number" step="0.01" min="0" max="100" name="tax"
                         style="width: 50px;" [(ngModel)]="userSettings.tax"/> %
                </td>
                <td *ngIf="vatDefinitions != null" class="md-form" style="text-align: right; white-space: nowrap;">
                  <mdb-select-2 [(ngModel)]="userSettings.tax" [placeholder]="'ChooseTax' | translate" [dropdownClass]="'mdb-select-dropdown-primary'">
                    <mdb-select-option *ngFor="let option of vatDefinitions" [value]="option">{{ option + '%'}}</mdb-select-option>
                  </mdb-select-2>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="allowSendToKitchen">{{"SendAllProductsToKitchen" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowSendToKitchen"
                             id="allowSendToKitchen" value="true"
                             [(ngModel)]="userSettings.allowSendToKitchen"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group d-flex" style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'EnableRetailPos_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="enableRetailPos" style="margin-top: 2px;">{{"EnableRetailPos" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="enableRetailPos"
                             id="enableRetailPos" value="true"
                             [(ngModel)]="userSettings.enableRetailPos"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group d-flex popover-container"
                       style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;" tabindex="4"
                       [mdbPopover]="'AllowCourses_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="allowCourses"
                           style="margin-top: 2px;">{{"AllowCourses" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowCourses" id="allowCourses"
                             value="true" [(ngModel)]="userSettings.allowCourses"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="canWaiterCancelServedItems">{{"CanWaiterCancelServedItems" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="canWaiterCancelServedItems"
                             id="canWaiterCancelServedItems" value="true"
                             [(ngModel)]="userSettings.canWaiterCancelServedItems"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="canWaiterCancelReadyItems">{{"CanWaiterCancelReadyItems" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="canWaiterCancelReadyItems"
                             id="canWaiterCancelReadyItems" value="true"
                             [(ngModel)]="userSettings.canWaiterCancelReadyItems"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{"ColorFreeTable" | translate}}</td>
                <td style="text-align: right" class="table-color">
                  <div class="table-color-selector z-depth-1-half"
                       [style.background-color]="userSettings.freeTableColor"
                       [mdbPopover]="freeTableColorTemplate" placement="left" containerClass="info-popover" triggers="focus">
                    {{"Table" | translate}}
                    <mdb-icon fas icon="caret-down"></mdb-icon>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{"ColorOrderedTable" | translate}}</td>
                <td style="text-align: right" class="table-color">
                  <div class="table-color-selector z-depth-1-half"
                       [style.background-color]="userSettings.orderedTableColor"
                       [mdbPopover]="orderedTableColorTemplate" placement="left" containerClass="info-popover" triggers="focus">
                    {{"Table" | translate}}
                    <mdb-icon fas icon="caret-down"></mdb-icon>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{"ColorServedTable" | translate}}</td>
                <td style="text-align: right" class="table-color">
                  <div class="table-color-selector z-depth-1-half"
                       [style.background-color]="userSettings.servedTableColor"
                       [mdbPopover]="servedTableColorTemplate" placement="left" containerClass="info-popover" triggers="focus">
                    {{"Table" | translate}}
                    <mdb-icon fas icon="caret-down"></mdb-icon>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{"ColorReadyTable" | translate}}</td>
                <td style="text-align: right" class="table-color">
                  <div class="table-color-selector z-depth-1-half"
                       [style.background-color]="userSettings.readyTableColor"
                       [mdbPopover]="readyTableColorTemplate" placement="left" containerClass="info-popover" triggers="focus">
                    {{"Table" | translate}}
                    <mdb-icon fas icon="caret-down"></mdb-icon>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </mdb-tab>
    <mdb-tab [heading]='"WaiterSettings" | translate'>
      <div class="row">
        <div class="col-12">
          <div id="waiter-settings" class="card-settings">
            <table class="table settings-table">
              <tr>
                <td>
                  <div class="form-group d-flex popover-container"
                       style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'CanWaiterAddProducts_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="canWaiterAddProducts"
                           style="margin-top: 2px;">{{"CanWaiterAddProducts" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="canWaiterAddProducts"
                             id="canWaiterAddProducts" value="true"
                             [(ngModel)]="userSettings.canWaiterAddProducts"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="canWaiterDeduct">{{"CanWaiterDeduct" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="canWaiterDeduct"
                             id="canWaiterDeduct" value="true"
                             [(ngModel)]="userSettings.canWaiterDeduct"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="canWaiterProcessPayment">{{"CanWaiterProcessPayment" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox"
                             name="canWaiterProcessPayment"
                             id="canWaiterProcessPayment" value="true"
                             [(ngModel)]="userSettings.canWaiterProcessPayment"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="hideProductPicture">{{"HideProductPicture" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="hideProductPicture"
                             id="hideProductPicture" value="true"
                             [(ngModel)]="userSettings.hideProductPicture"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="hideProductPrice">{{"HideProductPrice" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="hideProductPrice"
                             id="hideProductPrice" value="true"
                             [(ngModel)]="userSettings.hideProductPrice"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="allowEmployeesToChat">{{"AllowEmployeesToChat" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowEmployeesToChat"
                             id="allowEmployeesToChat" value="true"
                             [(ngModel)]="userSettings.allowEmployeesToChat"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr *ngIf="deviceId">
                <td>
                  <label for="allowEmployeesToChat">{{"AllowDeviceForTimeRecording" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowDeviceForTimeRecording"
                             id="allowDeviceForTimeRecording" value="true"
                             [(ngModel)]="allowDeviceForTimeRecording"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </mdb-tab>
    <mdb-tab [heading]='"ReceiptSettings" | translate'>
      <div class="row">
        <div class="col-12">
          <div id="receipt-settings" class="card-settings">
            <table class="table settings-table">
              <tr *ngIf="showHostingSlip">
                <td>
                  <label for="allowHostingSlip">{{"AllowHostingSlip" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowHostingSlip" id="allowHostingSlip" value="true" [(ngModel)]="userSettings.allowHostingSlip"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="printReceiptAlways">{{"PrintReceiptAlways" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="printReceiptAlways" id="printReceiptAlways" value="true" [(ngModel)]="userSettings.printReceiptAlways"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="printOrderedItemAlways">{{"PrintOrderedItemAlways" | translate}}</label>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="printOrderedItemAlways" id="printOrderedItemAlways" value="true" [(ngModel)]="userSettings.printOrderedItemAlways"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </mdb-tab>
    <mdb-tab [heading]='"SelfServiceSettings" | translate'>
      <div class="row">
        <div class="col-12">
          <div id="self-service-settings" class="card-settings">
            <table class="table settings-table">
              <tr>
                <td>
                  <div class="form-group d-flex" style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'AllowSelfOrdering_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="allowSelfOrdering"
                           style="margin-top: 2px;">{{"AllowSelfOrdering" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="allowSelfOrdering"
                             id="allowSelfOrdering" value="true"
                             [(ngModel)]="userSettings.allowSelfOrdering"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group d-flex" style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'AllowAnonymousSelfOrdering_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="allowAnonymousSelfOrdering"
                           style="margin-top: 2px;">{{"AllowAnonymousSelfOrdering" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox"
                             name="allowAnonymousSelfOrdering"
                             id="allowAnonymousSelfOrdering" value="true"
                             [(ngModel)]="userSettings.allowAnonymousSelfOrdering"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group d-flex" style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'ApproveSelfOrders_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="approveSelfOrders"
                           style="margin-top: 2px;">{{"ApproveSelfOrders" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="approveSelfOrders"
                             id="approveSelfOrders" value="true"
                             [(ngModel)]="userSettings.approveSelfOrders"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group d-flex" style="margin-bottom: 0px;">
                    <a href="javascript:void(0);" class="info-icon"
                       style="margin-right: 8px !important;"
                       [mdbPopover]="'SelfOrderUseDigitalQRCode_Help' | translate"
                       containerClass="info-popover"
                       placement="right" triggers="'focus'">
                      <i class="fa fa-info-circle"></i>
                    </a>
                    <label for="selfOrderDigitalQRCode"
                           style="margin-top: 2px;">{{"SelfOrderUseDigitalQRCode" | translate}}</label>
                  </div>
                </td>
                <td style="text-align: right">
                  <div class="switch round blue-white-switch">
                    <label>
                      <input type="checkbox" name="selfOrderDigitalQRCode"
                             id="selfOrderDigitalQRCode" value="true"
                             [(ngModel)]="userSettings.selfOrderDigitalQRCode"/>
                      <span class="lever"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </mdb-tab>
  </mdb-tabset>
  <div class="pt-2 pb-4">
    <button class="btn btn-primary btn-rounded d-flex justify-content-center" (click)="saveSettings()">
      <span>{{"Update" | translate}}</span>
    </button>
  </div>
</div>

<ng-template #freeTableColorTemplate>
  <color-circle [colors]="tableColors" (onChange)="userSettings.freeTableColor = $event.color.hex"></color-circle>
</ng-template>

<ng-template #orderedTableColorTemplate>
  <color-circle [colors]="tableColors" (onChange)="userSettings.orderedTableColor = $event.color.hex"></color-circle>
</ng-template>

<ng-template #readyTableColorTemplate>
  <color-circle [colors]="tableColors" (onChange)="userSettings.readyTableColor = $event.color.hex"></color-circle>
</ng-template>

<ng-template #servedTableColorTemplate>
  <color-circle [colors]="tableColors" (onChange)="userSettings.servedTableColor = $event.color.hex"></color-circle>
</ng-template>
