import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import { ModalClassComponent } from "src/app/interfaces/ModalClassComponent";
import { ModalService } from "src/app/services/modal.service";
import {AccountService} from "../../../services/account.service";
import {LocalStorageService} from "../../../services/localstorage.service";
import { PaymentTypes } from "src/app/models/payment.types.model";
import {MessageService} from "../../../services/message.service";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-payment-types-dialog",
  templateUrl: "./payment-types-dialog.component.html",
  styleUrls: ["./payment-types-dialog.component.scss"],
})
export class PaymentTypesDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;
  @ViewChild("formRef", {read: ElementRef}) public formRef: ElementRef;

  public paymentTypes: PaymentTypes[] = [];
  constructor(
    private localStorage: LocalStorageService,
    private modalService: ModalService,
    private accountService: AccountService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    super();
  }

  public async ngOnInit() {
    this.modalService.register("payment-types-dialog", this);
  }

  public hide() {
    super.hide();
    this.modal.hide();
    this.paymentTypes = [];
  }

  public show(dialogModel: any) {
    this.localStorage.getPaymentTypesAsync(false).then(types => {
      this.paymentTypes = cloneDeep(types);
    });

    super.show(dialogModel);
    this.modal.show();
  }

  savePaymentTypes() {
    if(!this.formRef.nativeElement.checkValidity()){
      const list = this.formRef.nativeElement.querySelector(':invalid');
      list.focus();
      return;
    }

    this.accountService.savePaymentTypes(this.paymentTypes).subscribe((paymentTypes) => {
      this.paymentTypes = paymentTypes;
      this.localStorage.setPaymentTypes(this.paymentTypes);
      this.hide();
      this.messageService.success(this.translateService.instant("PaymentTypes.SaveSuccess"));
    }, err => {
      this.messageService.error(err);
    });
  }

  public stopEventPropagation(event: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  addPaymentType() {
    this.paymentTypes.push({
      name: '',
      label: '',
      isActive: true
    });
  }
}
