import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ButtonsModule, DropdownModule, InputsModule, ModalModule, SelectModule, TooltipModule, WavesModule} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../../common/common.module";
import { CreateEmployeeDialogComponent } from "./create-employee-dialog/create-employee-dialog.component";
import {EmployeeRoutingModule} from "./employee-routing.module";
import { EmployeesComponent } from "./employees.component";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { QrCodeDialogComponent } from "./qr-code/qr-code-dialog.component";
import { SingleEmployeeComponent } from "./single-employee/single-employee.component";

@NgModule({
  declarations: [
    EmployeesComponent,
    SingleEmployeeComponent,
    CreateEmployeeDialogComponent,
    MessageDialogComponent,
    QrCodeDialogComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    EmployeeRoutingModule,
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    ButtonsModule.forRoot(),
    SelectModule,
    WavesModule.forRoot(),
    TooltipModule.forRoot(),
    DropdownModule.forRoot(),
  ],
})
export class EmployeesModule { }
