<mdb-navbar SideClass="navbar navbar-light blue-grey lighten-5 p-0 pl-2" *ngIf="allProductsLength > 0">
  <ul class="navbar-nav mr-auto">
    <li>
      <label id="categoryName" style="padding-top: 15px;font-size: 25px;">{{title | translate}}</label>
    </li>
  </ul>
  <div *ngIf="products.length > 0" class="btn-group" data-toggle="buttons" style="margin:10px;">
    <label [class.active]="!utilsService.showAsList" class="btn btn-info btn-rounded active" style="padding: 10px 20px;height: 38px;" (click)="changeListType('')">
      <input class="form-check-input" type="radio" checked autocomplete="off">
      <i class="fa fa-th-large"></i>
    </label>
    <label [class.active]="utilsService.showAsList" class="btn btn-info btn-rounded" style="padding: 10px 20px;height: 38px;" (click)="changeListType('list')">
      <input class="form-check-input" type="radio" autocomplete="off">
      <i class="fa fa-list"></i>
    </label>
  </div>
</mdb-navbar>

<div [class.pos-product-list]="utilsService.showAsList" *ngIf="allProductsLength > 0 && category?.productCategoryId != '2'">
  <div class="row" style="margin: 0px;" *ngIf="category?.productCategoryId !== '1' && category?.productCategoryId !== '2'">
    <div *ngIf="products.length == 0" id="alert-box" class="text-warning alert-no-product" style="margin-top: 10px;">
      {{"NoProductToChoose" | translate}}
    </div>

    <app-menu-single-product *ngFor="let product of products"
                             [product]="product"
                             [retailMode]="retailMode"
                             [newItems]="orderedItems"></app-menu-single-product>
  </div>
  <div class="row p-2" *ngIf="category?.productCategoryId == '1'">
    <ng-container *ngFor="let item of orderedItems">
      <app-single-order-item
        *ngIf="item.status == 0"
        [item]="item"
        [courseItems]="orderedItems"
        class="w-100"></app-single-order-item>
    </ng-container>
  </div>
</div>

<div class="row" *ngIf="category?.productCategoryId == '2'">
  <app-quick-access [newItems]="orderedItems" class="w-100"></app-quick-access>
</div>
