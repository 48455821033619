import {Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {MessageService} from "../../../../services/message.service";
import {ModalService} from "../../../../services/modal.service";
import {NotificationService} from "../../../../services/notifiction.service";

@Component({
  selector: "app-message-dialog",
  templateUrl: "./message-dialog.component.html",
  styleUrls: ["./message-dialog.component.scss"],
})
export class MessageDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @BlockUI() public blockUI: NgBlockUI;

  public to: string;
  public message: string;
  public userId: any;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private messageService: MessageService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("send-message-dialog", this);
  }

  public sendMessage() {
    this.notificationService.sendMessage(this.userId, this.message).subscribe(async () => {
        this.messageService.success(await this.translateService.get("SendMessageSuccess").toPromise());
        this.hide();
    }, (error) => {
      this.messageService.error(error);
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.message = "";
    this.userId = params.userId;
    this.to = params.to;
    this.modal.show();
  }
}
