import { SelfOrderScanAgainComponent } from './self-order-scan-again/self-order-scan-again.component';
import { SelfOrderMenuNotExistingComponent } from './self-order-menu-not-existing/self-order-menu-not-existing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SelfOrderRoMenuComponent} from "./self-order-ro-menu/self-order-ro-menu.component";
import {SelfOrderComponent} from './self-order.component';
import {SelfOrderRegisterComponent} from './self-order-register/self-order-register.component';
import {SelfOrderSmsVerifyComponent} from './self-order-sms-verify/self-order-sms-verify.component';
import { MenuResolver } from 'src/app/resolver/menu.resolver';
import { SelfOrderWithoutRefreshComponent } from './self-order-without-refresh/self-order-without-refresh.component';

const routes: Routes = [
  { path: '', component: SelfOrderComponent },
  { path: 'no', component: SelfOrderWithoutRefreshComponent },
  { path: 'scan', component: SelfOrderScanAgainComponent },
  { path: 'register', component: SelfOrderRegisterComponent },
  { path: 'verify', component: SelfOrderSmsVerifyComponent },
  { path: 'romenu', component: SelfOrderRoMenuComponent },
  { path: ':menuId', component: SelfOrderRoMenuComponent, resolve: { menu: MenuResolver } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelfOrderRoutingModule { }
