<li class="list-group-item receipt-single-item z-depth-1"
    style="margin-bottom:7px;font-size:14px;"
    (click)="showReceipt(receipt)"
    [class.activereceipt]="isActive"
    [class.cancelled]="receipt.totalPriceGross < 0">

    <span class='receipt-lock'>
        <i class="fa" [class.fa-lock]="isLocked" [class.fa-unlock]="!isLocked"></i>
    </span>
  <div class="row" style="margin:0px;">
    <div class="col-md-3" style="padding:0 5px;">
      <div style="display:flex;">
        <span>{{"InvoiceNumber" | translate}}</span>:&nbsp;
        <span style="font-weight:bold;">{{receipt.invoiceNumber}}</span>
      </div>
      <div style="display:flex;">
        <span>{{"Table" | translate}}</span>:&nbsp;
        <span style="font-weight:bold;">{{receipt.tableName}}</span>
      </div>
    </div>
    <div class="col-md-6" style="padding:0 5px;">
      <div style="display:flex;">
        <span>{{"PaymentMethod" | translate}}</span>:&nbsp;
        <span style="font-weight:bold;">{{paymentTypeLabel}}</span>
      </div>
      <div style="display:flex;">
        <span>{{"PaymentDate" | translate}}</span>:&nbsp;
        <span  class="receipt-date" style="font-weight:bold;">
          {{receiptDate}}
        </span>
      </div>
    </div>
    <div class="col-md-3 receipt-price" style="text-align:right;padding:0 5px;">
      {{(receipt.totalPriceGross - receipt.discountAmount) | otcurrency}}
    </div>
  </div>
  <div class="row" style="margin:0px;">
    <div style="display: flex;margin-left: 5px;" *ngIf="receipt.referenceInvoiceNumber?.length > 0">
      <span>
        {{(receipt.totalPriceGross < 0 ? "InvoiceCreatedByCancellation" : "InvoiceCancelled") | translate}}
      </span>:&nbsp;
      <span style="font-weight:bold;">
        {{receipt.referenceInvoiceNumber.join(",")}}
      </span>
    </div>
  </div>
</li>
