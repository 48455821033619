<div class="self-order-product-list">
    <div class="text-warning pt-4 pb-4" role="alert" *ngIf="products.length == 0">
        {{"NoProductToChoose" | translate}}
    </div>

    <div class="row" style="margin: 0px;">
        <div class="self-order-product-container" *ngFor="let product of products" [class.out-of-stock-container]="product.isOutOfStock">
            <div class="product-desc-container flex-grow-1" (click)="stopPropagation($event)">
                <label class="product-name">{{product.productName}}</label>
                <label class="product-description" *ngIf="product.productDescription" [innerHTML]="product.productDescription | sanitizeHtml"></label>
                <div class="d-flex justify-content-between mt-4 action-container">
                    <div class="d-flex" *ngIf="!product.isOutOfStock && !readOnly">
                        <button class="btn btn-flat text-primary product-action product-action-add mb-0" style="font-size:13px" (click)="orderProduct(product, $event)">
              <span>{{"AddToOrderList" | translate}}</span>
            </button>
                        <div class="order-count" [hidden]="!product['ordered']" [class.slide-out-tl]="product['ordered']">
                            {{product["orderCount"]}}
                        </div>
                    </div>
                    <button class="btn btn-flat text-primary product-action product-action-add mb-0" style="font-size:13px" (click)="showProductDetails(product, $event)">
            <span>{{"SeeProductInfo" | translate}}</span>
          </button>
                    <div *ngIf="readOnly">
                        <div class="product-price">
                            <label *ngIf="product.unitPrices?.length < 2" class='product-single-price'>
                {{ formattedUnitPrices[product.productId] }}
              </label>
                            <div *ngIf="product.unitPrices?.length > 1" class='table-responsive'>
                                <table class='table table-bordered m-0 table-sm'>
                                    <thead>
                                        <tr>
                                            <th *ngFor="let price of product.priceCategories">{{price}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td *ngFor="let price of formattedUnitPrices[product.productId]">{{ price }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label class="out-of-stock" *ngIf="product.isOutOfStock">
        {{"OutOfStock" | translate}}
      </label>
        </div>
    </div>
</div>
