<div class="section section-header" style="text-align:center;font-size:40px;font-weight:bold;">
  <span>
    -{{ "QuickCommunication_Title" | translate }}-
  </span>
</div>

<div class="section" [innerHTML]='"QuickCommunication_Communication" | translate'></div>

<div class="section" [innerHTML]='"QuickCommunication_ReadyToServe" | translate'></div>

<div class="section" [innerHTML]='"QuickCommunication_CallWaiter" | translate'></div>

<div class="section" style="text-align:center;">
  <label style="font-weight:bold;" [innerHTML]='"QuickCommunication_VideoTitle" | translate'>
  </label>
</div>
<div class="section" style="text-align:center;">
  <div class="youtube embed-responsive embed-responsive-16by9 z-depth-3" embed="RX83hZgL3u0" youTubeVideo>
    <div class="play-button"></div>
  </div>
</div>
