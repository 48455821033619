import {Component, OnInit, ViewChild} from "@angular/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../interfaces/ModalClassComponent";
import {Product} from "../../../models";
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: "app-product-special-wishes-dialog",
  templateUrl: "./product-special-wishes-dialog.component.html",
  styleUrls: ["./product-special-wishes-dialog.component.scss"],
})
export class ProductSpecialWishesDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  public wishList: string[] = [];
  public product: Product;
  public onClose: any;
  public manualExtras: string[] = [];

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("special-wishes-dialog", this);
  }

  public hide() {
    super.hide();
    this.modal.hide();

    this.onClose && this.onClose(this.manualExtras.join(","));
  }

  public show(args: any) {
    super.show(args);
    this.product = args.product;
    this.onClose = args.onClose;
    if (args.manualExtras) {
      this.manualExtras = args.manualExtras.split(",");
    } else {
      this.manualExtras = [];
    }

    if (this.product.specialWishes) {
      this.wishList = this.product.specialWishes?.split(",");
    }

    this.modal.show();
  }

  public addSpecialWish(wish: string) {
    if (this.manualExtras.indexOf(wish) == -1) {
      this.manualExtras.push(wish);
    }
  }
}
