import {Injectable, OnDestroy} from "@angular/core";
import { ModalComponent } from "../interfaces/ModalComponent";

@Injectable({

  providedIn: "root",

})

export class ModalService implements OnDestroy {

  public modals = {};
  public zIndex = 3010;

  constructor() { }

  public register(name: string, modal) {
    this.modals[name] = modal;
  }

  public show(name: string, params: any) {
    const modal = this.modals[name] as ModalComponent;
    if (modal) {
      if (!params) { params = {}; }
      this.zIndex += 10;
      params.zIndex = this.zIndex;
      modal.show(params);
    }
  }

  public hide(name: string) {
    const modal = this.modals[name] as ModalComponent;
    if (modal) {
      modal.hide();
    }
  }

  public ngOnDestroy(): void {
    this.modals = null;
  }

  hideAll() {
    const modalNames = Object.keys(this.modals);
    modalNames.forEach(name => {
      this.hide(name);
    })
  }
}
