<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade top" tabindex="-1" role="dialog" [style.z-index]="zIndex+1"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body mb-0 p-0">
        <div class="youtube embed-responsive embed-responsive-16by9 z-depth-1-half" youTubeVideo [embedObservable]="embed" autoPlay="true">
          <div class="play-button"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-rounded" data-dismiss="modal" (click)="stopVideoAndHideModal()">{{"Close" | translate}}</button>
      </div>
    </div>
  </div>
</div>
