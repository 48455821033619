<nav id="sidebar" [class.active]="isMenuOpened">
  <div class="sidebar-header">
    <a href="javascript:void(0);" (click)="navigateToHome()">
      <img [attr.src]="logoUrl" alt="OT" style="max-height :75px;">
    </a>
    <div class="sidebar-fullname">{{fullName}}</div>
  </div>
  <div class="sidebar-menu">
    <ul class="list-unstyled components">
      <li *ngFor="let page of pages" [class.active]="page.data.active">
        <a [id]="page.data.id" (click)="navigateItem(page)">
          <i [class]="page.data.iconClass"></i>
          <span>
            {{page.data.title | translate}}
          </span>
          <i class="fas menu-arrow" *ngIf="page.children?.length > 0" [class.fa-chevron-down]="!page.data.expanded" [class.fa-chevron-up]="page.data.expanded"></i>
        </a>

        <ul *ngIf="page.children?.length > 0" class="list-unstyled" [hidden]="!page.data.expanded">
          <li *ngFor="let subPage of page.children" [class.active]="subPage.data.active" [hidden]="subPage.data.visible === false">
            <a [id]="subPage.data.id" (click)="navigateItem(subPage)">
              <i [class]="subPage.data.iconClass"></i>
              <span>
                {{subPage.data.title | translate}}
              </span>
            </a>
          </li>
        </ul>

      </li>

      <li *ngIf="isAdmin">
        <a href="javascript:void(0);" (click)="closeDay()" class="nav-link">
          <i class="fa fa-flag-checkered"></i>
          <span>{{"CloseDay" | translate}}</span>
        </a>
      </li>

      <li *ngIf="canUseChat">
        <a href="javascript:void(0);" (click)="startChat()">
          <i class="far fa-comment-dots"></i>
          {{'StartChat' | translate }}
        </a>
      </li>

      <li>
        <a href="javascript:void(0);" (click)="showLogoutModal()">
          <i class="fas fa-sign-out-alt"></i>
          <span>{{"Logoff" | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>

<div class="overlay" (click)="hideMenu()" [hidden]="!isMenuOpened"></div>
