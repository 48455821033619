import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {TableCategory} from "../../../../models";
import {MessageService} from "../../../../services/message.service";
import { ModalService } from "../../../../services/modal.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-create-multi-tables-dialog",
  templateUrl: "./create-multi-tables-dialog.component.html",
  styleUrls: ["./create-multi-tables-dialog.component.scss"],
})
export class CreateMultiTablesDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("nameRef")
  public nameInputRef: ElementRef;

  public template: string;

  public count: number;

  public tax: number = undefined;

  public category: TableCategory;
  public onTableAdded: any;

  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.modalService.register("create-multiple-tables-dialog", this);
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public show(params: any) {
    super.show(params);
    this.template = "";
    this.count = undefined;
    this.tax = undefined;
    this.onTableAdded = params.onTableAdded;
    this.category = params.category;

    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      500,
    );
  }

  public addTables() {

    const model = {
      template: this.template,
      count: this.count,
      tax: this.tax === undefined ? -1 : this.tax,
      categoryId: this.category.id,
    };
    this.tableService.addMultipleTables(model).subscribe((tables) => {
      this.messageService.success(this.translateService.instant("AddTablesSuccess"));

      if (this.onTableAdded) {
        this.onTableAdded(tables);
      }

      this.hide();
    }, () => {
      this.messageService.error(this.translateService.instant("AddTablesSuccessError"));
    });
  }
}
