import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ButtonsModule, CardsModule, DropdownModule, IconsModule, InputsModule, MdbSelectModule, ModalModule, PopoverModule, SelectModule, WavesModule} from "ng-uikit-pro-standard";
import {AppCommonModule} from "../../common/common.module";
import {CategoryListComponent} from "./category-list/category-list.component";
import {CategoryComponent} from "./category/category.component";
import {CreateCategoryDialogComponent} from "./create-category-dialog/create-category-dialog.component";
import {CreateMultiTablesDialogComponent} from "./create-multi-tables-dialog/create-multi-tables-dialog.component";
import {CreateTableDialogComponent} from "./create-table-dialog/create-table-dialog.component";
import {ImportTablesDialogComponent} from "./import-tables-dialog/import-tables-dialog.component";
import {SelfOrderSettingsDialogComponent} from "./selforder-settings-dialog/selforder-settings-dialog.component";
import {ShowTableListDialogComponent} from "./show-table-list-dialog/show-table-list-dialog.component";
import {SingleTableComponent} from "./single-table/single-table.component";
import {TableRoutingModule} from "./table-routing.module";
import {TableComponent} from "./table.component";

@NgModule({
  declarations: [
    TableComponent,
    CategoryComponent,
    CategoryListComponent,
    CreateCategoryDialogComponent,
    ShowTableListDialogComponent,
    SingleTableComponent,
    CreateTableDialogComponent,
    SelfOrderSettingsDialogComponent,
    CreateMultiTablesDialogComponent,
    ImportTablesDialogComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TableRoutingModule,
    FormsModule,
    ButtonsModule.forRoot(),
    WavesModule,
    SelectModule,
    DropdownModule.forRoot(),
    ModalModule.forRoot(),
    InputsModule.forRoot(),
    CardsModule.forRoot(),
    MdbSelectModule,
    IconsModule,
    PopoverModule,
  ],
})
export class TableModule {
}
