<div mdbModal #modal="mdbModal" [config]="{backdrop: false, keyboard: false, ignoreBackdropClick: true}" class="modal fade right" role="dialog" aria-hidden="true" data-toggle="modal" data-backdrop="true" style="top: 0" [style.z-index]="zIndex+1">
  <div class="modal-dialog modal-full-height modal-right modal-info sidedish-modal" role="document" style="">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead mr-5" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{product.productName}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body overflow-auto">
        <mdb-carousel #carouselRef [isControls]="false" [allowSwipe]="false"
                      [interval]="'0'" [animation]="'slide'" [noWrap]="true"
                      (activeSlideChange)="onActiveSlideChange()">
          <mdb-carousel-item *ngIf="product.priceCategories.length > 1">
            <div class="w-100">
              <label style="font-weight: bold">{{"ChoosePortionSize" | translate}}</label>
              <div class="inputGroup" *ngFor="let category of product.priceCategories">
                <input type="radio" name="portionSize"
                       [attr.id]="category"
                       [value]="category"
                       [(ngModel)]="selectedPortionSize"
                       (ngModelChange)="portionSizeSelected(category)"/>
                <label [attr.for]="category">{{category}}</label>
              </div>
            </div>
          </mdb-carousel-item>

          <mdb-carousel-item *ngIf="sideDishes.length > 0">
            <div class="w-100">
              <label style="font-weight: bold">{{"ChooseSideDish" | translate}}</label>

              <div class="inputGroup" *ngFor="let sideDish of sideDishes; let ind = index;">
                <input type="checkbox"
                       [attr.id]="sideDish.name + ind"
                       [attr.name]="sideDish.name + ind"
                       [(ngModel)]="sideDish.isSelected"
                       (change)="sideDishSelected()"/>
                <label [attr.for]="sideDish.name + ind">
                  {{sideDish.name}} {{sideDish.price > 0 ? ' (' + (sideDish.price | otcurrency) + ')' : ''}}
                </label>
              </div>
            </div>
          </mdb-carousel-item>

          <mdb-carousel-item *ngIf="extras.length > 0">
            <div class="w-100">
              <label style="margin-bottom: 20px;font-weight: bold">{{"ChooseProductExtras" | translate}}</label>
              <div class="d-flex">
                <textarea
                  [attr.placeholder]="'EnterSpecialWishes' | translate"
                  [(ngModel)]="manualExtra"
                  style="width: 100%;border: 1px solid #dedede;margin-bottom: 20px; padding-left: 10px;"
                  class="flex-grow-1">
                </textarea>
                <button class="btn btn-rounded btn-outline-primary ml-1"
                        style="padding: 0;width: 45px;height: 40px;"
                        (click)="showSpecialWishes()">
                  <span style="white-space: nowrap;font-size: 22px;line-height: 11px;vertical-align: super;">...</span>
                </button>
              </div>
              <table style="width: 100%;">
                <thead>
                <tr style="border-bottom: 1px solid #dedede;">
                  <th></th>
                  <th>#</th>
                  <th></th>
                  <th>{{"Extra" |translate}}</th>
                  <th>{{"Price" |translate}}</th>
                </tr>
                </thead>

                <tbody>
                <tr style="border-bottom: 1px solid #dedede;" *ngFor="let extra of extras">
                  <td style="width: 50px; padding: 5px;">
                    <button type="button" class="btn btn-primary m-0" style="padding: 5px 10px;"
                            (click)="increaseCount(extra)">
                      <i class="fa fa-plus white-text" style="font-size:25px; padding-top: 1px;"></i>
                    </button>
                  </td>
                  <td style="width: 35px; padding-right: 5px;">
                    <div style="width: 100%; height:40px; display: flex;align-items: center;justify-content: center;">
                      {{extra.count}}x
                    </div>
                  </td>
                  <td style="width: 50px;">
                    <button type="button" class="btn btn-danger m-0" style="padding: 5px 10px;"
                            (click)="decreaseCount(extra)">
                      <i class="fa fa-minus white-text" style="font-size:25px; padding-top: 2px;"></i>
                    </button>
                  </td>
                  <td style="padding-left: 5px;">{{extra.name}}</td>
                  <td style="width: 60px;">
                    <input type="text" readonly="readonly" style="width: 90%; height:40px; border: none; padding-left: 5px;" [value]="extra.price | otcurrency"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </mdb-carousel-item>

          <mdb-carousel-item *ngIf="ingredients.length > 0">
            <div class="w-100">
              <label style="font-weight: bold">{{"RemoveProductIngredients_Title" |translate}}</label>
              <table style="width: 100%;">
                <tbody>
                <tr style="border-bottom: 1px solid #dedede;" *ngFor="let ingredient of ingredients" (click)="toggleSelect(ingredient, $event)">
                  <td style="padding: 5px;">{{ingredient.name}}</td>
                  <td style="width: 45px; padding: 5px;">
                    <div class="switch round blue-white-switch">
                      <label>
                        <input type="checkbox" readonly
                               [checked]="ingredient.selected"
                               [attr.id]="ingredient.name"/>
                        <span class="lever"></span>
                      </label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </mdb-carousel-item>
        </mdb-carousel>
      </div>
      <!--Footer-->
      <div class="modal-footer align-items-center p-2" style="position: relative;">
        <div style="width: 55px">
          <a mdbBtn floating="true" color="default" mdbWavesEffect class="carousel-control-prev side-dish-control-prev"
             [hidden]="!showPrevButton" (click)="showPrevSlide()">
            <mdb-icon fas icon="angle-left" size="2x"></mdb-icon>
          </a>
        </div>

        <div class="flex-grow-1 d-flex justify-content-center">
          <button class="btn btn-primary float-right toolbar-item" [disabled]="!canAddProduct" (click)="orderProduct()">
            <i class="fa fa-plus mr-1 white-text"></i>
            <span>{{"AddToOrderList" | translate}}</span>
          </button>
        </div>

        <div style="width: 55px">
          <a mdbBtn floating="true" color="default" mdbWavesEffect class="carousel-control-next side-dish-control-next"
             [hidden]="!showNextButton" (click)="showNextSlide()">
            <mdb-icon fas icon="angle-right" size="2x"></mdb-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
