import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ButtonsModule, InputsModule, MdbSelectModule, SelectModule, WavesModule} from "ng-uikit-pro-standard";
import {NgxStripeModule} from "ngx-stripe";
import {environment} from "../../../environments/environment";
import {AppCommonModule} from "../common/common.module";
import {AuthRoutingModule} from "./auth-routing.module";
import {ForgetPasswordComponent} from "./forget-password/forget-password.component";
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
  ],
  exports: [
    LoginComponent,
    RegisterComponent,
  ],
  imports: [
    AuthRoutingModule,
    AppCommonModule,
    SelectModule,
    WavesModule,
    ButtonsModule,
    InputsModule,
    FormsModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    MdbSelectModule,
  ],
})
export class AuthModule {
}
