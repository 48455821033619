import {Injectable} from "@angular/core";
import {PrinterSettings, ReceiptSettings, Table} from "../models";
import {PaymentTypes} from "../models/payment.types.model";
import {LocalStorageService} from "./localstorage.service";
import {MessageService} from "./message.service";

@Injectable({
  providedIn: "root"
})
export class NativeAppService {

  constructor(
    private localStorage: LocalStorageService,
    private messageService: MessageService) {
  }

  public userLoggedOut() {
    console.log("Calling c# method, userLoggedOut");
    if (window["_appContainer"]) {
      window.location.href = "https://userloggedout";
    }
  }

  public async userLoggedIn() {
    console.log("Calling c# method, userLoggedIn");
    if (window["_appContainer"]) {
      const extraInfo = "user=" + encodeURIComponent(JSON.stringify(await this.localStorage.getUserAsync()))
        + "&token=" + await this.localStorage.getValueAsync("jwt_token")
        + "&printerSettings=" + encodeURIComponent(JSON.stringify(await this.localStorage.getPrinterSettingsAsync()))
        + "&receiptSettings=" + encodeURIComponent(JSON.stringify(await this.localStorage.getReceiptSettingsAsync()));
      window.location.href = "https://userloggedin?" + extraInfo;
    }
  }

  public refreshDisplay(table: Table) {
    console.log("Calling c# method, refreshDisplay");
    if (window["_appContainer"]) {
      const extraInfo = "mac=" + table.macAddress
        + "&tableId=" + table.id
        + "&text1=" + encodeURIComponent("You are currently sitting at table " + table.name)
        + "&text2=" + encodeURIComponent("No more waiting\n\nScan the QR Code and start ordering")
        + "&text3=" + encodeURIComponent("Our Happy Hour begins at 22:00")
        + "&text4=" + encodeURIComponent("Average waiting time is 20 minutes.");
      window.location.href = "https://refreshdisplay?" + extraInfo;
    }
  }

  public vibrate() {
    console.log("Calling c# method, vibrate");
    if (window["_appContainer"]) {
      window.location.href = "https://vibrate";
    }
  }

  public printOrderedItems(itemsToPrint: any[], tableName: string, printerSettings: PrinterSettings[]) {
    console.log("Calling c# method, printordereditems");
    if (window["_appContainer"]) {
      window.location.href = "https://printordereditems?" +
        "items=" + encodeURIComponent(JSON.stringify(itemsToPrint)) +
        "&tableName=" + encodeURIComponent(tableName) +
        "&printerSettings=" + encodeURIComponent(JSON.stringify(printerSettings));
    }
  }

  public async printInvoice(invoice: any, printerSettings: PrinterSettings[] = null, printDetails: boolean = false): Promise<any> {
    console.log("Calling c# method, printInvoice");
    if (window["_appContainer"]) {
      if (!printerSettings) {
        printerSettings = await this.localStorage.getPrinterSettingsAsync();
      }

      window.location.href = "https://print_invoice?"
        + "invoice=" + encodeURIComponent(JSON.stringify(invoice))
        + "&printerSettings=" + encodeURIComponent(JSON.stringify(printerSettings))
        + "&receiptSettings=" + encodeURIComponent(JSON.stringify(await this.localStorage.getReceiptSettingsAsync()))
        + "&pd=" + (printDetails ? "1" : "0");
    }
  }

  public updateReceiptSettings(settings: ReceiptSettings) {
    console.log("Calling c# method, receiptsettingssaved");
    if (window["_appContainer"]) {
      window.location.href = "https://receiptsettingssaved?receiptSettings=" + encodeURIComponent(JSON.stringify(settings));
    }
  }

  public printReceipt(invoiceNumber: number, itemsToPrint: any[], paymentViewModel, tableName: string, printerSettings: PrinterSettings[], receiptSettings: ReceiptSettings) {
    console.log("Calling c# method, printreceipt");

    if (window["_appContainer"]) {
      window.location.href = "https://printreceipt?" +
        "items=" + encodeURIComponent(JSON.stringify(itemsToPrint)) +
        "&invoiceNumber=" + invoiceNumber +
        "&paymentViewModel=" + encodeURIComponent(JSON.stringify(paymentViewModel)) +
        "&tableName=" + tableName +
        "&printerSettings=" + encodeURIComponent(JSON.stringify(printerSettings)) +
        "&receiptSettings=" + encodeURIComponent(JSON.stringify(receiptSettings));
    } else {
      this.messageService.error("This is available only on the device");
    }
  }

  public printInvoiceById(invoiceNumber: number, year: number, pd: string, printerSettings: PrinterSettings[], receiptSettings: ReceiptSettings) {
    console.log("Calling c# method, printinvoice");

    if (window["_appContainer"]) {
      window.location.href = "https://printinvoice?" +
        "id=" + invoiceNumber +
        "&year=" + year +
        "&pd=" + pd +
        "&printerSettings=" + encodeURIComponent(JSON.stringify(printerSettings)) +
        "&receiptSettings=" + encodeURIComponent(JSON.stringify(receiptSettings));
    } else {
      this.messageService.error("This is available only on the device");
    }
  }

  public printZReport(reportId: string, printerSettings: PrinterSettings[], supressError = false) {
    console.log("Calling c# method, printZReport");

    this.localStorage.getPaymentTypesAsync().then(paymentTypes => {
      if (window["_appContainer"]) {
        window.location.href = "https://printzreport?" +
          "id=" + reportId +
          "&printerSettings=" + encodeURIComponent(JSON.stringify(printerSettings)) +
          "&paymentTypes=" + encodeURIComponent(JSON.stringify(paymentTypes));
      } else if (!supressError) {
        this.messageService.error("This is available only on the device");
      }
    });
  }

  public updateServerUrl(url: string) {
    window.location.href = "https://updateserverurl?" +
      "url=" + url;
  }

  public refreshBluetoothPrinters() {
    console.log("Calling c# method, refreshBluetoothPrinters");

    if (window["_appContainer"]) {
      window.location.href = "https://getposprinters";
    }
  }
}
