import {BaseModel} from "./base.model";

export class ReceiptSettings extends BaseModel {
  public headerBeforeLogo: string;

  public logo: string;

  public headerAfterLogo: string;

  public telephone: string;

  public taxNumber: string;

  public headerAfterAddress: string;

  public invoiceNumber: string;

  public invoiceDate: string;

  public server: string;

  public netPrice: string;

  public discount: string;

  public cardFee: string;

  public totalPrice: string;

  public barPayment: string;

  public cardPayment: string;

  public voucherPayment: string;

  public combinedPayment: string;

  public payBack: string;

  public footer: string;

  public taxRate: string;

  public taxNetPrice: string;

  public taxPrice: string;
}
