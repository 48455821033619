import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ModalDirective} from "ng-uikit-pro-standard";
import {v4 as uuid} from "uuid";
import {ModalClassComponent} from "../../../../interfaces/ModalClassComponent";
import {Table, TableCategory, UserSettings} from "../../../../models";
import {VatDefinition} from "../../../../models/vat.definition.model";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import { ModalService } from "../../../../services/modal.service";
import {TableService} from "../../../../services/table.service";

@Component({
  selector: "app-create-table-dialog",
  templateUrl: "./create-table-dialog.component.html",
  styleUrls: ["./create-table-dialog.component.scss"],
})
export class CreateTableDialogComponent extends ModalClassComponent implements OnInit {
  @ViewChild("modal")
  public modal: ModalDirective;

  @ViewChild("nameRef")
  public nameInputRef: ElementRef;

  public table: Table = new Table();
  public category: TableCategory;
  public onTableAdded: any;

  public tableCategories: Array<{value, label}> = [];
  public userSettings: UserSettings = new UserSettings();
  public isUpdate = false;

  public vatDefinitions: any[];

  constructor(
    private modalService: ModalService,
    private tableService: TableService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
  ) {
    super();
  }

  public async ngOnInit() {
    this.modalService.register("create-table-dialog", this);
    this.localStorage.getUserSettingsAsync().then(res => {
      this.userSettings = res;
    });

    this.localStorage.getVatDefinitions().then((res: VatDefinition[]) => {
      if(res != null ) {
        this.vatDefinitions = [];
        this.vatDefinitions.push({value: null, label: this.translateService.instant("NoTaxRate")});
        res.forEach(entry => {
          if(this.vatDefinitions.find(e => e.value == entry.percentage) == null)
            this.vatDefinitions.push({value: entry.percentage, label: entry.percentage + "%"});
        });
      }
    });
  }

  public hide() {
    this.modal.hide();
    super.hide();
  }

  public async show(params: any): Promise<any> {
    super.show(params);
    this.onTableAdded = params.onTableAdded;
    this.category = params.category;
    this.isUpdate = params.isUpdate;

    this.tableCategories = [];
    (await this.localStorage.getTableCategoriesAsync()).forEach((category) => {
      this.tableCategories.push({
        value: category.id,
        label: category.name,
      });
    });

    if (params.table != null) {
      this.table = params.table;
    } else {
      this.table = new Table();
    }

    this.modal.show();
    setTimeout(
      () => {
        this.nameInputRef.nativeElement.focus();
      },
      300,
    );
  }

  public addTable() {
    if (!this.table.name) {
      return;
    }

    let isNewTable = false;
    if (!this.table.id) {
      isNewTable = true;
      this.table.id = uuid();
      this.table.categoryId = this.category.id;
    }

    this.tableService.addTable(this.table).subscribe((table) => {
      this.messageService.success(this.translateService.instant(isNewTable ? "AddTableSuccess": "UpdateTableSuccess"));

      if (this.onTableAdded) {
        this.onTableAdded(table);
      }

      if (isNewTable) {
        this.table = new Table();
        this.nameInputRef.nativeElement.focus();
      } else {
        this.hide();
      }
    }, (error) => {
      this.messageService.error(error);
    });
  }

  public getSelectedCategory(id: any) {
    this.table.categoryId = id;
  }
}
