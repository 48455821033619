<div class="card mx-auto p-0 mt-2 export-header" style="width:90%">
  <div class="card-body">
    <div class="row m-0 p-0">
      <div class="col-md-6 col-sm-12 form-group row m-0 p-0">
        <div class="row m-0 p-0">
          <label class="col-md-4 col-sm-12 col-form-label font-weight-bold" for="startingDate">{{"DatevExportStartDate"| translate}}</label>
          <div class="row col-md-8 m-0 p-0">
            <div class="col-7">
              <mdb-date-picker name="startingDate" id="startingDate"
                               [options]="datePickerOptions"
                               [placeholder]="'ChooseDatePlaceHolder' | translate" [(ngModel)]="startDateStr"
                               (dateChanged)="startDateUpdated($event)"
                               [locale]="currentLocale"
                               required></mdb-date-picker>
              <i class="datepicker-inline-icon far fa-calendar-alt"></i>
            </div>
            <div class="col-5 time-picker-container">
              <mdb-timepicker-toggle [mdbTimePickerToggle]="startTimePicker"></mdb-timepicker-toggle>
              <mdb-time-picker
                #startTimePicker
                [buttonClear]="false"
                [buttonLabel]="doneLabel"
                [(ngModel)]="startTimeStr"></mdb-time-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 form-group row m-0 p-0">
        <div class="row m-0 p-0">
          <label class="col-md-4 col-sm-12 col-form-label font-weight-bold" for="startingDate">{{"DatevExportEndDate"| translate}}</label>
          <div class="row col-md-8 m-0 p-0">
            <div class="col-7">
              <mdb-date-picker name="endDate" id="endDate"
                               [options]="datePickerOptions"
                               [placeholder]="'ChooseDatePlaceHolder' | translate" [(ngModel)]="endDateStr"
                               (dateChanged)="endDateUpdated($event)"
                               [locale]="currentLocale"
                               required></mdb-date-picker>
              <i class="datepicker-inline-icon far fa-calendar-alt"></i>
            </div>
            <div class="col-5 time-picker-container">
              <mdb-timepicker-toggle [mdbTimePickerToggle]="endTimePicker"></mdb-timepicker-toggle>
              <mdb-time-picker
                #endTimePicker
                [buttonClear]="false"
                [buttonLabel]="doneLabel"
                [(ngModel)]="endTimeStr"></mdb-time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button type="button" class="btn btn-primary btn-rounded float-right toolbar-item" (click)="exportWaiterDayEndReport()">
        <i class="fas fa-file-export mr-1" style="font-size:16px;"></i>
        <span>{{"Export" | translate}}</span>
      </button>
    </div>
  </div>
</div>

<div id="export-list" class="mt-3 ml-auto mr-auto" style="width:90%;" [hidden]="reports.length == 0">
  <div class="row ml-2 mt-2 mb-1">
    <mdb-checkbox class="mr-4" *ngFor="let payment of paymentTypes" [ngModel]="selectedPaymentTypes.indexOf(payment.name) > -1" (ngModelChange)="setSelectedPaymentType(payment, $event)">{{payment.label}}</mdb-checkbox>
  </div>
  <table id="datevExportTable" mdbTable #tableEl="mdbTable" stickyHeader="true" hover="true" striped="true" class="z-depth-1">
    <thead>
    <tr>
      <th *ngFor="let head of columns; let i = index" aria-controls="datevExportTable" scope="col" [mdbTableSort]="reports" [sortBy]="columns[i].key">{{head.name | translate}}
        <mdb-icon fas icon="sort" [hidden]="head.sortable === false"></mdb-icon>
      </th>
    </tr>
    </thead>
    <tbody #row>
    <tr mdbTableCol *ngFor="let report of reports; let i = index">
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{report['startDate']}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{report['endDate']}}</td>
      <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex">{{report['employeeName']}}</td>

      <td *ngFor="let payment of selectedPaymentTypes">{{ report.paymentTypesSum[payment] }}</td>
    </tr>
    </tbody>
    <tfoot class="grey lighten-5 w-100">
    <tr>
      <td [attr.colspan]="3 + paymentTypes.length">
        <mdb-table-pagination [tableEl]="tableEl" paginationAlign="" [searchDataSource]="reports"></mdb-table-pagination>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="reports.length == 0" class="text-info informative-text ml-auto mr-auto mt-3" role="alert" style="width:90%;;">
  {{"DatevExportNotFound" | translate}}
</div>
<app-send-email-dialog></app-send-email-dialog>
