import {Directive, ElementRef, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Directive({
  selector: "[validateForm]",
})
export class FormValidationDirective  implements OnInit {
  constructor(
    private el: ElementRef,
    private translateService: TranslateService) {
  }

  public ngOnInit() {
    this.validate(this.el.nativeElement);
  }

  public validate(formElement) {
    const elementsToValidate = formElement.querySelectorAll("input, textarea, select");
    elementsToValidate.forEach((el) => {
      this.setCustomValidity(el);
    });

    formElement.addEventListener("submit", function() {
      const invalidFields: NodeListOf<HTMLElement> = formElement.querySelectorAll(":invalid");
      if (invalidFields.length > 0) {
        invalidFields.item(0).focus();
      }
    });
  }

  public setCustomValidity(element) {
    if (element.type === "button" || element.type === "hidden") { return; }

    element.addEventListener("change", (event) => {this.setLocalizedValidationMessage(event, this.translateService); }, false);
    element.addEventListener("keyup", (event) => {this.setLocalizedValidationMessage(event, this.translateService); }, false);
    element.dispatchEvent(new Event("keyup"));
  }

  public setLocalizedValidationMessage(e, translateService) {
    // manually validated
    if (!e.currentTarget.validity.customValidated) {

      // 'setCustomValidity not only sets the message, but also marks
      // the field as invalid. In order to see whether the field is really invalid
      e.currentTarget.setCustomValidity("");
      e.currentTarget.dataset.originalTitle = e.currentTarget.title = "";
    }

    if (e.currentTarget.validity.valid) {
      e.currentTarget.setCustomValidity("");

      if (!e.currentTarget.getAttribute("destroyed")) {
        e.currentTarget.setAttribute("destroyed", "destroyed");
      }
    } else {
      if (e.currentTarget.validity.badInput) {
        if (e.currentTarget.type === "email") {
          e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("NotValidEmail");
        } else if (e.currentTarget.type === "number") {
          e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("NumericValueValidationError");
        }
      } else if (e.currentTarget.validity.rangeOverflow) {
        const maxValue = parseFloat(e.currentTarget.max);
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("MaxValueValidationError").printf([maxValue]);
      } else if (e.currentTarget.validity.rangeUnderflow) {
        const minValue = parseFloat(e.currentTarget.min);
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("MinValueValidationError").printf([minValue]);
      } else if (e.currentTarget.validity.tooLong) {
        const maxLength = e.currentTarget.maxLength;
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("MaxValueMissing").printf([maxLength]);
      } else if (e.currentTarget.validity.tooShort) {
        const minLength = e.currentTarget.minLength;
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("MinValueMissing").printf([minLength]);
      } else if (e.currentTarget.validity.patternMismatch) {
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("PatternValueMismatchError");
      } else if (e.currentTarget.validity.typeMismatch) {
        if (e.currentTarget.type === "email") {
          e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("NotValidEmail");
        }
      } else if (e.currentTarget.validity.valueMissing) {
        e.currentTarget.dataset.originalTitle = e.currentTarget.title = translateService.instant("RequiredValueMissing");
      }
      e.currentTarget.setCustomValidity("");
    }

    if (e.currentTarget.value) { e.currentTarget.classList.add("active"); } else { e.currentTarget.classList.remove("active"); }
  }
}
