  <mdb-card class="product-single-view m-2">
  <mdb-card-body style="text-align: center;">
    <div id="product-images" class="product-images">
      <mdb-carousel *ngIf="images.length > 1" [isControls]="true" [animation]="'slide'" [interval]="5000">
        <mdb-carousel-item *ngFor="let image of images" class="justify-content-center align-items-center">
          <object [data]="getThumbnail(image.url) | sanitizeUrl" type="image/jpeg" class="d-flex justify-content-center align-items-center" style="height:150px;">
            <img class="d-block mw-100 ml-auto mr-auto" src="{{image.url}}" alt="Product image" style="max-height: 150px;">
          </object>
        </mdb-carousel-item>
      </mdb-carousel>

      <object *ngIf="images.length == 1" [data]="images[0].thumbnailUrl | sanitizeUrl" type="image/jpeg" style="height:130px;">
        <img *ngIf="images.length == 1" class="d-block mw-100 ml-auto mr-auto" src="{{images[0].url}}" alt="Product image" style="max-height: 150px;">
      </object>

    </div>
    <div class="product-data">
      <label class="lbl-product-name">{{product.productName}}</label>
      <div class="product-price">
        <label *ngIf="product.unitPrices?.length < 2" class='product-single-price'>{{ formattedUnitPrices[0] }}</label>
        <div *ngIf="product.unitPrices?.length > 1" class='table-responsive'>
          <table class='table table-bordered m-0'>
            <thead>
            <tr>
              <th *ngFor="let price of product.priceCategories">{{price}}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td *ngFor="let price of formattedUnitPrices">{{ price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mdb-card-body>
  <mdb-card-footer class="d-flex justify-content-center">
    <div class="dropdown" mdbDropdown dynamicPosition="true">
      <a href="javascript:void(0)" mdbDropdownToggle rounded="true" class="dropdown-toggle waves-light w-100 btn-table-action" mdbWavesEffect>
        {{"Actions" | translate}}
      </a>

      <div class="dropdown-menu">
        <a href="javascript:void(0);" class="dropdown-item" (click)="showUpdateProductDialog()">
          <i class="fas fa-pencil-alt mr-1" style="font-size: 18px;color:inherit"></i>
          <span>{{"EditProduct" | translate}}</span>
        </a>
        <a href="javascript:void(0);" class="dropdown-item" (click)="showCloneProductDialog()">
          <span class="fa fa-clone mr-1" style="font-size: 18px;color:inherit"></span>
          <span>{{"CloneProduct" | translate}}</span>
        </a>
        <a href="javascript:void(0);" class="dropdown-item danger-text" (click)="showDeleteProductConfirmation()">
          <i class="far fa-trash-alt mr-1" style="font-size: 18px;color:inherit"></i>
          <span>{{"DeleteProduct" | translate}}</span>
        </a>
      </div>
    </div>
  </mdb-card-footer>
</mdb-card>


