<div class="module-settings">
  <div class="container" style="padding: 0px;">
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="action-toolbar card justify-content-between flex-row" style="margin-bottom: 3px;flex-direction:row">
          <div class="flex-grow-1 mb-sm-2">
            <div class="d-flex align-items-center" style="max-width: 350px;">
              <label class="pr-3 m-0" style="white-space: nowrap;">
                <span>{{"PaperWidth" | translate}}</span>
              </label>
              <mdb-select name="roles" [options]="printerSizes" [placeholder]="'ChoosePaperWidth' | translate" [(ngModel)]="printerSize"></mdb-select>
            </div>
          </div>
          <button mdbBtn color="primary" outline="true" rounded="true" mdbWavesEffect (click)="showPaymentTypes()" class="toolbar-item background-white">
            <i class="far fa-edit" style="font-size:15px; margin-right: 3px;"></i>
            <span>{{"PaymentTypes" | translate}}</span>
          </button>
          <button mdbBtn color="primary" outline="true" rounded="true" mdbWavesEffect (click)="saveSettings()" class="toolbar-item background-white">
            <i class="far fa-save" style="font-size:15px; margin-right: 3px;"></i>
            <span>{{"Update" | translate}}</span>
          </button>
          <button mdbBtn color="primary" outline="true" rounded="true" mdbWavesEffect (click)="printSettings()" class="btn-settings-button toolbar-item background-white">
            <i class="fas fa-print" style="font-size:15px; margin-right: 3px;"></i>
            <span>{{"Print" | translate}}</span>
          </button>
        </div>

        <div class="d-flex receipt-tab-container" style="margin: 20px;">
          <div class="receipt-settings-container ml-auto mr-auto" style="top: 0px !important;">
            <div class="settings-receipt-container" [ngClass]="{'extra-small': printerSize == 1, 'small': printerSize == 2, 'large': printerSize == 3, 'extra-large': printerSize == 4}">
              <app-inline-editor [classes]="'font-a alignment-center full-width'"
                                 [(model)]="receiptSettings.headerBeforeLogo"
                                 [helpText]="'ReceiptHeaderBeforeLogo' | translate"
                                 [inputType]="'textarea'">
              </app-inline-editor>
              <div class="blocker"></div>

              <div class="receipt-logo-container">
                <dropzone [config]="dropzoneConfig" [message]="'ReceiptLogoHelp' | translate" (init)="initDropzone($event)" (complete)="onUploadSuccess($event)"></dropzone>
                <button type="button" class="btn btn-danger receipt-logo-remove" (click)="removeLogo()" *ngIf="receiptSettings.logo">
                  <i class="far fa-trash-alt" aria-hidden="true"></i>
                </button>
                <div class="receipt-logo-loader css-loader" [hidden]="!logoLoading"></div>
              </div>
              <div class="blocker"></div>
              <app-inline-editor [classes]="'font-a alignment-center full-width'"
                                 [(model)]="receiptSettings.headerAfterLogo"
                                 [helpText]="'ReceiptHeaderAfterLogo' | translate"
                                 [inputType]="'textarea'">
              </app-inline-editor>
              <div class="blocker"></div>

              <div class="font-a double-height emphasizing-on alignment-center">{{user.restaurantName}}</div>
              <div class="font-a alignment-center">{{user.address}}</div>
              <div class="font-a alignment-center">{{user.zip + ' ' + user.city}}</div>
              <div class="font-a alignment-center">
                <app-inline-editor [(model)]="receiptSettings.telephone"></app-inline-editor>
                <span *ngIf="user.phoneNumber">{{user.phoneNumber}}</span>
              </div>
              <div class="font-a alignment-center">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.taxNumber">
                </app-inline-editor>
                <span *ngIf="user.taxId">{{user.taxId}}</span>
              </div>
              <div class="blocker"></div>

              <app-inline-editor [classes]="'font-a alignment-center full-width'"
                                 [(model)]="receiptSettings.headerAfterAddress"
                                 [helpText]="'ReceiptHeaderAfterAddress' | translate"
                                 [inputType]="'textarea'">
              </app-inline-editor>
              <div class="blocker"></div>

              <div class="font-a d-flex justify-content-between">
                <div>
                  <app-inline-editor [classes]="'font-a alignment-center'"
                                     [(model)]="receiptSettings.invoiceNumber"
                                     [helpText]="'ReceiptInvoiceNumber' | translate">
                  </app-inline-editor>
                  <span> 123</span>
                </div>
                <span>{{"Table" | translate}}1</span>
              </div>
              <div class="font-a">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.invoiceDate"
                                   [helpText]="'ReceiptInvoiceDate' | translate">
                </app-inline-editor>
                <span>{{todayDate}}</span>
              </div>
              <div class="font-a">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.server"
                                   [helpText]="'ReceiptInvoiceOperator' | translate">
                </app-inline-editor>
                <span>{{user.firstName + ' ' + user.lastName}}</span>
              </div>
              <div class="blocker"></div>
              <div class="separator"></div>
              <div class="font-a d-flex justify-content-between">
                <span>2x Espresso</span>
                <span>4.40(1)</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <span>1x Pizza Fungi</span>
                <span>7.50(1)</span>
              </div>
              <div class="separator"></div>
              <div class="font-a d-flex justify-content-between">
                <div>
                  <app-inline-editor [classes]="'font-a alignment-center'"
                                     [(model)]="receiptSettings.netPrice"
                                     [helpText]="'ReceiptInvoiceSubTotal' | translate">
                  </app-inline-editor>
                  <span>{{user.currency}}</span>
                </div>
                <span>11.12</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <span style="padding-left: 20px;">(1)={{userSettings.tax}}%</span>
                <span>0.78</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.cardFee"
                                   [helpText]="'ReceiptInvoiceCardFee' | translate">
                </app-inline-editor>
                <span>0.0</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.discount"
                                   [helpText]="'ReceiptInvoiceDiscount' | translate">
                </app-inline-editor>
                <span>0.0</span>
              </div>
              <div class="separator"></div>
              <div class="font-a d-flex justify-content-between">
                <div class="d-flex">
                  <app-inline-editor [classes]="'font-a alignment-center double-height d-block mr-2'"
                                     [(model)]="receiptSettings.totalPrice"
                                     [helpText]="'ReceiptInvoiceTotal' | translate">
                  </app-inline-editor>
                  <span class="double-height">{{user.currency}}</span>
                </div>
                <span class="double-height">11.90</span>
              </div>
              <div class="blocker"></div>
              <div class="font-a d-flex justify-content-between" *ngFor="let paymentType of paymentTypes">
                <span>{{paymentType.label}}</span>
                <span>11.90</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.combinedPayment"
                                   [helpText]="'ReceiptInvoicePaymentTypeCombined' | translate">
                </app-inline-editor>
                <span>11.90</span>
              </div>
              <div class="font-a d-flex justify-content-between">
                <app-inline-editor [classes]="'font-a alignment-center'"
                                   [(model)]="receiptSettings.payBack"
                                   [helpText]="'ReceiptInvoicePayBack' | translate">
                </app-inline-editor>
                <span>0</span>
              </div>
              <div class="blocker"></div>
              <app-inline-editor [classes]="'font-a alignment-center full-width'"
                                 [helpText]="'ReceiptInvoiceFooter' | translate"
                                 [(model)]="receiptSettings.footer"
                                 [inputType]="'textarea'">
              </app-inline-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-payment-types-dialog></app-payment-types-dialog>
