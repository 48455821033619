import { ModalService } from './../../../../services/modal.service';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as dayjs from "dayjs";
import * as html2pdf from "html2pdf.js";
import {Subscription} from "rxjs";

import {BlockUI, NgBlockUI} from "ng-block-ui";
import {OrderStatus} from "../../../../enums/order.status.enum";
import {DayEndReportStatistic, User} from "../../../../models";
import { InvoiceItem } from "../../../../models/invoice-item.model";
import {InvoiceModel} from "../../../../models/invoice.model";
import {LocalStorageService} from "../../../../services/localstorage.service";
import {MessageService} from "../../../../services/message.service";
import {NativeAppService} from "../../../../services/native-app.service";
import {ReportService} from "../../../../services/report.service";
import {SubscriptionService} from "../../../../services/subscription.service";

@Component({
  selector: "app-receipt-overview",
  templateUrl: "./receipt-overview.component.html",
  styleUrls: ["./receipt-overview.component.scss"],
})
export class ReceiptOverviewComponent implements OnInit, OnDestroy {
  @BlockUI() public blockUI: NgBlockUI;

  public receipt: InvoiceModel = new InvoiceModel();
  public cancelActivated: boolean;
  public isEditable: boolean;
  public isCombinedPayment: boolean;
  public showPrintDetails: boolean;
  public showHostingSplit: boolean;

  public orderStatus: typeof OrderStatus = OrderStatus;
  public localizedCombinedTypes: string;
  public receiptSelectedSubscription: Subscription;
  public dayEndStatistic: DayEndReportStatistic;
  public isAdmin: boolean;
  public user: User;

  public paymentType = "";
  public paymentTypes = [];

  constructor(
    private localStorage: LocalStorageService,
    private translateService: TranslateService,
    private subscriptionService: SubscriptionService,
    private reportService: ReportService,
    private messageService: MessageService,
    private nativeAppService: NativeAppService,
    private modalService: ModalService,
  ) { }

  public async ngOnInit() {
    this.user = await this.localStorage.getUserAsync();
    this.isAdmin = this.user.role === "Administrator" || this.user.role === "AdminHelper";

    const userSettings = await this.localStorage.getUserSettingsAsync();
    this.showHostingSplit = userSettings?.allowHostingSlip || false;

    this.paymentTypes = await this.localStorage.getPaymentTypesAsync();

    this.dayEndStatistic = await this.localStorage.getDayEndStatisticAsync();

    this.receiptSelectedSubscription = this.subscriptionService.receiptSelectedEvent.subscribe((receipt) => {
      this.receipt = receipt;
      this.paymentType = this.receipt.paymentType;
      this.localizedCombinedTypes = this.getLocalizedCombinedTypes();

      this.setTaxCategoryIndexes();
      this.isEditable = !this.isReceiptLocked() && this.isAdmin;
    });
  }

  public ngOnDestroy(): void {
    this.receiptSelectedSubscription?.unsubscribe();
  }

  public isReceiptLocked() {
    if (!this.dayEndStatistic) { return false; }

    const orderDate = dayjs(this.receipt.date);
    return orderDate.isBefore(this.dayEndStatistic.lastReportDate) || this.receipt.totalPriceGross < 0;
  }

  public hideOverview() {
    this.subscriptionService.receiptSelected(new InvoiceModel());
  }

  public selectItem(item: InvoiceItem) {
    if (item.status === OrderStatus.cancelled) { return; }

    if (item["selectionCount"] === item.quantity) {
      item["selectionCount"] = 0;
    } else {
      item["selectionCount"] = item["selectionCount"] + 1;
    }
  }

  public setTaxCategoryIndexes() {
    let categoryIndex = 1;
    this.receipt?.taxAmounts?.forEach((taxInfo) => {
      taxInfo["taxIndex"] = categoryIndex;

      const items = this.receipt.invoiceItems?.filter((item) => item.taxRate === taxInfo.taxRate);
      items.forEach((item) => {
        item["taxIndex"] = categoryIndex;
      });

      categoryIndex++;
    });
  }

  public getLocalizedCombinedTypes() {
    if (this.receipt.paymentType !== "CombinedPayment") { return ""; }

    const methodsArr = Object.keys(this.receipt.combinedPaymentTypes);
    const localizedArr = [];
    methodsArr.forEach((method) => {
      const payment = this.paymentTypes.find(p => p.name == method);
      localizedArr.push(payment ? payment.label : this.translateService.instant(method));
    });

    return "(" + localizedArr.join(", ") + ")";
  }

  public showCancelOrders() {
    this.cancelActivated = true;
    this.receipt.invoiceItems.forEach((item) => {
      item["selectionCount"] = 0;
    });
  }

  public backToOverview() {
    this.cancelActivated = false;
  }

  public cancelOrders() {
    const selectedItems = this.receipt.invoiceItems.filter((item) => item["selectionCount"] > 0);
    if (selectedItems.length === 0) {
      this.messageService.error(this.translateService.instant("ChooseAtLeastOneItem"));
      return;
    }

    const cancelledOrderedIds = {};
    selectedItems.forEach((item) => {
      cancelledOrderedIds[item.id] = item["selectionCount"];
    });

    const invoiceDate = dayjs(this.receipt.date);

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.reportService.cancelOrders(cancelledOrderedIds, this.receipt.invoiceNumber, invoiceDate.toISOString()).subscribe((result) => {
      if (result.oldInvoice) {
        if (!result.oldInvoice.combinedPaymentTypes) { result.oldInvoice.combinedPaymentTypes = {}; }
        this.receipt = result.oldInvoice;
        this.isEditable = !this.isReceiptLocked() && this.isAdmin;
        this.subscriptionService.receiptUpdated(result.oldInvoice);
      }

      if (result.newInvoice) {
        if (!result.newInvoice.combinedPaymentTypes) { result.newInvoice.combinedPaymentTypes = {}; }
        this.subscriptionService.receiptAdded(result.newInvoice);
      }

      this.blockUI.stop();
      this.cancelActivated = false;
      this.messageService.success(this.translateService.instant("CancellationSuccess"));

    }, (err) => {
      this.blockUI.stop();
      this.messageService.error(err);
    });
  }

  public saveInvoicePaymentType() {
    if (this.user.fiskalyOrganisationId) {
      const confirmModel = {
        message: this.translateService.instant("UpdatePaymentTypeWithTSE"),
        confirmed: () => {
          this.updatePayment();
        },
      };

      this.modalService.show("confirm-dialog", confirmModel);
    } else {
      this.updatePayment();
    }
  }

  public updatePayment() {
    const invoiceNumber = this.receipt.invoiceNumber;
    const date = dayjs(this.receipt.date).toISOString();

    this.blockUI.start(this.translateService.instant("PleaseWait"));
    this.reportService.updateReceiptPaymentType(invoiceNumber, date, this.paymentType).subscribe((receipts) => {
      this.blockUI.stop();
      this.messageService.success(this.translateService.instant("PaymentUpdateSuccess"));

      if(receipts.length > 0) {
        this.receipt = receipts[0];
        this.subscriptionService.receiptUpdated(this.receipt);
      }
      if(receipts.length > 1) {
        this.subscriptionService.receiptAdded(receipts[1]);
      }
      if(receipts.length > 2) {
        this.subscriptionService.receiptAdded(receipts[2]);
      }

    }, (err) => {
      this.blockUI.stop();
      this.messageService.error(err);
    });
  }

  public async printReceipt(): Promise<any> {
    await this.nativeAppService.printInvoice(this.receipt, await this.localStorage.getPrinterSettingsAsync(), this.showPrintDetails);
  }

  public exportAsPdf() {
    const element = document.getElementById("receipt-overview");
    const opt = {
      margin:       1,
      filename:     `${this.translateService.instant("InvoiceNumber")}${this.receipt.invoiceNumber}.pdf`,
      image:        { type: "jpeg", quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  }
}
